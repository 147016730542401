import {
  action, computed, observable
} from 'mobx';
import type {
  Mesh, MeshBasicMaterial, Object3D, OrthographicCamera, PerspectiveCamera, Vector2
} from 'three';
import {
  AxesHelper, EventDispatcher, GridHelper, Scene
} from 'three';
import type { Font } from 'three/examples/jsm/loaders/FontLoader';
import flatMap from 'lodash/flatMap';
import memoize from 'lodash/memoize';
import throttle from 'lodash/throttle';
import type Store from '../../stores/Store';
import type { Segment } from '../../domain/graphics/Segment';
import type { Vector2D } from '../../domain/typings';
import {
  EApplicationContext, ECursor
} from '../../domain/typings';
import { DesignStep } from '../../domain/models/Design/DesignState';
import type DomainStore from '../DomainStore/DomainStore';
import type MapStore from '../UiStore/MapStore/MapStore';
import type { IWorkspace } from '../UiStore/WorkspaceStore/types';
import { isProjectWorkspace } from '../UiStore/WorkspaceStore/utils';
import { fit } from '../../domain/models/Limit';
import type { Stringing } from '../../domain/graphics/stringing/Stringing';
import { getCanvasFont } from '../../utils/fonts';
import BaseImageryProvider from '../../domain/typings/BaseImageryProvider';
import type { ServiceBus } from '../ServiceBus/ServiceBus';
import type { IUpdateBaseImageryCommandDependencies } from '../ServiceBus/Commands/UpdateBaseImageryCommand';
import type { Drawable } from '../../domain/mixins/Drawable';
import type { PolygonDrawable } from '../../domain/mixins/PolygonDrawable';
import type { Unzoomable } from '../../domain/mixins/Unzoomable';
import type { Marker } from '../../domain/models/SiteDesign/Marker';
import {
  SceneObjectType, getSiteEquipmentMarkerTypes
} from '../../domain/models/Constants';
import { getLyraModelByMesh } from '../../domain/sceneObjectsWithLyraModelsHelpers';
import type PvModulePosition from '../../domain/models/SiteDesign/PvModulePosition';
import type { RoofFace } from '../../domain/models/SiteDesign/RoofFace';
import { BaseControl } from './Controls/BaseControl';
import CursorController from './CursorController';
import { ViewPort } from './ViewportController';
import {
  MAXIMUM_ZOOM_LEVEL, ZOOM_LIMIT
} from './constants';
import { CameraControls } from './Controls/CameraControls';
import type { IApplicationContextChangeEvent } from './Controls/ControlEvents';
import type { ISiteData } from 'domain/entities/SiteDesign/Site';
import type { SiteEquipmentItemKeyName } from '../../domain/models/SiteDesign/SiteEquipmentTypesAndHelpers';

class EditorStore extends EventDispatcher<{
  canvasReady: {};
  application_control_state_change: IApplicationContextChangeEvent;
}> {
  /**
   * Keys in renderingDistanceBoundaries must match respective keys in Constants.SceneObjectType
   */
  readonly renderingDistanceBoundaries: { [key in SceneObjectType]?: number } = {
    [SceneObjectType.Compass]: 50,
    [SceneObjectType.MainServicePanel]: 75,
    // Utility meter and meter-main will never be in the same design, so it's safe to give them equivalent values
    [SceneObjectType.UtilityMeter]: 80,
    [SceneObjectType.MeterMain]: 80,
    [SceneObjectType.GasMeter]: 85,
    [SceneObjectType.Subpanel]: 90,
    [SceneObjectType.Street]: 95,
    [SceneObjectType.ElectricalEquipmentMarker]: 100,
    [SceneObjectType.PotentialInfo]: 150
  };

  baseMouseControl!: BaseControl;
  font!: Font;
  domain!: DomainStore;

  @observable
  currentZoom: number = 1;
  viewport!: ViewPort;

  showHelpers: boolean = false;

  @observable
  streetModal: boolean = false;

  @observable
  mouseClicksIgnoringTransparentLayerTriggeredWarningMessage = {
    visibility: false,
    message: ''
  };

  @observable mapZoomFactor: number = 0;

  applicationContext!: EApplicationContext;
  isBackgroundLoaded: boolean = false;

  backgroundScene?: Scene; // this one contains only the google satellite image texture on a plane
  scene?: Scene; // This one contains everything else
  private serviceBus!: ServiceBus;
  private map!: MapStore;

  private _cameraControls?: CameraControls;
  private cursorController!: CursorController;

  private editorSetupSuccess!: () => void;
  private lastProjectId?: string;
  private lastSiteImagery?: ISiteData['imagery'];

  editorSetupPromise?: Promise<void>;
  overrideShiftIsPressed: boolean = false;

  private cachedUuidToObject = memoize((uuid: string): Object3D | unknown => {
    return this.scene!.getObjectByProperty('uuid', uuid);
  });

  init(root: Store): void {
    this.domain = root.domain;
    this.map = root.map;
    this.serviceBus = root.serviceBus;

    this.applicationContext = EApplicationContext.DRAW_CONTEXT;
    this.loadAssets();

    this.editorSetupPromise = new Promise((resolve, reject): void => {
      this.editorSetupSuccess = resolve;
    });
  }

  @computed
  get rendererDom(): HTMLCanvasElement {
    return this.viewport.renderer.domElement;
  }

  get canvasParent(): HTMLElement {
    return this.rendererDom.parentElement!;
  }

  @computed
  get scaleFactor(): number {
    return 1 / this.currentZoom;
  }

  @action.bound
  setMouseClicksIgnoringTransparentLayerTriggeredWarningMessage(
    visibility: boolean,
    message?: string | undefined
  ): void {
    this.mouseClicksIgnoringTransparentLayerTriggeredWarningMessage.visibility = visibility;
    this.mouseClicksIgnoringTransparentLayerTriggeredWarningMessage.message = message ?? '';
  }

  get cameraControls(): CameraControls | undefined {
    return this._cameraControls;
  }

  get activeCamera(): OrthographicCamera | PerspectiveCamera | undefined {
    return this._cameraControls?.activeCamera;
  }

  get minimalRenderDistance(): number | undefined {
    return this._cameraControls?.minimalRenderDistance;
  }

  @action.bound
  setMapZoomFactor(newMapZoomFactor: number): void {
    this.mapZoomFactor = newMapZoomFactor;
  }

  toggleCanvasLoaderCursorState(isLoading: boolean): void {
    this.rendererDom.style.cursor = isLoading ? 'wait' : 'default';
  }

  beforeRemount(): void {
    this.backgroundScene!.clear();
    this.scene!.clear();
    this.baseMouseControl.deactivate();
    this._cameraControls!.deactivate();
  }

  afterRemount(): void {
    this.baseMouseControl.activate();
    this._cameraControls!.updateCanvas(this.rendererDom);
    this._cameraControls!.activate();
    // We don't have to activate base/selection/drag control, as it's done by workspace store
  }

  setup(viewportSize: Vector2D, canvasParent: HTMLElement, testEnv: boolean = false): void {
    // Initial setup happens only once when the app is loaded. Subsequent project changes do
    // call this method, but do not require full reinitialization.
    let initialSetup = !this.scene;
    if (initialSetup) {
      this.scene = new Scene();
      this.scene.name = 'lyra_scene';
      this.scene.matrixWorldAutoUpdate = false;

      this.backgroundScene = new Scene();
      this.backgroundScene.name = 'lyra_background_satellite_scene';
      this.backgroundScene.matrixWorldAutoUpdate = false;

      this.viewport = new ViewPort(this);
      this.viewport.setup(viewportSize, testEnv);
    }
    if (this.showHelpers) {
      this.configureHelpers();
    }

    if (!canvasParent.contains(this.rendererDom)) {
      canvasParent.appendChild(this.rendererDom);

      if (!initialSetup) {
        this.afterRemount();
      }
    }

    if (initialSetup) {
      canvasParent.appendChild(this.rendererDom);

      this._cameraControls = new CameraControls(this.rendererDom);
      this._cameraControls.signals.cameraPropsChange.add(this.updateUnzoomable);
      this._cameraControls.activate();
      this.cursorController = new CursorController(this.canvasParent);
      this.baseMouseControl = BaseControl.getInstance(this, this.viewport, this.activeCamera);

      this.viewport.addControls(this._cameraControls);
      this.viewport.addControls(this.baseMouseControl);
    }

    // Subscribe events after setting up THREE.js and canvas context
    this.dispatchEvent({
      type: 'canvasReady'
    });

    this.editorSetupSuccess();
  }

  onWindowResize(viewportSize: Vector2D): void {
    this.viewport.setRendererSize(viewportSize);
    this.cameraControls!.resize(viewportSize.x, viewportSize.y);
    this.onCanvasResize(viewportSize);
  }

  async changeMapBackground(
    provider: BaseImageryProvider,
    updateTheProject: boolean = true,
    clearCustomImageryData: boolean = false
  ): Promise<void> {
    if (updateTheProject) {
      this.serviceBus.send('update_base_imagery_command', {
        domain: this.domain,
        newProvider: provider,
        clearCustomImageryData
      } as IUpdateBaseImageryCommandDependencies);
    }
    await this.setBaseImagery(this.lastProjectId!, {
      ...this.lastSiteImagery!,
      provider
    });
  }

  async setBaseImagery(projectId: string, projectBaseImagery: ISiteData['imagery']): Promise<void> {
    this.lastProjectId = projectId;
    this.lastSiteImagery = projectBaseImagery;
    const { zoomLevel } = projectBaseImagery;
    // Amplify run tests without NODE_ENV === 'test', so we need this hacky check
    // of this.map.googleMap.
    if (process.env.NODE_ENV === 'test' || this.map.googleMap === undefined) {
      return;
    }

    const mapZoomFactor = zoomLevel > MAXIMUM_ZOOM_LEVEL ? -1 : 20 - zoomLevel;
    this.setMapZoomFactor(mapZoomFactor);

    if (projectBaseImagery.provider === BaseImageryProvider.CUSTOM && !projectBaseImagery.CUSTOM?.scaleFactor) {
      // eslint-disable-next-line no-console
      console.error('Corrupt custom base imagery data, reverting to google maps');
      projectBaseImagery.provider = BaseImageryProvider.GOOGLE_MAPS;
      this.serviceBus.send('update_base_imagery_command', {
        domain: this.domain,
        newProvider: projectBaseImagery.provider
      } as IUpdateBaseImageryCommandDependencies);
    }
    const canvasBackgroundMesh = await this.map.createMapTextureMesh({
      projectId,
      projectBaseImagery,
      coordinateSystemOrigin: this.domain.project.site.coordinateSystemOrigin,
      editor: this
    });
    this.addBackground(canvasBackgroundMesh);

    this.isBackgroundLoaded = true;
  }

  configureHelpers(): void {
    const axesHelper = new AxesHelper(100);
    const grid = new GridHelper(10, 10);
    this.scene!.add(axesHelper);
    this.scene!.add(grid);
  }

  /**
   * Loading and setting basic assets for the canvas context
   * Like fonts or images
   *
   */
  loadAssets(): void {
    this.font = getCanvasFont();
  }

  /**
   * This method allow to change the application state event handlers
   * through the application.
   * @param controlState application state can be ON_DRAW_EDITION OR
   * ON_PROPERTIES_EDITION
   */
  updateApplicationContext(controlState: EApplicationContext): void {
    if (this.applicationContext !== controlState) {
      this.applicationContext = controlState;
      this.dispatchEvent({
        type: 'application_control_state_change',
        state: this.applicationContext
      });
    }
  }

  updateCursor(cursor: ECursor = ECursor.DEFAULT, cleanHistory: boolean = false): void {
    if (cleanHistory) {
      this.cursorController.cleanHistory();
    }
    this.cursorController.loadCursor(cursor);
  }

  getViewportSize(): Vector2 {
    return ViewPort.resolution;
  }

  addBackground(canvasBackgroundMesh: Mesh): void {
    this.backgroundScene!.clear();
    // Setting background a tad lower to avoid same-level polygon artifacts.
    canvasBackgroundMesh.position.z = -1;
    this.backgroundScene!.add(canvasBackgroundMesh);

    const material = canvasBackgroundMesh.material as MeshBasicMaterial;
    if (material.map !== null) {
      this.updateUnzoomable();
      this.viewport.render();
    }

    this.cameraControls!.setSize(
      this.rendererDom.width,
      this.rendererDom.height,
      canvasBackgroundMesh.scale.x,
      canvasBackgroundMesh.scale.y,
      this.mapZoomFactor
    );
  }

  async finishCustomBaseImageryTransformation(): Promise<void> {
    await this.setBaseImagery(this.lastProjectId!, this.domain.project.site.imagery);
  }

  onWorkspaceSwitch(): void {
    this.getObjectsByType<RoofFace>(SceneObjectType.RoofFace, true).forEach((roofFace: RoofFace) =>
      roofFace.setAzimuthArrowVisibility(false)
    );
  }

  addOrUpdateObject(object: Object3D): void {
    if (this.scene) {
      this.updateViewportSizeForObject(object, this.getViewportSize());

      const existingObject = this.cachedUuidToObject(object.uuid);

      if (!existingObject) {
        this.scene.add(object);
      }

      this.unzoomObject(object);
    }
  }

  getObjectsByCondition(condition: (object: Object3D) => boolean): Object3D[] {
    const result: Object3D[] = [];
    this.scene!.traverse((object3D: Object3D): void => {
      if (condition(object3D)) {
        result.push(object3D);
      }
    });
    return result;
  }

  getObjectsByTypes(types: string[], recursive?: boolean): PolygonDrawable[] {
    return flatMap(types, (type: string): PolygonDrawable[] => this.getObjectsByType(type, recursive));
  }

  /** Calculates Z-value of an object, so that it's positioned lower than all the objects of `type`
   * @argument type - Three.js object's type
   * @argument offset - (in world units) distance to move object away by from calculated height
   */
  getObjectRenderHeight(type: SceneObjectType, offset?: number): number {
    if (this.renderingDistanceBoundaries[type] === undefined) {
      throw new Error(`The scene object type of ${type} doesn't have a rendering boundary`);
    }
    return (this.minimalRenderDistance ?? 0) - (this.renderingDistanceBoundaries[type] ?? 0) - (offset ?? 0);
  }

  /**
   * This function is called on every render, but we don't need to update
   * marker heights on every render, so we throttle it.
   */
  throttledUpdateMarkerHeights = throttle(() => {
    this.updateMarkerHeights();
  }, 250);

  updateMarkerHeights(): void {
    this.getObjectsByTypes(getSiteEquipmentMarkerTypes()).forEach((marker: PolygonDrawable, index: number) => {
      (marker as PolygonDrawable).mesh.position.setZ(this.getObjectRenderHeight(marker.type as SceneObjectType, index));
    });
  }

  getCollidableObjects(): PolygonDrawable[] {
    return [
      ...this.getObjectsByType<PvModulePosition>(SceneObjectType.PvModulePosition, true).filter(
        (object: PolygonDrawable) => !(object as PvModulePosition).isPvModulePositionPreview
      ),
      ...this.getObjectsByTypes([SceneObjectType.Setback, SceneObjectType.Pathway, SceneObjectType.Protrusion], true)
    ];
  }

  getObjectsByType<T extends Drawable>(type: string, recursive?: boolean): T[] {
    const result: T[] = [];
    if (recursive) {
      this.scene!.traverse((object3D: Object3D): void => {
        if (this.is<T>(object3D, type)) {
          result.push(getLyraModelByMesh(object3D));
        }
      });
    } else {
      this.scene!.children?.forEach((object3D: Object3D): void => {
        if (this.is<T>(object3D, type)) {
          result.push(getLyraModelByMesh(object3D));
        }
      });
    }
    return result;
  }

  is<T extends Drawable>(target: unknown, type: string): target is T {
    return (target as T).type === type || ((target as Object3D).userData?.lyraModel as T)?.type === type;
  }

  removeObject(object: Object3D & { dispose?: () => void }): void {
    object.dispose?.();
    this.scene!.remove(object);
    this.viewport.render();
  }

  removeObjectsByType(type: string): void {
    this.getObjectsByType(type).forEach((object: Drawable & { dispose?: () => void }): void => {
      object.dispose?.();
      this.scene!.remove(object.mesh);
    });
    this.viewport.render();
  }

  @action.bound
  canZoom(factor: number): boolean {
    const oldZoom = this.currentZoom;
    const newZoom = fit(ZOOM_LIMIT, oldZoom + factor);
    return oldZoom !== newZoom;
  }

  @action.bound
  zoomIn(): void {
    if (this.canZoom(1)) {
      this._cameraControls!.zoomToDirection(1);
    }
  }

  @action.bound
  zoomOut(): void {
    if (this.canZoom(-1)) {
      this._cameraControls!.zoomToDirection(-1);
    }
  }

  @action.bound
  clickModalSV(showModal: boolean): void {
    this.streetModal = showModal;
  }

  updateUnzoomable = (): void => {
    const cameraZoomValue = this._cameraControls?.cameraZoomValue;
    this.currentZoom =
      (Math.round(cameraZoomValue || 1) === ZOOM_LIMIT.upper ? ZOOM_LIMIT.upper : cameraZoomValue) || 1;
    this.unzoomObject(this.scene!);
  };

  @action.bound
  renderSiteMarkers(workspace: IWorkspace): void {
    const stage = this.domain.optionalDesign?.state.toData().user;

    this.domain.siteEquipment.getRenderableKeyNames().forEach((equipment: string): void => {
      const editorFn = this.shouldRenderSiteEquipmentForStage(equipment, workspace, stage)
        ? 'addOrUpdateObject'
        : 'removeObject';
      const equipmentKey = equipment as keyof typeof SiteEquipmentItemKeyName;
      const equipmentValue = this.domain.siteEquipment[equipmentKey] as Marker;

      if (equipmentValue) {
        this[editorFn](equipmentValue.mesh);
      }
    });

    if (stage !== DesignStep.ELECTRICAL_BOS) {
      this.removeObjectsByType(SceneObjectType.ElectricalEquipmentMarker);
    }
  }

  private shouldRenderSiteEquipmentForStage(equipment: string, workspace: IWorkspace, stage?: DesignStep): boolean {
    if (isProjectWorkspace(workspace)) {
      return true;
    }

    if (stage === DesignStep.ARRAY_PLACEMENT || stage === DesignStep.LAYOUT_DESIGN) {
      return equipment === 'streetLocation';
    }

    if (stage === DesignStep.ELECTRICAL_BOS) {
      return equipment !== 'streetLocation';
    }

    return false;
  }

  private unzoomObject(object: Object3D): void {
    const factor = this.scaleFactor;
    object?.children.forEach((child: Object3D): void => {
      this.unzoomObject(child);
    });

    if (getLyraModelByMesh<Unzoomable>(object).isUnzoomable) {
      getLyraModelByMesh<Unzoomable>(object).unzoom(factor);
    }
  }

  private onCanvasResize(viewportSize: Vector2D): void {
    this.updateViewportSizeForObject(this.scene!, viewportSize);
  }

  private updateViewportSizeForObject(object: Object3D, viewportSize: Vector2D): void {
    object?.traverse((obj: Object3D): void => {
      getLyraModelByMesh<Segment>(obj).onCanvasResize?.(viewportSize);
      getLyraModelByMesh<Stringing>(obj).redrawStringingOnCanvasResize?.();
    });
  }
}

export default EditorStore;
