import { canvasConfig } from '../../../../../config/canvasConfig';
import type { Marker } from '../../../../../domain/models/SiteDesign/Marker';
import { SelectionControl } from '../../../../EditorStore/Controls/SelectionControl';
import { getUiStore } from '../../../../RootStoreInversion';
import { PropsPanelUICodes } from '../../../Properties/propertiesStoreConstants';
import { SceneObjectType } from '../../../../../domain/models/Constants';
import { LoadCenter } from '../../../../../domain/models/SiteDesign/SiteEquipment/LoadCenter';
import { MeterBase } from '../../../../../domain/models/SiteDesign/SiteEquipment/MeterBase';
import { MeterMain } from '../../../../../domain/models/SiteDesign/SiteEquipment/MeterMain';
import type { IBaseSiteEquipmentToolDependencies } from './BaseSiteEquipmentTool';
import { BaseSiteEquipmentTool } from './BaseSiteEquipmentTool';
import { ServiceEntranceEquipmentType } from '../../../../../domain/models/SiteDesign/SiteEquipmentTypesAndHelpers';

export const SERVICE_ENTRANCE_EQUIPMENT_TOOL_ID = 'service-entrance-equipment-tool-id';

export class ServiceEntranceEquipmentTool extends BaseSiteEquipmentTool {
  override readonly id: string = SERVICE_ENTRANCE_EQUIPMENT_TOOL_ID;
  override readonly icon: string = 'SE';
  override readonly title: string = 'Service Entrance Equipment';
  override readonly description: string = this.title;
  override testId: string = 'SETool';

  constructor(dependencies: IBaseSiteEquipmentToolDependencies) {
    super(dependencies);
  }

  override whenSelected(): void {
    this.selectionControl = SelectionControl.getInstance(this.editor, this.editor.viewport, this.editor.activeCamera);

    const equipment = this.getEquipment();
    if (equipment.length) {
      this.toolbar.activateSelectionToolInProjectWorkspace().finally((): void => {
        getUiStore().properties.setPropertyPanel(PropsPanelUICodes.ServiceEntranceEquipmentForm);
      });
    } else {
      getUiStore().properties.setPropertyPanel(PropsPanelUICodes.ServiceEntranceEquipmentOptions);
    }

    this.addMouseEventListeners();
  }

  createLoadCenter(serviceEntranceEquipmentType: ServiceEntranceEquipmentType): LoadCenter {
    return new LoadCenter({
      sceneObjectType: SceneObjectType.MainServicePanel,
      color: canvasConfig.mainServicePanelIconColor,
      meterSocket: serviceEntranceEquipmentType === ServiceEntranceEquipmentType.MeterMainLoadCenter,
      serviceEntranceEquipmentType: serviceEntranceEquipmentType
    });
  }

  createMeterBase(serviceEntranceEquipmentType: ServiceEntranceEquipmentType): MeterBase {
    return new MeterBase({
      sceneObjectType: SceneObjectType.UtilityMeter,
      color: canvasConfig.utilityMeterIconColor,
      serviceEntranceEquipmentType: serviceEntranceEquipmentType
    });
  }

  createMeterMain(serviceEntranceEquipmentType: ServiceEntranceEquipmentType): MeterMain {
    return new MeterMain({
      sceneObjectType: SceneObjectType.MeterMain,
      color: canvasConfig.utilityMeterIconColor,
      serviceEntranceEquipmentType: serviceEntranceEquipmentType
    });
  }

  createServiceEntranceEquipment(serviceEntranceEquipmentType: ServiceEntranceEquipmentType): void {
    const equipmentList = [];
    switch (serviceEntranceEquipmentType) {
    case ServiceEntranceEquipmentType.MeterMainLoadCenter: {
      equipmentList.push(this.createLoadCenter(serviceEntranceEquipmentType));
      break;
    }
    case ServiceEntranceEquipmentType.MeterBaseAndMainBreakerLoadCenter:{
      equipmentList.push(this.createLoadCenter(serviceEntranceEquipmentType));
      equipmentList.push(this.createMeterBase(serviceEntranceEquipmentType));
      break;
    }
    case ServiceEntranceEquipmentType.MeterMainAndMainLugLoadCenter: {
      equipmentList.push(this.createLoadCenter(serviceEntranceEquipmentType));
      equipmentList.push(this.createMeterMain(serviceEntranceEquipmentType));
      break;
    }
      // no default
    }

    let markerIndex = 0;
    for (const equipment of equipmentList) {
      this.serviceBus.send('add_site_equipment', {
        editor: this.editor,
        domain: this.domain,
        equipment
      });

      // Note: when multiple equipment items are present, this is effectively
      // the same as setting the hint for the last item only
      this.properties.setPropertyPanel(PropsPanelUICodes.HintPanelServiceEntranceEquipment);

      // Initialize dragging
      equipment.onDragStart(equipment.mesh.position);
      equipment.mesh.visible = false;

      equipment.positionShift = markerIndex;

      markerIndex++;
    }
  }

  override getEquipment(): Marker[] {
    return this.domain.siteEquipment?.getServiceEntranceEquipment() ?? [];
  }
}
