import type { Dictionary } from '../../../domain/typings';
import { AddOrUpdateBuildingCommand } from './AddOrUpdateBuildingCommand';
import { AddOrUpdateParcelCommand } from './AddOrUpdateParcelCommand';
import { AddProtrusionCommand } from './AddProtrusionCommand';
import { AddPvModulePositions } from './AddPvModulePositionsCommand';
import { AddRoofFaceCommand } from './AddRoofFaceCommand';
import { AddRoofOutlineCommand } from './AddRoofOutlineCommand';
import { AddSiteEquipmentCommand } from './AddSiteEquipmentCommand';
import { AddVertexToOutlineCommand } from './AddVertexToOutlineCommand';
import { AddVertexToRoofFaceCommand } from './AddVertexToRoofFaceCommand';
import type { ICommand } from './Command';
import { CreateDesignCommand } from './CreateDesignCommand';
import { DeleteBuildingCommand } from './DeleteBuildingCommand';
import { DeleteEmptyBuildings } from './DeleteEmptyBuildingsCommand';
import { DeleteOutline } from './DeleteOutline';
import { DeleteParcelCommand } from './DeleteParcelCommand';
import { DeleteProtrusionCommand } from './DeleteProtrusionCommand';
import { DeletePvModulesPositionsCommand } from './DeletePvModulesPositionsCommand';
import { DeleteRoofFaceCommand } from './DeleteRoofFaceCommand';
import { DeleteSiteEquipment } from './DeleteSiteEquipment';
import { DeleteSiteImageCommand } from './DeleteSiteImageCommand';
import { DeleteStringCommand } from './DeleteStringCommand';
import { Import3DModelFromWizard } from './Import3DModelFromWizard';
import { RemoveVertexFromPolygonCommand } from './RemoveVertexFromPolygon';
import { SaveRoofProtrusionPropertyCommand } from './SaveRoofProtrusionPropertyCommand';
import { UpdateTemperatureValuesCommand } from './TemperatureCommand';
import { UpdateBaseImageryCommand } from './UpdateBaseImageryCommand';
import { UpdateBuildingPropertyCommand } from './UpdateBuildingPropertyCommand';
import { UpdateBuildingVisibilityCommand } from './UpdateBuildingVisibilityCommand';
import { UpdateDesignDeltaCommand } from './UpdateDesignDeltaCommand';
import { UpdateDesignState } from './UpdateDesignState';
import { UpdateDesignSupplementalDataCommand } from './UpdateDesignSupplementalDataCommand';
import { UpdateEquipmentCommand } from './UpdateEquipmentCommand';
import { UpdateGroundSnowLoadCommand } from './UpdateGroundSnowLoadCommand';
import { UpdateOtherSitePropertiesCommand } from './UpdateOtherSitePropertiesCommand';
import { UpdatePvModulePositionsCommand } from './UpdatePvModulePositionsCommand';
import { UpdatePvModules } from './UpdatePvModules';
import { UpdateRoofFaceLevelCommand } from './UpdateRoofFaceLevelCommand';
import { UpdateRoofFacePropertyCommand } from './UpdateRoofFacePropertyCommand';
import { UpdateRoofProtrussionPropertyCommand } from './UpdateRoofProtrusionPropertyCommand';
import { UpdateSupplementalMountingSystemDataCommand } from './UpdateSupplementalMountingSystemDataCommand';
import { UpdateSupplementalPvModuleDefinitionDataCommand } from './UpdateSupplementalPvModuleDefinitionDataCommand';
import { UpdateWindDesignParametersCommand } from './UpdateWindDesignParametersCommand';
import { UpdateWindExposureValuesCommand } from './UpdateWindExposureValuesCommand';

type CommandConstructor<A = ICommand> =
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  new (deps: any) => A;

export enum PromisableCommands {
  update_design_delta = 'update_design_delta'
}

export const registryCommands: Dictionary<CommandConstructor> = {
  add_or_update_building: AddOrUpdateBuildingCommand,
  add_or_update_parcel: AddOrUpdateParcelCommand,
  add_protrusion: AddProtrusionCommand,
  add_pv_module_positions: AddPvModulePositions,
  add_roof_face: AddRoofFaceCommand,
  add_roof_outline: AddRoofOutlineCommand,
  add_site_equipment: AddSiteEquipmentCommand,
  add_vertex_to_outline: AddVertexToOutlineCommand,
  add_vertex_to_roof_face: AddVertexToRoofFaceCommand,
  create_design: CreateDesignCommand,
  delete_building: DeleteBuildingCommand,
  delete_parcel_boundary: DeleteParcelCommand,
  delete_empty_buildings: DeleteEmptyBuildings,
  delete_site_equipment: DeleteSiteEquipment,
  delete_gas_meter: DeleteSiteEquipment,
  delete_main_service_panel: DeleteSiteEquipment,
  delete_street_location: DeleteSiteEquipment,
  delete_subpanel: DeleteSiteEquipment,
  delete_utility_meter: DeleteSiteEquipment,
  delete_outline: DeleteOutline,
  delete_pv_modules_and_module_positions: DeletePvModulesPositionsCommand,
  delete_rectangular_protrusion: DeleteProtrusionCommand,
  delete_roof_face: DeleteRoofFaceCommand,
  delete_site_image: DeleteSiteImageCommand,
  delete_string_command: DeleteStringCommand,
  import_3d_model: Import3DModelFromWizard,
  remove_vertex_from_polygon: RemoveVertexFromPolygonCommand,
  save_roof_protrusion_property_command: SaveRoofProtrusionPropertyCommand,
  update_base_imagery_command: UpdateBaseImageryCommand,
  update_building_property: UpdateBuildingPropertyCommand,
  update_building_visibility: UpdateBuildingVisibilityCommand,
  [PromisableCommands.update_design_delta]: UpdateDesignDeltaCommand,
  update_design_state: UpdateDesignState,
  update_design_supplemental_data: UpdateDesignSupplementalDataCommand,
  update_equipment: UpdateEquipmentCommand,
  update_groundSnow_values: UpdateGroundSnowLoadCommand,
  update_other_site_properties: UpdateOtherSitePropertiesCommand,
  update_pv_module_position: UpdatePvModulePositionsCommand,
  update_pv_modules: UpdatePvModules,
  update_roof_face_level: UpdateRoofFaceLevelCommand,
  update_roof_face_property: UpdateRoofFacePropertyCommand,
  update_roof_protrusion_property: UpdateRoofProtrussionPropertyCommand,
  update_supplemental_mounting_system_definition_data: UpdateSupplementalMountingSystemDataCommand,
  update_supplemental_pv_module_definition_data: UpdateSupplementalPvModuleDefinitionDataCommand,
  update_temperature_values: UpdateTemperatureValuesCommand,
  update_windExposure_values: UpdateWindExposureValuesCommand,
  update_windSpeed_values: UpdateWindDesignParametersCommand
};
