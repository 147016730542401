import styled, {
  css, withTheme
} from 'styled-components';

export const ModuleContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 100%;
`;

export const LeftContainer = styled.div`
  margin-right: 15px;
`;
export const RightContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
`;

export const ModuleNumbers = styled.div`
  width: 44px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 28px;
  border-radius: 4px;
  border: solid 1px #cccccc;
  background-color: #f2f2f2;
  font-family: 'Roboto', sans-serif;
  font-size: 24px;
  font-weight: normal;
  line-height: 1;
  text-align: center;
  color: #4a4a4a;
`;

const ModuleTextMixin = css`
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  line-height: normal;
  font-weight: normal;
`;

export const ModelContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  .title {
    ${ModuleTextMixin};
    color: #8f8f8f;
    min-width: 50px;
  }
  .content {
    ${ModuleTextMixin};
    font-weight: bold;
    color: #4a4a4a;
    word-break: break-word;
  }
`;
