import type {
  Intersection, Object3D, OrthographicCamera, PerspectiveCamera
} from 'three';
import {
  Plane, Vector3
} from 'three';
import throttle from 'lodash/throttle';
import defer from 'lodash/defer';
import type EditorStore from '../EditorStore';
import type { IDisposable } from '../../../domain/typings';
import type SmartGuidesStore from '../../UiStore/SmartGuidesStore/SmartGuidesStore';
import type { ViewPort } from '../ViewportController';
import {
  EventType, getEventSystemSubscribe
} from '../../../services/eventSystem/eventSystemHook';
import type { Drawable } from '../../../domain/mixins/Drawable';
import type { Draggable } from '../../../domain/mixins/Draggable';
import type { Selectable } from '../../../domain/mixins/Selectable';

import { getLyraModelByMesh } from '../../../domain/sceneObjectsWithLyraModelsHelpers';
import { BaseCastObjectControl } from './BaseCastObjectControl';
import type { IControls } from './Controls';

type DragEvents = {
  hoveron: { object: Object3D };
  hoveroff: { object: Object3D };
  dragstart: { object: Draggable; selectedObjects: Draggable[] };
  drag: { object: Draggable; selectedObjects: Draggable[]; objectTarget: Intersection[] };
  dragend: { object: Draggable; selectedObjects: Draggable[]; objectTarget: Intersection[] };
};

export class DragControl extends BaseCastObjectControl<DragEvents> implements IControls, IDisposable {
  protected static sInstance: DragControl;
  static override getInstance(
    editor?: EditorStore,
    viewport?: ViewPort,
    camera?: OrthographicCamera | PerspectiveCamera,
    smartGuides?: SmartGuidesStore
  ): DragControl {
    if (editor !== undefined && viewport !== undefined && camera !== undefined && smartGuides !== undefined) {
      if (!DragControl.sInstance) {
        DragControl.sInstance = new DragControl(editor, viewport, camera, smartGuides);
      } else if (!DragControl.getInstance()) {
        throw new Error('Singleton instance cannot be created without parameters');
      }
    }
    return DragControl.sInstance;
  }

  snapIgnoreServerIds: string[] = [];

  isDragging: boolean = false;

  throttledHoverHandler = throttle((): void => {
    const intersected = this.getCastedObjects();

    if (intersected.length > 0) {
      const obj = intersected[0].object;
      this.plane.setFromNormalAndCoplanarPoint(
        this.camera.getWorldDirection(this.plane.normal),
        this.worldPosition.setFromMatrixPosition(obj.matrixWorld)
      );

      if (this.hovered !== obj) {
        this.dispatchEvent({
          type: 'hoveron',
          object: obj
        });

        this.editor.canvasParent.style.cursor = 'pointer';
        this.hovered = obj;
      }
    } else {
      if (this.hovered !== undefined) {
        this.dispatchEvent({
          type: 'hoveroff',
          object: this.hovered
        });

        this.editor.canvasParent.style.cursor = 'auto';
        this.hovered = undefined;
      }
    }
  }, 100);

  private debugVertexMovementCoords: Vector3[] = [];
  private debugVertexMovementTime: Number[] = [];
  private plane = new Plane();
  private intersection = new Vector3();
  private worldPosition = new Vector3();
  private selected?: Draggable;
  private hovered?: Object3D;
  private readonly smartGuides: SmartGuidesStore;
  private dragOccurred: boolean = false;
  private minimalDragDistancePassed: boolean = false;
  private additionalSelectedObjects: Draggable[] = [];
  private lastMovedObjects: Draggable[] = [];
  private lastPositionsAfterMove: Vector3[][] = [];

  private constructor(
    editor: EditorStore,
    viewport: ViewPort,
    camera: OrthographicCamera | PerspectiveCamera,
    smartGuides: SmartGuidesStore,
    objects?: Selectable[]
  ) {
    super(editor, viewport, camera, objects);
    this.smartGuides = smartGuides;

    // This is a temporary code to debug the non-coplanar polygon issue (LYRA-7163)
    getEventSystemSubscribe(EventType.NonCoplanarPolygonDetected, () => {
      // eslint-disable-next-line no-console
      console.error('Non coplanar issue occurred (drag control)');
      let debugString = '';
      let metaCounter = 1;
      let counter = 0;
      let totalCounter = 0;
      for (const vector of this.debugVertexMovementCoords) {
        debugString += `{x:${vector.x},y:${vector.x},z:${vector.x},timestamp:${this.debugVertexMovementTime[totalCounter]}}, `;
        counter++;
        if (counter >= 25) {
          // TODO: use sentry file attachment feature
          // eslint-disable-next-line no-console
          console.error(`debugString ${metaCounter}: ${debugString}`);
          counter = 0;
          metaCounter++;
        }
        totalCounter++;
      }
      if (counter > 0) {
        // eslint-disable-next-line no-console
        console.error(`debugString ${metaCounter}: ${debugString}`);
      }
    });
  }

  setAdditionalSelectedObjects(objects: Draggable[]) {
    this.additionalSelectedObjects = objects;
  }

  override activate(): void {
    this.enabled = true;

    this.editor.canvasParent.addEventListener('mousedown', this.onMouseDown);
    this.editor.canvasParent.addEventListener('mouseup', this.onMouseUp);
    this.editor.canvasParent.addEventListener('mousemove', this.onMouseMove);
    this.editor.canvasParent.addEventListener('mouseleave', this.onMouseLeave);
  }

  override deactivate(): void {
    this.enabled = false;
    this.additionalSelectedObjects = [];

    this.editor.canvasParent.removeEventListener('mousedown', this.onMouseDown);
    this.editor.canvasParent.removeEventListener('mouseup', this.onMouseUp);
    this.editor.canvasParent.removeEventListener('mousemove', this.onMouseMove);
    this.editor.canvasParent.removeEventListener('mouseleave', this.onMouseLeave);
  }

  override getTargetObjects(): Selectable[] {
    const result = super.getTargetObjects();
    return result.filter((obj: Selectable): boolean => {
      // This allows for making any elements draggable by reference.
      return this.isDrawable(obj.mesh ?? obj) || (obj as Drawable).mesh?.userData?.draggableParent;
    });
  }

  isDrawable(target: Object3D): boolean {
    return getLyraModelByMesh(target)?.isDrawable;
  }

  isDraggableMesh(target: Object3D): boolean {
    return target.userData?.lyraModel?.isDraggable;
  }

  /**
   Drag start should happen before the actual drag, this way we
   can avoid calling dragend if drag didn't happen.
   */
  protected override onMouseDown = (event: MouseEvent): void => {
    event.preventDefault();
    this.mouseStart.copy(this.mouse);

    const intersected = this.getCastedObjects();

    let firstDraggableObject = intersected
      .map(
        (i: Intersection): Object3D =>
          i.object.userData.draggableParent ? (i.object.userData.draggableParent as Object3D) : i.object
      )
      .find((o: Object3D): boolean => this.isDraggableMesh(o)) as Object3D | null;

    if (firstDraggableObject) {
      firstDraggableObject = getLyraModelByMesh(firstDraggableObject);
    }

    if (firstDraggableObject) {
      this.selected = getLyraModelByMesh(firstDraggableObject);
      this.editor.canvasParent.style.cursor = 'move';
    }
  };

  protected override onMouseMove = (event: MouseEvent): void => {
    event.preventDefault();
    this.setMouse(event.clientX, event.clientY);

    if (this.selected !== undefined && this.enabled && this.selected.isDrawable) {
      if (this.raycaster.ray.intersectPlane(this.plane, this.intersection)) {
        const worldPosition = this.unprojectMouseToFrustum(this.mouse);

        // unprojectMouseToFrustum always returns with 0 as the z value,
        // so we set it back to the original value

        // We can turn this back on once https://solardesigntool.atlassian.net/browse/LYRA-7536 is completed
        // worldPosition.z = this.selected.position.z;

        /**
         * Calculate positions and collisions separately:
         */
        const allMovableObjects = [
          this.selected,
          ...this.additionalSelectedObjects.filter(
            ({ serverId }: Draggable): boolean => serverId !== this.selected!.serverId
          )
        ];

        // Calling drag start:
        if (!this.dragOccurred) {
          allMovableObjects.forEach((selected: Draggable): void => {
            selected.onDragStart(worldPosition);
          });

          this.dispatchEvent({
            type: 'dragstart',
            object: this.selected,
            selectedObjects: [...allMovableObjects]
          });

          this.dragOccurred = true;
        }

        if (
          this.minimalDragDistancePassed
          || this.selected.isMovePossible(worldPosition, this.smartGuides, this.snapIgnoreServerIds)
        ) {
          const positionsAfterMove: Vector3[][] = allMovableObjects.map((item: Draggable): Vector3[] =>
            item.performMove(
              worldPosition,
              this.editor,
              this.smartGuides,
              this.snapIgnoreServerIds,
              // Call afterMove immediately for single-vertex draggables
              !item.isMultipleVertices // callAfterMoveCallbackImmediately
            )
          );

          this.lastMovedObjects = allMovableObjects;
          this.lastPositionsAfterMove = positionsAfterMove;
          this.minimalDragDistancePassed = true;

          // Use defer to unblock user interaction:
          defer(() => {
            this.lastMovedObjects.forEach((item: Draggable, index: number): void => {
              // Call afterMove asynchronously for multi-vertex draggables to unblock UI sooner
              if (item.isMultipleVertices) {
                item.afterMove(this.lastPositionsAfterMove[index], this.editor, this.smartGuides);
              }
            });

            this.dispatchEvent({
              type: 'drag',
              object: this.selected!,
              selectedObjects: this.lastMovedObjects,
              objectTarget: this.getCastedObjects()
            });
          });
        }
      }

      this.isDragging = true;
      return;
    }

    this.throttledHoverHandler();
  };

  protected override onMouseUp = (event: MouseEvent): void => {
    event.preventDefault();
    this.mouseEnd.copy(this.mouse);

    this.mouseDelta.subVectors(this.mouseEnd, this.mouseStart);

    this.debugVertexMovementCoords.push(this.unprojectMouseToFrustum(this.mouseEnd));
    this.debugVertexMovementTime.push(new Date().getTime());
    if (this.debugVertexMovementCoords.length > 100) {
      // Remove first element
      this.debugVertexMovementCoords.splice(0, 1);
      this.debugVertexMovementTime.splice(0, 1);
    }

    if (this.selected) {
      this.stopDragging();
    }

    this.dragOccurred = false;
    this.minimalDragDistancePassed = false;

    this.editor.canvasParent.style.cursor = this.hovered ? 'pointer' : 'auto';
  };

  protected override onMouseLeave = (event: MouseEvent): void => {
    event.preventDefault();
    this.stopDragging();
    this.dragOccurred = false;
    this.minimalDragDistancePassed = false;
  };

  private stopDragging(): void {
    this.isDragging = false;

    if (!this.dragOccurred) {
      this.selected = undefined;
      return;
    }

    // Ending the drag
    this.dispatchEvent({
      type: 'dragend',
      object: this.selected!,
      selectedObjects: this.lastMovedObjects,
      objectTarget: this.getCastedObjects()
    });
    this.selected?.onDragFinish(this.editor, this.smartGuides);
    this.additionalSelectedObjects.forEach((selected: Draggable): void => {
      selected.onDragFinish(this.editor, this.smartGuides);
    });
    this.selected = undefined;
  }
}
