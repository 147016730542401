import {
  action, observable
} from 'mobx';
import { Vector3 } from 'three';
import cloneDeep from 'lodash/cloneDeep';
import { getRootStore } from '../../../stores/RootStoreInversion';
import {
  type ISiteEquipmentData, SiteEquipmentDataKeys
} from '../../entities/SiteDesign/SiteEquipment';
import { SceneObjectType } from '../Constants';
import type { Drawable } from '../../mixins/Drawable';
import { canvasConfig } from '../../../config/canvasConfig';
import { GasMeter } from './GasMeter';
import type { StreetLocation } from './StreetLocation';
import { Subpanel } from './Subpanel';
import { LoadCenter } from './SiteEquipment/LoadCenter';
import { MeterBase } from './SiteEquipment/MeterBase';
import type { Marker } from './Marker';
import type { GenericSiteEquipment } from './SiteEquipment/GenericSiteEquipment';
import {
  ServiceEntranceEquipmentType, SiteEquipmentItemKeyName
} from './SiteEquipmentTypesAndHelpers';
import { MeterMain } from './SiteEquipment/MeterMain';

export enum ServiceEntranceEquipmentItemKeyName {
  mainServicePanel = 'mainServicePanel',
  utilityMeter = 'utilityMeter',
  meterMain = 'meterMain'
}

function inferServiceEntranceEquipmentTypeFromData(
  siteEquipmentData: ISiteEquipmentData
): ServiceEntranceEquipmentType {
  if (siteEquipmentData.meterMain) {
    return ServiceEntranceEquipmentType.MeterMainAndMainLugLoadCenter;
  }

  if (siteEquipmentData.mainServicePanel && siteEquipmentData.utilityMeter) {
    return ServiceEntranceEquipmentType.MeterBaseAndMainBreakerLoadCenter;
  }

  if (siteEquipmentData.mainServicePanel) {
    return ServiceEntranceEquipmentType.MeterMainLoadCenter;
  }

  throw new Error(`Current equipment configuration not supported: ${JSON.stringify(siteEquipmentData)}`);
}

export type ServiceEntranceEquipment = LoadCenter | MeterMain | MeterBase;
export type SiteEquipmentUnionType = ServiceEntranceEquipment | GasMeter | Subpanel;

export class SiteEquipment {
  @observable
  [SiteEquipmentItemKeyName.mainServicePanel]?: LoadCenter;
  [SiteEquipmentItemKeyName.subpanel]?: Subpanel;
  [SiteEquipmentItemKeyName.utilityMeter]?: MeterBase;
  [SiteEquipmentItemKeyName.meterMain]?: MeterMain;
  [SiteEquipmentItemKeyName.gasMeter]?: GasMeter;
  /**
   * Street location is mapped from `site.roadways` in project data structure
   * to `siteEquipment.streetLocation` class in the domain store.
   */
  [SiteEquipmentItemKeyName.streetLocation]?: StreetLocation;

  constructor(data?: ISiteEquipmentData) {
    const siteEquipmentData = this.migrateToNewSiteEquipmentDataStructure(data);
    const {
      mainServicePanel: mainServicePanelData,
      utilityMeter: utilityMeterData,
      meterMain: meterMainData,
      subpanel: subpanelData,
      gasMeter: gasMeterData
    } = siteEquipmentData;

    if (mainServicePanelData) {
      const targetServiceEntranceEquipmentType = inferServiceEntranceEquipmentTypeFromData(siteEquipmentData);
      const mainServicePanel = new LoadCenter({
        color: canvasConfig.mainServicePanelIconColor,
        sceneObjectType: SceneObjectType.MainServicePanel,
        serviceEntranceEquipmentType: targetServiceEntranceEquipmentType
      });
      mainServicePanel.enrichWithData(mainServicePanelData);

      const editor = getRootStore().editor;
      const renderHeight = editor.getObjectRenderHeight(mainServicePanel.type as SceneObjectType);
      const renderPosition = new Vector3(
        mainServicePanelData.location.x,
        mainServicePanelData.location.y,
        renderHeight
      );
      mainServicePanel.draw(renderPosition);

      this[SiteEquipmentItemKeyName.mainServicePanel] = mainServicePanel;
    }

    if (utilityMeterData) {
      const targetServiceEntranceEquipmentType = inferServiceEntranceEquipmentTypeFromData(siteEquipmentData);
      const utilityMeter = new MeterBase({
        color: canvasConfig.utilityMeterIconColor,
        sceneObjectType: SceneObjectType.UtilityMeter,
        serviceEntranceEquipmentType: targetServiceEntranceEquipmentType
      });
      utilityMeter.enrichWithMeterBaseData(utilityMeterData);

      const editor = getRootStore().editor;
      const renderHeight = editor.getObjectRenderHeight(utilityMeter.type as SceneObjectType);
      const renderPosition = new Vector3(utilityMeterData.location.x, utilityMeterData.location.y, renderHeight);
      utilityMeter.draw(renderPosition);

      this[SiteEquipmentItemKeyName.utilityMeter] = utilityMeter;
    }

    if (meterMainData) {
      const targetServiceEntranceEquipmentType = inferServiceEntranceEquipmentTypeFromData(siteEquipmentData);
      const meterMain = new MeterMain({
        color: canvasConfig.utilityMeterIconColor,
        sceneObjectType: SceneObjectType.MeterMain,
        serviceEntranceEquipmentType: targetServiceEntranceEquipmentType
      });
      meterMain.enrichWithMeterMainData(meterMainData);

      const editor = getRootStore().editor;
      const renderHeight = editor.getObjectRenderHeight(meterMain.type as SceneObjectType);
      const renderPosition = new Vector3(meterMainData.location.x, meterMainData.location.y, renderHeight);
      meterMain.draw(renderPosition);

      this[SiteEquipmentItemKeyName.meterMain] = meterMain;
    }

    if (subpanelData) {
      const subpanel = new Subpanel();
      subpanel.fromData(subpanelData);

      const renderHeight = getRootStore().editor.getObjectRenderHeight(subpanel.type as SceneObjectType);
      const renderPosition = new Vector3(subpanelData.location.x, subpanelData.location.y, renderHeight);
      subpanel.draw(renderPosition);

      this[SiteEquipmentItemKeyName.subpanel] = subpanel;
    }

    if (gasMeterData) {
      const gasMeter = new GasMeter();
      gasMeter.fromData(gasMeterData);

      const renderHeight = getRootStore().editor.getObjectRenderHeight(gasMeter.type as SceneObjectType);
      const renderPosition = new Vector3(gasMeterData.location.x, gasMeterData.location.y, renderHeight);
      gasMeter.draw(renderPosition);

      this[SiteEquipmentItemKeyName.gasMeter] = gasMeter;
    }
  }

  @action
  deleteEquipment({
    siteEquipmentToDelete,
    deleteSubpanelWithServiceEntranceEquipment = true
  }: {
    siteEquipmentToDelete: SiteEquipmentUnionType;
    deleteSubpanelWithServiceEntranceEquipment?: boolean;
  }): void {
    const {
      editor, domain
    } = getRootStore();
    // If one piece of service entrance equipment is deleted, the other ones should be deleted as well
    if ((siteEquipmentToDelete as GenericSiteEquipment).isServiceEntranceEquipment) {
      const subpanelDrawable = editor.getObjectsByType(SceneObjectType.Subpanel)[0];
      // We don't want to remove subpanel when we switch service entrance equipment type,
      // so instead of adding service-entrance-equipment-specific field, we can rely on
      // skipConfirmation that's used only for this action.
      if (subpanelDrawable && deleteSubpanelWithServiceEntranceEquipment) {
        editor.removeObject(subpanelDrawable.mesh);
      }
      const subpanel = this[SiteEquipmentItemKeyName.subpanel];
      if (subpanel && deleteSubpanelWithServiceEntranceEquipment) {
        this[SiteEquipmentItemKeyName.subpanel] = undefined;
      }

      // Remove objects from canvas first
      [
        ...editor.getObjectsByType(SceneObjectType.UtilityMeter),
        ...editor.getObjectsByType(SceneObjectType.MainServicePanel),
        ...editor.getObjectsByType(SceneObjectType.MeterMain)
      ].forEach((drawable: Drawable) => {
        editor.removeObject(drawable.mesh);
      });

      // Then remove them from domain
      const utilityMeter = this[SiteEquipmentItemKeyName.utilityMeter];
      if (utilityMeter) {
        this[SiteEquipmentItemKeyName.utilityMeter] = undefined;
      }
      const mainServicePanel = this[SiteEquipmentItemKeyName.mainServicePanel];
      if (mainServicePanel) {
        this[SiteEquipmentItemKeyName.mainServicePanel] = undefined;
      }
      const meterMain = this[SiteEquipmentItemKeyName.meterMain];
      if (meterMain) {
        this[SiteEquipmentItemKeyName.meterMain] = undefined;
      }
    } else {
      editor.removeObject(siteEquipmentToDelete.mesh);
      domain.deleteGenericSiteEquipment(siteEquipmentToDelete);
    }
  }

  getRenderableKeyNames(): string[] {
    return [
      SiteEquipmentItemKeyName.mainServicePanel,
      SiteEquipmentItemKeyName.utilityMeter,
      SiteEquipmentItemKeyName.meterMain,
      SiteEquipmentItemKeyName.subpanel,
      SiteEquipmentItemKeyName.gasMeter,
      SiteEquipmentItemKeyName.streetLocation
    ];
  }

  getServiceEntranceEquipment = (): Marker[] => {
    const mainServicePanel = this.mainServicePanel;
    const utilityMeter = this.utilityMeter;
    const meterMain = this.meterMain;
    const result: Marker[] = [];

    if (mainServicePanel) {
      result.push(mainServicePanel);
    }
    if (utilityMeter) {
      result.push(utilityMeter);
    }
    if (meterMain) {
      result.push(meterMain);
    }

    return result;
  };

  inferServiceEntranceEquipmentType = (): ServiceEntranceEquipmentType | undefined => {
    if (this[SiteEquipmentItemKeyName.mainServicePanel] && this[SiteEquipmentItemKeyName.utilityMeter]) {
      return ServiceEntranceEquipmentType.MeterBaseAndMainBreakerLoadCenter;
    }
    if (this[SiteEquipmentItemKeyName.mainServicePanel] && this[SiteEquipmentItemKeyName.meterMain]) {
      return ServiceEntranceEquipmentType.MeterMainAndMainLugLoadCenter;
    }
    if (this[SiteEquipmentItemKeyName.mainServicePanel]) {
      return ServiceEntranceEquipmentType.MeterMainLoadCenter;
    }
  };

  getEquipmentMarkerObjects = (): (SiteEquipmentUnionType | StreetLocation)[] =>
    [
      this[SiteEquipmentItemKeyName.mainServicePanel],
      this[SiteEquipmentItemKeyName.subpanel],
      this[SiteEquipmentItemKeyName.utilityMeter],
      this[SiteEquipmentItemKeyName.meterMain],
      this[SiteEquipmentItemKeyName.gasMeter],
      this[SiteEquipmentItemKeyName.streetLocation]
    ].filter((equipment) => equipment !== undefined) as (SiteEquipmentUnionType | StreetLocation)[];

  toData(): Partial<ISiteEquipmentData> {
    const result: Partial<ISiteEquipmentData> = {};

    if (this[SiteEquipmentItemKeyName.mainServicePanel]) {
      result.mainServicePanel = this[SiteEquipmentItemKeyName.mainServicePanel]!.toData();
    }
    if (this[SiteEquipmentItemKeyName.utilityMeter]) {
      result[SiteEquipmentDataKeys.utilityMeter] = this[SiteEquipmentItemKeyName.utilityMeter]!.toData();
    }
    if (this[SiteEquipmentItemKeyName.meterMain]) {
      result[SiteEquipmentDataKeys.meterMain] = this[SiteEquipmentItemKeyName.meterMain]!.toData();
    }
    if (this[SiteEquipmentItemKeyName.subpanel]) {
      result[SiteEquipmentDataKeys.subpanel] = this[SiteEquipmentItemKeyName.subpanel]!.toData();
    }
    if (this[SiteEquipmentItemKeyName.gasMeter]) {
      result[SiteEquipmentDataKeys.gasMeter] = this[SiteEquipmentItemKeyName.gasMeter]!.toData();
    }

    return result;
  }

  migrateToNewSiteEquipmentDataStructure(siteEquipmentData?: ISiteEquipmentData): ISiteEquipmentData {
    const clonedSiteEquipmentData = cloneDeep(siteEquipmentData);
    if (clonedSiteEquipmentData?.instances?.mainServicePanel) {
      clonedSiteEquipmentData.mainServicePanel = clonedSiteEquipmentData.instances.mainServicePanel;
      clonedSiteEquipmentData.mainServicePanel.definition = clonedSiteEquipmentData.definitions!.mainServicePanel!;
      delete clonedSiteEquipmentData.mainServicePanel.definition.id;
      delete clonedSiteEquipmentData.mainServicePanel.definitionId;
      delete clonedSiteEquipmentData.instances.mainServicePanel;
    }
    if (clonedSiteEquipmentData?.instances?.utilityMeter) {
      clonedSiteEquipmentData.utilityMeter = clonedSiteEquipmentData.instances.utilityMeter;
      delete clonedSiteEquipmentData.instances.utilityMeter;
    }
    if (clonedSiteEquipmentData?.instances?.meterMain) {
      clonedSiteEquipmentData.meterMain = clonedSiteEquipmentData.instances.meterMain;
      delete clonedSiteEquipmentData.instances.meterMain;
    }
    if (clonedSiteEquipmentData?.instances?.subpanel) {
      clonedSiteEquipmentData.subpanel = clonedSiteEquipmentData.instances.subpanel;
      clonedSiteEquipmentData.subpanel.definition = clonedSiteEquipmentData.definitions!.subpanel!;
      delete clonedSiteEquipmentData.subpanel.definition.id;
      delete clonedSiteEquipmentData.subpanel.definitionId;
      delete clonedSiteEquipmentData.instances.subpanel;
    }
    if (clonedSiteEquipmentData?.instances?.gasMeter) {
      clonedSiteEquipmentData.gasMeter = clonedSiteEquipmentData.instances.gasMeter;
      delete clonedSiteEquipmentData.instances.gasMeter;
    }
    return clonedSiteEquipmentData ?? {};
  }
}
