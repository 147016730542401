import type {
  AxiosRequestConfig, AxiosResponse
} from 'axios';
import type { IDesignData } from '../../../domain/models/Design/Design';
import type { ICircuitConnectionsData } from '../../../domain/entities/CircuitConnection/CircuitConnectionsOptions';
import type {
  IDocumentsGenerationOptionsData,
  IPreliminaryDocumentGenerationOptionsData,
  IDocumentResponse
} from '../../../domain/entities/Documents/DocumentsResponse';
import type {
  IPreliminarySystemDatasheetParams,
  ISystemDatasheetOptions
} from '../../../domain/entities/Documents/SystemDatasheetOptions';
import type { IFormData } from '../../../domain/entities/Form/FormData';
import type IOrderPreview from '../../../domain/entities/OrderPreview/IOrderPreview';
import type { IPermitPackageDocumentGenerationOptionsData } from '../../../domain/models/DocumentGenerationOptions/PermitPackage/PermitPackageDocumentGenerationOptions';
import type { ISitePlanData } from '../../../domain/entities/SitePlan/SitePlan';
import type {
  CircuitDataType,
  CircuitsConnectionsResponse
} from '../../../stores/UiStore/Modal/ViewModels/CircuitTable/CircuitTableViewModel';
import {
  blobifyApiResponse, parseContentDisposition
} from '../../../utils/request';
import Http from '../Http';
import config from '../../../config/config';
import type { IProjectData } from '../../../domain/models/SiteDesign/Project';
import {
  SiteImageUploadedEvent,
  CustomBaseImageryUploadedEvent
} from '../../../services/analytics/DesignToolAnalyticsEvents';
import type { IFormOptionsRulesAndStateData } from '../../../domain/entities/Form/FormOptionsRulesAndState';
import type { IUpdatedSitePlanData } from '../../../domain/entities/SitePlan/UpdatedSitePlan';

export type BillOfMaterialsType = 'PDF' | 'EXCEL';

export interface IPermitPackageUrls {
  readonly preview: string;
  readonly purchase: string;
}

export class DocumentsService {
  get baseURL(): string {
    return config.api.documents;
  }

  async importProject(project: IProjectData): Promise<void> {
    const urlService: string = this.baseURL.concat(`/projects/${project.id}`);
    await Http.put<IProjectData, void>(urlService, project);
  }

  async importDesign(design: IDesignData): Promise<void> {
    const urlService: string = this.baseURL.concat(`/designs/${design.id}`);
    await Http.put<IDesignData, void>(urlService, design);
  }

  async getElectricalBosOptions(designID: string): Promise<IFormOptionsRulesAndStateData> {
    const urlService: string = this.baseURL.concat(`/designs/${designID}/bos-options/electrical`);
    const response = await Http.get<IFormOptionsRulesAndStateData>(urlService, {});
    return response.data;
  }

  async getFormMountingBOS(designID: string): Promise<IFormOptionsRulesAndStateData> {
    const urlService: string = this.baseURL.concat(`/designs/${designID}/bos-options/mounting`);
    const response = await Http.get<IFormOptionsRulesAndStateData>(urlService, {});
    return response.data;
  }

  async saveElectricalBos(designID: string, form: IFormData): Promise<void> {
    const urlService: string = this.baseURL.concat(`/designs/${designID}/bos/electrical`);
    await Http.put<IFormData, void>(urlService, form);
  }

  async saveFormMountingBOS(designID: string, form: IFormData): Promise<void> {
    const urlService: string = this.baseURL.concat(`/designs/${designID}/bos/mounting`);
    await Http.post<IFormData, void>(urlService, form);
  }

  async getConceptDesignOptions(url: string, data: {} = {}): Promise<IDocumentsGenerationOptionsData> {
    const currentUrl: string = this.baseURL.concat(url);
    const response = await Http.get<IDocumentsGenerationOptionsData>(currentUrl, data);
    return response.data;
  }

  async getConceptDesignDocument(
    url: string,
    payload: IPreliminaryDocumentGenerationOptionsData | IDocumentsGenerationOptionsData
  ): Promise<IDocumentResponse> {
    const currentUrl: string = this.baseURL.concat(url);
    const args: AxiosRequestConfig = {
      headers: Http.defaultHeader,
      responseType: 'blob'
    };

    type ConceptDesignOptionsResponse = IPreliminaryDocumentGenerationOptionsData | IDocumentsGenerationOptionsData;
    const response = await Http.post<ConceptDesignOptionsResponse, Blob>(currentUrl, payload, args);

    const contentDispositionHeader = response.request.getResponseHeader('Content-Disposition');
    const fileName = parseContentDisposition(contentDispositionHeader);

    return {
      file: response.data,
      fileName: fileName ?? 'ConceptDesign.pdf'
    };
  }

  async uploadSiteImage(accountId: string, projectId: string, file: File): Promise<string> {
    const url = `/accounts/${accountId}/projects/${projectId}/site-images`;

    const imageId = await this.uploadFile(url, file);

    config.analytics?.trackEvent(new SiteImageUploadedEvent(projectId, imageId));

    return imageId;
  }

  async uploadCustomBaseImagery(accountId: string, projectId: string, file: File): Promise<void> {
    const url = `/accounts/${accountId}/projects/${projectId}/custom-base-imagery`;

    await this.uploadFile(url, file, 'put');

    config.analytics?.trackEvent(new CustomBaseImageryUploadedEvent(projectId));
  }

  async uploadDesignFile(designId: string, file: File): Promise<string> {
    const url = `/designs/${designId}/custom-design-files`;

    return this.uploadFile(url, file);
  }

  private async uploadFile(url: string, file: File, methodStr: 'post' | 'put' = 'post'): Promise<string> {
    const urlService: string = this.baseURL.concat(url);
    const args: AxiosRequestConfig = {
      headers: {
        ...Http.defaultHeader,
        'Content-Type': file.type
      }
    };
    const httpMethod: typeof Http.put | typeof Http.post = Http[methodStr].bind(Http);
    const response = await httpMethod<File, { id: string }>(urlService, file, args);
    return response.data.id;
  }

  async getCircuitConnections(designID: string): Promise<ICircuitConnectionsData> {
    const urlService: string = this.baseURL.concat(`/designs/${designID}/circuit-connections`);
    const response = await Http.get<ICircuitConnectionsData>(urlService, {});
    return response.data;
  }

  async patchCircuitConnections(designID: string, form: CircuitDataType): Promise<CircuitsConnectionsResponse> {
    const urlService: string = this.baseURL.concat(`/designs/${designID}/circuit-connections`);
    const response = await Http.patch<CircuitDataType, CircuitsConnectionsResponse>(urlService, form);
    return response.data;
  }

  async getSitePlan(designID: string): Promise<ISitePlanData> {
    const urlService: string = this.baseURL.concat(`/designs/${designID}/site-plan`);
    const response = await Http.get<ISitePlanData>(urlService, {});
    return response.data;
  }

  async updateSitePlan(designID: string, IUpdatedSitePlanData: IUpdatedSitePlanData): Promise<void> {
    const urlService: string = this.baseURL.concat(`/designs/${designID}/site-plan`);
    await Http.post<IFormData, void>(urlService, IUpdatedSitePlanData);
  }

  getSiteImageThumbnailUrl(accountId: string, projectId: string, imageId: string): string {
    return this.baseURL.concat(`/accounts/${accountId}/projects/${projectId}/site-images/${imageId}/thumbnail`);
  }

  async getPermitPackageOptions(designID: string): Promise<IPermitPackageDocumentGenerationOptionsData> {
    const urlService: string = this.baseURL.concat(`/designs/${designID}/documents/permit-package-options`);
    const response = await Http.get<IPermitPackageDocumentGenerationOptionsData>(urlService, {});
    return response.data;
  }

  async getOrder(designID: string): Promise<IOrderPreview> {
    const urlService: string = this.baseURL.concat(`/designs/${designID}/documents/permit-package/order`);
    const response = await Http.get<IOrderPreview>(urlService, {});
    return response.data;
  }

  async savePermitPackageOptions(
    designId: string,
    permitPackageGenerationOptions: IPermitPackageDocumentGenerationOptionsData
  ): Promise<IPermitPackageUrls> {
    const urlService: string = this.baseURL.concat(`/designs/${designId}/documents/permit-package`);
    const response = await Http.post<IPermitPackageDocumentGenerationOptionsData, IPermitPackageUrls>(
      urlService,
      permitPackageGenerationOptions
    );
    return response.data;
  }

  // Due to hard limitation of API gateway that we are using on the BE,
  // we cannot return files more than 10 MB in size directly in response.
  // That's why we return direct pre-authorized link instead, so that FE
  // can get file directly AWS file storage bypassing API gateway.
  async getPermitPackageLink(url: string): Promise<string> {
    const urlService: string = this.baseURL.concat(url);

    const response: AxiosResponse = await Http.get(urlService, {});
    return response.data.location;
  }

  // This method returns actual blob of the PermitPackage/PermitPackagePreview file.
  // See comment above for more details why we pass url here from the
  // upper layer of the abstraction.
  async getPermitPackageDoc(url: string, preview?: boolean): Promise<IDocumentResponse> {
    const args: AxiosRequestConfig = {
      responseType: 'blob'
    };

    const defaultFileName = preview ? 'PreviewPermitPackage.pdf' : 'PermitPackage.zip';
    const mimeType = preview ? 'application/pdf' : 'application/zip';

    const response: AxiosResponse = await Http.get(url, {}, args, false);
    const contentDispositionHeader = response.request.getResponseHeader('Content-Disposition');
    const blobify = blobifyApiResponse(mimeType);

    const file = blobify(response.data);
    const fileName = parseContentDisposition(contentDispositionHeader) ?? defaultFileName;

    return {
      file,
      fileName
    };
  }

  async getSystemDatasheetOptions(designId: string): Promise<ISystemDatasheetOptions> {
    const url = this.baseURL.concat(`/designs/${designId}/documents/system-datasheet-options`);
    const response: AxiosResponse<ISystemDatasheetOptions> = await Http.get(url, {});
    return response.data;
  }

  async getPreliminarySystemDatasheetOptions(
    designId: string,
    extInstallerId: string
  ): Promise<ISystemDatasheetOptions> {
    const url = this.baseURL.concat(`/designs/${designId}/documents/preliminary-system-datasheet-options`);
    const response: AxiosResponse<ISystemDatasheetOptions> = await Http.get(url, {
      externalInstallerId: extInstallerId
    });
    return response.data;
  }

  async getBillOfMaterialsDocument(designId: string, format: BillOfMaterialsType): Promise<IDocumentResponse> {
    const url = this.baseURL.concat(`/designs/${designId}/documents/bill-of-materials`);
    const params = { format };
    const extension = format === 'PDF' ? 'pdf' : 'xlsx';

    const args: AxiosRequestConfig = {
      responseType: 'blob'
    };

    const response: AxiosResponse = await Http.get(url, params, args, true);

    const contentDispositionHeader = response.request.getResponseHeader('Content-Disposition');
    const fileName = parseContentDisposition(contentDispositionHeader);

    return {
      file: response.data,
      fileName: fileName ?? `Bill of Materials.${extension}`
    };
  }

  async generateSystemDatasheet(designId: string, data: ISystemDatasheetOptions): Promise<IDocumentResponse> {
    const url = this.baseURL.concat(`/designs/${designId}/documents/system-datasheet`);
    const args: AxiosRequestConfig = {
      headers: Http.defaultHeader,
      responseType: 'blob'
    };
    const response: AxiosResponse = await Http.post(url, data, args);

    const contentDispositionHeader = response.request.getResponseHeader('Content-Disposition');
    const fileName = parseContentDisposition(contentDispositionHeader);

    return {
      file: response.data,
      fileName: fileName ?? 'SystemDatasheet.pdf'
    };
  }

  async generatePreliminarySystemDataSheet(
    designId: string,
    data: IPreliminarySystemDatasheetParams
  ): Promise<IDocumentResponse> {
    const url = this.baseURL.concat(`/designs/${designId}/documents/preliminary-system-datasheet`);
    const args: AxiosRequestConfig = {
      headers: Http.defaultHeader,
      responseType: 'blob'
    };
    const response: AxiosResponse = await Http.post(url, data, args);

    const contentDispositionHeader = response.request.getResponseHeader('Content-Disposition');
    const fileName = parseContentDisposition(contentDispositionHeader);

    return {
      file: response.data,
      fileName: fileName ?? 'SystemDatasheet.pdf'
    };
  }

  uploadLogo = async (installerId: string, file: File): Promise<void> => {
    const url = this.baseURL.concat(`/installers/${installerId}/logo`);
    await Http.put<File, void>(url, file, {
      headers: {
        'Content-Type': file.type
      }
    });
  };

  deleteLogo = async (installerId: string): Promise<void> => {
    const url = this.baseURL.concat(`/installers/${installerId}/logo`);
    await Http.delete(url);
  };

  checkLogo = async (accountId: string, installerId: string): Promise<void> => {
    const url = this.baseURL.concat(`/accounts/${accountId}/installers/${installerId}/logo`);
    await Http.get(
      url,
      {},
      {
        headers: {
          Accept: 'image/*'
        }
      }
    );
  };
}
