import type { PropsTheme } from 'styled-components';
import styled from 'styled-components';
import type { Color } from '../../../domain/typings';
import { CollapseStyle } from '../../components/Collapse/styles';

type AddImageButtonProps = {
  onClick?: (event: MouseEvent) => void;
};

export const AddImageButton = styled.button<PropsTheme<AddImageButtonProps>>`
  width: 100%;
  min-height: 30px;
  border-radius: 16px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.5);
  cursor: pointer;
  position: relative;
  background-color: ${({ theme }: PropsTheme<AddImageButtonProps>): Color => theme.colors.whiteBg};
  margin-top: 15px;

  svg {
    position: absolute;
    left: 15px;
    margin: auto;
    top: 0;
    bottom: 0;
  }
`;

export const BasicPropsContainer = styled.div`
  width: 100%;
  padding: 15px 0px 15px;

  & > ${CollapseStyle} {
    position: relative;

    & > div:first-child {
      background: none;
      padding: 0;
      font-family: 'Roboto', sans-serif;
      font-weight: bold;
      font-size: 14px;
      color: #4a4a4a;

      & > span {
        font-size: 14px;
        font-weight: bold;
      }

      & svg {
        position: absolute;
        top: 0;
        right: 0;
      }
    }
  }
`;
