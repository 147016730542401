import { LyraCard } from '@aurorasolar/lyra-ui-kit';
import styled, { withTheme } from 'styled-components';

export const ElectricalDesignCard = styled(LyraCard.Card)`
  padding: 15px 0px;
  width: auto;
`;

export const InverterWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const InverterRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  &:nth-child(2n) {
    margin-top: 15px;
  }
`;

export const InverterDescription = styled.div`
  display: flex;
  flex-direction: column;
  width: 75%;
`;

export const InverterDescriptTitle = styled.span`
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: normal;
  line-height: normal;
  color: #8f8f8f;
`;

export const InverterDescriptName = styled.span`
  font-size: 13px;
  font-weight: bold;
  line-height: normal;
  color: #4a4a4a;
`;
