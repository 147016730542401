import Registry from '../../../utils/Registry';
import EquipmentPanel from './EquipmentPanel/EquipmentPanel';
import MpptAndMultiInverterSelectionPanel from './MPPTAndMultiInverterSelectionPanel/MPPTAndMultiInverterSelectionPanel';
import SystemSummaryPanel from './SystemSummaryPanel';

const panelRegistry = new Registry();

panelRegistry.add('system_summary_panel', SystemSummaryPanel);
panelRegistry.add('MPPT_and_multi_inverter_selection_panel', MpptAndMultiInverterSelectionPanel);
panelRegistry.add('equipment_panel', EquipmentPanel);

export { panelRegistry };
