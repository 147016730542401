import { observable } from 'mobx';
import EngineerOfRecord, { type IEngineerOfRecordData } from './EngineerOfRecord';
import PermitPackageAdvancedSettings, { type IPermitPackageAdvancedSettingsData } from './PermitPackageAdvancedSettings';
import PermitPackageFiles from './PermitPackageFiles';
import type { IPermitPackageFilesData } from './PermitPackageFiles';
import PermitPackageOptions, { type IPermitPackageOptionsData } from './PermitPackageOptions';

export interface IPermitPackageDocumentGenerationOptionsData {
  readonly options: IPermitPackageOptionsData;
  readonly includedFiles: IPermitPackageFilesData;
  readonly advancedSettings: IPermitPackageAdvancedSettingsData;
  readonly engineerOfRecord?: IEngineerOfRecordData;
}

export default class PermitPackageDocumentGenerationOptions {
  @observable
  options: PermitPackageOptions;

  @observable
  includedFiles: PermitPackageFiles;

  @observable
  advancedSettings: PermitPackageAdvancedSettings;

  @observable
  engineerOfRecord: EngineerOfRecord;

  constructor(data: IPermitPackageDocumentGenerationOptionsData, internalReferenceId: string) {
    this.options = new PermitPackageOptions(data.options, internalReferenceId);
    this.includedFiles = new PermitPackageFiles(data.includedFiles);
    this.advancedSettings = new PermitPackageAdvancedSettings(data.advancedSettings);
    this.engineerOfRecord = new EngineerOfRecord(data.engineerOfRecord);
  }

  toData(): IPermitPackageDocumentGenerationOptionsData {
    return {
      options: this.options.toData(),
      includedFiles: this.includedFiles.toData(),
      advancedSettings: this.advancedSettings.toData(),
      engineerOfRecord: this.engineerOfRecord.toData(this.advancedSettings.titleBlock.showEngineerOfRecordInfo)
    };
  }
}
