import type Limit from './Limit';

export const YES_OR_NO_OPTIONS = [
  {
    name: 'YES',
    value: 'YES'
  },
  {
    name: 'NO',
    value: 'NO'
  }
];
export const NUMBER_OF_STORIES_LIMIT: Limit = {
  lower: 1,
  upper: 4
};

export const MEASURE_LIMIT: Limit = {
  lower: 1,
  upper: 9999999999
};

// Math constants radians
export const PI = Math.PI;
export const QUARTER_PI = PI / 4;
export const HALF_PI = PI / 2;
export const TWO_PI = PI * 2;
// degrees
export const STRAIGHT_ANGLE = 180;
export const RIGHT_ANGLE = 90;
export const FULL_ANGLE = 360;

// Z Levels
export const DEFAULT_Z = 0;
// PvModule Types
export const AC_BRANCH = 'AC_BRANCH';
export const AC_MODULE = 'AC_MODULE';
export const DC_MODULE = 'MODULE';
// Default voltage supported
export const AC_POINT_OF_INTERCONNECTION_VOLTAGE = 240;
// Summary panel types
export const BRANCH_MICROINVERTER: string = 'BRANCH_OF_MICROINVERTERS';
export const BRANCH_AC_MODULE: string = 'BRANCH_OF_AC_MODULES';
export const STRING_WITH_DC_OPTIMIZERS: string = 'SERIES_STRING_OF_DC_OPTIMIZERS';
export const CENTRAL_MPPT_STRING: string = 'SERIES_STRING_OF_DC_MODULES';

// Toast Constants
export const CONFIRMATION: string = 'confirmation';
export const ERROR: string = 'error';
export const WARNING: string = 'warning';

// Inverter types
export const INVERTER_TYPE_MICROINVERTER_SHORT = 'MICRO';
export const INVERTER_TYPE_STRING_INVERTER = 'STRING_INVERTER';
export const INVERTER_SOURCE_TYPE_MPPT = 'MPPT';
export const INVERTER_SOURCE_TYPE_MPPT_PARALLEL_STRING = 'PV_SOURCE_CIRCUIT';
export const INVERTER_SOURCE_TYPE_DC_OPTIMIZER = 'DC_OPTIMIZER_SOURCE_CIRCUIT';
export const ROOF_FACE_SLOPE_FRACTION_DIGITS: number = 2;

// Building Heights limits
export const CEILING_HEIGHT_LIMIT: Limit = {
  lower: 6,
  upper: 30
};
export const FOUNDATION_HEIGHT_LIMIT: Limit = {
  lower: 0.5,
  upper: 10
};

// Roof face slope limits
export const ROOF_FACE_SLOPE_PITCH_LIMIT: Limit = {
  lower: 0.1,
  upper: 90
};
export const ROOF_FACE_SLOPE_DEGREES_LIMIT: Limit = {
  lower: 0.1,
  upper: 82.41
};

// Elevation limits
export const ELEVATION_FEET_LIMIT: Limit = {
  lower: 0,
  upper: 29031
};

//Messages constants
export const MESSAGE_BACKGROUND_COLOR = {
  success: 'rgba(82, 184, 184, 0.25)',
  error: 'rgba(255, 115, 110, 0.25)',
  warning: 'rgb(255,173,110, 0.25)',
  info: 'rgb(110,163,255, 0.25)'
};

export const MESSAGE_BORDER_COLOR = {
  success: '#1fc0c0',
  error: '#ff736e',
  warning: '#ffad6e',
  info: '#6ec5ff'
};

export enum SceneObjectType {
  // Keys in SceneObjectType match respective keys in EditorStore.RenderingDistanceBoundary
  Protrusion = 'rectangular_protrusion',
  RoofFace = 'roof_face',
  Outline = 'outline',
  ParcelBoundary = 'parcel_boundary',
  /**
   * Part of service entrance equipment
   */
  MainServicePanel = 'main_service_panel',
  /**
   * Part of service entrance equipment
   */
  UtilityMeter = 'utility_meter',
  MeterMain = 'meter_main',
  GasMeter = 'gas_meter',
  Street = 'street_location',
  Subpanel = 'subpanel',
  ElectricalEquipmentMarker = 'electrical_equipment_marker',
  Setback = 'setback',
  Pathway = 'pathway',
  PvModulePosition = 'pv_module_position',
  PvModule = 'pv_module',
  String = 'string',
  CustomBaseImagery = 'custom-base-imagery',
  CustomBaseImageryHandle = 'custom-base-imagery-handle',
  Vertex = 'vertex',
  Arrow = 'arrow',
  Segment = 'segment',
  PotentialInfo = 'potential_info',
  Compass = 'compass',
  TraceToDefineScalingLine = 'trace-to-define-scaling-line'
}

export const getSiteEquipmentMarkerTypes = (): SceneObjectType[] => {
  return [
    SceneObjectType.MainServicePanel,
    SceneObjectType.UtilityMeter,
    SceneObjectType.MeterMain,
    SceneObjectType.GasMeter,
    SceneObjectType.Subpanel,
    SceneObjectType.Street,
    SceneObjectType.ElectricalEquipmentMarker
  ];
};

export const enum HintObjectType {
  UtilityMeter = 'site_marker_utility_meter',
  GasMeter = 'site_marker_gas_meter',
  Street = 'site_marker_street_location',
  Subpanel = 'site_marker_subpanel',
  ServiceEntranceEquipment = 'site_marker_service_entrance_equipment'
}

export const enum PropsPanelHintsForTools {
  OutlineTool = 'outline_tool',
  ParcelBoundaryTool = 'parcel_boundary_tool'
}
