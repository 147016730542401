(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("@aurorasolar/lyra-ui-kit"), require("react"), require("styled-components"), require("react-toastify"), require("react-dom"), require("@aurorasolar/lyra-form-generator"));
	else if(typeof define === 'function' && define.amd)
		define("lyra-design-tool", ["@aurorasolar/lyra-ui-kit", "React", "styled-components", "react-toastify", "ReactDOM", "@aurorasolar/lyra-form-generator"], factory);
	else if(typeof exports === 'object')
		exports["lyra-design-tool"] = factory(require("@aurorasolar/lyra-ui-kit"), require("react"), require("styled-components"), require("react-toastify"), require("react-dom"), require("@aurorasolar/lyra-form-generator"));
	else
		root["lyra-design-tool"] = factory(root["@aurorasolar/lyra-ui-kit"], root["React"], root["styled-components"], root["react-toastify"], root["ReactDOM"], root["@aurorasolar/lyra-form-generator"]);
})(self, (__WEBPACK_EXTERNAL_MODULE_ZtK8__, __WEBPACK_EXTERNAL_MODULE_LCbx__, __WEBPACK_EXTERNAL_MODULE__1zHa__, __WEBPACK_EXTERNAL_MODULE_ly8N__, __WEBPACK_EXTERNAL_MODULE_vrhC__, __WEBPACK_EXTERNAL_MODULE_Qh_7__) => {
return 