import * as FileSaver from 'file-saver';
import {
  action, observable
} from 'mobx';
import find from 'lodash/find';
import type { Option } from '@aurorasolar/lyra-ui-kit';
import type { IDocumentResponse } from '../../../../../domain/entities/Documents/DocumentsResponse';
import {
  handleApiError, notify
} from '../../../../../utils/helpers';
import type { BillOfMaterialsType } from '../../../../../infrastructure/services/api/DocumentsService';
import type DomainStore from '../../../../DomainStore/DomainStore';
import type EditorStore from '../../../../EditorStore/EditorStore';
import { BaseViewModel } from '../../BaseViewModel';
import type { ModalStore } from '../../Modal';
import { applyBlobMimeHackForFirefox } from '../../../../../utils/request';
import { BillOfMaterialsGeneratedEvent } from '../../../../../services/analytics/DesignToolAnalyticsEvents';
import config from '../../../../../config/config';

type FilterFunction = (option: Option) => boolean;

interface IBillOfMaterialsViewModelDependencies {
  readonly modal: ModalStore;
  readonly domain: DomainStore;
  readonly editor: EditorStore;
}

export class BillOfMaterialsViewModel extends BaseViewModel {
  readonly propCodeUI = 'bill_of_materials_modal';
  override readonly editor: EditorStore;

  readonly documentFormatOptions: Option[] = [
    {
      name: 'PDF',
      value: 'PDF'
    },
    {
      name: 'XLSX',
      value: 'EXCEL'
    }
  ];

  @observable
  documentFormat: Option = this.documentFormatOptions[0];

  constructor(dependencies: IBillOfMaterialsViewModelDependencies) {
    super(dependencies);
    this.domain = dependencies.domain;
    this.editor = dependencies.editor;
  }

  @action.bound
  setDocumentFormat(value?: string): void {
    this.documentFormat = find(this.documentFormatOptions, this.findOptionByValue(value))!;
  }

  downloadBillOfMaterialsDocument(): void {
    const documentFormat = this.documentFormat.value as BillOfMaterialsType;
    const designId = this.domain.design.id;

    this.documentsService
      .getBillOfMaterialsDocument(designId, documentFormat)
      .then((response: IDocumentResponse): void => {
        FileSaver.saveAs(applyBlobMimeHackForFirefox(response.file), response.fileName);
        this.closeModal();
        config.analytics?.trackEvent(new BillOfMaterialsGeneratedEvent(this.domain));
        notify('The Bill of Materials is downloaded', 'confirmation');
      })
      .catch(handleApiError(`Could not generate ${documentFormat} bill of materials`));
  }

  private findOptionByValue(value: string = ''): FilterFunction {
    return (option: Option): boolean => `${option.value}` === `${value}`;
  }

  override dispose() {
    // do nothing
  }
}
