import flatMap from 'lodash/flatMap';
import sortBy from 'lodash/sortBy';
import map from 'lodash/map';
import max from 'lodash/max';
import uniqBy from 'lodash/uniqBy';
import uniq from 'lodash/uniq';
import find from 'lodash/find';
import findLast from 'lodash/findLast';
import type {
  ISeriesStringConfigurationData,
  IStringingOptionsResponseData as StringingOption,
  IStringingOptionsResponseData,
  IMpptStringingOptionData,
  IStringingOptionData
} from '../../domain/entities/StringingOption/IStringingOptionData';
import type { IInverterInfo } from '../../domain/stages/DesignStages/ElectricalDesignStage';
import { Stringing } from '../../domain/graphics/stringing/Stringing';
import type { IPvSystemCircuitData } from '../../domain/models/PvSystem/PvSystemCircuit';
import type { SupplementalData } from '../../domain/models/SupplementalData/SupplementalData';
import type { Design } from '../../domain/models/Design/Design';
import type PvModule from '../../domain/models/SiteDesign/PvModule';

import {
  DC_MODULE,
  AC_BRANCH,
  INVERTER_TYPE_STRING_INVERTER,
  INVERTER_SOURCE_TYPE_MPPT,
  INVERTER_SOURCE_TYPE_MPPT_PARALLEL_STRING,
  BRANCH_MICROINVERTER,
  BRANCH_AC_MODULE,
  STRING_WITH_DC_OPTIMIZERS,
  CENTRAL_MPPT_STRING,
  INVERTER_SOURCE_TYPE_DC_OPTIMIZER
} from '../../domain/models/Constants';
import type { Inverters } from '../../domain/models/PvSystem/Inverters';
import { stackTracedLog } from '../../utils/stackTracedLog';
import type Limit from '../../domain/models/Limit';
import { isWithin } from '../../domain/models/Limit';
import type {
  IInverterSelected, IExtraDataInverters
} from '../../domain/models/SupplementalData/IInverterInfo';
import type { Source } from './types';
import {
  isAcceptableRating,
  isInvalidRatingDueToHavingNotEnoughPvModules,
  StringingAcceptabilityRating
} from './stringingAcceptabilityRating';

enum DebugLoggingLevel {
  Basic = 'Basic',
  Detailed = 'Detailed'
}
// static configuration:
// local storage configuration:
const debugLogging: boolean = localStorage.stringingDebugLogging ?? false;
// static configuration:
const debugLoggingLevel: DebugLoggingLevel = DebugLoggingLevel.Basic;
// static configuration:
const debugLoggingWithStackTrace: boolean = true;

function debugLog(...args: unknown[]): void {
  if (debugLogging) {
    if (debugLoggingWithStackTrace) {
      // eslint-disable-next-line no-restricted-syntax
      stackTracedLog(...args);
    } else {
      // eslint-disable-next-line no-console
      console.log(...args);
    }
  }
}
function detailedDebugLog(...args: unknown[]): void {
  if (debugLogging && debugLoggingLevel === DebugLoggingLevel.Detailed) {
    if (debugLoggingWithStackTrace) {
      // eslint-disable-next-line no-restricted-syntax
      stackTracedLog(...args);
    } else {
      // eslint-disable-next-line no-console
      console.log(...args);
    }
  }
}

type ExtendedSource = Source & {
  mpptId?: string;
  dcOptimizerStringId?: string; // One MPPT may have multiple DC optimizer strings
};

export function getIdListByType({
  circuitSources = [],
  sourceType
}: {
  circuitSources: Source[];
  sourceType: string;
}): string[] {
  const idList: string[] = [];
  circuitSources.forEach((item: Source): void => {
    const {
      id, sources, type
    } = item;
    if (type !== sourceType && sources) {
      idList.push(
        ...getIdListByType({
          circuitSources: sources,
          sourceType: sourceType
        })
      );
    } else {
      idList.push(id);
    }
  });

  detailedDebugLog('Digging into sources structure ', circuitSources, ' to find type: ', sourceType);

  return idList;
}

export function buildInverterInfoList(design: Design): IInverterInfo[] {
  const inverters = design.system.equipment.inverters;
  const supplementalInverterData = design.supplementalData.invertersSelected;
  if (!inverters || !supplementalInverterData) {
    // System with AC PV modules or corrupt supplemental data
    return [];
  }
  if (inverters.areMicroinverters) {
    return [
      {
        model: supplementalInverterData[0]?.name || '',
        units: inverters.microinverterCount
      }
    ];
  }
  return supplementalInverterData.map(
    (supplementalInverterData: IInverterSelected): IInverterInfo => ({
      model: supplementalInverterData.name || '',
      units: 1
    })
  );
}

export function pickPvModulesUsingCircuitSources({
  design,
  sources = [],
  pvModules
}: {
  design: Design;
  sources?: Source[];
  pvModules: PvModule[];
}): PvModule[] {
  const pvModuleList: PvModule[] = [];
  const pvModuleType = design.supplementalData.pvModuleInfo?.type ?? DC_MODULE;
  const moduleIdList = getIdListByType({
    circuitSources: sources,
    sourceType: pvModuleType
  });
  moduleIdList.forEach((moduleId: string): void => {
    const pvModule = pvModules.find((value: PvModule): boolean => value.serverId === moduleId);
    if (pvModule) {
      pvModuleList.push(pvModule);
    }
  });

  detailedDebugLog('Taking pv module info info from domain: ', pvModuleList);
  return pvModuleList;
}

export function getStringingOptionsForInverter({
  stringingOptions,
  inverterId
}: {
  stringingOptions: IStringingOptionsResponseData[];
  inverterId: string;
}): IStringingOptionsResponseData {
  detailedDebugLog('Finding stringing options for inverterId: ', inverterId, ' in list: ', stringingOptions);
  if (inverterId) {
    const idx =
      stringingOptions.findIndex((item: IStringingOptionsResponseData): boolean => item.inverterId === inverterId) ?? 0;
    return stringingOptions[idx];
  }
  return stringingOptions[0];
}

type StringingModulesLimitReturnType = {
  maxModules: number;
  acceptableModulesLimitsToParallelStringNumber: Record<
    string /* parallel string number */,
    Record<string /* inverter ID */, Limit>
  >;
  maxStringsPerMppt: number;
};
/**
 * @param stringingOptionsList
 * @param inverterId
 * @returns [number, number, number] max modules, max acceptable modules, max strings per mppt
 */
export function getStringModulesLimits({
  stringingOptionsList,
  inverterId
}: {
  stringingOptionsList: IStringingOptionsResponseData[];
  inverterId: string;
}): StringingModulesLimitReturnType {
  const result: StringingModulesLimitReturnType = {
    maxModules: 0,
    acceptableModulesLimitsToParallelStringNumber: {
      '1': {
        default: {
          lower: 0,
          upper: 0
        }
      }
    },
    maxStringsPerMppt: 1
  };

  const {
    mppts = [], stringingOptions
  } = getStringingOptionsForInverter({
    stringingOptions: stringingOptionsList,
    inverterId: inverterId
  });

  detailedDebugLog('Getting stringing modules limit using mppts; ', mppts, ' or stringing options: ', stringingOptions);

  if (mppts.length) {
    result.maxStringsPerMppt =
      max(mppts[0].stringingOptions.map((option: IMpptStringingOptionData): number => option.numberOfStrings)) ?? 0;

    const singleStringStringingOptions = mppts[0].stringingOptions.filter(
      (stringingOption: IMpptStringingOptionData): boolean => stringingOption.numberOfStrings === 1
    );

    result.maxModules =
      max(
        singleStringStringingOptions.map(
          (option: IMpptStringingOptionData): number => option.seriesStringDefinition.numberOfModules
        )
      ) ?? 0;

    // Set limits for a parallel string:
    // Go through all stringing options for each MPPT and find
    // the lowest and highest valid number of modules.
    for (let mpptIndex in mppts) {
      let mpptId = mppts[mpptIndex].mppt;
      mppts[mpptIndex].stringingOptions.forEach((stringingOption: IMpptStringingOptionData): void => {
        result.acceptableModulesLimitsToParallelStringNumber[stringingOption.numberOfStrings] ||= {};
        result.acceptableModulesLimitsToParallelStringNumber[stringingOption.numberOfStrings][mpptId] ||= {
          lower: Infinity,
          upper: 1
        };

        if (
          isAcceptableRating(stringingOption.acceptabilityRating)
          && stringingOption.seriesStringDefinition.numberOfModules
            < result.acceptableModulesLimitsToParallelStringNumber[stringingOption.numberOfStrings][mpptId].lower
        ) {
          result.acceptableModulesLimitsToParallelStringNumber[stringingOption.numberOfStrings][mpptId].lower =
            stringingOption.seriesStringDefinition.numberOfModules;
        }
        if (
          isAcceptableRating(stringingOption.acceptabilityRating)
          && stringingOption.seriesStringDefinition.numberOfModules
            > result.acceptableModulesLimitsToParallelStringNumber[stringingOption.numberOfStrings][mpptId].upper
        ) {
          result.acceptableModulesLimitsToParallelStringNumber[stringingOption.numberOfStrings][mpptId].upper =
            stringingOption.seriesStringDefinition.numberOfModules;
        }
      });
    }

    return result;
  }

  result.maxModules = max(stringingOptions.map((option: IStringingOptionData): number => option.numberOfModules)) ?? 0;
  // Sort stringing options by numberOfModules
  sortBy(stringingOptions, [(option: IStringingOptionData): number => option.numberOfModules]);
  const firstAcceptableOption = find(stringingOptions, (stringingOption: IStringingOptionData): boolean =>
    isAcceptableRating(stringingOption.acceptabilityRating)
  );
  const lastAcceptableOption = findLast(stringingOptions, (stringingOption: IStringingOptionData): boolean =>
    isAcceptableRating(stringingOption.acceptabilityRating)
  );

  result.acceptableModulesLimitsToParallelStringNumber['1'].default.lower = firstAcceptableOption?.numberOfModules ?? 0;
  result.acceptableModulesLimitsToParallelStringNumber['1'].default.upper = lastAcceptableOption?.numberOfModules ?? 0;

  return result;
}

export function stringingOptionExists({
  selectedString,
  stringingOptionsList
}: {
  selectedString: Stringing;
  stringingOptionsList: IStringingOptionsResponseData[];
}): boolean {
  const inverterId = selectedString.getInverterId();
  const optionsObject = getStringingOptionsForInverter({
    stringingOptions: stringingOptionsList,
    inverterId: inverterId
  });

  if (!optionsObject) {
    return false;
  }

  const {
    stringingOptions, mppts
  } = optionsObject;

  // stringingOptions can be at root of /stringing-options response, for
  // inverters with MPPT it's MPPTs[].stringingOptions, so we're checking both:
  // whether we have MPPTs[].stringingOptions and current stringing has MPPT
  return !!stringingOptions || (!!mppts && !!selectedString.getMpptId());
}

export function setValidationRatingForAllStrings({
  allStrings,
  stringingOptionsList
}: {
  allStrings: Stringing[];
  stringingOptionsList: IStringingOptionsResponseData[];
}): void {
  detailedDebugLog(
    'Setting stringing rating for; ',
    allStrings,
    ' using stringing options list: ',
    stringingOptionsList
  );
  for (let string of allStrings) {
    let rating: StringingAcceptabilityRating;

    const sortedParallelStrings = selectSortedParallelStrings({
      strings: allStrings,
      targetString: string
    });
    const selectedStringModulesCount = string.getModules().length;
    const allParallelStringsHaveTheSameLength = sortedParallelStrings.every(
      (string: Stringing): boolean => string.getModules().length === selectedStringModulesCount
    );

    if (!allParallelStringsHaveTheSameLength) {
      rating = StringingAcceptabilityRating.ParallelStringsUnbalanced;
    } else {
      const inverterId = string.getInverterId();
      const mpptId = string.getMpptId();
      const parallelMpptStringNumber = string.parallelMpptStringNumber;

      const {
        mppts = [], stringingOptions
      } = getStringingOptionsForInverter({
        stringingOptions: stringingOptionsList,
        inverterId: inverterId
      });
      const modulesCount = string.getModules().length;
      const mpptConfiguration = mppts.find((value: ISeriesStringConfigurationData): boolean => value.mppt === mpptId);

      if (mpptConfiguration) {
        rating =
          getStringingOptionByModuleAndStringCount({
            mpptConfiguration: mpptConfiguration,
            numberOfPvModules: modulesCount,
            numberOfStrings: parallelMpptStringNumber
          })?.acceptabilityRating ?? StringingAcceptabilityRating.DangerousVocAboveInverterMaximumInputVoltage;
      } else {
        detailedDebugLog(
          'stringingOptions[index]',
          stringingOptions[modulesCount - 1],
          stringingOptions,
          modulesCount - 1
        );
        rating =
          stringingOptions[modulesCount - 1]?.acceptabilityRating
          ?? StringingAcceptabilityRating.DangerousVocAboveInverterMaximumInputVoltage;
      }
    }

    string.setValidationRating(rating);
  }
}

export function getStringingOptionByModuleAndStringCount({
  mpptConfiguration,
  numberOfPvModules,
  numberOfStrings
}: {
  mpptConfiguration: ISeriesStringConfigurationData;
  numberOfPvModules: number;
  numberOfStrings: number;
}): IMpptStringingOptionData | undefined {
  return mpptConfiguration.stringingOptions.find(
    (option: IMpptStringingOptionData): boolean =>
      option.numberOfStrings === numberOfStrings && option.seriesStringDefinition.numberOfModules === numberOfPvModules
  );
}

export function createStringFromCircuit({
  circuit,
  design,
  pvModules,
  stringingOptionsList,
  otherStrings
}: {
  circuit: IPvSystemCircuitData;
  design: Design;
  pvModules: PvModule[];
  stringingOptionsList: IStringingOptionsResponseData[];
  otherStrings: Stringing[];
}): Stringing[] {
  detailedDebugLog('Create strings from circuit: ', circuit);

  const inverterId: string | undefined = circuit.type === INVERTER_TYPE_STRING_INVERTER ? circuit.id : '';
  const { sources } = circuit;

  if (circuit.type === AC_BRANCH) {
    const pvModulesForThisString = pickPvModulesUsingCircuitSources({
      design: design,
      sources: sources,
      pvModules: pvModules
    });

    const string = new Stringing();
    string.serverId = circuit.id;

    pvModulesForThisString.forEach((pvModule: PvModule): void => {
      applyPvModuleToString(
        // Creating strings from server state, so no strings
        // conflicts are expected, passing empty array:
        {
          strings: [],
          selectedStrings: [string],
          moduleToProcess: pvModule,
          moduleToProcessTarget: string,
          targetStringEnd: 'finish'
        }
      );
      setValidationRatingForAllStrings({
        allStrings: mergeStrings(otherStrings, [string]),
        stringingOptionsList: stringingOptionsList
      });
    });
    string.finishStringing();
    string.resetModifiedState();

    debugLog('(createStringFromCircuit) Created AC branch string: ', string);

    return [string];
  } else {
    let parallelStringCountPerMpptId: { [key: string]: number } = {};
    return flattenAndEnrichDomainModelCircuitSources(sources).map((source: ExtendedSource): Stringing => {
      let mpptId: string = '';
      if (source.type === INVERTER_SOURCE_TYPE_MPPT) {
        mpptId = source.id;
      } else if (
        source.type === INVERTER_SOURCE_TYPE_DC_OPTIMIZER
        || source.type === INVERTER_SOURCE_TYPE_MPPT_PARALLEL_STRING
      ) {
        mpptId = source.mpptId ?? '';
      }

      if (!parallelStringCountPerMpptId[mpptId]) {
        parallelStringCountPerMpptId[mpptId] = 1;
      }

      const pvModulesForThisString = pickPvModulesUsingCircuitSources({
        design: design,
        sources: source.sources,
        pvModules: pvModules
      });

      const string = new Stringing(parallelStringCountPerMpptId[mpptId]);
      parallelStringCountPerMpptId[mpptId]++;

      string.serverId = source.dcOptimizerStringId || source.id;
      string.setInverterId(inverterId);
      string.setMpptId(mpptId);

      pvModulesForThisString.forEach((pvModule: PvModule): void => {
        applyPvModuleToString(
          // Creating strings from server state, so no strings
          // conflicts are expected, passing empty array:
          {
            strings: [],
            selectedStrings: [string],
            moduleToProcess: pvModule,
            moduleToProcessTarget: string,
            targetStringEnd: 'finish'
          }
        );
        setValidationRatingForAllStrings({
          allStrings: mergeStrings(otherStrings, [string]),
          stringingOptionsList: stringingOptionsList
        });
      });
      string.finishStringing();
      string.resetModifiedState();

      debugLog('(createStringFromCircuit) Created DC branch string: ', string);

      return string;
    });
  }
}

export function getPvModuleIdsFromCircuit({
  circuit,
  design,
  pvModules
}: {
  circuit: IPvSystemCircuitData;
  design: Design;
  pvModules: PvModule[];
}): {
  stringServerId: string;
  pvModuleIds: string[];
}[] {
  const { sources } = circuit;

  if (circuit.type === AC_BRANCH) {
    const pvModulesForThisString = pickPvModulesUsingCircuitSources({
      design: design,
      sources: sources,
      pvModules: pvModules
    });
    const stringServerId = circuit.id;

    return [
      {
        stringServerId,
        pvModuleIds: pvModulesForThisString.map((pvModule: PvModule): string => pvModule.serverId)
      }
    ];
  }

  return flattenAndEnrichDomainModelCircuitSources(sources).map(
    (
      source: ExtendedSource
    ): {
      stringServerId: string;
      pvModuleIds: string[];
    } => {
      const pvModulesForThisString = pickPvModulesUsingCircuitSources({
        design: design,
        sources: source.sources,
        pvModules: pvModules
      });
      const stringServerId = source.dcOptimizerStringId || source.id;

      return {
        stringServerId,
        pvModuleIds: pvModulesForThisString.map((pvModule: PvModule): string => pvModule.serverId)
      };
    }
  );
}

export function flattenAndEnrichDomainModelCircuitSources(sources?: IPvSystemCircuitData[]): ExtendedSource[] {
  const flatSources: ExtendedSource[] = [];
  sources?.forEach((source: Source): void => {
    if (source.sources?.[0].type === INVERTER_SOURCE_TYPE_MPPT_PARALLEL_STRING) {
      source.sources?.forEach((nestedSource: Source): void => {
        flatSources.push({
          ...nestedSource,
          mpptId: source.id
        });
      });
    } else if (
      source.type === INVERTER_SOURCE_TYPE_MPPT
      && source.sources?.[0].type === INVERTER_SOURCE_TYPE_DC_OPTIMIZER
    ) {
      // MPPT inverter with DC Optimizers
      source.sources?.forEach((nestedSource: Source): void => {
        flatSources.push({
          ...nestedSource,
          mpptId: source.id,
          dcOptimizerStringId: nestedSource.id
        });
      });
    } else {
      flatSources.push(source);
    }
  });

  return flatSources;
}

// TODO: it's actually possible to have different number of allowed
//       parallel strings on different MPPT channels.
export const getMaxStringsPerMpptNumber = (mpptConfiguration: ISeriesStringConfigurationData): number =>
  max(mpptConfiguration.stringingOptions.map((option: IMpptStringingOptionData): number => option.numberOfStrings))
  ?? 0;

export function hasMpptAvailable({
  strings,
  stringingOptionsList
}: {
  strings: Stringing[];
  stringingOptionsList: IStringingOptionsResponseData[];
}): boolean {
  detailedDebugLog('Checking if MPPT available.');

  const totalNumberOfMppts = stringingOptionsList.reduce(
    (sumOfMppts: number, item: StringingOption): number => sumOfMppts + (item.mppts?.length ?? 0),
    0
  );

  detailedDebugLog('total MPPTs: ', totalNumberOfMppts);

  if (totalNumberOfMppts === 0) {
    return true;
  }

  const usedMppts = strings
    .map((string: Stringing): string => string.getMpptId())
    .filter((mpptId: string | null) => typeof mpptId === 'string');

  let mpptAvailable: boolean = false;
  stringingOptionsList.forEach((optionsResponse: IStringingOptionsResponseData): void => {
    optionsResponse.mppts?.forEach((mpptConfiguration: ISeriesStringConfigurationData): void => {
      if (!usedMppts.includes(mpptConfiguration.mppt)) {
        mpptAvailable = true;
      }
    });
  });

  return mpptAvailable;
}

export function isSelectedPvModuleIsInNonSelectedString({
  pvModuleSelected,
  selectedStrings,
  strings
}: {
  pvModuleSelected: PvModule;
  selectedStrings: Stringing[];
  strings: Stringing[];
}): boolean {
  detailedDebugLog('Checking whether pv module is in other stringing.');
  const selectedStringsServerIds = selectedStrings.map((selectedString: Stringing): string => selectedString.serverId);
  return strings
    .filter((string: Stringing): boolean => !selectedStringsServerIds.includes(string.serverId))
    .some((string: Stringing): boolean => {
      return string.containsPvModule(pvModuleSelected);
    });
}

export function getStringByPvModule({
  pvModuleSelected,
  strings
}: {
  pvModuleSelected: PvModule;
  strings: Stringing[];
}): Stringing | undefined {
  detailedDebugLog('Getting stringing by PV module.');
  return strings.find((string: Stringing): boolean => string.containsPvModule(pvModuleSelected));
}

export function createNewString({
  pvModule,
  strings,
  stringingOptionsList
}: {
  pvModule: PvModule;
  strings: Stringing[];
  stringingOptionsList: IStringingOptionsResponseData[];
}): Stringing {
  detailedDebugLog('Creating new stringing.');

  const string = new Stringing();
  applyPvModuleToString({
    strings: strings,
    selectedStrings: [string],
    moduleToProcess: pvModule,
    moduleToProcessTarget: string,
    targetStringEnd: 'finish'
  });

  return string;
}

export enum InverterSelectionForNewStringResult {
  NeedToOpenMpptAndMultiInverterSelectionPanel = 'NeedToOpenMpptAndMultiInverterSelectionPanel',
  NoInverterId = 'NoInverterId',
  Success = 'Success',
  UseNewParallelString = 'UseNewParallelString'
}

export function selectAndSetInverterForNewString({
  string,
  inverters,
  invertersData,
  useParallelStringing = false,
  prevStringMpptId,
  prevStringInverterId,
  prevParallelMpptStringNumber
}: {
  string: Stringing;
  inverters: Inverters | undefined;
  invertersData: IInverterSelected[];
  useParallelStringing?: boolean;
  prevStringMpptId?: string;
  prevStringInverterId?: string;
  prevParallelMpptStringNumber?: number;
}): InverterSelectionForNewStringResult {
  detailedDebugLog('Selecting inverter for new stringing.');

  const isAcPvModuleSystem = !inverters;
  if (inverters?.areMicroinverters || isAcPvModuleSystem) {
    detailedDebugLog('Microinverter or AC PV module system, no inverter selection required.');
    return InverterSelectionForNewStringResult.Success;
  } else if (invertersData.length >= 1) {
    const inverter = invertersData[0];
    const inverterMppts = invertersData[0].data?.mppts;

    const canUseParallelStringing = inverterMppts && inverterMppts.length > 0 && useParallelStringing;
    if (canUseParallelStringing) {
      detailedDebugLog('Using next parallel string in multi-string MPPT system.');

      string.setInverterId(prevStringInverterId!);
      string.setMpptId(prevStringMpptId!);
      string.parallelMpptStringNumber = prevParallelMpptStringNumber! + 1;

      return InverterSelectionForNewStringResult.UseNewParallelString;
    }
    if (invertersData.length > 1) {
      detailedDebugLog('Multi inverter system, need to select inverter.');
      return InverterSelectionForNewStringResult.NeedToOpenMpptAndMultiInverterSelectionPanel;
    }
    const needToOpenMpptAndMultiInverterSelectionPanel = inverterMppts && inverterMppts.length > 1;
    if (needToOpenMpptAndMultiInverterSelectionPanel) {
      detailedDebugLog('Multi MPPT system, need to select MPPT.');

      return InverterSelectionForNewStringResult.NeedToOpenMpptAndMultiInverterSelectionPanel;
    }

    if (!inverter.instanceId) {
      detailedDebugLog('No inverter instance id found.');

      return InverterSelectionForNewStringResult.NoInverterId;
    }

    const mpptPresent = inverterMppts && inverterMppts.length === 1;
    if (mpptPresent) {
      string.setInverterId(inverter.instanceId);
      string.setMpptId(inverterMppts[0].mppt);
    } else {
      string.setInverterId(inverter.instanceId);
    }
  }

  return InverterSelectionForNewStringResult.Success;
}

export function getInverterType(stringingOptions: StringingOption[]): string {
  return stringingOptions[0]?.type;
}

export enum ApplyPvModuleToStringResult {
  BalanceParallelStrings = 'BalanceParallelStrings',
  CreateNewStringInParallelForTheSameMppt = 'CreateNewStringInParallelForTheSameMppt',
  SwitchToEditingPreviousParallelString = 'SwitchToEditingPreviousParallelString',
  SwitchToEditingPreviousParallelStringWithModuleTransfer = 'SwitchToEditingPreviousParallelStringWithModuleTransfer',
  Idle = 'Idle'
}

export function mergeStrings(stringsList1: Stringing[], stringsList2: Stringing[]): Stringing[] {
  return uniqBy([...stringsList1, ...stringsList2], 'serverId');
}

function shouldParallelStringsBeReplacedWithOneString({
  string,
  parallelStrings,
  stringingOptions,
  targetStringEnd = 'finish'
}: {
  string: Stringing;
  parallelStrings: Stringing[];
  stringingOptions?: IStringingOptionsResponseData[];
  targetStringEnd?: 'start' | 'finish';
}): boolean {
  if (!stringingOptions || parallelStrings.length < 2 || ![1, 2].includes(string.parallelMpptStringNumber)) {
    return false;
  }

  const allOptions = getStringingOptionsForInverter({
    stringingOptions: stringingOptions,
    inverterId: string.getInverterId()
  });
  const thisStringsOptions = allOptions.mppts?.find(
    (configuration: ISeriesStringConfigurationData): boolean => configuration.mppt === string.getMpptId()
  )?.stringingOptions;

  let currentStringingOptionIsInvalid: boolean = false;
  let stringingOptionIsPresent: boolean = false;
  const [firstString, secondString] = selectSortedParallelStrings({
    strings: parallelStrings,
    targetString: string
  });
  const currentSecondStringLength = secondString?.getModules().length ?? 0;
  const currentFirstStringLength = firstString.getModules().length;

  thisStringsOptions?.forEach((option: IMpptStringingOptionData): void => {
    if (
      option.numberOfStrings === 2
      && option.seriesStringDefinition.numberOfModules
        === (targetStringEnd === 'finish' ? currentSecondStringLength : currentFirstStringLength)
      && isInvalidRatingDueToHavingNotEnoughPvModules(option.acceptabilityRating)
    ) {
      currentStringingOptionIsInvalid = true;
    }
    if (
      option.numberOfStrings === 1
      && option.seriesStringDefinition.numberOfModules === currentFirstStringLength + currentSecondStringLength
    ) {
      stringingOptionIsPresent = true;
    }
  });

  return !!thisStringsOptions && stringingOptionIsPresent && currentStringingOptionIsInvalid;
}

export function getLastParallelString({
  parallelStrings,
  activeString
}: {
  parallelStrings: Stringing[];
  activeString: Stringing;
}): Stringing | undefined {
  const sortedParallelStrings = selectSortedParallelStrings({
    strings: parallelStrings,
    targetString: activeString
  });
  return sortedParallelStrings[sortedParallelStrings.length - 1];
}

export function applyPvModuleToString({
  strings,
  selectedStrings,
  moduleToProcess,
  moduleToProcessTarget,
  stringingOptions,
  targetStringEnd
}: {
  strings: Stringing[];
  selectedStrings: Stringing[];
  moduleToProcessTarget: Stringing;
  moduleToProcess: PvModule;
  stringingOptions?: IStringingOptionsResponseData[];
  targetStringEnd: 'start' | 'finish';
}): ApplyPvModuleToStringResult {
  detailedDebugLog('Processing module: ', moduleToProcess, ' for string: ', moduleToProcessTarget);

  const allStrings = mergeStrings(strings, selectedStrings);

  if (moduleToProcessTarget.getModules().length === 0) {
    detailedDebugLog('Beginning stringing.');
    moduleToProcessTarget.beginStringing(moduleToProcess);

    return ApplyPvModuleToStringResult.Idle;
  }

  const selectedStringsModules = moduleToProcessTarget.getModules();

  const parallelStrings = selectSortedParallelStrings({
    strings: allStrings,
    targetString: moduleToProcessTarget
  });
  const parallelStringsModules: PvModule[] = [];
  for (const string of parallelStrings) {
    parallelStringsModules.push(...string.getModules());
  }

  const firstParallelString = parallelStrings[0];
  const lastParallelString = parallelStrings[parallelStrings.length - 1];
  const stringHasParallelStrings = parallelStrings.length > 1;

  const moduleIsInAnyString: boolean = allStrings.some((string: Stringing): boolean =>
    string.containsPvModule(moduleToProcess)
  );

  const moduleIsInNonSelectedString = isSelectedPvModuleIsInNonSelectedString({
    pvModuleSelected: moduleToProcess,
    selectedStrings: selectedStrings,
    strings: allStrings
  });
  const moduleIsInSelectedOrParallelString = moduleIsInAnyString && !moduleIsInNonSelectedString;

  detailedDebugLog('Processing module for a string, already exists? - ', moduleIsInAnyString);

  // Check if spawning a parallel string is possible
  const allParallelStringsHaveMaxModules = parallelStrings
    .map((string): number => string.getModules().length)
    .every((modulesCount): boolean => modulesCount >= moduleToProcessTarget.acceptableStringModulesLimit.upper);

  const isParallelString = moduleToProcessTarget.maxStringsPerMppt > 1;
  const spawningParallelStringIsPossible =
    !moduleIsInAnyString
    && allParallelStringsHaveMaxModules
    && selectedStringsModules.length >= moduleToProcessTarget.acceptableStringModulesLimit.upper
    && isParallelString
    && lastParallelString.parallelMpptStringNumber < lastParallelString.maxStringsPerMppt;

  if (spawningParallelStringIsPossible) {
    detailedDebugLog('CreateNewStringInParallelForTheSameMppt');
    return ApplyPvModuleToStringResult.CreateNewStringInParallelForTheSameMppt;
  }

  const addModuleToStringIsPossible =
    !moduleIsInSelectedOrParallelString
    && !moduleIsInNonSelectedString
    // Check if string length is maxed out. For parallel strings, check if
    // combined modules length < upper limit * number of strings, and for a single
    // string, check if modules length < max modules (no matter valid or not)
    && (isParallelString
      ? parallelStringsModules.length
        < moduleToProcessTarget.acceptableStringModulesLimit.upper * moduleToProcessTarget.maxStringsPerMppt
      : selectedStringsModules.length < moduleToProcessTarget.maxModules);

  if (addModuleToStringIsPossible) {
    if (!moduleIsInNonSelectedString) {
      detailedDebugLog('Add new module', moduleToProcess, parallelStrings);

      if (targetStringEnd === 'start') {
        firstParallelString.pushModule({
          module: moduleToProcess,
          toHead: true
        });
      } else {
        moduleToProcessTarget.pushModule({
          module: moduleToProcess
        });
      }
    }
  } else if (selectedStringsModules.length > 1) {
    // moduleIsInSelectedOrParallelString
    // Did we put mouse on previous before last module? Delete the last module then
    const lastStringModules = lastParallelString.getModules();

    // take all parallel strings modules and check second and penultimate modules on THAT

    const penultimate: PvModule | undefined =
      lastStringModules.length > 1 // This should not apply to the case when the last string has a single module
        ? parallelStringsModules[parallelStringsModules.length - 2]
        : undefined;
    const second: PvModule | undefined = parallelStringsModules[1];

    const shouldRemoveModuleFromBeginning = targetStringEnd === 'start' && second && moduleToProcess.equals(second);
    const shouldRemoveModuleFromEnding =
      targetStringEnd === 'finish' && penultimate && moduleToProcess.equals(penultimate);

    if (shouldRemoveModuleFromBeginning || shouldRemoveModuleFromEnding) {
      detailedDebugLog('Remove module');
      const deleteModule: PvModule | undefined = (
        targetStringEnd === 'start' ? firstParallelString : lastParallelString
      ).unstringModule({
        fromBeginning: targetStringEnd === 'start'
      });
      const deleteModuleIsNotInOtherString =
        deleteModule
        && !isSelectedPvModuleIsInNonSelectedString({
          pvModuleSelected: deleteModule,
          selectedStrings: selectedStrings,
          strings: allStrings
        });
      if (deleteModuleIsNotInOtherString) {
        deleteModule?.changeMeshDefaultMaterial();
      }

      // Check if switching to one string is preferable
      if (
        shouldParallelStringsBeReplacedWithOneString({
          string: moduleToProcessTarget,
          parallelStrings: parallelStrings,
          stringingOptions: stringingOptions,
          targetStringEnd: targetStringEnd
        })
      ) {
        // Delete current string, transfer modules to previous one and start editing it
        detailedDebugLog('SwitchToEditingPreviousParallelStringWithModuleTransfer');
        return ApplyPvModuleToStringResult.SwitchToEditingPreviousParallelStringWithModuleTransfer;
      }
    } else {
      // do nothing
    }
  } else if (moduleIsInSelectedOrParallelString) {
    // TODO: add special cases for parallel strings?
    const otherStringsInParallel = selectSortedParallelStrings({
      strings: allStrings,
      targetString: moduleToProcessTarget
    }).filter((stringInList: Stringing): boolean => stringInList.serverId !== moduleToProcessTarget.serverId);

    const nextParallelStringModules =
      otherStringsInParallel[targetStringEnd === 'finish' ? otherStringsInParallel.length - 1 : 0]?.getModules() ?? [];
    const nextParallelStringClosestModule =
      nextParallelStringModules[targetStringEnd === 'finish' ? nextParallelStringModules.length - 1 : 0] ?? null;
    const switchToEditingClosestParallelString =
      nextParallelStringClosestModule && moduleToProcess.equals(nextParallelStringClosestModule);

    if (switchToEditingClosestParallelString) {
      detailedDebugLog('SwitchToEditingPreviousParallelString');
      // Got to a previous string's last module. Delete current string and start editing previous one
      return ApplyPvModuleToStringResult.SwitchToEditingPreviousParallelString;
    }
  }

  selectedStrings.forEach((string: Stringing, index: number): void => {
    string.placeStringingEndings(index, selectedStrings.length);
    string.redraw();
  });

  if (stringHasParallelStrings) {
    return ApplyPvModuleToStringResult.BalanceParallelStrings;
  }

  return ApplyPvModuleToStringResult.Idle;
}

export function stringingOptionsToSupplementalData({
  supplementalData,
  data
}: {
  supplementalData: SupplementalData;
  data: IExtraDataInverters['data'];
}): SupplementalData {
  detailedDebugLog('Converting stringing options to supplemental data.');

  if (supplementalData.invertersSelected) {
    for (const inverter of supplementalData.invertersSelected) {
      if (data.inverterId === inverter.instanceId) {
        inverter.data = data;
      }
    }
  }
  return supplementalData;
}

/**
 * Target string will always be the first returned
 * @param strings
 * @param targetString
 */
export function selectParallelStrings({
  strings,
  targetString
}: {
  strings: Stringing[];
  targetString: Stringing;
}): Stringing[] {
  return [
    targetString,
    ...strings.filter(
      (string: Stringing): boolean =>
        string.getMpptId().trim().length > 0
        && string.getMpptId() === targetString.getMpptId()
        && string.serverId !== targetString.serverId
    )
  ];
}

export function selectSortedParallelStrings({
  strings,
  targetString
}: {
  strings: Stringing[];
  targetString: Stringing;
}): Stringing[] {
  return strings.length
    ? sortBy(
      selectParallelStrings({
        strings: strings,
        targetString: targetString
      }),
      [(string: Stringing): number => string.parallelMpptStringNumber]
    )
    : [targetString];
}

export function balanceParallelStrings({
  strings,
  selectedString,
  stringingOptions
}: {
  strings: Stringing[];
  selectedString: Stringing;
  stringingOptions: IStringingOptionsResponseData[];
}): void {
  const allStrings = mergeStrings(strings, [selectedString]);
  const sortedParallelStrings = selectSortedParallelStrings({
    strings: allStrings,
    targetString: selectedString
  });
  const stringsCount = sortedParallelStrings.length;
  const allModules = flatMap(sortedParallelStrings, (string: Stringing): PvModule[] => string.getModules());
  const modulesCount = allModules.length;
  let parallelStringLength = 0;
  for (
    let possibleParallelStringLength = selectedString.acceptableStringModulesLimit.lower;
    isWithin(selectedString.acceptableStringModulesLimit, possibleParallelStringLength);
    possibleParallelStringLength++
  ) {
    if (stringsCount * possibleParallelStringLength === modulesCount) {
      parallelStringLength = possibleParallelStringLength;
      break;
    }
  }

  sortedParallelStrings.forEach((string: Stringing, index: number): void => {
    if (parallelStringLength) {
      const splicedModules = allModules.splice(0, parallelStringLength);
      string.replaceModules(splicedModules);

      string.resetPvModulesColor();
      string.placeStringingEndings(index, sortedParallelStrings.length);
      string.redraw();
    }

    setValidationRatingForAllStrings({
      allStrings: mergeStrings(strings, [string]),
      stringingOptionsList: stringingOptions
    });
  });

  if (parallelStringLength && allModules.length !== 0) {
    // eslint-disable-next-line no-console
    console.error('Parallel strings balancing failed');
  }
}

export function sortStringingOptions({
  rawStringingOptions,
  design
}: {
  rawStringingOptions: IStringingOptionsResponseData[];
  design: Design;
}): StringingOption[] {
  detailedDebugLog('sortStringingOptions', JSON.stringify(rawStringingOptions), rawStringingOptions);
  let stringingOptions: StringingOption[] = rawStringingOptions;

  if (!design.system.equipment.inverters) {
    return stringingOptions;
  }

  const sortedInverterIds = design.system.equipment.inverters.sortIds(map(stringingOptions, 'inverterId'));
  stringingOptions = sortedInverterIds.map(
    (inverterId: string): StringingOption =>
      stringingOptions.find((options: StringingOption): boolean => options.inverterId === inverterId)!
  );

  stringingOptions.forEach((options: StringingOption): void => {
    if (!options.mppts) {
      return;
    }

    const mpptIds = map(options.mppts, 'mppt');
    const sortedMpptIds = design.system.equipment.inverters!.sortIds(mpptIds);
    options.mppts = sortedMpptIds.map(
      (mpptId: string): ISeriesStringConfigurationData =>
        options.mppts!.find((configuration: ISeriesStringConfigurationData): boolean => configuration.mppt === mpptId)!
    );
  });
  detailedDebugLog('Sorted stringingOptions:', JSON.parse(JSON.stringify(stringingOptions)));

  return stringingOptions;
}

export type SortedAndAggregatedStrings = {
  // inverterIdToStrings, mpptIdToStrings, and sortedStrings will depend on existing strings
  inverterIdToStrings: { [key: string]: Stringing[] };
  mpptIdToStrings: { [key: string]: Stringing[] };
  sortedStrings: Stringing[];
  // sortedInverterIds and inverterIdToMpptIds will contain all possible strings
  sortedInverterIds: string[];
  inverterIdToSortedMpptIds: { [key: string]: string[] };
};

export function getSortedAndAggregatedStrings({
  strings,
  design
}: {
  strings: Stringing[];
  design: Design;
}): SortedAndAggregatedStrings {
  if (!design.system.equipment) {
    throw new Error('No system.equipment present, getSortedAndAggregatedStrings failed');
  }
  if (!design.system.equipment.inverters) {
    return {
      inverterIdToStrings: {},
      mpptIdToStrings: {},
      inverterIdToSortedMpptIds: {},
      sortedInverterIds: [],
      sortedStrings: strings
    };
  }

  const inverterIdToStrings: { [key: string]: Stringing[] } = {};
  const mpptIdToStrings: { [key: string]: Stringing[] } = {};

  const sortedInverterIds: string[] = design.system.equipment.inverters.getSortedInverterIds();
  const inverterIdToSortedMpptIds = design.system.equipment.inverters.invertersMappedToSortedMpptIds;

  const sortedStrings: Stringing[] = [];

  for (let string of strings) {
    const inverterId = string.getInverterId();
    const mpptId = string.getMpptId();

    if (!mpptId.trim()) {
      sortedStrings.push(string);
    }

    if (!mpptIdToStrings[mpptId]) {
      mpptIdToStrings[mpptId] = [];
    }
    mpptIdToStrings[mpptId].push(string);

    if (!inverterIdToStrings[inverterId]) {
      inverterIdToStrings[inverterId] = [];
    }
    inverterIdToStrings[inverterId].push(string);
  }

  sortedInverterIds.forEach((inverterId): void => {
    const mpptIds = inverterIdToStrings[inverterId]?.map((string: Stringing): string => string.getMpptId()) ?? [];
    const sortedMpptIds = design.system.equipment.inverters!.sortIds(uniq(mpptIds));
    const sortedResult: Stringing[] = [];

    uniq(sortedMpptIds)
      .filter((mpptId: string): boolean => !!mpptId.trim())
      .forEach((mpptId: string): void => {
        const sortedStringsForThisMppt = sortBy(mpptIdToStrings[mpptId], 'parallelMpptStringNumber');
        mpptIdToStrings[mpptId] = sortedStringsForThisMppt;
        sortedStrings.push(...sortedStringsForThisMppt);
        sortedResult.push(...sortedStringsForThisMppt);
      });
    if (sortedResult.length) {
      inverterIdToStrings[inverterId] = sortedResult;
    }
  });

  detailedDebugLog('sortedAndAggregatedStrings', {
    inverterIdToStrings,
    sortedStrings,
    mpptIdToStrings,

    inverterIdToSortedMpptIds,
    inverterIds: sortedInverterIds
  });

  return {
    inverterIdToStrings,
    sortedStrings,
    mpptIdToStrings,

    inverterIdToSortedMpptIds,
    sortedInverterIds
  };
}

export function getStringLabel({
  string,
  inverterType,
  sortedAndAggregatedStrings
}: {
  string: Stringing;
  inverterType: string;
  sortedAndAggregatedStrings: SortedAndAggregatedStrings;
}): string {
  const {
    sortedStrings, sortedInverterIds, inverterIdToStrings
  } = sortedAndAggregatedStrings;
  const showInverterIndex = sortedInverterIds.length > 1;
  const mpptId = string.getMpptId().trim();
  const inverterId = string.getInverterId().trim();
  const index = sortedStrings.indexOf(string) + 1;
  const inverterIndex = sortedInverterIds.indexOf(inverterId) + 1;

  detailedDebugLog('getStringLabel', sortedAndAggregatedStrings, string, {
    mpptId,
    inverterId
  });

  switch (inverterType) {
    case BRANCH_MICROINVERTER: {
      return `Branch ${index}`;
    }
    case BRANCH_AC_MODULE: {
      const acBranchLetter = String.fromCharCode('A'.charCodeAt(0) + (index - 1));
      return `Branch ${acBranchLetter}`;
    }
    case STRING_WITH_DC_OPTIMIZERS: {
      if (showInverterIndex) {
        return `String ${inverterIndex}-${inverterIdToStrings[inverterId].indexOf(string) + 1}`;
      }
      return `String ${index}`;
    }
    case CENTRAL_MPPT_STRING: {
      const {
        mpptIdToStrings, inverterIdToSortedMpptIds
      } = sortedAndAggregatedStrings;
      const stringNumber = mpptIdToStrings[mpptId].indexOf(string) + 1;
      const mpptNumber = inverterIdToSortedMpptIds[inverterId].indexOf(mpptId);
      const mpptLetter = String.fromCharCode('A'.charCodeAt(0) + mpptNumber);

      detailedDebugLog({
        stringNumber,
        mpptNumber,
        mpptLetter
      });

      const inverterIndexDisplayed = showInverterIndex ? `${inverterIndex}-` : '';

      if (inverterIdToSortedMpptIds[inverterId].length > 1) {
        return `String ${inverterIndexDisplayed}${mpptLetter}-${stringNumber}`;
      }
      return `String ${inverterIndexDisplayed}${stringNumber}`;
    }
  }
  return '...';
}

export function syncPvModulesOrderInStringsWithDomain({
  circuits,
  design,
  pvModules,
  stringsToSortModulesIn
}: {
  circuits: IPvSystemCircuitData[];
  design: Design;
  pvModules: PvModule[];
  stringsToSortModulesIn: Stringing[];
}): void {
  for (const circuit of circuits) {
    for (const list of getPvModuleIdsFromCircuit({
      circuit: circuit,
      design: design,
      pvModules: pvModules
    })) {
      const {
        stringServerId, pvModuleIds
      } = list;
      const stringToSortModulesIn = stringsToSortModulesIn.find(
        (string: Stringing): boolean => string.serverId === stringServerId
      );

      stringToSortModulesIn?.getModules().sort((moduleA: PvModule, moduleB: PvModule): number => {
        return pvModuleIds.indexOf(moduleA.serverId) > pvModuleIds.indexOf(moduleB.serverId) ? 1 : -1;
      });
      // We don't care about the string's position in parallel strings array here as we only need to "move" the endings
      stringToSortModulesIn?.placeStringingEndings(0, 1);
      stringToSortModulesIn?.unselect();
      stringToSortModulesIn?.redraw();
    }
  }
}
