/* eslint-disable @typescript-eslint/no-explicit-any */
import type { AxiosError } from 'axios';
import base64 from 'crypto-js/enc-base64';
import hmacSHA1 from 'crypto-js/hmac-sha1';
import each from 'lodash/each';
import extend from 'lodash/extend';
import find from 'lodash/find';
import type { LyraOptionsBar } from '@aurorasolar/lyra-ui-kit';
import { showToast } from '@aurorasolar/lyra-ui-kit/lib/components/Toast';
import type { OptionProps } from '@aurorasolar/lyra-ui-kit/lib/components/Grid';
import type {
  MutableRefObject, ReactElement
} from 'react';
import type {
  BufferGeometry, Vector2
} from 'three';
import {
  BufferAttribute, Vector3
} from 'three';
import debounce from 'lodash/debounce';
import {
  ERROR, TWO_PI
} from '../domain/models/Constants';
import type { Dictionary } from '../domain/typings';
import ToastRender from '../ui/components/ToastRender/ToastRender';
import type { RoofTopArrayAreas } from '../domain/models/RoofTopArray/RoofTopArrayAreas';
import type { PvSystem } from '../domain/models/PvSystem/PvSystem';
import type { Segment } from '../domain/graphics/Segment';
import type { Vertex } from '../domain/graphics/Vertex';
import type { Line } from '../domain/graphics/Line';
import { SentryException } from './sentryLog';

export function getElementFromRef<T = HTMLElement>(ref: MutableRefObject<T>): T {
  const element: T = ref.current || ({} as T);
  return element;
}

export function encryptHMAC(str: string, secret: string): string {
  const decodeSecret: string = base64.parse(secret);
  return base64.stringify(hmacSHA1(str, decodeSecret));
}

export function removePropertiesIfNullOrUndefined<T>(obj: T): T {
  for (const prop in obj) {
    if (obj[prop] == undefined) {
      delete obj[prop];
    }
  }
  return obj;
}

/**
 * Test if a value passed is a number type
 * it doesn't take in count strings with numbers as number type
 *
 * @param value - value which we're going to cast and test if it's a number
 * @return assert if the value passed is a number
 */
export function isNumber(value: number | string | null | undefined | object): boolean {
  return typeof value === 'number';
}

/**
 *  Merge two arrays, it means, create a new element in the target array
 *  if it does not exist, or update the elements if matches.
 * The matches work based on the prop attribute, that must be a
 * key of the type of the object array
 *
 *  {RoofFace[]} origin As is
 *  {RoofFace[]} target As should be
 *  {keyof RoofFace} prop Prop to filter (ex: id)
 */
export function mergeByProperty<T>(origin: T[], target: T[], prop: keyof T): void {
  each(target, (targetObject: T): void => {
    const originObject = find(origin, (targetObj: T): boolean => targetObj[prop] === targetObject[prop]);

    if (originObject) {
      extend(originObject, targetObject);
    } else {
      origin.push(targetObject);
    }
  });
}

/**
 * Looking if and object is empty, with no keys defined
 *
 */
export function isEmpty<T>(obj: Dictionary<T>): boolean {
  return Object.keys(obj).length === 0;
}

/**
 *
 * Set position attribute if the line does not have the specific
 * coordinates
 * @export
 * @param v1 started vector
 * @param v2 end vector
 * @param line Line
 */
export function setLinePositionAttribute(v1: Vector3, v2: Vector3, line: Line): void {
  const lineGeometry = line.geometry as BufferGeometry;
  const posAttr = new BufferAttribute(new Float32Array([v1.x, v1.y, v1.z, v2.x, v2.y, v2.z]), 3);
  lineGeometry.setAttribute('position', posAttr);
}

/**
 *
 * Calculate if two vectors are equals taking into account a tolerance
 * @param vec1 Vector 1
 * @param vec2 Vector 2
 * @param tolerance Tolerance optional to compare the value of the vectors
 * @returns true if equals, otherwise false
 */
function vectorEquals(vec1: Vector3, vec2: Vector3, tolerance?: number): boolean {
  // If there is not tolerance, take EPSILON number
  tolerance = tolerance || Number.EPSILON;
  return (
    Math.abs(vec1.x - vec2.x) < tolerance
    && Math.abs(vec1.y - vec2.y) < tolerance
    && Math.abs(vec1.z - vec2.z) < tolerance
  );
}

/**
 * Calculate if two segments are equals
 * @param seg1 Segment 1
 * @param seg2 Segment 2
 * @param tolerance Tolerance optional to compare the length of the segments
 * @returns true if equals, otherwise false
 */
function segmentEquals(seg1: Segment, seg2: Segment, tolerance?: number): boolean {
  // If there is not tolerance, take EPSILON number
  tolerance = tolerance ? tolerance : Number.EPSILON;
  if (!seg1 || !seg2 || Math.abs(seg1.length - seg2.length) > tolerance) {
    return false;
  }
  const segmentA = seg1.points;
  const segmentB = seg2.points;
  return (
    vectorEquals(segmentA[0].getVector3(), segmentB[0].getVector3(), 0.005)
    && vectorEquals(segmentA[1].getVector3(), segmentB[1].getVector3(), 0.005)
  );
}

/**
 *
 * Function that converts a vertex Array (domain model) to
 * Vector3 Array (threejs model)
 */
export function vertexArrayToVector3Array(vertexArr: Vertex[]): Vector3[] {
  return vertexArr.map((v: Vertex): Vector3 => new Vector3(v.x, v.y, v.z));
}

/**
 * Getting the angle between two lines/vectors
 *
 * @param v1 - firs vector
 * @param v2 - second vector
 * @returns - local angle between two lines/vectors
 */
export function calculateAngle(v1: Vector2, v2: Vector2): number {
  // NOTE: Cross product method provided from threejs utility
  // it's not working for vector 2d
  const crossProduct = v1.x * v2.y - v2.x * v1.y;
  const dotProduct = v1.dot(v2);
  const angle = Math.atan2(crossProduct, dotProduct);

  // converting from PI <=> -PI to 0 <=> PI^2
  return (angle + TWO_PI) % TWO_PI;
}

/**
 * Helper used for show toast message
 */
let notifyQueueCounter: number = 0;
let notifyQueue: Promise<void> = Promise.resolve();
const pushToNotifyQueue = (callback: () => void, timeout: number = 1000): void => {
  const queueIsEmpty: boolean = notifyQueueCounter === 0;

  notifyQueueCounter++;
  notifyQueue = notifyQueue.then((): Promise<void> => {
    return new Promise<void>((resolve: () => void): void => {
      setTimeout(
        (): void => {
          callback();
          notifyQueueCounter--;
          resolve();
        },
        queueIsEmpty ? 0 : timeout
      ); // Do not timeout if queue is empty.
    });
  });
};
const debouncedPushToNotifyQueue = debounce(pushToNotifyQueue, 250);
let lastNotifyText: string = '';

export function notify(text: string, icon: string): void {
  const useDebounce: boolean = text === lastNotifyText || lastNotifyText === '';

  (useDebounce ? debouncedPushToNotifyQueue : pushToNotifyQueue)((): void => {
    showToast((): ReactElement => ToastRender(text, icon));
  });
  lastNotifyText = text;
}

/**
 * handleApiError
 * @param errorMessage
 * @param extraData
 * @param expectedError the error will not be logged to console and Sentry if true
 * @returns {(e: unknown) => Promise<never>}
 */
export function handleApiError(
  errorMessage?: string,
  extraData?: any,
  expectedError: boolean = false
): (e: unknown) => Promise<never> {
  return async (e: unknown): Promise<never> => {
    SentryException(`API Error: ${errorMessage}`, e);

    const errorDetails = e
      ? await parseErrorDetailsMessage(e as AxiosError)
      : `[No error object], manual stack trace: ${new Error().stack}`;
    const messageToShow = !!errorMessage ? `${errorMessage}, error: ${errorDetails}` : errorDetails;

    notify(messageToShow, ERROR);
    // eslint-disable-next-line no-console
    console.error(messageToShow, ERROR);

    if (extraData) {
      // @ts-ignore
      e.extraData = extraData;
    }

    (e as Error).message = `${errorMessage} : ${(e as Error).message}`;

    throw e;
  };
}

export async function parseErrorDetailsMessage(e: AxiosError): Promise<string> {
  const error = e.response ?? e.request;

  // In case we got a non-axios error (no response/request fields)
  if (!error) {
    return (e as Error).message || (e as Error).name;
  }

  // Handle blob error message
  if (error.data?.text?.().then) {
    const errorJson = await error.data.text();
    return JSON.parse(errorJson).message;
  }

  // Handle array error type from API
  if (error.data?.messages instanceof Array && error.data?.messages?.length > 0) {
    if (typeof error.data?.messages[0] === 'object') {
      try {
        return error.data.messages.reduce((acc: string, message: { key: string; level: string; message: string }) => {
          return `${acc}${message.key} [${message.level}] ${message.message} \n`;
        }, '');
      } catch (exceptionError) {
        // eslint-disable-next-line no-console
        console.error('Could not parse non-empty API error.response.data.messages string: ', exceptionError);
        return JSON.stringify(error.data.messages);
      }
    } else if (typeof error.data?.messages[0] === 'string') {
      return error.data.messages.join(' ');
    }
  }

  // Handle single error message from API
  if (error?.data?.message) {
    return error.data.message;
  }

  // If no message present in API response,
  // just show network error message or original axios message
  return error.message || e.message;
}

export function convertFromCentigradesToFahrenheit(centigrades: number): number {
  return (centigrades * 9) / 5 + 32;
}

export function convertFromFahrenheitToCentigrades(fahrenheit: number): number {
  return ((fahrenheit - 32) * 5) / 9;
}

type OptionPropsValues = LyraOptionsBar.Option;

export function searchValueFromDropdown(
  options: OptionPropsValues[],
  searchedValue: string | number
): OptionPropsValues | undefined {
  return options.find((currentOption: OptionPropsValues): boolean => currentOption.value === searchedValue);
}

export function searchByNumericValueFromDropdown(
  options: OptionPropsValues[],
  searchedValue: string | number
): OptionPropsValues | undefined {
  return options.find((currentOption: OptionPropsValues): boolean => Number(currentOption.value) === searchedValue);
}

export function precisionNumber(value: number, fractionDigits: number = 5): number {
  return Number(value.toFixed(fractionDigits));
}

export function formatNumberToShow(value: number): string {
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export function searchValueFromOptions(options: OptionProps[], searchedValue: string): OptionProps | undefined {
  return options.find((currentOption: OptionProps): boolean => currentOption.value === searchedValue);
}

/**
 * @name pushUniqueItem adds a unique item to a collection unless
 * it's already present in a collection.
 * @param list collection
 * @param item for addition
 */
export function pushUniqueItem<T>(list: T[], item: T): boolean {
  if (list.includes(item)) {
    return false;
  }
  list.push(item);
  return true;
}

/**
 * @name deleteItem Deletes an item from a collection if present.
 * @param list collection
 * @param item for deletion
 */
export function deleteItem<T>(list: T[], item: T): boolean {
  if (!list.includes(item)) {
    return false;
  }
  list.splice(list.indexOf(item), 1);
  return true;
}

interface IEnergyProduction {
  energyProductionEstimate: number;
  totalModules: number;
}

export function calculateEnergyProductionEstimate(
  roofTopArrayAreas: RoofTopArrayAreas,
  system: PvSystem
): IEnergyProduction {
  const occupiedPvModulePositionIds = system.equipment.pvModules?.idsOfPvModulePositions ?? [];
  const totalEnergyProductionEstimateInKwh =
    roofTopArrayAreas.energyProductionEstimateInKwh(occupiedPvModulePositionIds);

  return {
    energyProductionEstimate: Math.round(totalEnergyProductionEstimateInKwh),
    totalModules: occupiedPvModulePositionIds.length
  };
}

const thrower = (): void => {
  throw new Error('uncaught design tool error');
};

export const throwWrapper = (): void => {
  thrower();
};
