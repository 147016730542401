import {
  action, computed, observable
} from 'mobx';
import type { ServiceBus } from '../../../../ServiceBus/ServiceBus';
import { BaseViewModel } from '../../BaseViewModel';
import { ModalUiCodes } from '../../ModalUiCodes';
import type { WorkspaceStore } from '../../../WorkspaceStore';
import { handleApiError } from '../../../../../utils/helpers';
import type { PropertiesStore } from '../../../Properties/Properties';
import type { ToolbarStore } from '../../../ToolbarStore/Toolbar';
import { CustomBaseImageryTransformationTool } from '../../../ToolbarStore/Project/CustomBaseImageryTransformationTool';
import type MapStore from '../../../MapStore/MapStore';
import type SmartGuidesStore from '../../../SmartGuidesStore/SmartGuidesStore';
import { rootStore } from '../../../../Store';

class UploadCustomBaseImageryModalViewModel extends BaseViewModel {
  readonly propCodeUI: string = ModalUiCodes.UploadCustomSiteImageryModal;

  @observable
  file?: File;

  @observable
  fileName?: string;

  @observable
  isSaving: boolean = false;
  private readonly workspace: WorkspaceStore;
  private readonly properties: PropertiesStore;
  private readonly toolbar: ToolbarStore;
  private readonly serviceBus: ServiceBus;
  private readonly mapStore: MapStore;
  private readonly smartGuides: SmartGuidesStore;

  constructor() {
    super(rootStore);
    this.serviceBus = rootStore.serviceBus;
    this.properties = rootStore.properties;
    this.mapStore = rootStore.map;
    this.smartGuides = rootStore.smartGuides;
    this.workspace = rootStore.workspace;
    this.properties = rootStore.properties;
    this.toolbar = rootStore.toolbar;
  }

  @action.bound
  setFile = (file: File): void => {
    this.fileName = file.name;
    this.file = file;
  };

  @computed
  get hasFile(): boolean {
    return !!this.file;
  }

  @action
  save = (): void => {
    this.isSaving = true;

    const accountId = this.domain.project.account;
    const projectId = this.domain.project.id;

    this.documentsService
      .uploadCustomBaseImagery(accountId, projectId, this.file!)
      .then(async (): Promise<void> => {
        // Start editing the uploaded custom base imagery:
        const {
          editor, modal, toolbar, serviceBus, mapStore, smartGuides
        } = this;
        const tool = new CustomBaseImageryTransformationTool({
          editor,
          toolbar,
          serviceBus,
          mapStore: mapStore,
          smartGuides,
          isCustomBaseImageryConfigured: false
        });
        toolbar.selectTool(tool);

        this.closeModal();
      })
      .catch(handleApiError('Failed to upload image'))
      .finally((): void => {
        this.isSaving = false;
      });
  };

  override dispose() {
    // do nothing
  }
}

export { UploadCustomBaseImageryModalViewModel };
