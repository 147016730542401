import type {
  Object3D, Vector3
} from 'three';
import { MathUtils as ThreeMath } from 'three';
import {
  action, computed, observable
} from 'mobx';
import type PvModule from '../../models/SiteDesign/PvModule';
import { EWorkspace } from '../../typings/index';
import { StringingAcceptabilityRating } from '../../../services/stringing/stringingAcceptabilityRating';
import type Limit from '../../models/Limit';
import { SceneObjectType } from '../../models/Constants';
import { Deletable } from '../../mixins/Deletable';
import { Selectable } from '../../mixins/Selectable';
import { Unzoomable } from '../../mixins/Unzoomable';
import { Drawable } from '../../mixins/Drawable';
import { getRootStore } from '../../../stores/RootStoreInversion';
import { StringingLine } from './StringingLine';
import {
  StringDotKind, StringingDot
} from './StringingDot';
import { UnbalancedStringLabelController } from './UnbalancedStringLabel';
import { StringLabelController } from './StringLabel';

const MixedClass = Deletable(Selectable(Unzoomable(Drawable(class SimpleClass {}))));

export class Stringing extends MixedClass {
  tag: EWorkspace = EWorkspace.DESIGN;
  propertyId: string = SceneObjectType.String;
  selectWithParent: boolean = false;
  parallelMpptStringNumber: number = 1;
  stringLabelController: StringLabelController;
  unbalancedStringLabelController: UnbalancedStringLabelController;

  private startStringing: StringingDot;
  private endStringing: StringingDot;
  private stringingLazzo: StringingLine[];
  private modules: PvModule[];
  @observable
  private inverterId: string;
  private mpptId: string;
  private modified?: boolean;

  constructor(parallelStringingCount?: number) {
    super();
    this.type = SceneObjectType.String;
    this.name = 'Stringing';
    this.serverId = ThreeMath.generateUUID();
    this.modules = [];
    this.stringingLazzo = [];
    this.parallelMpptStringNumber = parallelStringingCount ?? 1;
    this.startStringing = new StringingDot(StringDotKind.BEGIN);
    this.endStringing = new StringingDot(StringDotKind.END);
    this.mesh.position.setZ(500);
    this.inverterId = '';
    this.mpptId = '';

    this.stringLabelController = new StringLabelController();
    this.unbalancedStringLabelController = new UnbalancedStringLabelController();
  }

  override select(): void {
    this.startStringing?.select();
    this.endStringing?.select();
    this.redraw();
  }

  selectAsParallelStringListBeginning(): void {
    this.startStringing?.select();
    this.endStringing?.unselect();
    this.redraw();
  }

  selectAsParallelStringListMiddle(): void {
    this.unselect();
  }

  selectAsParallelStringListEnding(): void {
    this.startStringing?.unselect();
    this.endStringing?.select();
    this.redraw();
  }

  override unselect(): void {
    this.startStringing?.unselect();
    this.endStringing?.unselect();
    this.redraw();
  }

  redraw(): void {
    if (this.modules.length > 0) {
      if (!this.getStringingDot(StringDotKind.BEGIN)) {
        this.mesh.add(this.startStringing.mesh);
      }

      if (!this.getStringingDot(StringDotKind.END)) {
        this.mesh.add(this.endStringing.mesh);
      }

      this.drawStringingLazzo();
    } else {
      this.mesh.remove(this.startStringing.mesh);
      this.mesh.remove(this.endStringing.mesh);
      this.stringLabelController.remove();
    }
    this.drawStringLabels();
  }

  unzoom(): void {
    this.redraw();
  }

  redrawStringingOnCanvasResize(): void {
    this.redraw();
  }

  finishStringing(): void {
    this.unselect();
    this.drawStringLabels(true);
  }

  removeLabels(): void {
    this.stringLabelController.remove();
    this.unbalancedStringLabelController.remove();
  }

  drawStringLabels(redrawMainLabelOnlyIfItAlreadyExists: boolean = false): void {
    if (!redrawMainLabelOnlyIfItAlreadyExists || this.stringLabelController.stringLabelExists) {
      this.stringLabelController.draw(this.startStringing.mesh, this.stringingLazzo);
    }
    this.unbalancedStringLabelController.draw(this.endStringing.mesh);
  }

  getModules(): PvModule[] {
    return this.modules;
  }

  pushModule({
    module, toHead = false
  }: { module: PvModule; toHead?: boolean }): void {
    this.modified = true;
    this.modules[toHead ? 'unshift' : 'push'](module);
  }

  unstringModule({ fromBeginning = false }: { fromBeginning?: boolean }): PvModule | undefined {
    this.modified = true;
    return this.modules[fromBeginning ? 'shift' : 'pop']();
  }

  replaceModules(newPvModules: PvModule[]): void {
    this.modified = true;
    this.modules = [...newPvModules];
  }

  getInverterId(): string {
    return this.inverterId;
  }

  getMpptId(): string {
    return this.mpptId;
  }

  @action
  setInverterId(id: string): void {
    this.inverterId = id;
  }

  setMpptId(id: string): void {
    this.mpptId = id;
  }

  hasMppt(): boolean {
    return !!this.mpptId;
  }

  // Alias for validateStringingConfiguration
  setValidationRating(rating: string): void {
    const color = this.colorForRating(rating);
    this.modules.forEach((pvModule: PvModule): void => pvModule.highlightStringingState(color));
  }

  resetPvModulesColor(): void {
    this.getModules().forEach((pvModule: PvModule): void => {
      pvModule.changeMeshDefaultMaterial();
    });
  }

  private colorForRating(rating: string): string {
    switch (rating) {
      case StringingAcceptabilityRating.Standard:
      case StringingAcceptabilityRating.Optimal:
        return '#58AC65'; // Green

      case StringingAcceptabilityRating.UnderperformingBelowMpptVoltage:
      case StringingAcceptabilityRating.UnderperformingAboveMpptVoltage:
      case StringingAcceptabilityRating.UnderperformingLowPower:
        return '#B0DF6C'; // Yellow-ish green

      case StringingAcceptabilityRating.NonfunctionalBelowMinAllowableNumberOfModulesPerString:
      case StringingAcceptabilityRating.NonfunctionalVmpBelowInverterMinimumInputVoltage:
        return '#C3BC6A'; // Yellow

      case StringingAcceptabilityRating.UnderperformingExcessPower:
      case StringingAcceptabilityRating.UnderperformingImpExceedsMpptMaxInputCurrent:
        return '#E7AB39'; // Orange

      case StringingAcceptabilityRating.NonfunctionalAboveMaxAllowableNumberOfInvertersPerBranch:
      case StringingAcceptabilityRating.NonfunctionalAboveMaxAllowableNumberOfModulesPerString:
      case StringingAcceptabilityRating.NonfunctionalIscExceedsMpptAbsoluteMaxIsc:
      case StringingAcceptabilityRating.DangerousExceedsElectricalCodeLimit:
      case StringingAcceptabilityRating.DangerousVocAboveInverterMaximumInputVoltage:
      case StringingAcceptabilityRating.DangerousVocAboveModuleMaximumSystemVoltage:
      case StringingAcceptabilityRating.ParallelStringsUnbalanced:
        return '#D34A4A'; // Red

      default:
        return '#D34A4A'; // Red as a conservative default
    }
  }

  containsPvModule(selectedPvModule: PvModule): boolean {
    return this.modules.some((pvModule: PvModule): boolean => pvModule.equals(selectedPvModule));
  }

  @computed
  get acceptableStringModulesLimit(): Limit {
    const stringingService = getRootStore().serviceProxy.getStringing();
    const { acceptableModulesLimitsToParallelStringNumber } = stringingService.getStringModulesLimits({
      stringingOptionsList: stringingService.stringingOptions,
      inverterId: this.inverterId
    });
    const limitsToMpptId = acceptableModulesLimitsToParallelStringNumber[String(this.parallelMpptStringNumber)];

    return limitsToMpptId[this.mpptId] ?? limitsToMpptId.default;
  }

  @computed
  get maxModules(): number {
    const stringingService = getRootStore().serviceProxy.getStringing();
    const { maxModules } = stringingService.getStringModulesLimits({
      stringingOptionsList: stringingService.stringingOptions,
      inverterId: this.inverterId
    });
    return maxModules;
  }

  @computed
  get maxStringsPerMppt(): number {
    const stringingService = getRootStore().serviceProxy.getStringing();
    return stringingService.getStringModulesLimits({
      stringingOptionsList: stringingService.stringingOptions,
      inverterId: this.inverterId
    }).maxStringsPerMppt;
  }

  beginStringing(startModule: PvModule): void {
    const centroidFirst: Vector3 = startModule.getCenter();
    this.startStringing.changePositionAndSelect(centroidFirst);
    this.endStringing.changePositionAndSelect(centroidFirst);
    this.mesh.add(this.startStringing.mesh);
    this.pushModule({ module: startModule });
    this.redraw();
  }

  placeStringingEndings(thisStringIndex: number, parallelStringCount: number): void {
    const centroidFirst: Vector3 = this.modules[0].getCenter();
    this.startStringing.changePosition(centroidFirst);
    if (thisStringIndex === 0) {
      this.startStringing.select();
    }

    const lastModule: number = this.modules.length - 1;
    const centroidLast: Vector3 = this.modules[lastModule].getCenter();

    this.endStringing.changePosition(centroidLast);
    if (thisStringIndex === parallelStringCount - 1) {
      this.endStringing.select();
    }
  }

  isModified(): boolean {
    return this.modified ?? false;
  }

  resetModifiedState(): void {
    this.modified = false;
  }

  private drawStringingLazzo(): void {
    this.mesh.remove(...this.stringingLazzo.map((model: StringingLine) => model.mesh));
    this.stringingLazzo = [];

    for (let i = 0; i < this.modules.length - 1; i++) {
      const centroidF: Vector3 = this.modules[i].getCenter();
      const centroidS: Vector3 = this.modules[i + 1].getCenter();

      const lineStringing: StringingLine = new StringingLine();
      lineStringing.set(centroidF, centroidS);

      this.stringingLazzo.push(lineStringing);
      this.mesh.add(lineStringing.mesh);
    }
  }

  private getStringingDot(kind: StringDotKind): Object3D | undefined {
    return this.mesh.getObjectByProperty('kind', kind);
  }
}
