import {
  Vector3, PerspectiveCamera, OrthographicCamera, MathUtils as THREEMath, Frustum, Matrix4
} from 'three';
import isFinite from 'lodash/isFinite';
import { KeyboardListener } from '../../../utils/KeyboardListener';
import {
  DURATIONS, Easing
} from '../../../utils/Convergence';
import { BoundedConvergence } from '../../../utils/BoundedConvergence';
import type { Pointer } from '../../../utils/PointerDetector';
import { PointerDetector } from '../../../utils/PointerDetector';
import type { IPinchZoomGestureData } from '../../../utils/PinchZoomGesture';
import { PinchZoomGesture } from '../../../utils/PinchZoomGesture';
import type {
  ISignalP0, ISignalP1
} from '../../../utils/signal/Signal';
import { Signal } from '../../../utils/signal/Signal';
import type {
  SimpleVector2, ISimpleVector3
} from '../../../utils/ThreeUtils';
import { ThreeUtils } from '../../../utils/ThreeUtils';
import { HTMLUtils } from '../../../utils/HTMLUtils';
import { PanAndZoomGestures } from '../../../utils/PanAndZoomGestures';
import { TimeStamp } from '../TimeStamp';
import { ZOOM_LIMIT } from '../constants';
import { getRootStore } from '../../RootStoreInversion';
import type { IControls } from './Controls';

// Static configuration:
const EPSILON = 0.00001;
const FOV = 60;
const MapUnitDimension: number = 1280;
const MINIMAL_DISTANCE_FROM_FRUSTUM_TOP = 15;

interface IVec2Convergence {
  x: BoundedConvergence;
  y: BoundedConvergence;
}

// static configuration:
// Set this to true, if you'd like to tilt the camera while holding the shift key
const enableTiltAndRotateWithShift = false;

// This is to ensure that every object is in front of the camera, so they're visible
// Without this, objects with very high positions can be "behind" the camera
const maxDistanceMultiplier = 10;

type DefaultZoom = 'fit' | 'fill';
const defaultZoom: DefaultZoom = 'fill';

type CameraSignals = {
  cameraPropsChange: ISignalP0;
  cameraZoomChange: ISignalP0;
  cameraGrabbed: ISignalP1<Pointer>;
  cameraReleased: ISignalP1<Pointer>;
};

export class CameraControls implements IControls {
  private canvas!: HTMLCanvasElement;
  private cameraTarget: IVec2Convergence;
  private cameraTargetV3: Vector3 = new Vector3();
  private perspectiveCamera: PerspectiveCamera;
  private orthographicCamera: OrthographicCamera;
  activeCamera: PerspectiveCamera | OrthographicCamera;
  private targetToCamera: Vector3 = new Vector3();
  private frustum: Frustum = new Frustum();
  private frustumMatrix: Matrix4 = new Matrix4();
  private _minimalRenderDistance?: number;

  private mapZoomFactor: number = 0;

  private pointerDetector: PointerDetector;
  private pointerStart?: {
    world: SimpleVector2;
    local: SimpleVector2;
  } | null;
  private isMiddleBtnDown: boolean = false;
  private rightMouseButton = {
    timeStampOnDown: 0,
    shouldTriggerClickEvent: false,
    isPressed: false,
    threshold: {
      duration: 1000, // ms
      delta: 5 // px
    }
  };
  private zoomSpeed = 1.25;
  private cameraDistance: BoundedConvergence = new BoundedConvergence(1, 1, 0, 1, Easing.EASE_OUT);
  private panAndZoomGestures: PanAndZoomGestures;
  private pinchZoomGesture: PinchZoomGesture;
  private animatedZoom = true;
  private pinchZoomData: {
    cameraZoomOnPinchStart: number;
  } = {
      cameraZoomOnPinchStart: 1
    };

  private imageryWidth?: number;
  private imageryHeight?: number;
  private spaceWidth?: number;
  private spaceHeight?: number;
  private canvasWidth?: number;
  private canvasHeight?: number;
  private isActive: boolean = false;
  isPanningToolSelected: boolean = false;

  ignoreZoom: boolean = false;

  private azimuthAngle: BoundedConvergence = new BoundedConvergence(
    0,
    0,
    -Infinity,
    Infinity,
    Easing.EASE_OUT,
    DURATIONS.CAMERA_MOVEMENT
  );
  private polarAngle: BoundedConvergence = new BoundedConvergence(
    EPSILON,
    EPSILON,
    EPSILON,
    THREEMath.degToRad(80),
    Easing.EASE_OUT,
    DURATIONS.CAMERA_MOVEMENT
  );
  private toX: Vector3 = new Vector3(1, 0, 0);
  private toZ: Vector3 = new Vector3(0, 0, 1);

  private _cameraDataOnPointerDown?: {
    target: SimpleVector2;
    cameraObject: PerspectiveCamera | OrthographicCamera;
    distanceFromTarget: number;
  };

  private _polarAngleOnPointerDown?: number;
  private _azimuthAngleOnPointerDown?: number;

  private _previousCursorStyle?: string;

  // Below ones are needed for damping
  private _dampOnPointerUp: boolean = false;
  private _timeoutId: number = -1;
  // For some reason convergence.prevDeltaValue becomes 0 before the touchup event is called,
  // so we save the proper prev values to these objects below
  private _prevTiltSpeed: SimpleVector2 = {
    x: 0,
    y: 0
  };
  private _prevMoveSpeed: SimpleVector2 = {
    x: 0,
    y: 0
  };

  private _movementUpdateShouldBeCalled: boolean = false;
  private _savedPointerObject?: Pointer;

  signals: CameraSignals = {
    cameraPropsChange: Signal.create(),
    cameraZoomChange: Signal.create(),
    cameraGrabbed: Signal.create<Pointer>(),
    cameraReleased: Signal.create<Pointer>()
  };

  constructor(canvas: HTMLCanvasElement) {
    this.updateCanvas(canvas);
    this.pointerDetector = new PointerDetector({
      element: this._domElement,
      maxPointers: 2,
      disableContextMenu: true,
      ignoreMiddleButton: false,
      ignoreRightButton: false,
      autoEnable: true
    });

    this.orthographicCamera = new OrthographicCamera(
      -this.canvas.width / 2,
      this.canvas.width / 2,
      this.canvas.height / 2,
      -this.canvas.height / 2,
      0.1,
      20
    );
    this.orthographicCamera.up = this.toZ.clone();
    this.perspectiveCamera = new PerspectiveCamera(FOV, 1, 1, 200);
    this.perspectiveCamera.up = this.toZ.clone();
    this.perspectiveCamera.position.setZ(5);
    this.activeCamera = this.orthographicCamera;
    this.cameraTarget = {
      x: new BoundedConvergence(
        this.activeCamera.position.x,
        this.activeCamera.position.x,
        -Infinity,
        Infinity,
        Easing.EASE_OUT,
        DURATIONS.CAMERA_MOVEMENT
      ),
      y: new BoundedConvergence(
        this.activeCamera.position.y,
        this.activeCamera.position.y,
        -Infinity,
        Infinity,
        Easing.EASE_OUT,
        DURATIONS.CAMERA_MOVEMENT
      )
    };

    this.pinchZoomGesture = new PinchZoomGesture(this.pointerDetector);

    this.panAndZoomGestures = new PanAndZoomGestures({
      clickDistanceTolerance: 3,
      element: this._domElement,
      longTap: {
        enabled: true,
        timeout: 750
      }
    });

    // Enables the keyboardListener
    KeyboardListener.getInstance();

    if (window.location.hostname === 'localhost') {
      console.warn('Adding debugEditor, debugCamera, debugPerspectiveCamera and debugScene to window');

      // @ts-ignore
      window.debugEditor = getRootStore().editor;
      // @ts-ignore
      window.debugCamera = this.orthographicCamera;
      // @ts-ignore
      window.debugPerspectiveCamera = this.perspectiveCamera;
      // @ts-ignore
      window.debugScene = getRootStore().editor.scene;
    }
  }

  private get _domElement(): HTMLElement {
    return this.canvas.parentElement!;
  }

  get minimalRenderDistance(): number | undefined {
    return this._minimalRenderDistance;
  }

  updateCanvas(canvas: HTMLCanvasElement): void {
    this.canvas = canvas;
  }

  activate(): void {
    if (!this.isActive) {
      this.isActive = true;
      this.pointerDetector.signals.down.add(this.onPointerDown);
      this.pointerDetector.signals.move.add(this.onPointerMove);
      this.pointerDetector.signals.up.add(this.onPointerUp);
      this._domElement.addEventListener('wheel', this.onMouseWheel, {
        passive: false
      });
      this.panAndZoomGestures.signals.longClick.add(this.onLongClick);
      this.pinchZoomGesture.signals.start.add(this.onStartPinchZoom);
      this.pinchZoomGesture.signals.update.add(this.onUpdatePinchZoom);
      this.pinchZoomGesture.signals.end.add(this.onEndPinchZoom);
      this.pinchZoomGesture.listen();
      this.cameraDistance.signals.onUpdate.add(this.onCameraDistanceUpdate);
    }
  }

  deactivate(): void {
    if (this.isActive) {
      this.pointerDetector.signals.down.remove(this.onPointerDown);
      this.pointerDetector.signals.move.remove(this.onPointerMove);
      this.pointerDetector.signals.up.remove(this.onPointerUp);
      this._domElement.removeEventListener('wheel', this.onMouseWheel);
      this.panAndZoomGestures.signals.longClick.remove(this.onLongClick);
      this.pinchZoomGesture.signals.start.remove(this.onStartPinchZoom);
      this.pinchZoomGesture.signals.update.remove(this.onUpdatePinchZoom);
      this.pinchZoomGesture.signals.end.remove(this.onEndPinchZoom);
      this.pinchZoomGesture.endListen();
      this.cameraDistance.signals.onUpdate.remove(this.onCameraDistanceUpdate);
      this.isActive = false;
      this.resetZoom();
    }
  }

  private isTiltRotateModeActive(): boolean {
    return KeyboardListener.isShiftDown && enableTiltAndRotateWithShift;
  }

  /**
   * MouseMove event can run a lot more frequently than the refresh rate of the user's display.
   * We optimize this way: on mousemove, only the new mouse coordinates are saved,
   * and the rest of the calculations are called only once / frame refresh
   */
  private updatePointerMovement = (): void => {
    if (this._movementUpdateShouldBeCalled) {
      this._movementUpdateShouldBeCalled = false;
      if (this.isTiltRotateModeActive()) {
        const deltaX = this._savedPointerObject!.localX! - this._savedPointerObject!.startX!;
        const deltaY = this._savedPointerObject!.localY! - this._savedPointerObject!.startY!;
        this.rotateCamera(deltaX, deltaY);

        this._dampOnPointerUp = true;
        clearTimeout(this._timeoutId);
        this._timeoutId = window.setTimeout(this.cancelDamping, 100);
      } else {
        if (this.cameraDistance.value !== this._cameraDataOnPointerDown!.distanceFromTarget) {
          const camera = this._cameraDataOnPointerDown!.cameraObject;
          this.updateCameraPos(
            camera,
            this._cameraDataOnPointerDown!.target.x,
            this._cameraDataOnPointerDown!.target.y
          );

          if (camera instanceof OrthographicCamera) {
            camera.zoom = this.cameraZoomValue;
            camera.updateProjectionMatrix();
          }
          ThreeUtils.updateMatrices(camera);
        }
        const worldPos = ThreeUtils.domCoordinatesToWorldCoordinates(
          this._savedPointerObject!.localX!,
          this._savedPointerObject!.localY!,
          this._domElement,
          this._cameraDataOnPointerDown!.cameraObject
        );
        if (worldPos) {
          this.pan(this.pointerStart?.world.x! - worldPos.x, this.pointerStart?.world.y! - worldPos.y);

          this._dampOnPointerUp = true;
          clearTimeout(this._timeoutId);
          this._timeoutId = window.setTimeout(this.cancelDamping, 100);
        }
      }
    }
  };

  private onLongClick = (pointer: Pointer): void => {
    if (pointer.originalEvent?.type.includes('touch')) {
      this.onOpenContextMenu(pointer);
    }
  };

  private onPointerDown = (pointer: Pointer): void => {
    this.pointerDown(pointer);
  };

  private pointerDown(pointer: Pointer, force: boolean = false): void {
    if (
      KeyboardListener.isSpaceDown
      || this.isTiltRotateModeActive()
      || KeyboardListener.isCtrlDown
      || (this.isPanningToolSelected && pointer.isNormalClick)
      || pointer.isMiddleClick
      || pointer.isRightClick
      || force
    ) {
      pointer.originalEvent?.stopImmediatePropagation();
      this._previousCursorStyle = this._domElement.style.cursor;
      this._domElement.style.cursor = 'grabbing';
      const worldPos = ThreeUtils.domCoordinatesToWorldCoordinates(
        pointer.localX!,
        pointer.localY!,
        this._domElement,
        this.activeCamera
      );
      this.pointerStart = {
        world: worldPos,
        local: {
          x: pointer.localX!,
          y: pointer.localY!
        }
      };
      this._cameraDataOnPointerDown = {
        target: {
          x: this.cameraTarget.x.value,
          y: this.cameraTarget.y.value
        },
        distanceFromTarget: this.cameraDistance.value,
        cameraObject: this.activeCamera.clone()
      };

      this.signals.cameraGrabbed.dispatch(pointer);

      if (!this.isTiltRotateModeActive()) {
        this.cameraTarget.x.reset(this.cameraTarget.x.value, this.cameraTarget.x.value);
        this.cameraTarget.y.reset(this.cameraTarget.y.value, this.cameraTarget.y.value);
      }

      this._polarAngleOnPointerDown = this.polarAngle.value;
      this._azimuthAngleOnPointerDown = this.azimuthAngle.value;

      if (pointer.isMiddleClick) {
        this.isMiddleBtnDown = true;
      } else if (pointer.isRightClick) {
        this.rightMouseButton.isPressed = true;
        this.rightMouseButton.shouldTriggerClickEvent = true;
        this.rightMouseButton.timeStampOnDown = TimeStamp.value;
      }
    }
  }

  private onOpenContextMenu(pointer: Pointer): void {
    const worldPos = ThreeUtils.domCoordinatesToWorldCoordinates(
      pointer.localX!,
      pointer.localY!,
      this._domElement,
      this.activeCamera
    );

    if (worldPos) {
      // Open actual context menu for selected item
    }
  }

  private onPointerMove = (pointer: Pointer): void => {
    this.pointerMove(pointer);
  };

  private pointerMove(pointer: Pointer): void {
    if (!this.pointerStart) {
      return;
    }

    if (pointer.localX !== pointer.startX || pointer.localY !== pointer.startY) {
      if (
        this.rightMouseButton.shouldTriggerClickEvent
        && (Math.abs(pointer.localX! - pointer.startX!) > this.rightMouseButton.threshold.delta
          || Math.abs(pointer.localY! - pointer.startY!) > this.rightMouseButton.threshold.delta)
      ) {
        this.rightMouseButton.shouldTriggerClickEvent = false;
      }

      this._savedPointerObject = pointer;
      this._movementUpdateShouldBeCalled = true;
    }
  }

  /**
   * Delta is difference between pointernow and pointerstart, NOT the previous pointerpos
   * @param deltaX
   * @param deltaY
   */
  private pan(deltaX: number, deltaY: number): void {
    this.moveCameraTo(
      this._cameraDataOnPointerDown!.target.x + deltaX,
      this._cameraDataOnPointerDown!.target.y + deltaY
    );
  }

  private rotateCamera(deltaX: number, deltaY: number): void {
    const coeff = 100;
    this.azimuthAngle.reset(
      this._azimuthAngleOnPointerDown! - deltaX / coeff,
      this._azimuthAngleOnPointerDown! - deltaX / coeff
    );
    this.polarAngle.reset(
      this._polarAngleOnPointerDown! - deltaY / coeff,
      this._polarAngleOnPointerDown! - deltaY / coeff
    );
  }

  private onPointerUp = (pointer: Pointer): void => {
    this._movementUpdateShouldBeCalled = false;
    if (!this.pointerStart) {
      return;
    }

    if (
      pointer.isRightClick
      && this.rightMouseButton.shouldTriggerClickEvent
      && TimeStamp.value - this.rightMouseButton.timeStampOnDown < this.rightMouseButton.threshold.duration
    ) {
      this.onOpenContextMenu(pointer);
    } else {
      if (this._dampOnPointerUp) {
        this._dampOnPointerUp = false;

        const isTiltRotateModeActive = this.isTiltRotateModeActive();
        const convergenceX = isTiltRotateModeActive ? this.azimuthAngle : this.cameraTarget.x;
        const convergenceY = isTiltRotateModeActive ? this.polarAngle : this.cameraTarget.y;
        const speed = isTiltRotateModeActive ? this._prevTiltSpeed : this._prevMoveSpeed;
        const speedAbs = ThreeUtils.getLength(speed);
        if (isFinite(speedAbs) && speedAbs > 0) {
          const multiplicator = convergenceX.derivateAt0;

          // s = v * t => delta
          const time = DURATIONS.CAMERA_MOVEMENT;
          const delta = {
            x: (time * speed.x) / multiplicator,
            y: (time * speed.y) / multiplicator
          };

          convergenceX.setEnd(convergenceX.value + delta.x);
          convergenceY.setEnd(convergenceY.value + delta.y);
        }
      }
    }

    this._domElement.style.cursor = this._previousCursorStyle!;
    this.pointerStart = null;
    this.signals.cameraReleased.dispatch(pointer);

    if (pointer.isMiddleClick) {
      this.isMiddleBtnDown = false;
    }
    if (pointer.isRightClick) {
      this.rightMouseButton.isPressed = false;
      this.rightMouseButton.shouldTriggerClickEvent = false;
    }
  };

  private cancelDamping = (): void => {
    this._dampOnPointerUp = false;
  };

  private onStartPinchZoom = (zoomData: IPinchZoomGestureData): void => {
    this.pinchZoomData.cameraZoomOnPinchStart = this.cameraZoomValue;

    this.pointerDown(zoomData.middlePointer!, true);
  };

  private onUpdatePinchZoom = (zoomData: IPinchZoomGestureData): void => {
    const newZoomLevel = this.pinchZoomData.cameraZoomOnPinchStart * (zoomData.distance / zoomData.startDistance);

    const cursorWorldPos = ThreeUtils.domCoordinatesToWorldCoordinates(
      zoomData.middlePointer!.localX!,
      zoomData.middlePointer!.localY!,
      this._domElement,
      this.activeCamera
    );

    if (cursorWorldPos) {
      this.zoom(newZoomLevel / ZOOM_LIMIT.upper);
    }

    this.onPointerMove(zoomData.middlePointer!);
  };

  private onEndPinchZoom = (zoomData: IPinchZoomGestureData): void => {
    this.onPointerUp(zoomData.middlePointer!);
  };

  setSize(
    canvasWidth: number,
    canvasHeight: number,
    imageryWidth: number,
    imageryHeight: number,
    mapZoomFactor: number
  ): void {
    const canvasDimensionsChanged = this.canvasWidth !== canvasWidth || this.canvasHeight !== canvasHeight;
    const imageryDimensionsChanged = this.imageryWidth !== imageryWidth || this.imageryHeight !== imageryHeight;
    if (
      !canvasDimensionsChanged
      && this.imageryWidth === imageryWidth
      && this.imageryHeight === imageryHeight
      && this.mapZoomFactor === mapZoomFactor
    ) {
      // Nothing to do.
      return;
    }

    this.canvasWidth = canvasWidth;
    this.canvasHeight = canvasHeight;
    this.imageryWidth = imageryWidth;
    this.imageryHeight = imageryHeight;
    // We need to use a measure of distance as reference to map custom base imagery to
    // google imagery even when we're loading custom imagery first. To achieve that we're
    // relying on Google map unit having 1280x1280 dimensions (MapUnitDimension),
    // which should always be the case.
    this.spaceWidth = MapUnitDimension * Math.pow(2, mapZoomFactor);
    this.spaceHeight = MapUnitDimension * Math.pow(2, mapZoomFactor);
    this.mapZoomFactor = mapZoomFactor;

    if (canvasDimensionsChanged || imageryDimensionsChanged) {
      this.resize(canvasWidth, canvasHeight, true);
    }

    this.azimuthAngle.reset();
    this.polarAngle.reset();

    if (canvasDimensionsChanged) {
      this.cameraTarget.x.reset(undefined, undefined, -this.spaceWidth / 2, this.spaceWidth / 2);
      this.cameraTarget.y.reset(undefined, undefined, -this.spaceHeight / 2, this.spaceHeight / 2);

      this.moveCameraTo(0, 0);
    }
  }

  private clampCanvasSize(canvasSize: number): number {
    return THREEMath.clamp(canvasSize, 1, 10000);
  }

  private getDistanceToRectangle = (
    canvas: HTMLCanvasElement,
    rectWidth: number,
    rectHeight: number,
    mode: DefaultZoom
  ): number => {
    const canvasWidth = this.clampCanvasSize(canvas.width);
    const canvasHeight = this.clampCanvasSize(canvas.height);
    const canvasAspectRatio = canvasWidth / canvasHeight;
    const vFOV = THREEMath.degToRad(FOV);
    const hFOV = 2 * Math.atan(Math.tan(vFOV / 2) * canvasAspectRatio);
    const rectRatio = rectWidth / rectHeight;

    const checkHeight =
      (mode === 'fit' && rectRatio < canvasAspectRatio) || (mode === 'fill' && rectRatio > canvasAspectRatio);

    return checkHeight ? rectHeight / 2 / Math.tan(vFOV / 2) : rectWidth / 2 / Math.tan(hFOV / 2);
  };

  private updateMinMaxDistance(keepCurrentValuesIfPossible: boolean = false) {
    const canvas = this.canvas;
    if (canvas) {
      const maxDistance = this.getDistanceToRectangle(canvas, this.spaceWidth!, this.spaceHeight!, defaultZoom);
      const defaultDistance = keepCurrentValuesIfPossible ? this.cameraDistance.value : maxDistance;
      const minDistance = maxDistance / ZOOM_LIMIT.upper;
      if (maxDistance !== this.cameraDistance.max || minDistance !== this.cameraDistance.min) {
        this.cameraDistance.reset(defaultDistance, defaultDistance, minDistance, maxDistance);
      }
    }
  }

  resize(canvasWidth: number, canvasHeight: number, isInitial: boolean = false): void {
    // Without these clamps, we can easily have NaN values
    canvasWidth = this.clampCanvasSize(canvasWidth);
    canvasHeight = this.clampCanvasSize(canvasHeight);

    const canvasAspectRatio = canvasWidth / canvasHeight;
    this.perspectiveCamera.aspect = canvasAspectRatio;
    this.perspectiveCamera.updateProjectionMatrix();

    if (Math.max(this.spaceWidth!, this.spaceHeight!) > 0) {
      const maxDistance = this.getDistanceToRectangle(this.canvas, this.spaceWidth!, this.spaceHeight!, defaultZoom);
      const defaultDistance = maxDistance;
      const minDistance = maxDistance / ZOOM_LIMIT.upper;

      const vFOV = THREEMath.degToRad(FOV);
      this.activeCamera.near = (minDistance * Math.cos(vFOV)) / 10;
      const diagonal = Math.sqrt(this.spaceWidth! ** 2 + this.spaceHeight! ** 2);
      this.activeCamera.far = Math.sqrt(maxDistance ** 2 + diagonal ** 2) * maxDistanceMultiplier;
      this.activeCamera.updateProjectionMatrix();

      if (isInitial) {
        this.cameraDistance.reset(defaultDistance, defaultDistance, minDistance, maxDistance);
      } else {
        this.cameraDistance.reset(this.cameraDistance.value, this.cameraDistance.value, minDistance, maxDistance, true);
      }

      this.updateOrthoFrustum(false);
      this.limitCameraTarget();

      this.orthographicCamera.zoom = this.cameraZoomValue;
      this.orthographicCamera.updateProjectionMatrix();
    }

    this.updateMinMaxDistance();
  }

  private updateOrthoFrustum(updateProjectionMatrix: boolean) {
    const frustumSize = {
      width: 1,
      height: 1
    };
    if (this.canvas) {
      const canvasWidth = this.canvas.width;
      const canvasHeight = this.canvas.height;

      const canvasToSpaceRatio =
        defaultZoom === 'fit'
          ? Math.min(canvasWidth, canvasHeight) / Math.max(this.spaceWidth!, this.spaceHeight!)
          : Math.max(canvasWidth, canvasHeight) / Math.min(this.spaceWidth!, this.spaceHeight!);
      frustumSize.width = canvasWidth / canvasToSpaceRatio;
      frustumSize.height = canvasHeight / canvasToSpaceRatio;
      this.orthographicCamera.left = -frustumSize.width / 2;
      this.orthographicCamera.right = frustumSize.width / 2;
      this.orthographicCamera.top = frustumSize.height / 2;
      this.orthographicCamera.bottom = -frustumSize.height / 2;

      if (updateProjectionMatrix) {
        this.orthographicCamera.updateProjectionMatrix();
      }
    }
  }

  private onCameraDistanceUpdate = () => {
    this.limitCameraTarget();
  };

  private limitCameraTarget() {
    if (this.activeCamera === this.orthographicCamera && defaultZoom === 'fill') {
      const multiplicator = 1 / this.cameraZoomValue;
      const minX = -this.spaceWidth! / 2 - this.orthographicCamera.left * multiplicator;
      const maxX = this.spaceWidth! / 2 - this.orthographicCamera.right * multiplicator;
      const minY = -this.spaceHeight! / 2 - this.orthographicCamera.bottom * multiplicator;
      const maxY = this.spaceHeight! / 2 - this.orthographicCamera.top * multiplicator;

      if (minX <= maxX && minY <= maxY) {
        this.cameraTarget.x.setMin(-this.spaceWidth! / 2 - this.orthographicCamera.left * multiplicator, false);
        this.cameraTarget.x.setMax(this.spaceWidth! / 2 - this.orthographicCamera.right * multiplicator, false);
        this.cameraTarget.y.setMin(-this.spaceHeight! / 2 - this.orthographicCamera.bottom * multiplicator, false);
        this.cameraTarget.y.setMax(this.spaceHeight! / 2 - this.orthographicCamera.top * multiplicator, false);
      }
    }
  }

  private moveCameraTo(
    x: number,
    y: number,
    animated: boolean = false,
    animationDuration: number = this.cameraTarget.x.originalAnimationDuration
  ): void {
    if (animated) {
      this.cameraTarget.x.reset(this.cameraTarget.x.end, x, undefined, undefined, true, animationDuration);
      this.cameraTarget.y.reset(this.cameraTarget.y.end, y, undefined, undefined, true, animationDuration);
    } else {
      this.cameraTarget.x.reset(x, x);
      this.cameraTarget.y.reset(y, y);
    }
  }

  private onMouseWheel = (event: WheelEvent): void => {
    if (this.ignoreZoom) {
      return;
    }
    event.preventDefault();
    if (event.deltaY !== 0) {
      const cursorOffset = HTMLUtils.clientXYToOffsetXY(this._domElement, event.clientX, event.clientY);
      const cursorWorldPos = ThreeUtils.domCoordinatesToWorldCoordinates(
        cursorOffset.x,
        cursorOffset.y,
        this._domElement,
        this.activeCamera
      );

      if (cursorWorldPos) {
        const direction = -Math.sign(event.deltaY);
        this.zoomToDirection(direction, cursorWorldPos);
        this.ignoreZoom = true;
        setTimeout(() => {
          this.ignoreZoom = false;
        }, 80);
      }
    }
  };

  resetZoom(): void {
    this.cameraDistance.setEnd(this.cameraDistance.max, true, 0);
    getRootStore().editor.updateUnzoomable();
  }

  zoomToDirection(direction: number, pivot?: SimpleVector2): void {
    /** direction should be either -1 or 1 */
    const currentZoomValue = this.cameraDistance.min / this.cameraDistance.end; // on animation end
    this.zoom(direction > 0 ? currentZoomValue * this.zoomSpeed : currentZoomValue / this.zoomSpeed, pivot);
  }

  private zoom(
    amount: number,
    pivot?: SimpleVector2,
    animatedZoom: boolean = this.animatedZoom,
    animationDuration: number = this.cameraDistance.originalAnimationDuration
  ): void {
    const previousCameraDistance = this.cameraDistance.value;
    const newCameraDistance = this.cameraDistance.min / amount;

    if (animatedZoom) {
      this.cameraDistance.setEnd(newCameraDistance, true, animationDuration);
    } else {
      this.cameraDistance.reset(newCameraDistance, newCameraDistance, undefined, undefined, true);
    }

    if (pivot) {
      const clampedNewCameraDistance = THREEMath.clamp(
        newCameraDistance,
        this.cameraDistance.min,
        this.cameraDistance.max
      );

      const targetToPivot = {
        x: pivot.x - this.cameraTarget.x.value,
        y: pivot.y - this.cameraTarget.y.value
      };

      const coeff = (previousCameraDistance - clampedNewCameraDistance) / previousCameraDistance;

      const newTarget = {
        x: this.cameraTarget.x.value + targetToPivot.x * coeff,
        y: this.cameraTarget.y.value + targetToPivot.y * coeff
      };

      if (animatedZoom) {
        this.cameraTarget.x.setEnd(newTarget.x, true, animationDuration);
        this.cameraTarget.y.setEnd(newTarget.y, true, animationDuration);
      } else {
        this.cameraTarget.x.reset(newTarget.x, newTarget.x, undefined, undefined, true);
        this.cameraTarget.y.reset(newTarget.y, newTarget.y, undefined, undefined, true);
      }

      this.limitCameraTarget();
    }
  }

  private changeCameraType(newCameraType: 'perspective' | 'orthographic'): void {
    const oldCamera = this.activeCamera;

    if (newCameraType === 'perspective') {
      this.activeCamera = this.perspectiveCamera;
    } else {
      this.orthographicCamera.zoom = this.cameraZoomValue;
      this.activeCamera = this.orthographicCamera;
    }

    const hasChanged = oldCamera !== this.activeCamera;

    if (hasChanged) {
      this.activeCamera.far = oldCamera.far;
      this.activeCamera.near = oldCamera.near;
      this.activeCamera.updateProjectionMatrix();
      this.update(true);
    }
  }

  private updateCameraPos(
    camera: PerspectiveCamera | OrthographicCamera = this.activeCamera,
    targetX: number = this.cameraTarget.x.value,
    targetY: number = this.cameraTarget.y.value
  ): void {
    this.targetToCamera
      .copy(this.toZ)
      .applyAxisAngle(this.toX, this.polarAngle.value)
      .applyAxisAngle(this.toZ, this.azimuthAngle.value)
      .normalize()
      // With orthocamera, we're looking from far away now, to ensure
      // that the camera doesn't get inside the models, causing "invisible-models" problem
      .multiplyScalar(
        camera instanceof OrthographicCamera
          ? this.cameraDistance.max * maxDistanceMultiplier
          : this.cameraDistance.value
      );
    this.cameraTargetV3.set(targetX, targetY, 0);
    camera.position.copy(this.cameraTargetV3).add(this.targetToCamera);
  }

  update(force: boolean = false): void {
    this.updatePointerMovement();

    const hasZoomChanged = this.cameraDistance.hasChangedSinceLastTick;

    const hasAnyCameraPropsChanged =
      this.cameraTarget.x.hasChangedSinceLastTick
      || this.cameraTarget.y.hasChangedSinceLastTick
      || this.azimuthAngle.hasChangedSinceLastTick
      || this.polarAngle.hasChangedSinceLastTick
      || hasZoomChanged;

    if (hasAnyCameraPropsChanged || force) {
      this._prevTiltSpeed.x = this.azimuthAngle.prevDeltaValue / this.azimuthAngle.prevDeltaTime;
      this._prevTiltSpeed.y = this.polarAngle.prevDeltaValue / this.azimuthAngle.prevDeltaTime;
      this._prevMoveSpeed.x = this.cameraTarget.x.prevDeltaValue / this.cameraTarget.x.prevDeltaTime;
      this._prevMoveSpeed.y = this.cameraTarget.y.prevDeltaValue / this.cameraTarget.y.prevDeltaTime;
      if (this.polarAngle.hasChangedSinceLastTick) {
        const newCameraType =
          Math.abs(this.polarAngle.value - this.polarAngle.min) < EPSILON ? 'orthographic' : 'perspective';
        this.changeCameraType(newCameraType);
      }

      this.updateCameraPos();
      this._minimalRenderDistance =
        this.activeCamera.position.z - this.activeCamera.near - MINIMAL_DISTANCE_FROM_FRUSTUM_TOP;
      getRootStore().editor.throttledUpdateMarkerHeights();
      ThreeUtils.updateMatrices(this.activeCamera);
      this.activeCamera.lookAt(this.cameraTarget.x.value, this.cameraTarget.y.value, 0);

      if (hasZoomChanged) {
        if (this.activeCamera instanceof OrthographicCamera) {
          this.activeCamera.zoom = this.cameraZoomValue;
          this.activeCamera.updateProjectionMatrix();
        }
        this.signals.cameraZoomChange.dispatch();
      }

      if (hasAnyCameraPropsChanged) {
        this.signals.cameraPropsChange.dispatch();
      }
    }
  }

  private updateFrustum(): void {
    const camera = this.activeCamera;
    this.frustum.setFromProjectionMatrix(
      this.frustumMatrix.identity().multiplyMatrices(camera.projectionMatrix, camera.matrixWorldInverse)
    );
  }

  isPointVisibleForCamera(point: ISimpleVector3): boolean {
    this.updateFrustum();
    const pointAsVector3 = new Vector3(point.x, point.y, point.z);

    return this.frustum.containsPoint(pointAsVector3);
  }

  // in worldpos
  get target(): SimpleVector2 {
    return {
      x: this.cameraTarget.x.value,
      y: this.cameraTarget.y.value
    };
  }

  get isPanning(): boolean {
    return this.isMiddleBtnDown || this.rightMouseButton.isPressed || !!this.pointerStart;
  }

  get cameraZoomValue(): number {
    return (ZOOM_LIMIT.upper * this.cameraDistance.min) / this.cameraDistance.value;
  }
}
