import styled from 'styled-components';
import { LyraIcon } from '@aurorasolar/lyra-ui-kit';

export const DeleteButtonWrapper = styled.button`
  position: absolute;
  top: -12px;
  right: 0;
  width: 46px;
  height: 22px;
  border-radius: 16px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.5);
  background-color: #fff;
  cursor: pointer;
`;

export const DeleteIcon = styled(LyraIcon.Icon)`
  width: 12px;
  height: 12px;
  > path {
    fill: #4a4a4a;
  }
`;

export const ThumbnailWrap = styled.div`
  margin-bottom: 20px;
`;

export const FormWrap = styled.div`
  position: relative;

  input,
  textarea {
    font-family: 'Roboto', sans-serif;
    font-size: 11px;
    font-weight: normal;
    line-height: normal;
    color: #4a4a4a;
  }

  label {
    display: block;
    font-family: 'Roboto', sans-serif;
    font-size: 10px;
    font-weight: normal;
    line-height: normal;
    color: rgb(74, 74, 74);
    text-transform: uppercase;
    margin-bottom: 4px;
    height: auto;
  }

  textarea {
    border-radius: 4px;
    border: solid 1px #d9d9d9;
    background-color: #ffffff;
    padding: 4px 8px;
    box-sizing: border-box;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  box-sizing: border-box;
`;

export const FormRow = styled.div`
  position: relative;
  margin-bottom: 15px;
  &:last-child {
    margin-bottom: 0;
  }
`;
