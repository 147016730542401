import type { ComponentProps } from 'react';
import type React from 'react';
import { createElement } from 'react';
import Registry from '../../../utils/Registry';

export enum IconKeys {
  Map = 'map',
  MapSelected = 'map-selected',
  BaseMap = 'base-map',
  BaseMapSelected = 'base-map-selected',
  HideMap = 'hide-map',
  HideMapSelected = 'hide-map-selected',
  CustomMap = 'custom-map',
  CustomMapSelected = 'custom-map-selected',
  PositionImagery = 'position-imagery',
  ScaleImagery = 'scale-imagery',
  RotateImagery = 'rotate-imagery',
  TraceToScale = 'trace-to-scale'
}
const convertRawSvgToReactFC = (src: { default: string }): React.FC => {
  return ({ ...props }: ComponentProps<'div'>) =>
    createElement('div', {
      ...props,
      className: `icon-mapper-svg-wrapper ${props.className ? props.className : ''}`,
      dangerouslySetInnerHTML: {
        __html: src
      }
    });
};

const mappingIcons = {
  arrow: convertRawSvgToReactFC(require('./assets/icon-arrow.svg')),
  'arrow-collapse': convertRawSvgToReactFC(require('./assets/arrow-collapse.svg')),
  'arrow-down': convertRawSvgToReactFC(require('./assets/arrow-down.svg')),
  'bold-arrow': convertRawSvgToReactFC(require('./assets/icon-bold-arrow.svg')),
  circle: convertRawSvgToReactFC(require('./assets/circle-protrusion-tool.svg')),
  check: convertRawSvgToReactFC(require('./assets/check.svg')),
  'define-azimuth': convertRawSvgToReactFC(require('./assets/define-azimuth-icon.svg')),
  'define-slope': convertRawSvgToReactFC(require('./assets/define-slope-icon.svg')),
  'ground-snow': convertRawSvgToReactFC(require('./assets/ground-snow-load-icon.svg')),
  handler: convertRawSvgToReactFC(require('./assets/handler.svg')),
  'help-docs': convertRawSvgToReactFC(require('./assets/icon-documentation.svg')),
  'high-temperature': convertRawSvgToReactFC(require('./assets/high-temperature-icon.svg')),
  'horizontal-protrusion': convertRawSvgToReactFC(require('./assets/horizontal-protrusion.svg')),
  'import-3D': convertRawSvgToReactFC(require('./assets/subtool-import-3-d.svg')),
  'increase-decrease-arrow': convertRawSvgToReactFC(require('./assets/increase-decrease-arrow-icon.svg')),
  info: convertRawSvgToReactFC(require('./assets/icon-info.svg')),
  Logo: convertRawSvgToReactFC(require('./assets/Lyra-logo.svg')),
  'low-temperature': convertRawSvgToReactFC(require('./assets/low-temperature-icon.svg')),
  'map-source': convertRawSvgToReactFC(require('./assets/icon-map-source.svg')),
  other: convertRawSvgToReactFC(require('./assets/other-icon.svg')),
  'vertical-dots': convertRawSvgToReactFC(require('./assets/ellipsis-24px.svg')),
  pan: convertRawSvgToReactFC(require('./assets/icon-pan.svg')),
  panels: convertRawSvgToReactFC(require('./assets/icon-panels.svg')),
  'parallel-protrusion': convertRawSvgToReactFC(require('./assets/parallel-protrusion.svg')),
  pencil: convertRawSvgToReactFC(require('./assets/icon-pencil.svg')),
  protrusions: convertRawSvgToReactFC(require('./assets/icon-protrusions.svg')),
  'protrusion-height': convertRawSvgToReactFC(require('./assets/protrusion-height.svg')),
  undo: convertRawSvgToReactFC(require('./assets/undo-icon.svg')),
  rectangle: convertRawSvgToReactFC(require('./assets/rectangle-protrusion-tool.svg')),
  redo: convertRawSvgToReactFC(require('./assets/redo-icon.svg')),
  showHideElements: convertRawSvgToReactFC(require('./assets/showHideElements-icon.svg')),
  'roof-condition': convertRawSvgToReactFC(require('./assets/condition-of-roof-icon.svg')),
  'roof-decking': convertRawSvgToReactFC(require('./assets/roof-decking-icon.svg')),
  'roof-framing': convertRawSvgToReactFC(require('./assets/roof-framing-icon.svg')),
  'roof-surface': convertRawSvgToReactFC(require('./assets/roof-surface-icon.svg')),
  'roof-type-flat': convertRawSvgToReactFC(require('./assets/roof-type-flat.svg')),
  'roof-type-sloped': convertRawSvgToReactFC(require('./assets/roof-type-sloped-copy.svg')),
  selection: convertRawSvgToReactFC(require('./assets/icon-selection-tool.svg')),
  'parcel-boundary': convertRawSvgToReactFC(require('./assets/icon-parcel-boundary.svg')),
  'site-equipment': convertRawSvgToReactFC(require('./assets/icon-site-equipment.svg')),
  'site-features': convertRawSvgToReactFC(require('./assets/icon-site-features.svg')),
  /* SMART GUIDES ICONS */
  // Extension Lines guide
  'smartguide-extension-lines': convertRawSvgToReactFC(require('./assets/smartguide-extension-lines.svg')),

  // Parallel Lines guide
  'smartguide-parallel-lines': convertRawSvgToReactFC(require('./assets/smartguide-parallel-lines.svg')),

  // Angle Snap guide
  'smartguide-live-angles': convertRawSvgToReactFC(require('./assets/smartguide-live-angles.svg')),

  // Midpoints guide
  'smartguide-midpoints-lines': convertRawSvgToReactFC(require('./assets/smartguide-midpoints-lines.svg')),

  // Perpendicular Alignment guide
  'smartguide-perpendicular-alignment': convertRawSvgToReactFC(
    require('./assets/smartguide-perpendicular-alignment.svg')
  ),
  // Line & Point Snap guide
  'smartguide-snap': convertRawSvgToReactFC(require('./assets/smartguide-snap.svg')),

  'icon-document': convertRawSvgToReactFC(require('./assets/icon-document.svg')),
  smartguides: convertRawSvgToReactFC(require('./assets/smartguides.svg')),
  'solar-access': convertRawSvgToReactFC(require('./assets/solar-access-icon.svg')),
  'sun-success': convertRawSvgToReactFC(require('./assets/sun-success.svg')),
  'split-roof': convertRawSvgToReactFC(require('./assets/subtool-split-roof.svg')),
  'street-view': convertRawSvgToReactFC(require('./assets/street-view.svg')),
  'switch-values': convertRawSvgToReactFC(require('./assets/icon-switch-values.svg')),
  tool: convertRawSvgToReactFC(require('./assets/tool-icon.svg')),
  'tool-import-3d': convertRawSvgToReactFC(require('./assets/import-3-d-model-tool.svg')),
  'tool-properties': convertRawSvgToReactFC(require('./assets/tool-properties-icon.svg')),
  'trace-outlines': convertRawSvgToReactFC(require('./assets/subtool-trace-outlines.svg')),
  'trace-roof': convertRawSvgToReactFC(require('./assets/subtool-trace-roof.svg')),
  'tracing-tool': convertRawSvgToReactFC(require('./assets/icon-tracing-tool.svg')),
  'wind-exposure': convertRawSvgToReactFC(require('./assets/wind-exposure-cat-icon.svg')),
  'wind-speed': convertRawSvgToReactFC(require('./assets/wind-speed-icon.svg')),
  'yellow-arrow': convertRawSvgToReactFC(require('./assets/yellow-arrow.svg')),
  'drag-edge': convertRawSvgToReactFC(require('./assets/hint-drag-edge.svg')),
  move: convertRawSvgToReactFC(require('./assets/hint-move.svg')),
  rotate: convertRawSvgToReactFC(require('./assets/hint-rotate.svg')),
  scale: convertRawSvgToReactFC(require('./assets/hint-scale.svg')),
  'pull-edge': convertRawSvgToReactFC(require('./assets/hnt-pull-edge.svg')),
  'node-blue': convertRawSvgToReactFC(require('./assets/node-blue.svg')),
  'aligned-rows': convertRawSvgToReactFC(require('./assets/icon-aligned-rows.svg')),
  'maximized-modules': convertRawSvgToReactFC(require('./assets/icon-maximized-modules.svg')),
  'staggered-rows': convertRawSvgToReactFC(require('./assets/icon-staggered-rows.svg')),
  'strategy-portrait': convertRawSvgToReactFC(require('./assets/strategy-portrait.svg')),
  'strategy-landscape': convertRawSvgToReactFC(require('./assets/strategy-landscape.svg')),
  'alert-icon': convertRawSvgToReactFC(require('./assets/alert-icon.svg')),
  'graph-aligned-rows': convertRawSvgToReactFC(require('./assets/graph-aligned-rows.svg')),
  'graph-maximized-modules': convertRawSvgToReactFC(require('./assets/graph-maximized-modules.svg')),
  'graph-staggered-rows': convertRawSvgToReactFC(require('./assets/graph-staggered-rows.svg')),
  'add-module': convertRawSvgToReactFC(require('./assets/add-module.svg')),
  'new-remove-module': convertRawSvgToReactFC(require('./assets/new-remove-module.svg')),
  'change-module': convertRawSvgToReactFC(require('./assets/change-orientation.svg')),
  'review-circuits': convertRawSvgToReactFC(require('./assets/review-circuits-connection.svg')),
  'set-back': convertRawSvgToReactFC(require('./assets/setback.svg')),
  stringing: convertRawSvgToReactFC(require('./assets/stringing.svg')),
  'tool-settings': convertRawSvgToReactFC(require('./assets/tool-settings.svg')),
  'tool-wrench': convertRawSvgToReactFC(require('./assets/tool-wrench.svg')),
  [IconKeys.Map]: convertRawSvgToReactFC(require('./assets/icon-map.svg')),
  [IconKeys.MapSelected]: convertRawSvgToReactFC(require('./assets/icon-map-selected.svg')),
  [IconKeys.BaseMap]: convertRawSvgToReactFC(require('./assets/icon-baseMap-default.svg')),
  [IconKeys.BaseMapSelected]: convertRawSvgToReactFC(require('./assets/icon-baseMap-selected.svg')),
  [IconKeys.HideMap]: convertRawSvgToReactFC(require('./assets/icon-hideMaps-default.svg')),
  [IconKeys.HideMapSelected]: convertRawSvgToReactFC(require('./assets/icon-hideMaps-selected.svg')),
  [IconKeys.CustomMap]: convertRawSvgToReactFC(require('./assets/icon-customMap-default.svg')),
  [IconKeys.CustomMapSelected]: convertRawSvgToReactFC(require('./assets/icon-customMap-selected.svg')),
  [IconKeys.PositionImagery]: convertRawSvgToReactFC(require('./assets/position-icon.svg')),
  [IconKeys.ScaleImagery]: convertRawSvgToReactFC(require('./assets/scale-icon.svg')),
  [IconKeys.RotateImagery]: convertRawSvgToReactFC(require('./assets/rotate-icon.svg')),
  'wind-cat-b': convertRawSvgToReactFC(require('./assets/wind-cat-B.svg')),
  'wind-cat-c': convertRawSvgToReactFC(require('./assets/wind-cat-C.svg')),
  'wind-cat-d': convertRawSvgToReactFC(require('./assets/wind-cat-D.svg')),
  [IconKeys.TraceToScale]: convertRawSvgToReactFC(require('./assets/trace-to-scale.svg'))
};

const registry = new Registry(mappingIcons);

export default registry;
