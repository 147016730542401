import { Col, Row } from 'react-flexbox-grid';
import styled, { PropsTheme } from 'styled-components';
import { Color } from '../../typings';
import { H4, Paragraph } from '../FontFamily/Fonts';

const ListContainer = styled.div`
  border-radius: 4px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  background-color: ${({ theme }: PropsTheme): Color => theme.colors.whiteBg};
  padding: 15px;
`;

const CardWrapper = styled.div`
  border-radius: 4px;
  border: solid 1px ${({ theme }: PropsTheme): Color => theme.colors.grayColor6};
  padding: 15px 14px 10px 16px;
  margin-bottom: 15px;
`;

const TitleRow = styled(Row)`
  display: flex;
  align-items: center;
`;

const TitleWrapper = styled(H4)`
`;

const RightColumn = styled(Col)`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const CreatorRow = styled(Row)`
  display: flex;
  align-items: center;
  margin-top: 20px;
`;

const CreatorComment = styled(Paragraph)`
  color: ${({ theme }: PropsTheme): Color => theme.colors.grayColor2};
  margin-right: 5px;
`;

const FlexColumn = styled(Col)`
  display: flex;
  align-items: center;
`;

const SeparatorLine = styled.div`
  width: 100%;
  height: 1px;
  background: ${({ theme }: PropsTheme): Color => theme.colors.grayColor6};
  margin-right: 8px;
  margin-left: 8px;
  margin-top: 10px;
`;

const TagRow = styled(Row)`
  margin-top: 10px;
`;

export {
  ListContainer,
  CardWrapper,
  TitleRow,
  TitleWrapper,
  RightColumn,
  CreatorRow,
  CreatorComment,
  FlexColumn,
  SeparatorLine,
  TagRow
};
