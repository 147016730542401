import {
  LyraButtons, LyraDivider
} from '@aurorasolar/lyra-ui-kit';
import { EButtonType } from '@aurorasolar/lyra-ui-kit/lib/components/Buttons/EButtonType';
import { EThemeComponentColor } from '@aurorasolar/lyra-ui-kit/lib/components/Theme/EThemeComponentColor';
import { observer } from 'mobx-react-lite';
import type { ReactElement } from 'react';
import React, {
  useEffect, useState
} from 'react';
import { autorun } from 'mobx';
import type { ArrayPlacementStage } from '../../../domain/stages/DesignStages/ArrayPlacementStage';
import { stageIs } from '../../../domain/stages/DesignStages/utils';
import type { IStage } from '../../../domain/stages/IStage';
import useStore from '../../../stores/useStore';
import type { DesignWorkspace } from '../../../stores/UiStore/WorkspaceStore/workspaces/DesignWorkspace';
import { isDesignWorkspace } from '../../../stores/UiStore/WorkspaceStore/utils';
import PropertyPanelHeader from '../PropertyPanel/PropertyPanelHeader/PropertyPanelHeader';
import {
  NextStepWrapper,
  PropertyPanelContainer,
  PropertyPanelWrapper,
  SubmitSectionWrapper,
  PropertyContentWrapper
} from '../PropertyPanel/styles';
import { DesignStep } from '../../../domain/models/Design/DesignState';
import type { LayoutDesignStage } from '../../../domain/stages/DesignStages/LayoutDesignStage';
import { ModuleCard } from '../../components/SystemDesign/Module/ModuleCard';
import { DCPowerRatingCard } from '../../components/SystemDesign/DCPowerRating/DCPowerRatingCard';
import { LayoutStrategyCard } from '../../components/SystemDesign/LayoutStrategy/LayoutStrategyCard';
import { MountingSystemDefinitionsCard } from '../../components/SystemDesign/MountingSystemDefinitionsCard';
import config, { UI_MODE } from '../../../config/config';

export function isLayoutDesignStage(stage: IStage | undefined): boolean {
  if (stage) {
    return stageIs<LayoutDesignStage>(stage, DesignStep.LAYOUT_DESIGN);
  }
  return false;
}

export const LayoutDesign = observer((): ReactElement => {
  const {
    workspace, domain
  } = useStore();
  const [designWorkspace, setDesignWorkspace] = useState<DesignWorkspace | null>(null);
  const [layoutDesignStage, setLayoutDesignStage] = useState<LayoutDesignStage | null>(null);
  const [arrayPlacementStage, setArrayPlacementStage] = useState<ArrayPlacementStage | null>(null);
  const [totalModules, setTotalModules] = useState(0);

  useEffect(() => {
    //We need an autorun because the component observer can't track
    // layoutDesignStage?.totalModules as it's created after the initial render
    if (layoutDesignStage) {
      autorun(() => {
        const newTotalModules = layoutDesignStage?.totalModules;
        if (newTotalModules) {
          setTotalModules(newTotalModules);
        }
      });
    }
  }, [layoutDesignStage, layoutDesignStage?.totalModules]);

  useEffect((): void => {
    if (isDesignWorkspace(workspace.currentWorkspace)) {
      setDesignWorkspace(workspace.currentWorkspace as DesignWorkspace);
    } else {
      setDesignWorkspace(null);
    }
  }, [workspace.currentWorkspace]);

  useEffect((): void => {
    if (designWorkspace) {
      const stage = designWorkspace.stageManager?.currentStage;
      const previousStep = (designWorkspace.stageManager?.currentIndex ?? 1) - 1;

      setLayoutDesignStage(isLayoutDesignStage(stage) ? (stage as LayoutDesignStage) : null);

      setArrayPlacementStage(designWorkspace.stageManager?.getStageData(previousStep) as ArrayPlacementStage);
      layoutDesignStage?.getEnergyProductionEstimate();
    } else {
      setLayoutDesignStage(null);
      setArrayPlacementStage(null);
    }
  }, [designWorkspace, layoutDesignStage]);

  // This is possible when project teardown is happening
  if (!domain.optionalDesign) {
    return <></>;
  }

  const pvModuleInfo = domain.design.supplementalData.pvModuleInfo;

  return (
    <PropertyPanelWrapper>
      {layoutDesignStage && arrayPlacementStage && (
        <>
          <PropertyPanelContainer>
            <PropertyPanelHeader icon="tool-properties" name="Layout Design" iconBg="#427ecd" />
          </PropertyPanelContainer>
          <PropertyContentWrapper>
            <ModuleCard pvModuleInfo={pvModuleInfo} totalModules={totalModules} />
            <LyraDivider.Divider direction="horizontal" color={EThemeComponentColor.GRAY_200} />
            <MountingSystemDefinitionsCard arrayPlacementStage={arrayPlacementStage} editable={false} />
            <LyraDivider.Divider direction="horizontal" color={EThemeComponentColor.GRAY_200} />
            <LayoutStrategyCard arrayPlacementStage={arrayPlacementStage} editable={false} />
            <LyraDivider.Divider direction="horizontal" color={EThemeComponentColor.GRAY_200} />
            <DCPowerRatingCard layoutDesignStage={layoutDesignStage} domain={domain} />
          </PropertyContentWrapper>
          {config.featureFlag.uiMode !== UI_MODE.AURORA && (
            <SubmitSectionWrapper>
              <NextStepWrapper xs={12} className="pv-xs">
                <LyraButtons.Buttons
                  className="back-button"
                  styleType="flat"
                  colorTheme={EThemeComponentColor.TRANSPARENT}
                  fontColorTheme={EThemeComponentColor.AQUAMARINE}
                  isLeft
                  onClick={(): void => {
                    designWorkspace?.stageManager?.previous();
                  }}
                >
                  <b>Back</b>
                </LyraButtons.Buttons>
                <LyraButtons.ButtonIcon
                  icon="arrow"
                  type={EButtonType.ROUNDED_BUTTON}
                  colorTheme={EThemeComponentColor.AQUAMARINE}
                  withIconTheme
                  onClick={(): void => {
                    designWorkspace?.stageManager?.next();
                  }}
                >
                  <b>Electrical Design</b>
                </LyraButtons.ButtonIcon>
              </NextStepWrapper>
            </SubmitSectionWrapper>
          )}
        </>
      )}
    </PropertyPanelWrapper>
  );
});
