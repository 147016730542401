/* eslint-disable react-hooks/exhaustive-deps */
import type { StepData } from '@aurorasolar/lyra-ui-kit';
import {
  LyraButtons, LyraIcon, LyraPipelineSnapping
} from '@aurorasolar/lyra-ui-kit';
import { EThemeComponentColor } from '@aurorasolar/lyra-ui-kit/lib/components/Theme/EThemeComponentColor';
import { observer } from 'mobx-react-lite';
import type {
  PropsWithChildren, ReactElement
} from 'react';
import React, {
  useCallback, useEffect, useState
} from 'react';
import defer from 'lodash/defer';
import slice from 'lodash/slice';
import type { IProgressStepperStage } from '../../../domain/stages/IProgressStepperStage';
import useStore from '../../../stores/useStore';
import type { DesignWorkspace } from '../../../stores/UiStore/WorkspaceStore/workspaces/DesignWorkspace';
import type { ElectricalBosStage } from '../../../domain/stages/DesignStages/ElectricalBosStage';
import type { MountingBosStage } from '../../../domain/stages/DesignStages/MountingBosStage';
import { Workspace } from '../../../stores/UiStore/WorkspaceStore/index';
import config, { UI_MODE } from '../../../config/config';
import DocumentGenerationMenu from './DocumentGenerationMenu';

import {
  ButtonPermitPackagePreview,
  ButtonSiteDefinition,
  DocumentWrapper,
  ProgressContainer,
  WrapperStepper
} from './styles';

import {
  ArrayPlacementStageValue,
  CompleteStageValue,
  ElectricalBosStageValue,
  ElectricalDesignStageValue,
  LayoutDesignStageValue,
  MountingBosStageValue
} from './stages';

interface IProgressStepperProps {
  showSalesMenuSection: boolean;
}

const ProgressStepper = (props: IProgressStepperProps): ReactElement => {
  const {
    properties, workspace, domain
  } = useStore();

  const designWorkspace = workspace.designWorkspace as DesignWorkspace;
  const currentDesign = domain.optionalDesign;
  const stageManager = designWorkspace?.stageManager;
  const currentStage = stageManager?.currentStage;
  const minEnabledStage = config.featureFlag.uiMode === UI_MODE.AURORA ? 3 : 0;

  const [menuVisible, setMenuVisible] = useState(false);
  const [stepData, setStepData] = useState<StepData[]>([]);
  const [documentsAvailable, setDocumentsAvailable] = useState<number>(0);

  const calculateStepData = (): void => {
    const stages = stageManager?.stages ?? [];
    const result: StepData[] = slice(
      stages.filter((stage: IProgressStepperStage): boolean => !!stage.title),
      minEnabledStage
    );
    setStepData(result);
  };

  useEffect((): void => {
    if (currentStage) {
      calculateStepData();
      getStatusNumber();
      properties.setPropertyPanel(currentStage.propCodeUI);
    }
  }, [currentStage, properties]);

  const openDocumentGenerationMenu = (): void => {
    setMenuVisible(!menuVisible);
  };

  const showStepper = (): boolean => {
    return !!(currentStage?.title && stepData.length);
  };

  const previousStep = useCallback((): Promise<void> => {
    if (!stageManager!.stageTransitionInProgress) {
      return stageManager!.previous();
    }
    return Promise.resolve();
  }, [stageManager]);

  const nextStep = useCallback((): Promise<void> => {
    if (!stageManager || stageManager?.stageTransitionInProgress) {
      return Promise.resolve();
    }

    if (stageManager.currentIndex !== ElectricalBosStageValue) {
      return stageManager.next();
    }

    // Electrical stage is exceptional here. We need to check
    // if electrical equipment placed first and then let user
    // review circuits in separate modal. After review, user can proceed to the next stage.
    const electrical = currentStage as ElectricalBosStage;

    if (electrical.isEquipmentPlaced) {
      return electrical.openReviewCircuitsAndProceedToMountingModal();
    }
    return Promise.resolve();
  }, [stageManager, currentStage]);

  const isLastStep = (): boolean => {
    return !!stageManager && stageManager.currentIndex === stageManager.stages.length - 1;
  };

  const lastStep = (): MountingBosStage => {
    return stageManager!.currentStage as MountingBosStage;
  };

  const getStatusNumber = (): void => {
    const stageIndex = stageManager!.currentIndex;
    if (stageIndex === ArrayPlacementStageValue) {
      setDocumentsAvailable(0);
    }
    if (stageIndex === LayoutDesignStageValue) {
      setDocumentsAvailable(2);
    }
    if (stageIndex === ElectricalDesignStageValue) {
      setDocumentsAvailable(3);
    }
    if (stageIndex === ElectricalBosStageValue) {
      setDocumentsAvailable(6);
    }
    if (stageIndex === MountingBosStageValue) {
      setDocumentsAvailable(6);
    }
    if (stageIndex === CompleteStageValue) {
      setDocumentsAvailable(7);
    }
  };

  const changeWorkspace = useCallback((): void => {
    if (!stageManager?.stageTransitionInProgress) {
      workspace.changeWorkspace(Workspace.Project);
    }
  }, [workspace]);

  const showProgressContainer = designWorkspace && showStepper();

  if (!showProgressContainer) {
    return <></>;
  }
  const currentStep = stageManager!.currentIndex - 1;
  const isArrayPlacementStep = currentStep === 0;
  const documentsButtonDisabled = documentsAvailable === 0 || !currentDesign?.hasPvModules;
  const showPermitPackageButton = isLastStep();
  const showDocumentGenerationMenu = menuVisible && !isArrayPlacementStep;

  return (
    <ProgressContainer>
      <WrapperStepper>
        {isArrayPlacementStep && (
          <ButtonSiteDefinition onClick={changeWorkspace}>
            <LyraIcon.Icon name="arrow" colorTheme={EThemeComponentColor.AQUAMARINE} />
            <span>Back to Site Definition</span>
          </ButtonSiteDefinition>
        )}

        <LyraPipelineSnapping.PipelineSnapping
          currentStep={currentStep < minEnabledStage ? 0 : currentStep - minEnabledStage}
          data={stepData}
          onClickLeft={previousStep}
          onClickRight={nextStep}
        />

        {showPermitPackageButton && (
          <PermitPackagePreviewButton
            disabled={!lastStep().hasFormSpecification}
            openPermitPackagePreviewModal={(): void => {
              if (lastStep().hasFormFields) {
                // Do nothing, the form will be submitted as the button has type="submit" and form attribute.
                // Form generator's `onSubmit` will handle the submission and open the permit package modal.
              } else {
                // Using defer to ensure that the form is submitted before the element
                // is re-rendered. Otherwise, it aborts the submission.
                defer((): void => lastStep().openPermitPackageModal());
              }
            }}
          >
            <span>Preview Permit Package</span>
          </PermitPackagePreviewButton>
        )}
      </WrapperStepper>

      <DocumentWrapper>
        <LyraButtons.ButtonIcon
          icon="icon-available-documents"
          fullWidth
          disabled={documentsButtonDisabled}
          onClick={openDocumentGenerationMenu}
        >
          Available Documents
        </LyraButtons.ButtonIcon>
        {showDocumentGenerationMenu && <DocumentGenerationMenu showSalesMenuSection={props.showSalesMenuSection} />}
      </DocumentWrapper>
    </ProgressContainer>
  );
};

interface IPermitPackagePreviewButtonProps {
  disabled: boolean;
  openPermitPackagePreviewModal?: () => void;
}

/**
 * There are two scenarios:
 * 1. Mounting BOS form exists, then we render a button that submits the form (so that form validation can run)
 * 2. Mounting BOS form does not exist, then we simply open permit package dialog
 */
function PermitPackagePreviewButton(props: PropsWithChildren<IPermitPackagePreviewButtonProps>): ReactElement {
  const {
    disabled, children, openPermitPackagePreviewModal
  } = props;
  return (
    <ButtonPermitPackagePreview onClick={openPermitPackagePreviewModal} disabled={disabled}>
      {children}
    </ButtonPermitPackagePreview>
  );
}

export default observer(ProgressStepper);
