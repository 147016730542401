import React, { BaseProps, ReactElement } from 'react';
import { Grid } from '../Grid';
import { Aside } from './Aside';
import * as names from './constants';
import { Content } from './Content';
import { Footer } from './Footer';
import { Header } from './Header';
import { Tool } from './Tool';
import { SubTool } from './SubTool';

type LayoutProps = BaseProps & {
  auroraMode?: boolean;
};

//  Base:                                         auroraMode:
//  +-------------------------------+             +-------------------------------+
//  |             Header            |             |                       |       |
//  +-------------------------------+             +                       |       +
//  |  Tool |               | Aside |             |                       |       |
//  |       |               |       |             |        Content        | Aside |
//  +       +    Content    +       +             +                       +       +
//  |       |               |       |             |                       |       |
//  |       |               |       |             |                       |       |
//  +       +---------------+       +             +-----------------------+       +
//  |       |    Footer     |       |             |        Footer         |       |
//  +-------+---------------+-------+             +-------+---------------+-------+
// 
function SolarLayout({ children, auroraMode }: LayoutProps): ReactElement {
  const COLUMNS: string = `${!auroraMode ? names.WIDTH_TOOL : ``} 1fr ${names.WIDTH_ASIDE}`;
  const ROWS: string = `${!auroraMode ? names.HEIGHT_TOP : ``} 1fr ${names.HEIGHT_BOTTOM}`;
  let AREA: string = `
    "${!auroraMode ? names.TOOL : ''} ${names.CONTENT} ${names.ASIDE}"
    "${!auroraMode ? names.TOOL : ''} ${names.FOOTER} ${names.ASIDE}"
  `;

  if (!auroraMode) {
    AREA = `"${names.HEADER} ${names.HEADER} ${names.HEADER}"` + AREA;
  }
  
  return (
    <Grid areas={AREA} rows={ROWS} cols={COLUMNS}>
      {children}
    </Grid>
  );
}

export {
  Header,
  Tool,
  SubTool,
  Content,
  Aside,
  Footer,
  SolarLayout
};
