import { SceneObjectType } from '../Constants';
import type { EquipmentExposure } from './EquipmentExposure';
import { Marker } from './Marker';

export class StreetLocation extends Marker {
  /**
   * Street location marker is the only marker to have default vertical position as 0
   */
  static override defaultEquipmentZValueInWorldUnits = () => 0;

  override readonly label: string = 'STREET';
  override readonly type: string = SceneObjectType.Street;
  override readonly propertyId: string = SceneObjectType.Street;
  override readonly name: string = 'Street Location';

  serialNumber?: string;
  description?: string;
  exposure: EquipmentExposure;

  constructor() {
    super();
    this.exposure = {
      type: 'OUTDOOR',
      constrained: true
    };
  }

  override redraw(): void {
    // This has to be implemented to prevent runtime errors,
    // eg.: Selectable.ts calls "select", or "unselect", and they both call "redraw",
    // which leads to an error when this is not defined.
    // However, for this particular case, we don't have to actually redraw the object,
    // because it looks exactly the same when selected vs when not
  }
}
