import type { Option } from '@aurorasolar/lyra-ui-kit';
import type { IOption } from '../../../../domain/models/SiteDesign/IOption';
import type { MarkerAttributes } from '../../../../domain/typings/Attributes';
import { DesignService } from '../../../../infrastructure/services/api/DesignService';
import type DomainStore from '../../../DomainStore/DomainStore';
import type EditorStore from '../../../EditorStore/EditorStore';
import type { ServiceBus } from '../../../ServiceBus/ServiceBus';
import type { IUpdateEquipmentCommand } from '../../../ServiceBus/Commands/UpdateEquipmentCommand';
import { EquipmentService } from '../../../../infrastructure/services/api/EquipmentService';
import type { Marker } from '../../../../domain/models/SiteDesign/Marker';
import { SceneObjectType } from '../../../../domain/models/Constants';
import type { LoadCenter } from '../../../../domain/models/SiteDesign/SiteEquipment/LoadCenter';

export interface IBaseSiteEquipmentViewModelDependencies {
  editor: EditorStore;
  domain: DomainStore;
  serviceBus: ServiceBus;
}

type FilterFunction = (option: Option) => boolean;

export abstract class BaseSiteEquipmentViewModel {
  abstract propUICode: string;
  abstract title: string;

  protected editor: EditorStore;
  protected domain: DomainStore;
  protected serviceBus: ServiceBus;
  protected designService = new DesignService();
  protected equipmentService = new EquipmentService();

  constructor(dependencies: IBaseSiteEquipmentViewModelDependencies) {
    this.editor = dependencies.editor;
    this.domain = dependencies.domain;
    this.serviceBus = dependencies.serviceBus;
  }

  protected updateEquipment(equipment: Marker, confirmationDeclinedCallback?: () => void): void {
    const commandDependencies: IUpdateEquipmentCommand = {
      editor: this.editor,
      domain: this.domain,
      equipment,
      confirmationModalText: this.confirmationMessageIfNeeded(equipment)
    };

    this.serviceBus.send('update_equipment', commandDependencies, confirmationDeclinedCallback);
  }

  protected mapToItemOption(options: IOption<MarkerAttributes>[]): Option[] {
    return options.map(
      (option: IOption<MarkerAttributes>): Option => ({
        name: option.attributes.name,
        value: option.attributes.value
      })
    );
  }

  protected findOptionByValue(value: string | number = ''): FilterFunction {
    return (option: Option): boolean => `${option.value}` === `${value}`;
  }

  private confirmationMessageIfNeeded(equipment: Marker): string | undefined {
    const { utilityMeter } = this.domain.project?.site?.equipment;

    if (equipment.type === SceneObjectType.MainServicePanel && (equipment as LoadCenter).meterSocket && utilityMeter) {
      return 'Standalone meter base will be removed because a meter-main combination panel has been specified.';
    }
  }
}
