import { LyraIcon } from '@aurorasolar/lyra-ui-kit';
import type { FieldTemplateProps } from '@rjsf/utils';
import isEmpty from 'lodash/isEmpty';
import React from 'react';

/**
 * This is a field template that customizes the layout of regular form fields.
 *
 * Reference: https://rjsf-team.github.io/react-jsonschema-form/docs/advanced-customization/custom-templates#fieldtemplate
 *
 * Modifications:
 * - Do not render '*' next to required fields
 * - Render tooltip button if `rawHelp` is provided
 * - Title gets duplicated when dealing with nested fields -- don't render title if field type is object
 * - Do not render custom field if there's no UI schema to render
 * - Do not render description for every field -- only group title (if group attributes exist)
 */
function CustomFieldTemplate(props: FieldTemplateProps): JSX.Element | null {
  const {
    id,
    classNames,
    label,
    rawHelp,
    errors,
    children,
    uiSchema
  } = props;

  const isNotObjectField = !classNames?.includes('field-object');

  return !isEmpty(uiSchema) ? (
    <div className={classNames}>
      <div className="input-top">
        <div style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: '2px',
          marginRight: '2px',
          marginLeft: '2px',
          alignItems: 'center'
        }}>
          {isNotObjectField && <label htmlFor={id}>{label}</label>}
          {rawHelp && (
            <div
              data-lyra-tooltip={rawHelp}
              data-lyra-tooltip-with-delay={true}
              className="tooltip-left"
            >
              <LyraIcon.Icon
                name="tooltip"
                style={{
                  width: 14,
                  height: 14,
                  marginLeft: 4
                }}
              />
            </div>
          )}
        </div>
      </div>
      {children}
      {errors}
    </div>
  ) : null;
}

export default CustomFieldTemplate;
