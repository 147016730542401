import { observer } from 'mobx-react-lite';
import type {
  BaseProps, ReactElement
} from 'react';
import React, { useCallback } from 'react';
import type { Color } from '@aurorasolar/lyra-ui-kit';
import { LyraButtons } from '@aurorasolar/lyra-ui-kit';
import type { PropsTheme } from 'styled-components';
import styled from 'styled-components';
import { EButtonType } from '@aurorasolar/lyra-ui-kit/lib/components/Buttons/EButtonType';
import { EThemeComponentColor } from '@aurorasolar/lyra-ui-kit/lib/components/Theme/EThemeComponentColor';
import {
  PropertyContentWrapper, PropertyPanelWrapper
} from '../PropertyPanel/styles';
import useStore from '../../../stores/useStore';
import type { CustomBaseImageryTransformationTool } from '../../../stores/UiStore/ToolbarStore/Project/CustomBaseImageryTransformationTool';
import { IconKeys } from '../../components/Icons/registry';
import { TraceToDefineScalingTool } from '../../../stores/UiStore/ToolbarStore/Project/TraceToDefineScalingTool';
import { SiteStructureFactory } from '../../../domain/models/StructureFactory';
import IconMapper from '../../components/Icons';
import LineHr from '../../components/LineHr';

export const OuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 16px;
  background: ${({ theme }: PropsTheme<BaseProps>): Color => theme.colors.hintBg || ''};
  border-radius: 7px;
`;

export const ItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const IconContainer = styled.div`
  min-width: 42px;
  margin: 12px 0px 0px 12px;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  & svg {
    width: 42px;
  }
`;

export const PropertyPanelFooter = styled.div`
  background-color: ${({ theme }: PropsTheme<BaseProps>): Color => theme.colors.whiteBg};
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 15px 0 5px;
  min-height: 45px;
  box-sizing: border-box;
`;
export const RightSide = styled.div`
  font-size: 12px;
  margin: 16px;
`;

export const Header = styled.h5`
  color: ${({ theme }: PropsTheme<BaseProps>): Color => theme.colors.secondaryFont || ''};
  font-size: 14px;
  margin-bottom: 8px;
`;

export const ButtonWrapper = styled.div<{ noSvg: boolean }>`
  ${({ noSvg }: { noSvg: boolean } = { noSvg: false }): string => (noSvg ? '& svg {display: none};' : '')}
`;

const Divider = styled.div`
  padding: 0 12px;
`;

export const CustomBaseImageryTransformationPanel = observer((): ReactElement => {
  const store = useStore();
  const { toolbar } = store;
  const tool = toolbar.selectedTool as CustomBaseImageryTransformationTool;
  const traceToDefineScaling = useCallback(async (): Promise<void> => {
    await tool.persist();
    const traceToDefineTracing = new TraceToDefineScalingTool({
      ...store,
      toolbar: store.toolbar,
      drawableObjectsFactory: new SiteStructureFactory()
    });
    store.toolbar.selectTool(traceToDefineTracing);
  }, [tool, store]);

  return (
    <PropertyPanelWrapper>
      <PropertyContentWrapper>
        <OuterContainer>
          <ItemContainer>
            <IconContainer>
              <IconMapper name={IconKeys.PositionImagery} />
            </IconContainer>
            <RightSide>
              <Header>POSITION</Header>
              Click & drag image to position over basemap.
            </RightSide>
          </ItemContainer>
          <Divider>
            <LineHr />
          </Divider>
          <ItemContainer>
            <IconContainer>
              <IconMapper name={IconKeys.RotateImagery} />
            </IconContainer>
            <RightSide>
              <Header>ROTATE & SCALE</Header>
              Click & drag rotation icon near the edges to rotate and scale the image.
            </RightSide>
          </ItemContainer>
          <Divider>
            <LineHr />
          </Divider>
          <ItemContainer>
            <IconContainer></IconContainer>
            <RightSide>
              <Header>OPACITY</Header>
              Increase/decrease opacity of custom map to exactly match the basemap position.
            </RightSide>
          </ItemContainer>
        </OuterContainer>
      </PropertyContentWrapper>

      <PropertyPanelFooter>
        <ButtonWrapper noSvg className="mb-xs">
          <LyraButtons.Buttons
            styleType="flat"
            colorTheme={EThemeComponentColor.TRANSPARENT}
            fontColorTheme={EThemeComponentColor.AQUAMARINE}
            disabled={false}
            onClick={(): void => {
              traceToDefineScaling();
            }}
          >
            <b>Trace to define scale</b>
          </LyraButtons.Buttons>
        </ButtonWrapper>
        <ButtonWrapper noSvg={false}>
          <LyraButtons.ButtonIcon
            icon="arrow"
            type={EButtonType.ROUNDED_BUTTON}
            colorTheme={EThemeComponentColor.AQUAMARINE}
            withIconTheme
            disabled={false}
            onClick={(): void => {
              tool.persist();
            }}
          >
            <b>Apply changes</b>
          </LyraButtons.ButtonIcon>
        </ButtonWrapper>
      </PropertyPanelFooter>
    </PropertyPanelWrapper>
  );
});
