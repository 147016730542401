import type { BaseInputTemplateProps } from '@rjsf/utils';
import type { ReactElement } from 'react';
import React from 'react';
import type {
  JSONSchema7Array, JSONSchema7Type
} from 'json-schema';

import { DebounceInput } from 'react-debounce-input';

/**
 * Based on https://github.com/rjsf-team/react-jsonschema-form/blob/main/packages/core/src/components/templates/BaseInputTemplate.tsx
 * Modifications:
 * - Generic HTML `input` is changed to `DebounceInput`
 */
const DebouncedBaseInputTemplate = (props: BaseInputTemplateProps): ReactElement => {
  // Note: since React 15.2.0 we can't forward unknown element attributes, so we
  // exclude the "options" and "schema" ones here.
  if (!props.id) {
    throw new Error(`no id for props ${JSON.stringify(props)}`);
  }
  const {
    value,
    readonly,
    disabled,
    autofocus,
    onBlur,
    onFocus,
    options,
    schema,
    uiSchema,
    formContext,
    registry,
    rawErrors,
    ...inputProps
  } = props;

  // If options.inputType is set use that as the input type
  if (options.inputType) {
    inputProps.type = options.inputType;
  } else if (!inputProps.type) {
    switch (schema.type) {
      // If the schema is of type number or integer, set the input type to number
      case 'number':
        inputProps.type = 'number';
        // Setting step to 'any' fixes a bug in Safari where decimals are not
        // allowed in number inputs
        inputProps.step = 'any';
        break;
      case 'integer':
        inputProps.type = 'number';
        // Since this is integer, you always want to step up or down in multiples
        // of 1
        inputProps.step = '1';
        break;
      default:
        inputProps.type = 'text';
        break;
    }
  }

  if (options.autocomplete) {
    inputProps.autoComplete = options.autocomplete;
  }

  // If multipleOf is defined, use this as the step value. This mainly improves
  // the experience for keyboard users (who can use the up/down KB arrows).
  if (schema.multipleOf) {
    inputProps.step = schema.multipleOf;
  }

  if (schema.minimum !== undefined) {
    inputProps.min = schema.minimum;
  }

  if (schema.maximum !== undefined) {
    inputProps.max = schema.maximum;
  }

  const _onChange = ({ target: { value } }: React.ChangeEvent<HTMLInputElement>): void => {
    return props.onChange(value === '' ? options.emptyValue : value);
  };

  return (
    <>
      <DebounceInput
        debounceTimeout={300}
        key={inputProps.id}
        className="form-control"
        readOnly={readonly}
        disabled={disabled}
        autoFocus={autofocus}
        value={value ?? ''}
        {...inputProps}
        list={schema.examples ? `examples_${inputProps.id}` : null}
        onChange={_onChange}
        onBlur={(event: React.FocusEvent<HTMLInputElement>) => onBlur?.(
          inputProps.id, event.target.value
        )}
        onFocus={(event: React.FocusEvent<HTMLInputElement>) => onFocus?.(
          inputProps.id, event.target.value
        )}
      />
      {schema.examples ? (
        <datalist key={`datalist_${inputProps.id}`} id={`examples_${inputProps.id}`}>
          {[...new Set((schema.examples as JSONSchema7Array)
            .concat(schema.default ? [schema.default] : []))]
            .map((example: JSONSchema7Type) => (
              <option key={JSON.stringify(example)} value={JSON.stringify(example)} />
            ))}
        </datalist>
      ) : null}
    </>
  );
};

export default DebouncedBaseInputTemplate;
