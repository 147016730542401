import type {
  BaseProps, ReactElement
} from 'react';
import React, {
  useCallback, useEffect, useState
} from 'react';
import { isNumber } from '../../../utils/helpers';
import {
  CounterStyle, Label
} from './styles';

type Props = BaseProps & {
  min?: number;
  max?: number;
  label?: string;
  units?: string;
  initialValue?: number;
  value?: number;
  amount?: number;
  plusLimit?: number;
  minusLimit?: number;
  plus?: (value: number) => void;
  minus?: (value: number) => void;
};

function Counter(props: Props): ReactElement {
  const {
    min,
    max,
    plus,
    minus,
    plusLimit,
    minusLimit,
    value,
    initialValue = 0,
    amount = 1,
    units = '',
    label = '',
    className = ''
  } = props;
  // if handle functions are passed throuth this component
  const [provided] = useState<boolean>(isNumber(value));
  const [count, setCount] = useState<number>(initialValue);
  const onPlus = useCallback((): void => {
    const newValue = count + amount;

    if (typeof plus === 'function') {
      plus(count);
    }

    if ((!plusLimit || plusLimit > newValue) && !provided) {
      setCount(newValue);
    }
  }, [count, amount, plus, plusLimit, provided]);
  const onMinus = useCallback((): void => {
    const newValue = count - amount;

    if (typeof minus === 'function') {
      minus(count);
    }

    if ((!minusLimit || minusLimit < newValue) && !provided) {
      setCount(newValue);
    }
  }, [count, amount, minus, minusLimit, provided]);

  useEffect((): void => {
    if (provided) {
      setCount(value as number);
    }
  }, [value, provided]);

  return (
    <CounterStyle className={className}>
      <div>
        <button onClick={onMinus} disabled={count && min ? count === min : false}>
          <span>-</span>
        </button>
        <div>
          {count}
          {units}
        </div>
        <button onClick={onPlus} disabled={count && max ? count === max : false}>
          <span>+</span>
        </button>
      </div>
      {label && <Label>{label}</Label>}
    </CounterStyle>
  );
}

export default Counter;
