import { BaseProps, CSSProperties, ReactNode } from 'react';
import styled, { PropsTheme } from 'styled-components';
import { Color } from '../../typings';
import { ColorThemeProps, getColorTheme, getFontColorDependingOnTheme } from '../Theme/ColorThemeProps';
import { EButtonSize } from './EButtonSize';
import { EButtonType } from './EButtonType';
import { Flexbox } from '../Layout/Flexbox';
import { Paragraph, Notes } from '../FontFamily/Fonts';
import { TooltipWrapper, Arrow } from '../Tooltip';

export interface IStyleProps extends BaseProps {
  styleType: 'primary' | 'secondary' | 'third' | 'fourth' | 'flat';
  styles?: CSSProperties;
  size?: EButtonSize;
}

type ButtonProps = PropsTheme & {
  buttonColor?: string;
  paddingText?: string;
};

type ButtonContainerProps = ColorThemeProps & {
  fullWidth: boolean;
  buttonType: EButtonType;
  /**
   * ID of the HTML form to be submitted when the button is clicked
   */
  submitForm?: string;
};

type ButtonThemedProps = ColorThemeProps & {
  styleType: 'primary' | 'secondary' | 'third' | 'fourth' | 'flat';
  size?: EButtonSize;
};

type CTAButtonProps = PropsTheme & {
  fullWidth: boolean;
};

type VerticalButtonProps = PropsTheme & {
  enabled: boolean;
};

function setButtonIconPaddingByDefault({ className }: ButtonContainerProps): string {
  return className ? `` : `padding: 0.5rem 1rem`;
}

function setStylesDependingOnButtonType(buttonType: EButtonType): string {
  const buttonTypesStyles = {
    rounded_button: `
      border-radius: 9999px;
    `,
    contained_button: `
      border-radius: 3px;
    `
  };
  return buttonTypesStyles[buttonType];
}

function getPadding({ size, styleType }: Partial<PropsTheme<IStyleProps>>): string {
  let cssPaddingProperty: string = 'padding: ';

  if (size === EButtonSize.SMALL) {
    cssPaddingProperty += '6px 20px;';
  } else {
    switch (styleType) {
      case 'primary':
        cssPaddingProperty += '11px 57px;';
        break;
      case 'secondary':
        cssPaddingProperty += '11px 47px;';
        break;
      case 'third':
        cssPaddingProperty += '9px 17px;';
        break;
      default:
        cssPaddingProperty += '7px 27px';
    }
  }

  return cssPaddingProperty;
}

function getFontSize({ size, styleType }: Partial<PropsTheme<IStyleProps>>): string {
  let cssFontSizeProperty: string = 'font-size: ';

  if (size === EButtonSize.SMALL) {
    cssFontSizeProperty += '12px;';
  } else {
    switch (styleType) {
      case 'primary':
        cssFontSizeProperty += '16px;';
        break;
      case 'secondary':
        cssFontSizeProperty += '16px;';
        break;
      case 'third':
        cssFontSizeProperty += '12px;';
        break;
      default:
        cssFontSizeProperty += '12px;';
    }
  }

  return cssFontSizeProperty;
}

export const ButtonStyled = styled.button<IStyleProps>`
  background-color: ${
  ({ theme, styleType }: PropsTheme<IStyleProps>): Color =>
    styleType === 'primary' ? theme.colors.mainColor1
      : styleType === 'secondary' ? theme.colors.grayColor4
        : styleType === 'third' ? theme.colors.grayColor1
          : theme.colors.whiteBg
  };
  color: ${
  ({ theme, styleType }: PropsTheme<IStyleProps>): Color =>
    styleType === 'primary' ? theme.colors.whiteBg
      : styleType === 'secondary' ? theme.colors.grayColor1
        : styleType === 'third' ? theme.colors.whiteBg
          : theme.colors.mainColor1
  };
  border: ${
  ({ theme, styleType }: PropsTheme<IStyleProps>): Color =>
    styleType === 'primary' ? '1px solid ' + theme.colors.mainColor1
      : styleType === 'secondary' ? '1px solid ' + theme.colors.grayColor4
        : styleType === 'third' ? '1px solid ' + theme.colors.grayColor1
          : '1px solid #2d80ef'
  };
  outline: none;
  border-radius: 24px;
  cursor: pointer;
  font-family: ${
  ({ theme }: PropsTheme<IStyleProps>): string =>
    theme.fonts.fontFamily
  };
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-transform: uppercase;
  text-align: center;
  ${getFontSize}
  ${getPadding}
`;

export const ButtonThemed = styled.button<ColorThemeProps & ButtonThemedProps>`
  background: ${(props: ColorThemeProps): Color => getColorTheme({ ...props })};
  color: ${
  (props: ButtonThemedProps): Color =>
    props.styleType === 'primary' ? props.theme.colors.whiteBg
      : props.styleType === 'secondary' ? props.theme.colors.grayColor1
        : props.styleType === 'third' ? props.theme.colors.whiteBg
          : props.theme.colors.mainColor1
  };
  outline: none;
  border-radius: 24px;
  cursor: pointer;
  font-family: ${
  ({ theme }: PropsTheme<IStyleProps>): string =>
    theme.fonts.fontFamily
  };
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-transform: uppercase;
  text-align: center;
  ${({ size, styleType }: ButtonThemedProps): string => getFontSize({ size, styleType })}
  ${({ size, styleType }: ButtonThemedProps): string => getPadding({ size, styleType })}
`;

export const FlatButton = styled.button<ColorThemeProps>`
  background: ${(props: ColorThemeProps): Color => getColorTheme({ ...props })};
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 16px;
  letter-spacing: normal;
  text-align: center;
`;

export const Text = styled.p<ColorThemeProps>`
  color: ${(props: ColorThemeProps): Color => getFontColorDependingOnTheme({ ...props })};
  font-family: Roboto, sans-serif;
  font-size: 13px;
  padding: ${({ paddingText }: ButtonProps): string => paddingText ? paddingText : '0 .5rem'}
`;

export const FlatText = styled(Text)<ColorThemeProps>`
  color: ${(props: ColorThemeProps): Color => getColorTheme({ ...props })};
`;

export const ButtonWrapper = styled.div<PropsTheme<IStyleProps>>`
  display: inline;
  &.highlighted button:hover {
    background-color: ${
    ({ theme, styleType }: PropsTheme<IStyleProps>): Color =>
        styleType === 'primary' ? theme.colors.mainColor7
            : styleType === 'secondary' ? theme.colors.grayColor7
            : styleType === 'third' ? theme.colors.grayColor9
                : theme.colors.grayColor7
    };
  }
  &.highlighted button:active {
    background-color: ${
    ({ theme, styleType }: PropsTheme<IStyleProps>): Color =>
        styleType === 'primary' ? theme.colors.mainColor1
            : styleType === 'secondary' ? theme.colors.grayColor4
            : styleType === 'third' ? theme.colors.grayColor1
                : theme.colors.whiteBg
    };
  }
  & button:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
`;

export type ButtonIconWrapperProps = {
  stickToTopRight?: boolean;
};

export const ButtonIconWrapper = styled.div<ButtonIconWrapperProps>`
  ${({ stickToTopRight }: ButtonIconWrapperProps): string => stickToTopRight
    ? `position: absolute; top: 2px; right: 3px;`
    : ''
  };
  & ${TooltipWrapper} {
    display: block;
    min-width: unset;
    transform: none;
    width: 175px;
    inset: -5px 28px auto auto;
    border: 1px solid ${({ theme }: ButtonProps): Color => theme.colors.grey4};
    font-size: 10px;
    cursor: pointer;
  }
  
  & ${Arrow} {
    left: auto;
    top: 15px;
    right: -10px;
    transform: rotate(45deg) translateX(-50%);
  }
`;


export const ButtonIconContainer = styled.button<ButtonContainerProps & {
  type: string;
  form: string;
  children?: ReactNode;
}>`
  box-sizing: border-box;
  position: relative;
  background-color: ${
  (props: ColorThemeProps): Color => getColorTheme({ ...props })};
  ${({ fullWidth }: ButtonContainerProps): string => fullWidth ? `width: 100%` : ``}
  ${({ buttonType }: ButtonContainerProps): string => setStylesDependingOnButtonType(buttonType)}
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  &:disabled,
  &[disabled] {
    cursor: not-allowed;
    opacity: 0.35;
  }
  ${(props: ButtonContainerProps): string => setButtonIconPaddingByDefault({ ...props })}
`;

export const StatusContainer = styled.div`
  position: relative;
`;

export const StatusNumber = styled.div`
  background-color: ${({ theme }: ButtonProps): Color => theme.colors.mainColor3};
  text-align: center;
  line-height: 16px;
  height: 16px;
  width: 16px;
  border-radius: 50px;
  position: absolute;
  top: -3px;
  right: -7px;
  span {
    color: ${({ theme }: ButtonProps): Color => theme.colors.whiteBg};
    font-family: Roboto, sans-serif;
    font-weight: bold;
    font-size: 10px;
  }
`;

export const BigButtonWithIcon = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  & > p {
    font-family: Roboto, sans-serif;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #4a4a4a;
  }
`;

export const BigButtonIconContainer = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  border: solid 2px ${({ theme }: ButtonProps): Color => theme.colors.grey2};
  background-color: ${({ theme }: ButtonProps): Color => theme.colors.gray4};
  box-sizing: border-box;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.4);
  width: 100%;
  height: 100%;
  &:hover {
    background-color: ${({ theme }: ButtonProps): Color => theme.colors.whiteBg};
    border: solid 2px ${({ theme }: ButtonProps): Color => theme.colors.mainColor2};
    cursor: pointer;
  }
`;

export const CTAButtonContainer = styled.button<CTAButtonProps>`
  width: ${({ fullWidth }: CTAButtonProps): string => fullWidth ? '100%' : '202px'};
  min-height: 36px;
  padding: 8px 12px;
  text-align: left;
  border-radius: 16px;
  box-sizing: border-box;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.5);
  background-color: ${({ theme }: ButtonProps): Color => theme.colors.highlight};
  display: flex;
  cursor: pointer;
`;

export const IconView = styled.div`
  margin-right: 10px;
  width: 20px;
  & > div {
    line-height: 2;
    height: 20px;
  } 
`;

export const TextView = styled.div``;

export const FullTextView = styled.div`
  width: 100%;
`;

export const ButtonText = styled(Paragraph)`
  color: ${({ theme }: ButtonProps): Color => theme.colors.grey1};
  font-weight: 500;
`;

export const VerticalButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`;

export const IconWrapper = styled.div<VerticalButtonProps>`
  width: 67px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  border: solid 1px ${({ theme, enabled }: VerticalButtonProps): Color => enabled ? theme.colors.lineHr : theme.colors.opacityColor2};
  background-color: ${({ theme }: ButtonProps): Color => theme.colors.highlight};
`;

export const PanelView = styled(Flexbox).attrs({
  justify: 'center',
  align: 'center'
})`
  width: 44px;
  height: 24px;
`;

export const DownIconView = styled(Flexbox).attrs({
  justify: 'center',
  align: 'center'
}) <VerticalButtonProps>`
  width: 22px;
  height: 24px;
  border-left: solid 1px ${({ theme, enabled }: VerticalButtonProps): Color => enabled ? theme.colors.lineHr : theme.colors.opacityColor2};
  cursor: pointer;
`;

export const VerticalButtonText = styled(Notes)`
  color: ${({ theme }: ButtonProps): Color => theme.colors.grey1};
  margin-top: 5px;
`;

export const ChildrenView = styled.div`
  position: absolute;
  top: 50px;
`;
