import { observer } from 'mobx-react-lite';
import type { ReactElement } from 'react';
import * as React from 'react';
import styled from 'styled-components';
import LineVertical from '../../../ui/components/LineVertical';
import useStore from '../../../stores/useStore';
import { isDesignWorkspace } from '../../../stores/UiStore/WorkspaceStore/utils';
import config, { UI_MODE } from '../../../config/config';
import EnvProps from './EnvProps//EnvProps';
import { SiteDesignButton } from './SiteDesign/SiteDesign';
import { SystemDesignButton } from './SystemDesign/SystemDesignButton';

const WorkspaceButton = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  margin-left: 10px;
`;

const BottomPanel = observer((): ReactElement => {
  const {
    editor, workspace
  } = useStore();

  const startSystemDesign = React.useCallback(async (): Promise<void> => {
    await editor.editorSetupPromise;
    if (config.featureFlag.uiMode === UI_MODE.AURORA && isDesignWorkspace(workspace.currentWorkspace)) {
      workspace.currentWorkspace.startSystemDesign();
    }
    editor.renderSiteMarkers(workspace.currentWorkspace);
  }, [editor, workspace.currentWorkspace]);

  React.useEffect((): void => {
    startSystemDesign();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workspace.currentWorkspace]);

  return (
    <>
      {config.featureFlag.uiMode !== UI_MODE.AURORA && (
        <WorkspaceButton>
          <SiteDesignButton />
          <LineVertical />
          <SystemDesignButton />
          <LineVertical />
        </WorkspaceButton>
      )}
      <EnvProps />
    </>
  );
});

export default BottomPanel;
