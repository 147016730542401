import type {
  Color, PropsTheme
} from '@aurorasolar/lyra-ui-kit';
import styled, { withTheme } from 'styled-components';

const hexadecimalOpacity = '1a';

export const PropertiesTitle = styled.div`
  background: ${({ theme }: PropsTheme): Color => `${theme.colors.mainColor2}${hexadecimalOpacity}`};
  border-radius: 4px;
`;

export const TagsContainer = styled.div`
  ul {
    padding: 10px 0;
  }
  input {
    background-color: transparent;
  }
`;
