import isNil from 'lodash/isNil';
import { computed } from 'mobx';
import type { OptionProps } from '@aurorasolar/lyra-ui-kit/lib/components/Grid';

export enum StructuralStandard {
  ASCE_7_10 = 'ASCE_7_10',
  ASCE_7_16 = 'ASCE_7_16',
  ASCE_7_22 = 'ASCE_7_22'
}

export interface ICodesAndStandardsData {
  readonly fireCode?: string;
  readonly electricalCode?: string;
  readonly structuralStandard?: StructuralStandard;
  readonly other?: readonly string[];
}

export class CodesAndStandards {
  private static readonly STRUCTURAL_STANDARD_OPTIONS: OptionProps[] = [
    {
      name: 'ASCE 7-10',
      value: StructuralStandard.ASCE_7_10,
      component: 'ASCE 7-10'
    },
    {
      name: 'ASCE 7-16',
      value: StructuralStandard.ASCE_7_16,
      component: 'ASCE 7-16'
    },
    {
      name: 'ASCE 7-22',
      value: StructuralStandard.ASCE_7_22,
      component: 'ASCE 7-22'
    }
  ];

  /**
   * Enumeration - See OpenAPI spec for the list of possible values
   */
  readonly fireCode?: string;
  /**
   * Enumeration - See OpenAPI spec for the list of possible values
   */
  readonly electricalCode?: string;
  /**
   * Other applicable codes like building code, residential code, mechanical code, etc.
   */
  readonly other: readonly string[];
  readonly structuralStandard?: StructuralStandard;

  constructor(data?: ICodesAndStandardsData) {
    this.fireCode = data?.fireCode;
    this.electricalCode = data?.electricalCode;
    this.structuralStandard = data?.structuralStandard;
    this.other = [...(data?.other ?? [])];
  }

  toData = (): ICodesAndStandardsData | undefined => {
    if (this.isEmpty) {
      return undefined;
    }
    return {
      fireCode: this.fireCode,
      electricalCode: this.electricalCode,
      structuralStandard: this.structuralStandard,
      other: this.other.length > 0 ? [...this.other] : undefined
    };
  };

  @computed
  private get isEmpty(): boolean {
    return (
      isNil(this.fireCode) && isNil(this.electricalCode) && isNil(this.structuralStandard) && this.other.length === 0
    );
  }

  withCodes = (fireCode: string, electricalCode: string, otherCodes: readonly string[]): CodesAndStandards => {
    const data = this.toData() || {};
    return new CodesAndStandards({
      ...data,
      fireCode,
      electricalCode,
      other: otherCodes
    });
  };

  withStructuralStandard = (structuralStandard: StructuralStandard): CodesAndStandards => {
    const data = this.toData() || {};
    return new CodesAndStandards({
      ...data,
      structuralStandard
    });
  };

  @computed
  get structuralStandardDisplayName(): string {
    const displayNames = {
      ASCE_7_10: 'ASCE 7-10',
      ASCE_7_16: 'ASCE 7-16',
      ASCE_7_22: 'ASCE 7-22'
    };
    return (this.structuralStandard && displayNames[this.structuralStandard]) ?? '-';
  }

  @computed
  get structuralStandardOptions(): OptionProps[] {
    return CodesAndStandards.STRUCTURAL_STANDARD_OPTIONS;
  }

  @computed
  get structuralStandardAsOption(): OptionProps {
    const matchingOption = this.structuralStandardOptions.find(
      (option: OptionProps): boolean => option.value === this.structuralStandard
    );
    return matchingOption ?? this.structuralStandardOptions[0];
  }
}
