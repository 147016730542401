import { Vector3 } from 'three';
import type { IRoofProtrusionData as RoofProtrusionEntity } from '../../domain/entities/SiteDesign/RoofProtrusion';
import { SiteStructureFactory } from '../../domain/models/StructureFactory';
import type { RoofProtrusion as RoofProtrusionDTO } from '../../domain/models/SiteDesign/RoofProtrusion';
import { RectangleProtrusion } from '../../domain/models/SiteDesign/RectangleProtrusion';
import type { IVertexData } from '../../domain/entities/SiteDesign/Vertex';
import type { IMapping } from './IMapping';
import { VerticesMapping } from './VerticesMapping';

export class RoofProtrusionMapping implements IMapping<RoofProtrusionEntity, RoofProtrusionDTO> {
  private readonly mapperVertices = new VerticesMapping();

  convertEntityToDomainModel(entity: RoofProtrusionEntity): RectangleProtrusion {
    const drawableObjectsFactory = new SiteStructureFactory();
    const protrusion = drawableObjectsFactory.create<RectangleProtrusion>(
      RectangleProtrusion,
      {
        color: '#9d0007'
      } // TODO: remove hardcode
    );

    protrusion.name = entity.name ?? '';
    protrusion.firefighter = entity.walkable;

    // TODO: remove hardcode
    protrusion.vertexColor = '#9d0007';

    const vectorProtrusion: Vector3[] = entity.boundingBox.map(
      (vertex: IVertexData): Vector3 => new Vector3(vertex.x, vertex.y, vertex.z)
    );
    protrusion.setVerticesFromVector3s(vectorProtrusion);
    return protrusion;
  }

  convertDomainModelToEntity(domainModel: RoofProtrusionDTO): RoofProtrusionEntity {
    return {
      name: domainModel.name || undefined,
      walkable: domainModel.firefighter,
      shape: 'RECTANGULAR',
      boundingBox: this.mapperVertices.convertDomainModelToEntity3d(domainModel.boundary.vertices)
    };
  }
}
