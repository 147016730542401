import type { ITemperatureOptionsResponse } from '../../../domain/models/SiteDesign/TemperatureDesignParameter';
import type { ISearchInverterParameters } from '../../../domain/entities/Design/ISearchInverterParameters';
import type { IDesignData } from '../../../domain/models/Design/Design';
import type { IProjectMissingPropertiesResponse } from '../../../domain/entities/MissingProperties/IMissingProperties';
import type { IStringingOptionsResponseData } from '../../../domain/entities/StringingOption/IStringingOptionData';
import type { ILumberCrossSectionsRequest } from '../../../domain/models/ILumberCrossSectionsRequest';
import type { IPayloadForFireVentilationArea } from '../../../domain/models/RoofTopArray/FireVentilation';
import type { IChangeDCPowerRatingTargetRequest } from '../../../domain/request/ArrayPlacement/IChangeDCPowerRatingTargetRequest';
import type IChangeMountingSystemDefinition from '../../../domain/request/ChangeMountingSystemDefinitionRequest/ChangeMountingSystemDefinition';
import type { IDesignCreationRequest } from '../../../domain/request/DesignCreationRequest/IDesignCreationRequest';
import type { IEnergyProductionLossesChangeRequest } from '../../../domain/request/EnergyProductionLossesChange/IEnergyProductionLossesChangeRequest';
import type { IMountingSystemRequest } from '../../../domain/request/MountingSystemRequest/MountingSystemRequest';
import type { IPowerConversionEquipmentChangeRequest } from '../../../domain/request/PowerConversionChangeRequest/IPowerConversionChangeRequest';
import type { IPvArrayAreaChangeRequest } from '../../../domain/request/PvArrayAreaChangeRequest/IPvArrayAreaChangeRequest';
import type IPvModuleChangeRequest from '../../../domain/request/PvModuleChangeRequest/PvModuleChangeRequest';
import type { IRoofFace3D } from '../../../domain/request/Util/IRoofFace3D';
import type { IRoofFaceGeometryData } from '../../../domain/entities/SiteDesign/RoofFaceGeometry';
import type {
  MarkerAttributes,
  RiskCategoryOptionAttributes,
  WindSpeedOptionAttributes
} from '../../../domain/typings/Attributes';
import type { Coords } from '../../../domain/typings';
import type {
  IImport3DModelFileProps,
  IImport3DModelProps
} from '../../../stores/UiStore/Wizard/ViewModels/Import3DModel/Import3DModelViewModel';
import Http from '../Http';
import config from '../../../config/config';
import { DesignDelta } from '../../../domain/entities/Design/DesignDelta';
import type { ILayoutStrategyData } from '../../../domain/models/RoofTopArray/LayoutStrategy';
import type { IMountingSystemConfigurationData } from '../../../domain/models/MountingSystemDefinition/IConfiguration';
import type {
  IAdditionalProjectData,
  IProjectData,
  ExternalProposalData
} from '../../../domain/models/SiteDesign/Project';
import type {
  Address, IAddressData
} from '../../../domain/models/SiteDesign/Address';
import type {
  ICompanyInstallerData, ICompanyInstallerCreationRequest
} from '../../../domain/models/Installer';
import type {
  IDcSourceCircuitRequest,
  IDcSourceCircuitsRequest
} from '../../../domain/request/ElectricalDesign/IDcSourceCircuitRequest';
import type { IPersonParticipant } from '../../../domain/models/SiteDesign/Participant';
import {
  InstallerCreatedEvent, InstallerUpdatedEvent
} from '../../../services/analytics/DesignToolAnalyticsEvents';
import type { IUpdateFireVentilationAreasRequest } from '../../../domain/request/ArrayPlacement/IUpdateFireVentilationAreasRequest';
import type {
  IOption, BaseAttributes
} from '../../../domain/models/SiteDesign/IOption';
import type { Site } from '../../../domain/models/SiteDesign/Site';
import type { IAcBranchCircuitRequest } from '../../../domain/request/ElectricalDesign/IAcBranchCircuitRequest';
import type { IPvSourceCircuitRequest } from '../../../domain/request/ElectricalDesign/IPvSourceCircuitRequest';
import type { IBuildingData } from '../../../domain/entities/SiteDesign/Building';
import type { IVertexData } from '../../../domain/entities/SiteDesign/Vertex';

export type InstallerOption = {
  id: string;
  name: string;
};

const requestsInProgress = {
  changeArrayAreas: 0
};

interface ICreateProjectRequestData {
  readonly installer: string;
  readonly address: IAddressData;
  readonly coordinateSystemOrigin: Coords;
  readonly imagery: Site['imagery'];
  readonly customer?: IPersonParticipant;
  readonly internalReferenceId?: string;
}

export class DesignService {
  get baseURL(): string {
    return config.api.design;
  }

  async getAuxiliaryEnergyInputMaxOcpdRatingOptions(): Promise<IOption<MarkerAttributes>[]> {
    const response = await Http.getWithCache<IOption<MarkerAttributes>[]>(
      this.baseURL.concat('/selection-options/auxiliary-energy-input-max-ocpd-ratings'),
      {}
    );
    return response.data;
  }

  async getBusbarPointOfConnectionOptions(): Promise<IOption<MarkerAttributes>[]> {
    const response = await Http.getWithCache<IOption<MarkerAttributes>[]>(
      this.baseURL.concat('/selection-options/busbar-points-of-connection'),
      {}
    );
    return response.data;
  }

  async getBusbarRatingOptions(): Promise<IOption<MarkerAttributes>[]> {
    const response = await Http.getWithCache<IOption<MarkerAttributes>[]>(
      this.baseURL.concat('/selection-options/busbar-ratings'),
      {}
    );
    return response.data;
  }

  async getWindSpeeds(): Promise<IOption<WindSpeedOptionAttributes>[]> {
    const response = await Http.getWithCache<IOption<WindSpeedOptionAttributes>[]>(
      this.baseURL.concat('/selection-options/wind-speeds'),
      {}
    );
    return response.data;
  }

  async getRiskCategories(): Promise<IOption<RiskCategoryOptionAttributes>[]> {
    const response = await Http.getWithCache<IOption<RiskCategoryOptionAttributes>[]>(
      this.baseURL.concat('/selection-options/risk-categories'),
      {}
    );
    return response.data;
  }

  async getSubpanelBusbarRatingOptions(mainPanelBusbarRating: string | number): Promise<IOption<MarkerAttributes>[]> {
    const response = await Http.getWithCache<IOption<MarkerAttributes>[]>(
      this.baseURL.concat('/selection-options/subpanel-busbar-ratings'),
      {
        mainPanelBusbarRating
      }
    );
    return response.data;
  }

  async getMspMainBreakerRatingOptions(busbarRating: number): Promise<IOption<MarkerAttributes>[]> {
    const response = await Http.getWithCache<IOption<MarkerAttributes>[]>(
      this.baseURL.concat('/selection-options/main-breaker-ratings'),
      {
        busbarRating
      }
    );
    return response.data;
  }

  async getShortCircuitCurrentRatingOptions(
    mainBreakerAmpereInterruptingCapacity?: number
  ): Promise<IOption<MarkerAttributes>[]> {
    const response = await Http.getWithCache<IOption<MarkerAttributes>[]>(
      this.baseURL.concat('/selection-options/panel-short-circuit-current-ratings'),
      mainBreakerAmpereInterruptingCapacity
        ? {
          mainBreakerAmpereInterruptingCapacity
        }
        : {}
    );
    return response.data;
  }

  async getMainBreakerAmpereInterruptingCapacityOptions(
    panelShortCircuitCurrentRating?: string
  ): Promise<IOption<MarkerAttributes>[]> {
    const response = await Http.getWithCache<IOption<MarkerAttributes>[]>(
      this.baseURL.concat('/selection-options/main-breaker-ampere-interrupting-capacities'),
      panelShortCircuitCurrentRating
        ? {
          panelShortCircuitCurrentRating
        }
        : {}
    );
    return response.data;
  }

  async getDeckSheatingTypeOptions(): Promise<IOption<BaseAttributes>[]> {
    const response = await Http.getWithCache<IOption<BaseAttributes>[]>(
      this.baseURL.concat('/selection-options/deck-sheathing-types'),
      {}
    );
    return response.data;
  }

  async getRoofSurfaceTypeOptions(roofFaceSlope?: number): Promise<IOption<BaseAttributes>[]> {
    const queryParams = roofFaceSlope !== undefined ? `?slope=${roofFaceSlope}` : '';
    const response = await Http.getWithCache<IOption<BaseAttributes>[]>(
      this.baseURL.concat('/selection-options/roof-surface-types', queryParams),
      {}
    );
    return response.data;
  }

  async getRoofSurfaceSubTypeOptions(surfaceType: string): Promise<IOption<BaseAttributes>[]> {
    const response = await Http.getWithCache<IOption<BaseAttributes>[]>(
      this.baseURL.concat('/selection-options/roof-surface-sub-types'),
      {
        roofSurfaceType: surfaceType
      }
    );
    return response.data;
  }

  async getSubpanelMainBreakerRatingOptions(
    subpanelBusbarRating: number,
    mainPanelBusbarRating: number
  ): Promise<IOption<MarkerAttributes>[]> {
    const url = this.baseURL.concat('/selection-options/subpanel-main-breaker-ratings');
    const params = {
      subpanelBusbarRating,
      mainPanelBusbarRating
    };

    const response = await Http.getWithCache<IOption<MarkerAttributes>[]>(url, params);
    return response.data;
  }

  async getSubpanelFeederOcpdRatingOptions(
    subpanelBusbarRating: number,
    mspMainBreakerRating: number
  ): Promise<IOption<MarkerAttributes>[]> {
    const response = await Http.getWithCache<IOption<MarkerAttributes>[]>(
      this.baseURL.concat('/selection-options/subpanel-feeder-ocpd-ratings'),
      {
        subpanelBusbarRating,
        mspMainBreakerRating
      }
    );
    return response.data;
  }

  async getLumberCrossSections(parameters: ILumberCrossSectionsRequest): Promise<IOption<BaseAttributes>[]> {
    const response = await Http.getWithCache<IOption<BaseAttributes>[]>(
      this.baseURL.concat('/selection-options/dimension-lumber-cross-sections'),
      {
        ...parameters
      }
    );
    return response.data;
  }

  async getRoofConstructionTypes(): Promise<IOption<BaseAttributes>[]> {
    const response = await Http.getWithCache<IOption<BaseAttributes>[]>(
      this.baseURL.concat('/selection-options/roof-construction-styles'),
      {}
    );
    return response.data;
  }

  async getTopographicalCondition(): Promise<IOption<BaseAttributes>[]> {
    const response = await Http.getWithCache<IOption<BaseAttributes>[]>(
      this.baseURL.concat('/selection-options/topographical-conditions'),
      {}
    );
    return response.data;
  }

  async createProjectWithExternalProposalData(data: ExternalProposalData, installerId: string): Promise<IProjectData> {
    const url = this.baseURL.concat('/external-proposal-designs');
    const response = await Http.post<
      {
        externalProposalDesign: ExternalProposalData;
        installer: string;
      },
      IProjectData
    >(url, {
      externalProposalDesign: data,
      installer: installerId
    });
    return response.data;
  }

  /**
   * This is only used when the Design Tool is in charge of creating a project. This is either:
   * - Design Tool is running in standalone development mode
   * - Design Tool is running embedded in a Host App that does not provide project creation functionality
   */
  async createProject(
    address: Address,
    coordinates: Coords,
    imagery: Site['imagery'],
    installer: string,
    additionalProjectData: IAdditionalProjectData
  ): Promise<IProjectData> {
    const url = this.baseURL.concat('/projects');

    const { data: project } = await Http.post<ICreateProjectRequestData, IProjectData>(url, {
      address: address.toData(),
      coordinateSystemOrigin: coordinates,
      imagery,
      installer,
      internalReferenceId: additionalProjectData.internalReferenceId || undefined,
      customer: {
        firstName: additionalProjectData.customer!.firstName || undefined,
        lastName: additionalProjectData.customer!.lastName
      }
    });
    return project;
  }

  async loadInstallers(): Promise<InstallerOption[]> {
    const urlService: string = this.baseURL.concat('/accounts/current/installers');

    const response = await Http.get<InstallerOption[]>(urlService, {});

    return response.data ?? [];
  }

  async getInstaller(id: string): Promise<ICompanyInstallerData> {
    const url = this.baseURL.concat(`/installers/${id}`);

    const response = await Http.get<ICompanyInstallerData>(url, {});

    return response.data;
  }

  async createInstaller(data: ICompanyInstallerCreationRequest): Promise<ICompanyInstallerData> {
    const response = await Http.post<ICompanyInstallerCreationRequest, ICompanyInstallerData>(
      `${this.baseURL}/installers`,
      data
    );
    const createdInstaller: ICompanyInstallerData = response.data;

    config.analytics?.trackEvent(new InstallerCreatedEvent(createdInstaller));
    return createdInstaller;
  }

  async updateInstaller(id: string, data: ICompanyInstallerData): Promise<ICompanyInstallerData> {
    await Http.put<ICompanyInstallerData, void>(`${this.baseURL}/installers/${id}`, data);

    config.analytics?.trackEvent(new InstallerUpdatedEvent(data));
    return data;
  }

  async createDesign(body: IDesignCreationRequest, projectId: string): Promise<IDesignData> {
    const response = await Http.post<IDesignCreationRequest, IDesignData>(
      this.baseURL.concat(`/projects/${projectId}/designs`),
      body
    );
    return response.data;
  }

  async changeArrayAreas(payload: IPvArrayAreaChangeRequest): Promise<DesignDelta> {
    requestsInProgress.changeArrayAreas++;
    try {
      const response = await Http.post<IPvArrayAreaChangeRequest, DesignDelta>(
        this.baseURL.concat('/design-changes/array-areas'),
        payload
      );
      requestsInProgress.changeArrayAreas--;
      return new DesignDelta(requestsInProgress.changeArrayAreas ? {} : response.data);
    } catch (error) {
      requestsInProgress.changeArrayAreas--;
      throw error;
    }
  }

  async updateDcPowerRatingTarget(payload: IChangeDCPowerRatingTargetRequest): Promise<DesignDelta> {
    const url = this.baseURL.concat('/design-changes/dc-power-rating-target');
    const response = await Http.post<IChangeDCPowerRatingTargetRequest, DesignDelta>(url, payload);
    return new DesignDelta(response.data);
  }

  async getProject(id: string): Promise<IProjectData> {
    return (await Http.get<IProjectData>(this.baseURL.concat(`/projects/${id}`), {})).data;
  }

  async updateProject(project: IProjectData): Promise<void> {
    await Http.put<IProjectData, void>(this.baseURL.concat(`/projects/${project.id}`), project);
  }

  async getDesignsByProject(projectId: string): Promise<string[]> {
    return (await Http.get<string[]>(this.baseURL.concat(`/projects/${projectId}/designs`), {})).data;
  }

  async getDesign(id: string): Promise<IDesignData> {
    return (await Http.get<IDesignData>(this.baseURL.concat(`/designs/${id}`), {})).data;
  }

  async updateDesign(design: IDesignData): Promise<void> {
    await Http.put<IDesignData, void>(this.baseURL.concat(`/designs/${design.id}`), design);
  }

  /**
   * Getting the z values for roof-faces' vertices
   */
  async setRoofFaceZ(payload: IRoofFace3D): Promise<IRoofFaceGeometryData> {
    const response = await Http.post<IRoofFace3D, IRoofFaceGeometryData>(
      this.baseURL.concat('/utilities/3d-roof-face'),
      payload
    );
    return response.data;
  }

  async createFireVentilationArea(payload: IPayloadForFireVentilationArea): Promise<IVertexData[]> {
    const response = await Http.post<IPayloadForFireVentilationArea, IVertexData[]>(
      this.baseURL.concat('/utilities/fire-ventilation-area'),
      payload
    );
    return response.data;
  }

  async import3dModel(
    file: File,
    coordinateSystemOrigin: Coords,
    province: string,
    importParameters: IImport3DModelFileProps & IImport3DModelProps
  ): Promise<IBuildingData[]> {
    const formData = new FormData();
    formData.append('file', file, file.name);
    formData.append(
      'importParameters',
      new Blob([JSON.stringify(importParameters)], {
        type: 'application/json'
      })
    );

    formData.append(
      'project',
      new Blob(
        [
          JSON.stringify({
            coordinateSystemOrigin,
            state: province
          })
        ],
        {
          type: 'application/json'
        }
      )
    );

    const response = await Http.post<FormData, IBuildingData[]>(
      this.baseURL.concat('/utilities/external-buildings-model'),
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    );

    return response.data;
  }

  async updatePvSystemEnergyProductionLosses(
    energyProductionLosses: number,
    design: IDesignData
  ): Promise<DesignDelta> {
    const response = await Http.post<IEnergyProductionLossesChangeRequest, DesignDelta>(
      this.baseURL.concat('/design-changes/energy-production-losses'),
      {
        energyProductionLosses,
        design
      }
    );
    return new DesignDelta(response.data);
  }

  async updateMountingSystem(
    arrayAreaId: string,
    layoutStrategy: ILayoutStrategyData,
    design: IDesignData,
    configuration?: IMountingSystemConfigurationData
  ): Promise<DesignDelta> {
    const data = configuration
      ? {
        arrayAreaId,
        layoutStrategy,
        configuration,
        design
      }
      : {
        arrayAreaId,
        layoutStrategy,
        design
      };

    const response = await Http.post<IMountingSystemRequest, DesignDelta>(
      this.baseURL.concat('/design-changes/mounting-system'),
      data
    );
    return new DesignDelta(response.data);
  }

  async updateMountingSystemDefinitions(
    mountingSystemDefinitionRequest: IChangeMountingSystemDefinition
  ): Promise<DesignDelta> {
    const response = await Http.post<IChangeMountingSystemDefinition, DesignDelta>(
      this.baseURL.concat('/design-changes/mounting-system-definitions'),
      mountingSystemDefinitionRequest
    );
    return new DesignDelta(response.data);
  }

  async updatePvModuleDefinition(pvModuleChangeRequest: IPvModuleChangeRequest): Promise<DesignDelta> {
    const response = await Http.post<IPvModuleChangeRequest, DesignDelta>(
      this.baseURL.concat('/design-changes/pv-module-definition'),
      pvModuleChangeRequest
    );
    return new DesignDelta(response.data);
  }

  async setPowerConversionAndStorageEquipment(request: IPowerConversionEquipmentChangeRequest): Promise<DesignDelta> {
    const response = await Http.post<IPowerConversionEquipmentChangeRequest, DesignDelta>(
      this.baseURL.concat('/design-changes/power-conversion-and-storage-equipment'),
      request
    );
    return new DesignDelta(response.data);
  }

  async completeExternalProposalDesignImport(design: IDesignData): Promise<DesignDelta> {
    const response = await Http.post<{ design: IDesignData }, DesignDelta>(
      this.baseURL.concat('/design-changes/external-proposal-design'),
      { design }
    );
    return new DesignDelta(response.data);
  }

  async addAcBranchCircuit(acBranchCircuitRequest: IAcBranchCircuitRequest): Promise<DesignDelta> {
    const response = await Http.post<IAcBranchCircuitRequest, DesignDelta>(
      this.baseURL.concat('/design-changes/added-ac-branch-circuit'),
      acBranchCircuitRequest
    );
    return new DesignDelta(response.data);
  }

  async deleteAcBranchCircuit(acBranchCircuitRequest: IAcBranchCircuitRequest): Promise<DesignDelta> {
    const response = await Http.post<IAcBranchCircuitRequest, DesignDelta>(
      this.baseURL.concat('/design-changes/deleted-ac-branch-circuit'),
      acBranchCircuitRequest
    );
    return new DesignDelta(response.data);
  }

  async updateAcBranchCircuit(acBranchCircuitRequest: IAcBranchCircuitRequest): Promise<DesignDelta> {
    const response = await Http.post<IAcBranchCircuitRequest, DesignDelta>(
      this.baseURL.concat('/design-changes/updated-ac-branch-circuit'),
      acBranchCircuitRequest
    );
    return new DesignDelta(response.data);
  }

  async addDcSourceCircuit(dcSourceCircuitRequest: IDcSourceCircuitRequest): Promise<DesignDelta> {
    const response = await Http.post<IDcSourceCircuitRequest, DesignDelta>(
      this.baseURL.concat('/design-changes/added-dc-optimizer-source-circuit'),
      dcSourceCircuitRequest
    );
    return new DesignDelta(response.data);
  }

  async deleteDcSourceCircuit(dcSourceCircuitRequest: IDcSourceCircuitRequest): Promise<DesignDelta> {
    const response = await Http.post<IDcSourceCircuitRequest, DesignDelta>(
      this.baseURL.concat('/design-changes/deleted-dc-optimizer-source-circuit'),
      dcSourceCircuitRequest
    );
    return new DesignDelta(response.data);
  }

  async deleteDcSourceCircuits(dcSourceCircuitsRequest: IPvSourceCircuitRequest): Promise<DesignDelta> {
    const response = await Http.post<IPvSourceCircuitRequest, DesignDelta>(
      this.baseURL.concat('/design-changes/deleted-dc-optimizer-source-circuits'),
      dcSourceCircuitsRequest
    );
    return new DesignDelta(response.data);
  }

  async updateDcSourceCircuit(dcSourceCircuitRequest: IDcSourceCircuitRequest): Promise<DesignDelta> {
    const response = await Http.post<IDcSourceCircuitRequest, DesignDelta>(
      this.baseURL.concat('/design-changes/updated-dc-optimizer-source-circuit'),
      dcSourceCircuitRequest
    );
    return new DesignDelta(response.data);
  }

  async updateDcSourceCircuits(dcSourceCircuitsRequest: IDcSourceCircuitsRequest): Promise<DesignDelta> {
    const response = await Http.post<IDcSourceCircuitsRequest, DesignDelta>(
      this.baseURL.concat('/design-changes/updated-dc-optimizer-source-circuits'),
      dcSourceCircuitsRequest
    );
    return new DesignDelta(response.data);
  }

  async addPvSourceCircuit(pvSourceCircuitRequest: IPvSourceCircuitRequest): Promise<DesignDelta> {
    const response = await Http.post<IPvSourceCircuitRequest, DesignDelta>(
      this.baseURL.concat('/design-changes/added-pv-source-circuits'),
      pvSourceCircuitRequest
    );
    return new DesignDelta(response.data);
  }

  async deletePvSourceCircuit(pvSourceCircuitRequest: IPvSourceCircuitRequest): Promise<DesignDelta> {
    const response = await Http.post<IPvSourceCircuitRequest, DesignDelta>(
      this.baseURL.concat('/design-changes/deleted-pv-source-circuits'),
      pvSourceCircuitRequest
    );
    return new DesignDelta(response.data);
  }

  async updatePvSourceCircuit(pvSourceCircuitRequest: IPvSourceCircuitRequest): Promise<DesignDelta> {
    const response = await Http.post<IPvSourceCircuitRequest, DesignDelta>(
      this.baseURL.concat('/design-changes/updated-pv-source-circuits'),
      pvSourceCircuitRequest
    );
    return new DesignDelta(response.data);
  }

  async updateFireVentilationAreas(
    updateFireVentilationAreasRequest: IUpdateFireVentilationAreasRequest
  ): Promise<DesignDelta> {
    const response = await Http.post<IUpdateFireVentilationAreasRequest, DesignDelta>(
      this.baseURL.concat('/design-changes/fire-ventilation-areas'),
      updateFireVentilationAreasRequest
    );
    return new DesignDelta(response.data);
  }

  async getInverterSearchParameters(design: IDesignData): Promise<ISearchInverterParameters> {
    const response = await Http.get<ISearchInverterParameters>(
      this.baseURL.concat(`/designs/${design.id}/summaries/inverter-search-parameters`),
      {}
    );
    return response.data;
  }

  async getStringingOptions(design: IDesignData, inverterId?: string): Promise<IStringingOptionsResponseData> {
    const query: string = inverterId ? `?inverterId=${inverterId}` : '';
    const response = await Http.post<IDesignData, IStringingOptionsResponseData>(
      this.baseURL.concat(`/designs/stringing-options${query}`),
      design
    );
    return {
      ...response.data,
      inverterId: inverterId ?? ''
    };
  }

  async getFireCodeOptions(state: string, authorityHavingJurisdiction?: string): Promise<IOption<BaseAttributes>[]> {
    type Response = IOption<BaseAttributes>[];
    type RequestParams = { state: string; authorityHavingJurisdiction?: string };

    const params: RequestParams = {
      state
    };
    if (authorityHavingJurisdiction) {
      params.authorityHavingJurisdiction = authorityHavingJurisdiction;
    }

    const response = await Http.getWithCache<Response>(this.baseURL.concat('/selection-options/fire-codes'), params);
    return response.data;
  }

  async getElectricalCodeOptions(state: string): Promise<IOption<BaseAttributes>[]> {
    const response = await Http.getWithCache<IOption<BaseAttributes>[]>(
      this.baseURL.concat('/selection-options/electrical-codes'),
      { state }
    );
    return response.data;
  }

  async getProjectMissingProperties(projectId: string): Promise<IProjectMissingPropertiesResponse> {
    return (
      await Http.get<IProjectMissingPropertiesResponse>(
        this.baseURL.concat(`/projects/${projectId}/missing-required-properties`),
        {}
      )
    ).data;
  }

  async getDesignMissingProperties(designId: string, state: string): Promise<IProjectMissingPropertiesResponse> {
    const response = await Http.get<IProjectMissingPropertiesResponse>(
      this.baseURL.concat(`/designs/${designId}/missing-required-properties?state=${state}`),
      {}
    );
    return response.data;
  }

  async getProjectTemperatureOptions(projectId: string): Promise<ITemperatureOptionsResponse> {
    const response = await Http.getWithCache<ITemperatureOptionsResponse>(
      this.baseURL.concat(`/projects/${projectId}/design-parameter-options/temperature`),
      {}
    );
    return response.data;
  }

  async getUseAndOccupancyClassificationOptions(state: string): Promise<IOption<BaseAttributes>[]> {
    const response = await Http.getWithCache<IOption<BaseAttributes>[]>(
      this.baseURL.concat(`/selection-options/use-and-occupancy-classifications?state=${state}`),
      {}
    );
    return response.data;
  }
}
