import React, { BaseProps, CSSProperties, ReactElement, ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import { Icon } from '../Icon';
import { getFontPropDependingOnTheme } from '../Theme/ColorThemeProps';
import { EThemeComponentColor } from '../Theme/EThemeComponentColor';
import { EButtonType } from './EButtonType';
import {
  ButtonIconContainer,
  StatusContainer,
  StatusNumber,
  Text,
  ButtonIconWrapper
} from './styles';
import { Tooltip } from '../Tooltip';

export interface IProps {
  children?: ReactNode;
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  /**
   * As an alternative to onClick event, HTML form ID can be specified here.
   * This makes the button render with type="submit" form="{submitForm}" attributes.
   */
  submitForm?: string;
  textStyles?: CSSProperties;
  iconStyles?: CSSProperties;
  icon: string;
  flipIcon?: boolean;
  statusNumber?: number;
  colorTheme?: EThemeComponentColor;
  colorIconTheme?: EThemeComponentColor;
  type?: EButtonType;
  withIconTheme?: boolean;
  fullWidth ?: boolean;
  disabled?: boolean;
  tooltip?: string;
  tooltipContentAlign?: string;
  showTooltipOnClick?: boolean;
  className?: string;
  stickToTopRight?: boolean;
}

const ButtonIcon = (props: IProps & BaseProps): ReactElement => {
  const {
    children,
    onClick,
    submitForm,
    icon,
    textStyles,
    iconStyles = { width: 14 },
    statusNumber,
    className,
    colorTheme = EThemeComponentColor.GRAY,
    colorIconTheme,
    type = EButtonType.CONTAINED_BUTTON,
    fullWidth = false,
    withIconTheme = false,
    flipIcon = false,
    disabled = false,
    tooltip,
    tooltipContentAlign,
    showTooltipOnClick = false,
    stickToTopRight = false,
  } = props;

  const [clickableTooltipVisibility, setClickableTooltipVisibility] = useState(false);
  const iconRef = useRef<HTMLDivElement>(null);

  const colorIconThemeDefault = withIconTheme
    ? getFontPropDependingOnTheme(colorTheme)
    : colorIconTheme;

  useEffect((): (() => void) | undefined => {
    if (showTooltipOnClick) {
      const handleOutsideClick = (event: MouseEvent): void => {
        if (iconRef.current && !iconRef.current.contains(event.target as Node)) {
          setClickableTooltipVisibility(false);
        }
      };
      window.addEventListener('mousedown', handleOutsideClick);
      return (): void => {
        window.removeEventListener('mousedown', handleOutsideClick);
      };
    }
  }, [showTooltipOnClick]);

  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
    if (showTooltipOnClick) {
      setClickableTooltipVisibility(!clickableTooltipVisibility);
    }
    onClick?.(e);
  };
  return (
    <ButtonIconWrapper
      data-tooltip={!showTooltipOnClick ? tooltip : undefined}
      className={'tooltip-top'}
      ref={iconRef}
      stickToTopRight={stickToTopRight}
    >
      <ButtonIconContainer
        onClick={handleClick}
        submitForm={submitForm}
        className={className}
        colorTheme={colorTheme}
        fullWidth={fullWidth}
        buttonType={type}
        disabled={disabled}
        type={submitForm ? 'submit' : 'button'}
        form={submitForm ?? ''}
      >
        {clickableTooltipVisibility && (
          <Tooltip
            tooltipPosition="top"
            tooltipContentAlign={tooltipContentAlign}
          >
            {tooltip}
          </Tooltip>
        )}
        {flipIcon &&
          <Icon name={icon} style={iconStyles} colorTheme={colorIconThemeDefault} />
        }
        {children &&
          <Text
            style={textStyles}
            colorTheme={colorTheme}
          >
          {children}
          </Text>
        }

        <StatusContainer>
          {!flipIcon &&
            <Icon name={icon} style={iconStyles} colorTheme={colorIconThemeDefault}/>
          }
          {typeof statusNumber === 'number' &&
            <StatusNumber>
              <span>{statusNumber}</span>
            </StatusNumber>
          }
        </StatusContainer>
      </ButtonIconContainer>
    </ButtonIconWrapper>

  );
};

export { ButtonIcon };
