import { ReactNode } from 'react';
import styled, { CSSRule, PropsTheme, withTheme } from 'styled-components';
import { Area } from './Area';

type Props = {
  cols: string;
  rows: string;
  areas: string;
  // If you wanna this component actually working
  // You need to add Area components inside it
  // Sadly there is not a way to specify this declaring the type of component
  children?: ReactNode;
};

type WithTheme = PropsTheme<Props>;

const Grid = styled.div<WithTheme>`
  display: grid;
  ${({ theme }: WithTheme): CSSRule => theme.mixins.inheritMinMaxHeight}
  grid-template-columns: ${({ cols }: WithTheme): string => cols};
  grid-template-rows: ${({ rows }: WithTheme): string => rows};
  grid-template-areas: ${({ areas }: WithTheme): string => areas};
`;

export { Area, Grid };
