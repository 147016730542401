import {
  LyraButtons, LyraDivider
} from '@aurorasolar/lyra-ui-kit';
import { EButtonType } from '@aurorasolar/lyra-ui-kit/lib/components/Buttons/EButtonType';
import { EThemeComponentColor } from '@aurorasolar/lyra-ui-kit/lib/components/Theme/EThemeComponentColor';
import { observer } from 'mobx-react-lite';
import type { ReactElement } from 'react';
import * as React from 'react';
import {
  useEffect, useState
} from 'react';
import { AC_MODULE } from '../../../domain/models/Constants';
import { stageIs } from '../../../domain/stages/DesignStages/utils';
import type { IStage } from '../../../domain/stages/IStage';
import useStore from '../../../stores/useStore';
import { isDesignWorkspace } from '../../../stores/UiStore/WorkspaceStore/utils';
import Inverter from '../../components/ElectricalDesignRightMenuCards/InverterCard/Inverter';
import DcOptimizer from '../../components/ElectricalDesignRightMenuCards/DcOptimizerCard/DcOptimizer';
import PropertyPanelHeader from '../PropertyPanel/PropertyPanelHeader/PropertyPanelHeader';
import {
  Note,
  PropertyContentWrapper,
  NextStepWrapper,
  PropertyPanelContainer,
  PropertyPanelWrapper,
  SubmitSectionWrapper
} from '../PropertyPanel/styles';
import { DesignStep } from '../../../domain/models/Design/DesignState';
import EnergyStorageAndBackupCard from '../../components/ElectricalDesignRightMenuCards/EnergyStorageAndBackupCard/EnergyStorageAndBackupCard';
import type {
  ElectricalDesignStage,
  ManufacturerDefinition
} from '../../../domain/stages/DesignStages/ElectricalDesignStage';
import type { ArrayPlacementStage } from '../../../domain/stages/DesignStages/ArrayPlacementStage';
import type { LayoutDesignStage } from '../../../domain/stages/DesignStages/LayoutDesignStage';
import { ModuleCard } from '../../components/SystemDesign/Module/ModuleCard';
import { DCPowerRatingCard } from '../../components/SystemDesign/DCPowerRating/DCPowerRatingCard';
import config, { UI_MODE } from '../../../config/config';

function isElectricalDesignStage(stage: IStage): boolean {
  if (stage) {
    return stageIs<ElectricalDesignStage>(stage, DesignStep.ELECTRICAL_DESIGN);
  }
  return false;
}

export const ElectricalDesign = observer((): ReactElement => {
  const {
    workspace, domain
  } = useStore();
  const { currentWorkspace } = workspace;

  const [integratedInverter, setIntegratedInverter] = useState({
    manufacturer: 'manufacturer',
    make: 'make'
  });

  const { stageManager } = currentWorkspace;
  const stage = stageManager?.currentStage;
  const electricalDesignStage = stage && isElectricalDesignStage(stage) ? (stage as ElectricalDesignStage) : undefined;
  const currentDesign = domain.optionalDesign;

  const pvModuleInfo = currentDesign?.supplementalData.pvModuleInfo;
  const isAcModule = pvModuleInfo?.type === AC_MODULE;

  useEffect(() => {
    if (isAcModule) {
      electricalDesignStage?.showNoteForAcModule().then((inverter: ManufacturerDefinition): void => {
        setIntegratedInverter(inverter);
      });
    }
  }, [electricalDesignStage, isAcModule]);

  if (!isDesignWorkspace(currentWorkspace) || !currentDesign) {
    return <></>;
  }

  const arrayPlacementStep = stageManager!.currentIndex - 2;
  const layoutDesignStep = stageManager!.currentIndex - 1;

  const arrayPlacementStage = stageManager!.getStageData(arrayPlacementStep) as ArrayPlacementStage;
  const layoutDesignStage = stageManager!.getStageData(layoutDesignStep) as LayoutDesignStage;

  const showDesign = !!electricalDesignStage && !!layoutDesignStage && !!arrayPlacementStage;

  const showOptimizer = !!currentDesign?.supplementalData.optimizerInfo?.model;
  const showEnergyStorageAndBackup = electricalDesignStage?.showEnergyStorageAndBackup;

  return showDesign ? (
    <PropertyPanelWrapper>
      <PropertyPanelContainer>
        <PropertyPanelHeader icon="tool-properties" name="Electrical Design" iconBg="#427ecd" />
      </PropertyPanelContainer>
      <PropertyContentWrapper>
        <ModuleCard pvModuleInfo={pvModuleInfo} totalModules={layoutDesignStage?.totalModules} />

        {isAcModule && (
          <Note>
            AC PV module with integrated {integratedInverter.manufacturer} {integratedInverter.make} microinverter
          </Note>
        )}
        <LyraDivider.Divider direction="horizontal" color={EThemeComponentColor.GRAY_200} />

        {!isAcModule
          ? [
            <Inverter key="inverter" electricalDesignStage={electricalDesignStage!} editable={true} />,
            <LyraDivider.Divider key="divider" direction="horizontal" color={EThemeComponentColor.GRAY_200} />
          ]
          : null}

        {showOptimizer
          ? [
            <DcOptimizer key="optimizer" electricalDesignStage={electricalDesignStage!} editable={true} />,
            <LyraDivider.Divider key="divider" direction="horizontal" color={EThemeComponentColor.GRAY_200} />
          ]
          : null}

        {showEnergyStorageAndBackup && (
          <>
            <EnergyStorageAndBackupCard electricalDesignStage={electricalDesignStage} />
            <LyraDivider.Divider direction="horizontal" color={EThemeComponentColor.GRAY_200} />
          </>
        )}

        <DCPowerRatingCard layoutDesignStage={layoutDesignStage} domain={domain} />
        {!!electricalDesignStage && (
          <LyraDivider.Divider direction="horizontal" color={EThemeComponentColor.GRAY_200} />
        )}
      </PropertyContentWrapper>
      {config.featureFlag.uiMode !== UI_MODE.AURORA && (
        <SubmitSectionWrapper>
          <NextStepWrapper xs={12} className="pv-xs">
            <LyraButtons.Buttons
              className="back-button"
              styleType="flat"
              colorTheme={EThemeComponentColor.TRANSPARENT}
              fontColorTheme={EThemeComponentColor.AQUAMARINE}
              isLeft
              onClick={(): void => {
                workspace.currentWorkspace.stageManager?.previous();
              }}
            >
              <b>Back</b>
            </LyraButtons.Buttons>
            <LyraButtons.ButtonIcon
              icon="arrow"
              type={EButtonType.ROUNDED_BUTTON}
              colorTheme={EThemeComponentColor.AQUAMARINE}
              withIconTheme
              onClick={(): void => {
                currentWorkspace.stageManager?.next();
              }}
            >
              <b>Electrical BOS</b>
            </LyraButtons.ButtonIcon>
          </NextStepWrapper>
        </SubmitSectionWrapper>
      )}
    </PropertyPanelWrapper>
  ) : (
    <></>
  );
});
