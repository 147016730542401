import { observable } from 'mobx';
import type { IAddressData } from '../../SiteDesign/Address';

export interface IEngineerOfRecordData {
  readonly name: string;
  readonly licenseNumber: string;
  readonly address?: IAddressData;
  readonly phoneNumber: string;
}
export default class EngineerOfRecord {
  @observable
  name: string;

  @observable
  licenseNumber: string;

  @observable
  address: IAddressData;

  @observable
  phoneNumber: string;

  constructor(data?: IEngineerOfRecordData) {
    this.name = data?.name ?? '';
    this.licenseNumber = data?.licenseNumber ?? '';
    this.phoneNumber = data?.phoneNumber ?? '';
    this.address = {
      addressOne: data?.address?.addressOne ?? '',
      addressTwo: data?.address?.addressTwo ?? '',
      city: data?.address?.city ?? '',
      province: data?.address?.province ?? '',
      postalCode: data?.address?.postalCode ?? ''
    };
  }

  toData(showEngineerAddress?: boolean): IEngineerOfRecordData {
    return {
      name: this.name,
      licenseNumber: this.licenseNumber,
      phoneNumber: this.phoneNumber,
      ...(showEngineerAddress && { address: this.address })
    };
  }
}
