import type { IBaseViewModelDependencies } from '../../BaseViewModel';
import { BaseViewModel } from '../../BaseViewModel';
import type EditorStore from '../../../../EditorStore/EditorStore';
import type { ToolbarStore } from '../../../ToolbarStore/Toolbar';
import type { WizardStore } from '../../../Wizard/Wizard';
import type { RoofProtrusionStore } from '../../../Properties/RoofProtrusion/RoofProtrusionStore';
import type { ServiceBus } from '../../../../ServiceBus/ServiceBus';
import { Import3DModelViewModel } from '../../../Wizard/ViewModels/Import3DModel/Import3DModelViewModel';
import type { WorkspaceStore } from '../../../WorkspaceStore';
import { SelectionControl } from '../../../../EditorStore/Controls/SelectionControl';
import { OUTLINE_TOOL_ID } from '../../../ToolbarStore/Project/constants';

interface QuickStartGuideViewModelDependencies extends IBaseViewModelDependencies {
  readonly wizard: WizardStore;
  readonly roofProtrusion: RoofProtrusionStore;
  readonly serviceBus: ServiceBus;
  readonly toolbar: ToolbarStore;
  readonly workspace: WorkspaceStore;
}

export class QuickStartGuideViewModel extends BaseViewModel {
  readonly propCodeUI = 'quick_start_guide_modal';
  override readonly editor: EditorStore;

  private readonly wizard: WizardStore;
  private readonly roofProtrusion: RoofProtrusionStore;
  private readonly serviceBus: ServiceBus;
  private readonly toolbar: ToolbarStore;
  private readonly workspace: WorkspaceStore;
  private readonly selectionControl: SelectionControl;

  constructor(dependencies: QuickStartGuideViewModelDependencies) {
    super(dependencies);
    this.editor = dependencies.editor;
    this.wizard = dependencies.wizard;
    this.roofProtrusion = dependencies.roofProtrusion;
    this.serviceBus = dependencies.serviceBus;
    this.toolbar = dependencies.toolbar;
    this.workspace = dependencies.workspace;

    this.selectionControl = SelectionControl.getInstance(this.editor, this.editor.viewport, this.editor.activeCamera);
  }

  openImport3dModelWizard() {
    this.wizard.createOneStepWizard(Import3DModelViewModel, {
      wizard: this.wizard,
      domain: this.domain,
      editor: this.editor,
      serviceBus: this.serviceBus,
      toolbar: this.toolbar,
      roofProtrusion: this.roofProtrusion,
      onModalClose: () => {
        this.wizard.clear();
      }
    });
  }

  startTracingRoofFace() {
    this.toolbar.activateToolInProjectWorkspaceWithoutClick(OUTLINE_TOOL_ID);
  }

  override dispose() {
    this.selectionControl.dispose();
  }
}
