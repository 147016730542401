import styled, {
  CSSRule,
  keyframes,
  PropsTheme
} from 'styled-components';
import { Color } from '../../typings';
import { EModalType } from './EModalType';
import { EThemeComponentColor } from '../Theme/EThemeComponentColor';
import Keyframes from 'styled-components/dist/models/Keyframes';

function getHeaderColor(color: string | EModalType): string {
  return color === EModalType.BLUE ? 'mainColor1' : 'mainColor2';
}

function getDividerColor(color: 'white' | 'gray' = 'white'): string {
  return color === 'white' ? 'whiteBg' : 'grey4';
}

type BackgroundProps = PropsTheme & {
  $show?: boolean;
};

type ContainerProps = PropsTheme & {
  height?: string;
  width?: string;
  selectedOptionColor?: EThemeComponentColor | undefined;
};

type ContentProps = PropsTheme & {
  showHeader?: boolean;
};

type HeaderProps = PropsTheme & {
  color?: string | undefined;
};

type DividerProps = PropsTheme & {
  direction: 'vertical' | 'horizontal';
  thickness?: string;
  length: string;
  color?: 'white' | 'gray';
};

const fadeInAnimation = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const fadeOutAnimation = keyframes`
  from { opacity: 1; }
  to { opacity: 0; }
`;

export const Background = styled.div<BackgroundProps>`
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
  background: rgba(0.5, 0.5, 0.5, 0.3);
  ${({ theme }: PropsTheme): CSSRule => theme.mixins.modal}
  visibility: ${({ $show }: BackgroundProps): string => (!$show ? 'hidden' : 'visible')};
  animation: ${({ $show }: BackgroundProps): Keyframes =>
          !$show ? fadeOutAnimation : fadeInAnimation} 0.2s linear;
  transition: visibility 0.2s linear;
`;

export const Container = styled.div<ContainerProps>`
  max-width: 85%;
  max-height: 80%;
  height: ${({ height }: ContainerProps): string => height ?? ''};
  width: ${({ width }: ContainerProps): string => width ?? ''};
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  padding: 10px 10px 15px;
  background: ${({ theme }: PropsTheme): Color => theme.colors.secondary};
  ${({ theme }: PropsTheme): CSSRule => theme.mixins.modal}
`;

export const ContentWrapper = styled.div`
  overflow: hidden auto;
  height: 100%;
  scrollbar-gutter: stable;
`;

export const Content = styled.div`
  padding: 20px 10px 20px 20px;
`;

export const HeaderStyle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 4px;
  padding: 10px 15px;
  background: ${({ theme, color = EModalType.BLUE }: HeaderProps): Color =>
    theme.colors[getHeaderColor(color)]};

  & > h4 {
    margin-right: 4px;
    font-size: 16px;
    font-weight: bold;
    color: ${({ theme }: PropsTheme): Color => theme.colors.highlight};
  }

  & > span {
    font-size: 14px;
    color: ${({ theme }: PropsTheme): Color => theme.colors.highlight};
  }
`;

export const Separator = styled.div`
  flex: 1;
`;

export const Divider = styled.div<DividerProps>`
  height: ${({ direction, thickness = '1px', length }: DividerProps): string =>
    direction === 'vertical' ? length : thickness};
  width: ${({ direction, thickness = '1px', length }: DividerProps): string =>
    direction === 'horizontal' ? length : thickness};
  background: ${({ theme, color = 'white' }: DividerProps): Color =>
    theme.colors[getDividerColor(color)]};
`;

export const FooterStyle = styled.div`
  padding-left: 20px;
  padding-right: 20px;
`;
