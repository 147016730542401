import Registry from '../../../utils/Registry';
import DesignToolbar from './DesignToolbar/DesignToolbar';
import ProjectToolbar from './ProjectToolbar/ProjectToolbar';

const toolbarRegistry = new Registry();

toolbarRegistry.add('toolbar_design', DesignToolbar);
toolbarRegistry.add('toolbar_project', ProjectToolbar);

export { toolbarRegistry };
