import { observer } from 'mobx-react-lite';
import type {
  BaseProps, ReactElement
} from 'react';
import React, {
  useCallback, useState
} from 'react';
import type {
  PropsTheme
} from 'styled-components';
import styled from 'styled-components';
import { LyraIcon } from '@aurorasolar/lyra-ui-kit';
import type { Color } from '../../../domain/typings';
import useStore from '../../../stores/useStore';
import IconMapper from '../../../ui/components/Icons';
import { IconKeys } from '../../components/Icons/registry';
import BaseImageryProvider from '../../../domain/typings/BaseImageryProvider';
import { isDesignWorkspace } from '../../../stores/UiStore/WorkspaceStore/utils/index';
import { ModalUiCodes } from '../../../stores/UiStore/Modal/ModalUiCodes';
import { UploadCustomBaseImageryModalViewModel } from '../../../stores/UiStore/Modal/ViewModels/UploadCustomBaseImageryModalViewModel/UploadCustomBaseImageryModalViewModel';
import { CustomBaseImageryTransformationTool } from '../../../stores/UiStore/ToolbarStore/Project/CustomBaseImageryTransformationTool';
import { SelectionTool } from '../../../stores/UiStore/ToolbarStore/Project/SelectionTool';
import { CUSTOM_BASE_IMAGERY_TRANSFORMATION_TOOL_ID } from '../../../stores/UiStore/ToolbarStore/Project/constants';
import { DeleteConfirmationModal } from '../ConfirmCommandModal/DeleteConfirmationModalNoServiceBus';

type Props = BaseProps & {
  enable?: boolean;
  onClick?: (event: MouseEvent) => void;
};

const CustomBaseImageryExtraOptionsWrapper = styled.div<Props>`
  width: 280px;
  gap: 8px;
  bottom: calc(37px + 100px + 20px);
  padding: 20px 25px;
  position: absolute;
  left: calc(28px + 84px + 36px);
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const CustomBaseImageryExtraOptionEdit = styled.button<Props>`
  background: white;
  border-radius: 4px;
  padding: 8px;
  color: #427ecd;
  cursor: pointer;
`;

const TransparentBackground = styled.div<Props>`
  position: fixed;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
`;

const BaseImageryOptionButtonText = styled.div<PropsTheme<Props>>`
  margin-top: 8px;
  font-weight: ${({ enable }: Props): string => (enable ? 'bold' : 'none')};
  color: ${({
    enable, theme
  }: PropsTheme<Props>): Color => (enable ? theme.colors.mainColor1 : 'auto')};
`;

const BaseImageryButton = styled.button<PropsTheme<Props>>`
  background-color: ${({ theme }: PropsTheme<Props>): Color => theme.colors.whiteBg || ''};
  border-radius: 10px;
  bottom: 37px;
  cursor: pointer;
  height: 100px;
  position: absolute;
  left: 28px;
  width: 84px;
  z-index: 1;
  border: solid 3px
    ${({
    enable, theme
  }: PropsTheme<Props>): Color => (enable ? theme.colors.main1 : theme.colors.whiteBg)};
  color: ${({
    enable, theme
  }: PropsTheme<Props>): string | Color => (enable ? theme.colors.main1 : 'inherit')};
  font-weight: ${({ enable }: Props): string => (enable ? 'bold' : 'normal')};
  &:focus {
    outline: none;
  }
  &:hover {
    border: solid 3px ${({ theme }: PropsTheme<Props>): Color => theme.colors.main1};
    color: ${({ theme }: PropsTheme<Props>): Color => theme.colors.mainColor1};

    ${BaseImageryOptionButtonText} {
      color: ${({ theme }: PropsTheme): Color => theme.colors.mainColor1};
      font-weight: bold;
    }
  }
`;

const BaseImageryOptionButton = styled.button<PropsTheme<Props>>`
  background-color: ${({ theme }: PropsTheme<Props>): Color => theme.colors.whiteBg || ''};
  border: ${({
    enable, theme
  }: PropsTheme<Props>): Color =>
    enable ? `solid 3px ${theme.colors.mainColor1}` : `1px solid ${theme.colors.gray5}`};
  outline: ${({ enable }: Props): string => (enable ? 'none' : '2px solid transparent')};
  border-radius: 10px;
  height: 64px;
  width: 64px;
  cursor: pointer;
  &:focus {
    outline: none;
  }
`;

const BaseImagerySelectorWrapper = styled.div<PropsTheme<Props>>`
  background-color: ${({ theme }: PropsTheme<Props>): Color => theme.colors.whiteBg || ''};
  border-radius: 10px;
  bottom: 37px;
  padding: 20px 25px;
  position: absolute;
  left: calc(28px + 84px + 16px);
  display: flex;
`;

const BaseImageryOptionButtonWrapper = styled.div<PropsTheme>`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 100px;
  cursor: pointer;

  &:hover {
    ${BaseImageryOptionButton} {
      border: solid 3px ${({ theme }: PropsTheme): Color => theme.colors.mainColor1};
      color: ${({ theme }: PropsTheme): Color => theme.colors.mainColor1};
    }
    ${BaseImageryOptionButtonText} {
      color: ${({ theme }: PropsTheme): Color => theme.colors.mainColor1};
      font-weight: bold;
    }
  }
`;

const BaseImageryOptions = observer((): ReactElement => {
  const { workspace } = useStore();
  const [isHovered, setIsHovered] = useState(false);
  const handleMouseEnter = (): void => {
    setIsHovered(true);
  };
  const handleMouseLeave = (): void => {
    setIsHovered(false);
  };

  const [baseImagerySelectorOpened, setBaseImagerySelectorOpened] = useState<boolean>(false);
  const openBaseImagerySelector = useCallback((): void => {
    setBaseImagerySelectorOpened(!baseImagerySelectorOpened);
  }, [baseImagerySelectorOpened]);

  if (isDesignWorkspace(workspace.currentWorkspace)) {
    if (baseImagerySelectorOpened) {
      setBaseImagerySelectorOpened(false);
    }
    return <></>;
  }

  return (
    <>
      <BaseImageryButton
        onClick={openBaseImagerySelector}
        enable={baseImagerySelectorOpened}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <IconMapper name={baseImagerySelectorOpened || isHovered ? IconKeys.MapSelected : IconKeys.Map} />
        <BaseImageryOptionButtonText enable={baseImagerySelectorOpened}>
          BASE
          <br />
          IMAGERY
        </BaseImageryOptionButtonText>
      </BaseImageryButton>
      {baseImagerySelectorOpened && (
        <>
          <TransparentBackground
            onClick={(): void => {
              setBaseImagerySelectorOpened(false);
            }}
          ></TransparentBackground>
          <BaseImagerySelector closeOptions={(): void => setBaseImagerySelectorOpened(false)} />
        </>
      )}
    </>
  );
});

const BaseImagerySelector = observer(({ closeOptions }: { closeOptions: () => void }): ReactElement => {
  const store = useStore();
  const {
    editor, modal, map, domain
  } = store;
  const isCustomBaseImageryConfigured = !!domain.project.site.imagery.CUSTOM?.scaleFactor;
  const [deleteConfirmationOpened, setDeleteConfirmationOpened] = useState<boolean>(false);
  const [isCustomBaseImageryOverride, setIsCustomBaseImageryOverride] = useState<boolean>(
    store.toolbar.selectedTool?.id === CUSTOM_BASE_IMAGERY_TRANSFORMATION_TOOL_ID
  );

  const isGoogleMaps = !isCustomBaseImageryOverride && map.selectedProvider === BaseImageryProvider.GOOGLE_MAPS;
  const isCustomBaseImagery = isCustomBaseImageryOverride || map.selectedProvider === BaseImageryProvider.CUSTOM;
  const isNoBaseImagery = !isCustomBaseImageryOverride && map.selectedProvider === BaseImageryProvider.NONE;

  const cancelCustomBaseImageryEditing = useCallback((): void => {
    // Select "SelectionTool"
    store.toolbar.selectTool(new SelectionTool(store));
  }, [store]);

  const handleGoogleMapsClick = useCallback((): void => {
    setIsCustomBaseImageryOverride(false);
    cancelCustomBaseImageryEditing();
    editor.changeMapBackground(BaseImageryProvider.GOOGLE_MAPS);
  }, [editor, cancelCustomBaseImageryEditing]);

  const handleNoMapClick = useCallback((): void => {
    setIsCustomBaseImageryOverride(false);
    editor.changeMapBackground(BaseImageryProvider.NONE);
    cancelCustomBaseImageryEditing();
  }, [editor, cancelCustomBaseImageryEditing]);

  const [customImageryOptionsBlocked, setCustomImageryOptionsBlocked] = useState<boolean>(false);
  const handleEditCustomImageryClick = useCallback(
    async (closeOptionsInTheEnd: boolean = true): Promise<void> => {
      setCustomImageryOptionsBlocked(true);

      await store.editor.changeMapBackground(BaseImageryProvider.GOOGLE_MAPS, false);
      const tool = new CustomBaseImageryTransformationTool({
        ...store,
        toolbar: store.toolbar,
        smartGuides: store.smartGuides,
        mapStore: store.map,
        isCustomBaseImageryConfigured
      });
      tool.initializationPromise.finally((): void => {
        setCustomImageryOptionsBlocked(false);
      });

      store.toolbar.selectTool(tool);
      if (closeOptionsInTheEnd) {
        closeOptions();
      }
      setIsCustomBaseImageryOverride(true);
    },
    [store, closeOptions, isCustomBaseImageryConfigured]
  );

  const handleConfirmDeleteCustomBaseImageryClick = useCallback((): void => {
    // Same as handleGoogleMapsClick, but with additional params to
    // changeMapBackground in order to clear custom base imagery data.
    setIsCustomBaseImageryOverride(false);
    cancelCustomBaseImageryEditing();
    editor.changeMapBackground(BaseImageryProvider.GOOGLE_MAPS, true, true);

    setDeleteConfirmationOpened(false);
    closeOptions();
  }, [cancelCustomBaseImageryEditing, closeOptions, editor]);

  const handleCustomImageryClick = useCallback((): void => {
    cancelCustomBaseImageryEditing();
    if (isCustomBaseImageryConfigured) {
      // Only edit or delete options available via controls over it.
      store.editor.changeMapBackground(BaseImageryProvider.CUSTOM);
      return;
    }
    modal.createModal(ModalUiCodes.UploadCustomSiteImageryModal, new UploadCustomBaseImageryModalViewModel());
    closeOptions();
  }, [cancelCustomBaseImageryEditing, isCustomBaseImageryConfigured, modal, closeOptions, store.editor]);

  return (
    <>
      {deleteConfirmationOpened && (
        <DeleteConfirmationModal
          onCancel={(): void => setDeleteConfirmationOpened(false)}
          onDelete={handleConfirmDeleteCustomBaseImageryClick}
          description="Are you sure you want to delete this custom base imagery?"
        />
      )}
      {isCustomBaseImagery && (
        <CustomBaseImageryExtraOptionsWrapper>
          <CustomBaseImageryExtraOptionEdit
            disabled={customImageryOptionsBlocked}
            onClick={(): void => {
              handleEditCustomImageryClick();
            }}
          >
            EDIT IMAGE
          </CustomBaseImageryExtraOptionEdit>
          <CustomBaseImageryExtraOptionEdit
            disabled={customImageryOptionsBlocked}
            onClick={(): void => setDeleteConfirmationOpened(true)}
          >
            <LyraIcon.Icon name="icon-delete" />
          </CustomBaseImageryExtraOptionEdit>
        </CustomBaseImageryExtraOptionsWrapper>
      )}

      <BaseImagerySelectorWrapper>
        <BaseImageryOptionButtonWrapper onClick={handleGoogleMapsClick}>
          <BaseImageryOptionButton enable={isGoogleMaps}>
            <IconMapper name={isGoogleMaps ? IconKeys.BaseMapSelected : IconKeys.BaseMap} />
          </BaseImageryOptionButton>
          <BaseImageryOptionButtonText enable={isGoogleMaps}>Google&nbsp;Maps</BaseImageryOptionButtonText>
        </BaseImageryOptionButtonWrapper>

        <BaseImageryOptionButtonWrapper onClick={handleCustomImageryClick}>
          <BaseImageryOptionButton disabled={customImageryOptionsBlocked} enable={isCustomBaseImagery}>
            <IconMapper name={isCustomBaseImagery ? IconKeys.CustomMapSelected : IconKeys.CustomMap} />
          </BaseImageryOptionButton>
          <BaseImageryOptionButtonText enable={isCustomBaseImagery}>Custom</BaseImageryOptionButtonText>
        </BaseImageryOptionButtonWrapper>

        <BaseImageryOptionButtonWrapper onClick={handleNoMapClick}>
          <BaseImageryOptionButton enable={isNoBaseImagery}>
            <IconMapper name={isNoBaseImagery ? IconKeys.HideMapSelected : IconKeys.HideMap} />
          </BaseImageryOptionButton>
          <BaseImageryOptionButtonText enable={isNoBaseImagery}>None</BaseImageryOptionButtonText>
        </BaseImageryOptionButtonWrapper>
      </BaseImagerySelectorWrapper>
    </>
  );
});

export { BaseImageryOptions };
