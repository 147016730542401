import { observer } from 'mobx-react-lite';
import type { ReactElement } from 'react';
import React from 'react';
import { EquipmentExposureTypes } from '../../../../domain/entities/SiteDesign/EquipmentExposure';
import type { IElectricalEquipmentData } from '../../../../domain/entities/SitePlan/SitePlan';
import {
  ButtonView,
  IconView,
  InverterDescription,
  InverterItem,
  InverterItemWrapper,
  InverterTitle,
  MarkerIcon,
  StatusButton,
  TextView
} from './styles';

type Props = {
  equipment: IElectricalEquipmentData[];
  placed: boolean;

  onIconClick: (equipment: IElectricalEquipmentData) => void;
  onExposureChange: (id: string, exposure: EquipmentExposureTypes, placed: boolean) => void;
};

const EquipmentItem = observer((props: Props): ReactElement => {
  const {
    equipment, placed, onIconClick, onExposureChange
  } = props;
  return (
    <InverterItemWrapper>
      {equipment.map(
        (item: IElectricalEquipmentData, index: number): ReactElement => (
          <InverterItem key={index}>
            <IconView onClick={(): boolean | void => !placed && onIconClick(item)}>
              <MarkerIcon $active={placed} name="site-equipment" />
              <InverterTitle>{item.label}</InverterTitle>
            </IconView>
            <TextView>
              <InverterDescription>{item.description}</InverterDescription>
              {item.exposure && !item.exposure.constrained && (
                <ButtonView>
                  <StatusButton
                    active={item.exposure.type === EquipmentExposureTypes.INDOOR}
                    onClick={(): void => onExposureChange(item.id, EquipmentExposureTypes.INDOOR, placed)}
                  >
                    Indoor
                  </StatusButton>
                  <StatusButton
                    active={item.exposure.type === EquipmentExposureTypes.OUTDOOR}
                    onClick={(): void => onExposureChange(item.id, EquipmentExposureTypes.OUTDOOR, placed)}
                  >
                    Outdoor
                  </StatusButton>
                </ButtonView>
              )}
            </TextView>
          </InverterItem>
        )
      )}
    </InverterItemWrapper>
  );
});

export default EquipmentItem;
