import type { PropsTheme } from 'styled-components';
import styled from 'styled-components';
import type { Color } from '../../../domain/typings';

export const CounterStyle = styled.div`
  font-size: 14px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;

  & > div:first-child {
    min-width: 100px;
    background: white;
    border: 1px solid ${({ theme }: PropsTheme): Color => theme.colors.tertiary};
    border-radius: 3px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2px;
    height: 20px;
    & > div {
      margin: 0 7px;
    }
  }

  button {
    background: ${({ theme }: PropsTheme): Color => theme.colors.secondary};
    cursor: pointer;
    height: 100%;
    width: 20%;
    line-height: inherit;
    font-size: 20px;
    display: flex;
    justify-content: center;

    &:disabled {
      opacity: 0.25;
      cursor: not-allowed;
    }
  }
`;

export const Label = styled.div`
  font-size: 12px;
  line-height: 0.2;
`;
