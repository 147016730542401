import { Registry } from '../../utils';

const mappingIcons = {
  'arrow': require('./assets/icon-arrow.svg'),
  'arrow-collapse': require('./assets/arrow-collapse.svg'),
  'arrow-down': require('./assets/arrow-down.svg'),
  'arrow-right': require('./assets/arrow-right.svg'),
  'bold-arrow': require('./assets/icon-bold-arrow.svg'),
  'circle': require('./assets/circle-protrusion-tool.svg'),
  'check': require('./assets/check.svg'),
  'check-white': require('./assets/check-white.svg'),
  'close-icon': require('./assets/close-icon.svg'),
  'condition-roof': require('./assets/condition-of-roof-icon.svg'),
  'define-azimuth': require('./assets/define-azimuth-icon.svg'),
  'define-slope': require('./assets/define-slope-icon.svg'),
  'ground-snow': require('./assets/ground-snow-load-icon.svg'),
  'handler': require('./assets/handler.svg'),
  'hide-icon': require('./assets/hide-icon.svg'),
  'high-temperature': require('./assets/high-temperature-icon.svg'),
  'horizontal-protrusion': require('./assets/horizontal-protrusion.svg'),
  'import-3D': require('./assets/subtool-import-3-d.svg'),
  'increase-decrease-arrow':
  require('./assets/increase-decrease-arrow-icon.svg'),
  'info': require('./assets/icon-info.svg'),
  'tooltip': require('./assets/icon-info-light.svg'),
  'Logo': require('./assets/Lyra-logo.svg'),
  'low-temperature': require('./assets/low-temperature-icon.svg'),
  'map-source': require('./assets/icon-map-source.svg'),
  'other': require('./assets/other-icon.svg'),
  'pan': require('./assets/icon-pan.svg'),
  'pannels': require('./assets/icon-pannels.svg'),
  'parallel-protrusion': require('./assets/parallel-protrusion.svg'),
  'pencil': require('./assets/icon-pencil.svg'),
  'protrusions': require('./assets/icon-protrusions.svg'),
  'protrusion-height': require('./assets/protrusion-height.svg'),
  'rect-tool': require('./assets/icon-rectification-tool.svg'),
  'undo': require('./assets/undo-icon.svg'),
  'rectangle': require('./assets/rectangle-protrusion-tool.svg'),
  'redo': require('./assets/redo-icon.svg'),
  'showHideElements': require('./assets/showHideElements-icon.svg'),
  'roof-decking': require('./assets/roof-decking-icon.svg'),
  'roof-framing': require('./assets/roof-framing-icon.svg'),
  'roof-predictions': require('./assets/subtool-roof-predictions.svg'),
  'roof-surface': require('./assets/roof-surface-icon.svg'),
  'roof-type-flat': require('./assets/roof-type-flat.svg'),
  'roof-type-sloped': require('./assets/roof-type-sloped.svg'),
  'roof-type-flat-purple': require('./assets/roof-type-flat-purple.svg'),
  'roof-type-sloped-purple': require('./assets/roof-type-sloped-purple.svg'),
  'selection': require('./assets/icon-selection-tool.svg'),
  'site-equipment': require('./assets/icon-site-equipment.svg'),
  'site-features': require('./assets/icon-site-features.svg'),
  'site-images': require('./assets/icon-site-images.svg'),
  'smartguide-extension-lines':
    require('./assets/smartguide-extension-lines.svg'),
  'smartguide-live-angles':
    require('./assets/smartguide-live-angles.svg'),
  'smartguide-midpoints-lines':
    require('./assets/smartguide-midpoints-lines.svg'),
  'smartguide-parallel-lines':
    require('./assets/smartguide-parallel-lines.svg'),
  'smartguide-perpendicular-alignment':
    require('./assets/smartguide-perpendicular-alignment.svg'),
  'smartguide-snap': require('./assets/smartguide-snap.svg'),
  'smartguide-square': require('./assets/smart-square.svg'),
  'smartguides': require('./assets/smartguides.svg'),
  'solar-access': require('./assets/solar-access-icon.svg'),
  'split-roof': require('./assets/subtool-split-roof.svg'),
  'street-view': require('./assets/street-view.svg'),
  'switch-values': require('./assets/icon-switch-values.svg'),
  'tool': require('./assets/tool-icon.svg'),
  'tool-import-3d': require('./assets/import-3-d-model-tool.svg'),
  'tool-properties': require('./assets/tool-properties-icon.svg'),
  'trace-outlines': require('./assets/subtool-trace-outlines.svg'),
  'trace-roof': require('./assets/subtool-trace-roof.svg'),
  'tracing-tool': require('./assets/icon-tracing-tool.svg'),
  'wind-exposure': require('./assets/wind-exposure-cat-icon.svg'),
  'wind-speed': require('./assets/wind-speed-icon.svg'),
  'yellow-arrow': require('./assets/yellow-arrow.svg'),
  'drag-edge': require('./assets/hint-drag-edge.svg'),
  'move': require('./assets/hint-move.svg'),
  'rotate': require('./assets/hint-rotate.svg'),
  'scale': require('./assets/hint-scale.svg'),
  'pull-edge': require('./assets/hnt-pull-edge.svg'),
  'node-blue': require('./assets/node-blue.svg'),
  'aligned-rows': require('./assets/icon-aligned-rows.svg'),
  'maximized-modules': require('./assets/icon-maximized-modules.svg'),
  'staggered-rows': require('./assets/icon-staggered-rows.svg'),
  'strategy-portrait': require('./assets/strategy-portrait.svg'),
  'strategy-landscape': require('./assets/strategy-landscape.svg'),
  'alert-icon': require('./assets/alert-icon.svg'),
  'icon-tick': require('./assets/icon-tick.svg'),
  'icon-download': require('./assets/icon-download.svg'),
  'icon-download-default': require('./assets/icon-download-default.svg'),
  'icon-favorite-selected': require('./assets/icon-favorite-selected.svg'),
  'icon-favorite': require('./assets/icon-favorite.svg'),
  'icon-pp-purchased': require('./assets/icon-pp-purchased.svg'),
  'icon-pp-purchased-purple': require('./assets/icon-pp-purchased-purple.svg'),
  'icon-pp-purchased-disabled': require('./assets/icon-pp-purchased-disabled.svg'),
  'icon-dashboard': require('./assets/icon-dashboard.svg'),
  'icon-user': require('./assets/icon-user.svg'),
  'icon-account': require('./assets/icon-account.svg'),
  'icon-dashboard-white': require('./assets/icon-dashboard-white.svg'),
  'icon-user-white': require('./assets/icon-user-white.svg'),
  'icon-account-white': require('./assets/icon-account-white.svg'),
  'icon-available-documents': require('./assets/icon-available-documents.svg'),
  'pattern': require('./assets/BackgroundPattern.svg'),
  'logo-white': require('./assets/Lyra-logo-white.svg'),
  'icon-settings': require('./assets/icon-settings.svg'),
  'icon-logout': require('./assets/icon-logout.svg'),
  'three-dots': require('./assets/three-dots.svg'),
  'icon-notification': require('./assets/icon-notifications.svg'),
  'sales': require('./assets/sales.svg'),
  'sun-line': require('./assets/sun-line.svg'),
  'doubt-sun': require('./assets/doubt-sun.svg'),
  'icon-document-empty': require('./assets/icon-document-empty.svg'),
  'icon-address': require('./assets/icon-address.svg'),
  'sun-success': require('./assets/sun-success.svg'),
  'icon-filter-enabled': require('./assets/icon-filter-enabled.svg'),
  'icon-filter': require('./assets/icon-filter.svg'),
  'icon-phone-number': require('./assets/icon-phone-number.svg'),
  'ready-PermitPackage': require('./assets/ready-PermitPackage.svg'),
  'icon-account-big': require('./assets/icon-account-big.svg'),
  'icon-permit-package': require('./assets/icon-permit-package.svg'),
  'tick': require('./assets/tick.svg'),
  'icon-delete': require('./assets/icon-delete.svg'),
  'card-external': require('./assets/card-external.svg'),
  'card-logo': require('./assets/card-logo.svg'),
  'close': require('./assets/close.svg'),
  'arrow-dropdown': require('./assets/arrow-dropdown.svg'),
  'icon-edit-circuits': require('./assets/icon-edit-circuits.svg'),
  'tool-images': require('./assets/tool-images.svg'),
  'tool-settings': require('./assets/tool-settings.svg'),
  'tool-wrench': require('./assets/tool-wrench.svg'),
  'icon-document': require('./assets/icon-document.svg'),
  'icon-billable': require('./assets/icon-billable.svg'),
  'icon-danger': require('./assets/icon-danger.svg'),
  'icon-warning': require('./assets/icon-warning.svg'),
  'overdrive-alert-icon': require('./assets/overdrive-alert-icon.svg'),
  'remove-icon': require('./assets/remove-icon.svg'),
  'highTemp': require('./assets/highTemp.svg'),
  'lowTemp': require('./assets/lowTemp.svg'),
  'landscape-aligned': require('./assets/landscape-aligned.svg'),
  'landscape-maximized': require('./assets/landscape-maximized.svg'),
  'landscape-staggered': require('./assets/landscape-staggered.svg'),
  'portrait-aligned-mixed': require('./assets/portrait-aligned-mixed.svg'),
  'portrait-aligned-not-mixed': require('./assets/portrait-aligned-not-mixed.svg'),
  'portrait-maximized-mixed': require('./assets/portrait-maximized-mixed.svg'),
  'portrait-maximized-not-mixed': require('./assets/portrait-maximized-not-mixed.svg'),
  'portrait-staggered-mixed': require('./assets/portrait-staggered-mixed.svg'),
  'portrait-staggered-not-mixed': require('./assets/portrait-staggered-not-mixed.svg'),
  'landscape-icon-not-selected': require('./assets/landscape-icon-not-selected.svg'),
  'landscape-icon-selected': require('./assets/landscape-icon-selected.svg'),
  'portrait-icon-not-selected': require('./assets/portrait-icon-not-selected.svg'),
  'portrait-icon-selected': require('./assets/portrait-icon-selected.svg'),
  'icon-level-1': require('./assets/level-1.svg'),
  'icon-level-1-5': require('./assets/level-1-5.svg'),
  'icon-level-2': require('./assets/level-2.svg'),
  'icon-level-2-5': require('./assets/level-2-5.svg'),
  'icon-level-3': require('./assets/level-3.svg'),
  'icon-level-3-5': require('./assets/level-3-5.svg'),
  'icon-level-4': require('./assets/level-4.svg'),
  'new-building-icon': require('./assets/new-building-icon.svg'),
  'building-properties-icon': require('./assets/building-properties-icon.svg'),
  'environmental-parameters-icon': require('./assets/environmental-parameters-icon.svg'),
  'project-properties-icon': require('./assets/project-properties-icon.svg'),
  'site-equipment-icon': require('./assets/site-equipment-icon.svg'),
  'image-selection-check': require('./assets/image-selection-check.svg'),
  'list-item-check': require('./assets/list-item-check.svg'),
  'list-item-x': require('./assets/list-item-x.svg'),
  'doc-blue': require('./assets/doc-blue.svg'),
  'doc-check': require('./assets/doc-check.svg'),
  'doc-x': require('./assets/doc-x.svg'),
  'doc-preview': require('./assets/doc-preview.svg'),
  'angle_snap_disabled': require('./assets/angle_snap_disabled.svg'),
  'angle_snap_enabled': require('./assets/angle_snap_enabled.svg'),
  'angle_snap_selected': require('./assets/angle_snap_selected.svg'),
  'extension_lines_disabled': require('./assets/extension_lines_disabled.svg'),
  'extension_lines_enabled': require('./assets/extension_lines_enabled.svg'),
  'extension_lines_selected': require('./assets/extension_lines_selected.svg'),
  'midpoints_disabled': require('./assets/midpoints_disabled.svg'),
  'midpoints_enabled': require('./assets/midpoints_enabled.svg'),
  'midpoints_selected': require('./assets/midpoints_selected.svg'),
  'parallel_lines_disabled': require('./assets/parallel_lines_disabled.svg'),
  'parallel_lines_enabled': require('./assets/parallel_lines_enabled.svg'),
  'parallel_lines_selected': require('./assets/parallel_lines_selected.svg'),
  'perpendicular_alignment_disabled': require('./assets/perpendicular_alignment_disabled.svg'),
  'perpendicular_alignment_enabled': require('./assets/perpendicular_alignment_enabled.svg'),
  'perpendicular_alignment_selected': require('./assets/perpendicular_alignment_selected.svg'),
  'smart_line_disabled': require('./assets/smart_line_disabled.svg'),
  'smart_line_enabled': require('./assets/smart_line_enabled.svg'),
  'smart_line_selected': require('./assets/smart_line_selected.svg'),
  'smart_snap_disabled': require('./assets/smart_snap_disabled.svg'),
  'smart_snap_enabled': require('./assets/smart_snap_enabled.svg'),
  'smart_snap_selected': require('./assets/smart_snap_selected.svg'),
  'accordion-arrow-down': require('./assets/accordion-arrow-down.svg'),
  'accordion-arrow-left': require('./assets/accordion-arrow-left.svg'),
  'accordion-arrow-right': require('./assets/accordion-arrow-right.svg'),
  'accordion-arrow-up': require('./assets/accordion-arrow-up.svg'),
  'msp-icon': require('./assets/msp-icon.svg'),
  'sp-icon': require('./assets/sp-icon.svg'),
  'warning': require('./assets/warning.svg'),
  'error': require('./assets/error.svg'),
  'confirmation': require('./assets/confirmation.svg'),
  'shade-analysis-enabled': require('./assets/shade-analysis-enabled.svg'),
  'tool-solar-access-inspector': require('./assets/tool-solar-access-inspector.svg'),
  'smart-guide-disabled': require('./assets/smart-guide-disabled.svg'),
  'smart-guide-enabled': require('./assets/smart-guide-enabled.svg'),
  'dropdown-arrow-disabled': require('./assets/dropdown-arrow-disabled.svg'),
  'dropdown-arrow-enabled': require('./assets/dropdown-arrow-enabled.svg'),
  'close-icon-gray': require('./assets/close-icon-gray.svg'),
  'changes-pending': require('./assets/changes-pending.svg'),
  'save-failed': require('./assets/save-failed.svg'),
  'save-successful': require('./assets/save-successful.svg'),
  'engineer-of-record': require('./assets/icon-engineer-of-record.svg'),
  'property-boundaries': require('./assets/property-boundaries.svg')
};

const registry = new Registry(mappingIcons);

export { registry };
