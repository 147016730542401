import debounce from 'lodash/debounce';
import {
  action, computed, observable
} from 'mobx';
import type { Object3D } from 'three';
import type Store from '../../../Store';
import {
  handleApiError, notify
} from '../../../../utils/helpers';
import { isDesignWorkspaceObject } from '../../../../domain/mixins/WorkspaceTagged';
import type { RoofFace } from '../../../../domain/models/SiteDesign/RoofFace';
import type { IElectricalBosDependencies } from '../../../../domain/stages/DesignStages/ElectricalBosStage';
import { ElectricalBosStage } from '../../../../domain/stages/DesignStages/ElectricalBosStage';
import {
  LayoutDesignStage,
  type ILayoutDesignDependencies
} from '../../../../domain/stages/DesignStages/LayoutDesignStage';
import type { IStageManager } from '../../../../domain/stages/IStageManager';
import type { IWizardStager } from '../../../../domain/stages/IWizardStager';
import type { StageFactoryParameters } from '../../../../domain/stages/StageFactory';
import { StageManager } from '../../../../domain/stages/StageManager';
import type DomainStore from '../../../DomainStore/DomainStore';
import type EditorStore from '../../../EditorStore/EditorStore';
import type { PanelsStore } from '../../Panels/Panels';
import type { PropertiesStore } from '../../Properties/Properties';
import type { ServiceBus } from '../../../ServiceBus/ServiceBus';
import { canvasConfig } from '../../../../config/canvasConfig';
import type { ModalStore } from '../../Modal/Modal';
import { BillOfMaterialsViewModel } from '../../Modal/ViewModels/BillOfMaterials/BillOfMaterialsViewModel';
import { ConceptDesignOptionViewModel } from '../../Modal/ViewModels/ConceptDesignOption/ConceptDesignOptionViewModel';
import { SystemDatasheetOptionViewModel } from '../../Modal/ViewModels/SystemDatasheetOption/SystemDatasheetOptionViewModel';
import type SmartGuidesStore from '../../SmartGuidesStore/SmartGuidesStore';
import type { ToolbarStore } from '../../ToolbarStore/Toolbar';
import { Stringing } from '../../../../domain/graphics/stringing/Stringing';
import type { DesignCreationWizardStore } from '../../Wizard/DesignCreationWizardStore';
import { SceneObjectType } from '../../../../domain/models/Constants';
import type { PolygonDrawable } from '../../../../domain/mixins/PolygonDrawable';
import {
  getLyraModelByMesh, getParentLyraModelByMesh
} from '../../../../domain/sceneObjectsWithLyraModelsHelpers';
import { isCurrentWorkspace } from '../utils';
import type { UiStore } from '../../UiStore';
import type { IProgressStepperStage } from '../../../../domain/stages/IProgressStepperStage';
import type { IWizardStage } from '../../../../domain/stages/IWizardStage';
import {
  type IArrayPlacementDependencies,
  ArrayPlacementStage
} from '../../../../domain/stages/DesignStages/ArrayPlacementStage';
import {
  type ICreationDesignDependencies,
  CreationDesignStage
} from '../../../../domain/stages/DesignStages/CreationDesignStage';
import {
  type IElectricalDesignDependencies,
  ElectricalDesignStage
} from '../../../../domain/stages/DesignStages/ElectricalDesignStage';
import {
  type IMountingBosDependencies,
  MountingBosStage
} from '../../../../domain/stages/DesignStages/MountingBosStage';
import { DefaultWorkspace } from './DefaultWorkspace';

export class DesignWorkspace extends DefaultWorkspace {
  override readonly id: string = 'design';
  override readonly toolbarRegistry: string = 'toolbar_design';
  override readonly defaultToolsWhiteList: string[] = [];

  @observable
  override stageManager?: IStageManager<IProgressStepperStage>;

  @observable
  override wizard?: IWizardStager<IWizardStage>;

  private readonly designCreationWizardStore: DesignCreationWizardStore;
  @observable
  private readonly domain: DomainStore;
  private readonly editor: EditorStore;
  private readonly properties: PropertiesStore;
  private readonly serviceBus: ServiceBus;
  private readonly toolbar: ToolbarStore;
  private readonly modal: ModalStore;
  private readonly guidelines: SmartGuidesStore;
  @observable
  autoSaveCommandsCounter: number = 0;
  private readonly autoSaveMaxCommandsCounter: number = 10;
  private readonly debounceSave: () => void;
  private readonly panels: PanelsStore;
  private systemDesignStarted: Promise<void>;
  private resolveSystemDesignStarted?: () => void;

  constructor(root: Store, uiStore: UiStore) {
    super();
    this.designCreationWizardStore = uiStore.designCreationWizardStore;
    this.domain = root.domain;
    this.editor = root.editor;
    this.properties = uiStore.properties;
    this.serviceBus = root.serviceBus;
    this.toolbar = uiStore.toolbar;
    this.guidelines = uiStore.smartGuides;
    this.panels = uiStore.panels;
    this.modal = uiStore.modal;

    this.editor.addEventListener('canvasReady', this.setup);
    this.debounceSave = debounce((): void => {
      if (this.autoSaveCommandsCounter !== 0 && !localStorage.disableAutoSave) {
        this.persist();
      }
    }, canvasConfig.autosaveDebounce);

    this.systemDesignStarted = new Promise((resolve: () => void) => {
      this.resolveSystemDesignStarted = resolve;
    });
  }

  @computed
  get isUnsavedState(): boolean {
    return this.autoSaveCommandsCounter <= this.autoSaveMaxCommandsCounter;
  }
  // When navigating through the stages, a function that updates the design is triggered
  // and the counter becomes 1. I.e. it seems as if one action has been done.
  @computed
  get isSavedState(): boolean {
    return this.autoSaveCommandsCounter <= 1;
  }

  setup = (): void => {
    this.guidelines.enable = false;
    this.editor.onWorkspaceSwitch();
    this.serviceBus.addEventListener('commandExecuted', this.onCommandExecuted);
  };

  async dispose(): Promise<void> {
    await this.systemDesignStarted;
    // Dispose all the current stages
    this.stageManager?.clear();
    // Remove all the design workspaces objects
    this.removeDesignObjects();
    this.serviceBus.removeEventListener('commandExecuted', this.onCommandExecuted);
  }

  async saveManually(): Promise<void> {
    await this.persist();
    this.resetCounter();
  }

  async canContinue(): Promise<boolean> {
    return true;
  }

  onCommandExecuted = (): void => {
    this.save();
  };

  @action.bound
  setWizardStagerToWorkspace(wizard: IWizardStager<IWizardStage>): void {
    this.wizard = wizard;
  }

  openConceptDesignModal = (): void => {
    const conceptDesignViewModel = new ConceptDesignOptionViewModel({
      modal: this.modal,
      domain: this.domain,
      workspace: this,
      project: this.domain.project,
      editor: this.editor
    });
    this.modal.createModal('concept_design_option_modal', conceptDesignViewModel);
  };

  downloadConceptDesignWithDefaultValues = async (): Promise<void> => {
    const viewModel = new ConceptDesignOptionViewModel({
      modal: this.modal,
      domain: this.domain,
      workspace: this,
      project: this.domain.project,
      editor: this.editor
    });

    await viewModel.defaultValuesOptionsPromise;
    notify('The Concept Design is downloading', 'icon-download');
    viewModel.downloadConceptDesign();
  };

  openBillOfMaterialsModal = (): void => {
    const viewModel = new BillOfMaterialsViewModel({
      modal: this.modal,
      domain: this.domain,
      editor: this.editor
    });
    this.modal.createModal('bill_of_materials_modal', viewModel);
  };

  downloadBillOfMaterialsWithDefaultValuesAndType = (): void => {
    const viewModel = new BillOfMaterialsViewModel({
      modal: this.modal,
      domain: this.domain,
      editor: this.editor
    });

    notify('The Bill Of Materials is downloading', 'icon-download');
    viewModel.downloadBillOfMaterialsDocument();
  };

  openSystemDataSheetModal = (): void => {
    const viewModel = new SystemDatasheetOptionViewModel({
      workspace: this,
      modal: this.modal,
      domain: this.domain,
      editor: this.editor
    });
    this.modal.createModal('system_datasheet_option_modal', viewModel);
  };

  downloadSystemDataSheetWithDefaultValues = async (): Promise<void> => {
    const viewModel = new SystemDatasheetOptionViewModel({
      workspace: this,
      modal: this.modal,
      domain: this.domain,
      editor: this.editor
    });

    await viewModel.defaultValuesOptionsPromise;
    notify('The system Datasheet is downloading', 'icon-download');
    viewModel.downloadSystemDatasheet();
  };

  async startSystemDesign(): Promise<void> {
    // Note: this is a naive implementation - we always reload the Design just in case a Project has been updated.
    // A smarter implementation could track Project changes and only reload the Design when Project has changed.
    await this.updateDesignInCaseProjectDataHasChanged();
    if (isCurrentWorkspace('design')) {
      this.startStageManager();
    }
    this.guidelines.unSelectGuides();
    this.resolveSystemDesignStarted?.();
  }

  private removeDesignObjects(): void {
    const designObjects = this.editor.getObjectsByCondition(isDesignWorkspaceObject);
    designObjects.forEach((object3D: Object3D): void => {
      const model = getLyraModelByMesh(object3D);
      const parent = getParentLyraModelByMesh<PolygonDrawable>(object3D);
      parent.mesh?.remove(object3D);
      // Apparently, strings' parent is Scene object, which doesn't have a mesh
      this.editor.removeObject(object3D);
      parent.removeChildFromModel?.(object3D);
      if (model instanceof Stringing) {
        model.removeLabels();
      }
    });

    const allRoofFaces: RoofFace[] = this.editor.getObjectsByType(SceneObjectType.RoofFace, true);
    allRoofFaces.forEach((roof: RoofFace): void => roof.setRoofFaceIsPolygon());

    this.editor.viewport.render();
  }

  private async persist(): Promise<void> {
    this.saving = true;
    this.inProgress = false;
    this.errors.clear();
    try {
      this.serviceBus.startQueue();
      await this.stageManager?.currentStage?.awaitCompletionOfPendingChanges?.();
      await this.domain.updateDesign();
      this.serviceBus.processQueue();
      this.resetCounter();
    } catch (error) {
      this.errors.push(error as Error);
      handleApiError('Updating design workspace failed', {
        domainStoreJson: this.domain.toJson()
      })(error);
    } finally {
      this.saving = false;
    }
  }

  private save = async (): Promise<void> => {
    let shouldSave: boolean = true;
    if (this.stageManager?.currentStage instanceof LayoutDesignStage) {
      if (!this.stageManager.currentStage.arePositionsCollisionFree()) {
        shouldSave = false;
      }
    }
    if (shouldSave) {
      this.count();
      this.debounceSave();
      if (this.autoSaveCommandsCounter >= this.autoSaveMaxCommandsCounter && !localStorage.disableAutoSave) {
        this.resetCounter();
        await this.persist();
      }
    }
  };

  private count(): void {
    this.autoSaveCommandsCounter++;
    this.inProgress = true;
    this.saving = false;
  }

  private resetCounter(): void {
    this.autoSaveCommandsCounter = 0;
  }

  private async updateDesignInCaseProjectDataHasChanged(): Promise<void> {
    this.removeDesignObjects();
    await this.domain.loadDesign(this.domain.project.id);
  }

  private startStageManager(): void {
    const preCreationDesignConstructor: StageFactoryParameters<ICreationDesignDependencies, IProgressStepperStage> = {
      c: CreationDesignStage,
      dependencies: {
        designCreationWizardStore: this.designCreationWizardStore,
        domain: this.domain,
        designWorkspace: this,
        serviceBus: this.serviceBus,
        guidelines: this.guidelines
      }
    };
    const arrayPlacementConstructor: StageFactoryParameters<IArrayPlacementDependencies, IProgressStepperStage> = {
      c: ArrayPlacementStage,
      dependencies: {
        domain: this.domain,
        editor: this.editor,
        designWorkspace: this,
        serviceBus: this.serviceBus,
        toolbar: this.toolbar,
        properties: this.properties,
        modal: this.modal
      }
    };
    const layoutStageConstructor: StageFactoryParameters<ILayoutDesignDependencies, IProgressStepperStage> = {
      c: LayoutDesignStage,
      dependencies: {
        domain: this.domain,
        editor: this.editor,
        designWorkspace: this,
        serviceBus: this.serviceBus,
        toolbar: this.toolbar,
        guidelines: this.guidelines
      }
    };
    const electricalStageConstructor: StageFactoryParameters<IElectricalDesignDependencies, IProgressStepperStage> = {
      c: ElectricalDesignStage,
      dependencies: {
        domain: this.domain,
        editor: this.editor,
        designWorkspace: this,
        serviceBus: this.serviceBus,
        toolbar: this.toolbar,
        modal: this.modal,
        panels: this.panels
      }
    };
    const electricalBosStageConstructor: StageFactoryParameters<IElectricalBosDependencies, IProgressStepperStage> = {
      c: ElectricalBosStage,
      dependencies: {
        domain: this.domain,
        editor: this.editor,
        designWorkspace: this,
        serviceBus: this.serviceBus,
        toolbar: this.toolbar,
        modal: this.modal,
        panels: this.panels,
        guidelines: this.guidelines
      }
    };
    const mountingBosStageConstructor: StageFactoryParameters<IMountingBosDependencies, IProgressStepperStage> = {
      c: MountingBosStage,
      dependencies: {
        domain: this.domain,
        editor: this.editor,
        designWorkspace: this,
        serviceBus: this.serviceBus,
        toolbar: this.toolbar,
        modal: this.modal
      }
    };

    this.stageManager = new StageManager<IProgressStepperStage>({
      stageFactoryParameters: [
        preCreationDesignConstructor,
        arrayPlacementConstructor,
        layoutStageConstructor,
        electricalStageConstructor,
        electricalBosStageConstructor,
        mountingBosStageConstructor
      ],
      designWorkspace: this,
      stageManagerName: 'designStages'
    });

    if (this.domain.optionalDesign) {
      this.stageManager.recoverLastState();
    }
  }
}
