import { LyraFontFamily } from '@aurorasolar/lyra-ui-kit';
import type { PropsTheme } from 'styled-components';
import styled from 'styled-components';
import type { Color } from '../../../../domain/typings';
import Flexbox from '../../../../ui/components/Layout/Flexbox';
import type { PropsWithTheme } from '../../../../ui/components/Button/styles';

const {
  H4, Notes
} = LyraFontFamily.FontFamily;

type Props = {
  align?: string;
};

type DataInputProps = PropsTheme & {
  error?: boolean;
};

export const ModalWrapper = styled.div`
  width: 100%;
  height: 600px;
  margin-top: 10px;
`;

export const SummaryHeader = styled(Flexbox).attrs({
  justify: 'center',
  align: 'center'
})`
  height: 30px;
  border-radius: 2px;
  background-color: ${({ theme }: PropsWithTheme): Color => theme.colors.grayOpacity1};
  margin-top: 20px;
  width: 100%;
`;

export const SummaryText = styled(Notes)`
  font-weight: bold;
  text-align: center;
  font-size: 14px;
`;

export const SeparatorLine = styled.div`
  height: 1px;
  background-color: rgba(107, 111, 113, 0.25);
`;

export const VoltageDropView = styled.div`
  display: flex;
  justify-content: space-between;
  width: 450px;
`;

export const VoltageDropText = styled(H4)`
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  line-height: 0.94;
  margin-top: 11px;
  margin-left: 5px;
`;

export const Table = styled.div`
  width: 100%;
  display: inline-table;
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
  font-weight: bold;
`;

export const TableWrapper = styled.div`
  width: 100%;
  overflow-x: auto;
`;

export const TableHeaderWrapper = styled.div`
  display: table-row;
  width: 100%;
`;

export const HeaderItem = styled.div<Props>`
  background: ${({ theme }: PropsWithTheme): Color => theme.colors.grayOpacity2};
  border-radius: 2px;
  display: table-cell;
  vertical-align: middle;
  padding: 10px;
  text-align: ${({ align }: Props): string => align ?? 'center'};
  border: 1px solid white;
`;

export const TableRowWrapper = styled.div`
  display: table-row;
  width: 100%;
  margin-top: 1px;
`;

export const RowItem = styled.div`
  display: table-cell;
  vertical-align: middle;
  margin: 5px;
  padding: 10px;
  text-align: center;
  border-bottom: 1px solid ${({ theme }: PropsWithTheme): Color => theme.colors.grayOpacity3};
`;

export const PlainText = styled.div<Props>`
  font-size: 11px;
  text-align: ${({ align }: Props): string => align ?? 'center'};
  font-weight: normal;
`;

export const DataSelect = styled.select`
  height: 22px;
  border-radius: 4px;
  border: solid 1px ${({ theme }: PropsWithTheme): Color => theme.colors.tertiary};
  background-color: ${({ theme }: PropsWithTheme): Color => theme.colors.highlight};
  font-size: 11px;
  color: ${({ theme }: PropsWithTheme): Color => theme.colors.gray1};
  &:disabled,
  [disabled] {
    cursor: not-allowed;
    color: ${({ theme }: PropsWithTheme): Color => theme.colors.gray2};
    background-color: ${({ theme }: PropsWithTheme): Color => theme.colors.gray3};
  }
`;

export const DataInput = styled.input<DataInputProps>`
  width: 100%;
  height: 22px;
  padding: 0 0 0 6px;
  font-size: 11px;
  color: #4a4a4a;
  border-radius: 4px;
  border: solid 1px
    ${({
    theme, error
  }: DataInputProps): Color => (error ? theme.colors.redColor1 : theme.colors.tertiary)};
  background-color: ${({
    theme, error
  }: DataInputProps): Color =>
    error ? theme.colors.redColor2 : theme.colors.highlight};
  margin: 0;
  &:disabled,
  [disabled] {
    cursor: not-allowed;
    color: ${({ theme }: PropsWithTheme): Color => theme.colors.gray2};
    background-color: ${({ theme }: PropsWithTheme): Color => theme.colors.gray3};
  }
`;
