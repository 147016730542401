import {
  action, observable
} from 'mobx';
import SitePlan, { type ISitePlanData } from './SitePlan';
import SitePlacards, { type ISitePlacardsData } from './SitePlacards';
import FireSafetyPlan, { type IFireSafetyPlanData } from './FireSafetyPlan';
import AttachmentPlan, { type IAttachmentPlanData } from './AttachmentPlan';
import ElectricalPlan, { type IElectricalPlanData } from './ElectricalPlan';
import TitleBlock, { type ITitleBlockData } from './TitleBlock';

export interface IPermitPackageAdvancedSettingsData {
  readonly sitePlan: ISitePlanData;
  readonly includeDebugFiles: boolean;
  readonly includeElectricalCalculations: boolean;
  readonly sitePlacards: ISitePlacardsData;
  readonly attachmentPlan: IAttachmentPlanData;
  readonly electricalPlan: IElectricalPlanData;
  readonly fireSafetyPlan: IFireSafetyPlanData;
  readonly titleBlock: ITitleBlockData;
}

export default class PermitPackageAdvancedSettings {
  @observable
  includeDebugFiles: boolean;

  @observable
  includeElectricalCalculations: boolean;

  @observable
  sitePlacards: SitePlacards;

  @observable
  attachmentPlan: AttachmentPlan;

  @observable
  fireSafetyPlan: FireSafetyPlan;

  @observable
  sitePlan: SitePlan;

  @observable
  electricalPlan: ElectricalPlan;

  @observable
  titleBlock: TitleBlock;

  constructor(data: IPermitPackageAdvancedSettingsData) {
    this.includeDebugFiles = data.includeDebugFiles;
    this.includeElectricalCalculations = data.includeElectricalCalculations;
    this.titleBlock = new TitleBlock(data.titleBlock);
    this.sitePlan = new SitePlan(data.sitePlan);
    this.electricalPlan = new ElectricalPlan(data.electricalPlan);
    this.sitePlacards = new SitePlacards(data.sitePlacards);
    this.attachmentPlan = new AttachmentPlan(data.attachmentPlan);
    this.fireSafetyPlan = new FireSafetyPlan(data.fireSafetyPlan);
  }

  @action.bound
  setIncludeElectricalCalculations(value: boolean): void {
    this.includeElectricalCalculations = value;
  }

  toData(): IPermitPackageAdvancedSettingsData {
    return {
      includeDebugFiles: this.includeDebugFiles,
      includeElectricalCalculations: this.includeElectricalCalculations,
      sitePlacards: this.sitePlacards.toData(),
      attachmentPlan: this.attachmentPlan.toData(),
      fireSafetyPlan: this.fireSafetyPlan.toData(),
      sitePlan: this.sitePlan.toData(),
      electricalPlan: this.electricalPlan.toData(),
      titleBlock: this.titleBlock.toData()
    };
  }
}
