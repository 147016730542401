import { LyraCard } from '@aurorasolar/lyra-ui-kit';
import styled from 'styled-components';

export const ElectricalDesignCard = styled(LyraCard.Card)`
  width: auto;
  padding: 15px 0;
`;

export const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CardRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  &:nth-child(2n) {
    margin-top: 15px;
  }
`;

export const Quantity = styled.div`
  height: 28px;
  border-radius: 4px;
  border: solid 1px #cccccc;
  background-color: #f2f2f2;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 44px;
  margin-right: 15px;
`;

export const QuantityValue = styled.span`
  font-family: 'Roboto', sans-serif;
  font-size: 24px;
  text-align: center;
  color: #4a4a4a;
`;

export const Description = styled.div`
  width: max-content;
  display: flex;
  flex-direction: column;
`;

export const DescriptionName = styled.span`
  font-family: 'Roboto', sans-serif;
  font-size: 13px;
  font-weight: bold;
  color: #4a4a4a;
  white-space: nowrap;
  margin-top: 4px;
`;
