import React, { BaseProps, ReactElement } from 'react';
import { Area } from '../Grid';
import { ASIDE } from './constants';
import { FlexBG } from './FlexBG';

const Aside = ({ children, className }: BaseProps): ReactElement => {
  return (
    <Area name={ASIDE} className={className}>
      <FlexBG
        dir="column"
        align="center"
        justify="normal"
        fullHeight
      >
        {children}
      </FlexBG>
    </Area>
  );
};

export { Aside };
