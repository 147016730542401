import React, {
  PropsWithChildren,
  ReactElement, useEffect, useState
} from 'react';
import {
  TooltipConternt, TooltipStyle, TooltipWrapper, Arrow
} from './styles';

interface ITooltipProps {
  parentWidth?: number;
  step?: number;
  totalSteps?: number;
  tooltipContentAlign?: string;
  tooltipTheme?: 'white' | 'dark';
  tooltipPosition?: 'top' | 'bottom';
  arrowPosition?: 'top' | 'bottom';
  tooltipVisibility?: boolean;
}

const Tooltip = (props: PropsWithChildren<ITooltipProps>): ReactElement => {
  const {
    step,
    parentWidth,
    totalSteps,
    children,
    tooltipContentAlign,
    tooltipTheme = 'white',
    arrowPosition = 'bottom',
    tooltipPosition = 'top',
    tooltipVisibility = false
  } = props;
  const [arrowAlignmentStyle, setArrowAlignmentStyle] = useState(0);

  useEffect((): void => {
    if (step !== undefined && parentWidth && totalSteps) {
      const baseWidth = parentWidth * ((step - 1) / totalSteps);
      const halfWidthByStep = ((1 / totalSteps) * parentWidth) / 2;

      setArrowAlignmentStyle(baseWidth + halfWidthByStep);
    }
  }, [totalSteps, step, parentWidth]);

  return (
    <TooltipWrapper
      tooltipPosition={tooltipPosition}
      tooltipVisibility={tooltipVisibility}
      tooltipContentAlign={tooltipContentAlign}
    >
      <TooltipStyle
        tooltipTheme={tooltipTheme}
      >
        <TooltipConternt>
          {children}
        </TooltipConternt>
        <Arrow
          arrowPosition={arrowPosition}
          arrowStyles={arrowAlignmentStyle}
          className="arrow"
        />
      </TooltipStyle>
    </TooltipWrapper>
  );
};

export { Tooltip };
