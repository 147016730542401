import { css, PropsTheme } from 'styled-components';
import { Color } from '../../typings';

const grayColor = css`
  ${({ theme }: PropsTheme): Color => theme.colors.grayColor9};
`;


const Tooltip = css`
/* Add this attribute to the element that needs a tooltip */
[data-lyra-tooltip] {
  position: relative;
  cursor: pointer;
}

/* Hide the tooltip content by default */
[data-lyra-tooltip]:before,
[data-lyra-tooltip]:after {
  visibility: hidden;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 0;
  pointer-events: none;
  z-index:100;
}

[data-lyra-tooltip].tooltip-top {
  &:before {
    top: -10px;
    box-shadow: 3px 2px 10px rgba(0, 0, 0, 0.2);
    margin-bottom: 5px;
    transform: translateY(-100%);
  }
  &:after {
    transform: translate(-50%, -100%);
    top: -6px;
    left: 50%;
  }
}

[data-lyra-tooltip].tooltip-right {
  &:before {
    right: -10px;
    top: 50%;
    transform: translate(100%, -50%);
    box-shadow: 3px 2px 10px rgba(0, 0, 0, 0.2);
    margin-left: 7px;
  }

  &:after {
    top: 50%;
    right: -3px;
    transform: translate(100%, -50%) rotate(90deg);
  }
}

[data-lyra-tooltip].tooltip-left {
  &:before {
    left: -6px;
    top: 50%;
    transform: translate(-100%, -50%);
    box-shadow: 3px 2px 10px rgba(0, 0, 0, 0.2);
    margin-right: 7px;
  }

  &:after {
    top: 50%;
    transform: translate(-100%, -50%) rotate(-90deg);
    left: 0px;
  }
}

[data-lyra-tooltip].tooltip-bottom {
  &:before {
    bottom: -10px;
    box-shadow: 3px 2px 10px rgba(0, 0, 0, 0.2);
    margin-top: 5px;
    transform: translateY(100%);
  }
  &:after {
    transform: translate(-50%, 100%) rotate(180deg);
    left: 50%;
    bottom: -6px;
  }
}

/* Position tooltip above the element */
[data-lyra-tooltip]:before {
  position: absolute;
  padding: 7px;
  width: ${({ auroraMode }: PropsTheme & { auroraMode?: boolean }): string => auroraMode ? '200' : '176'}px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  background-color: #FFFFFF;
  border: .5px solid #F6F6F6;
  content: attr(data-lyra-tooltip);
  text-align: center;
  font-size: ${({ auroraMode }: PropsTheme & { auroraMode?: boolean }): string => auroraMode ? '10' : '12'}px;
  line-height: 1.5;
  letter-spacing: normal;
  color: ${grayColor};
}

/* Triangle hack to make tooltip look like a speech bubble */
[data-lyra-tooltip]:after {
  position: absolute;
  width: 0;
  border-top: 5px solid #fff;
  border-right: 5px solid transparent;
  border-left: 5px solid transparent;
  content: "";
  font-size: 0;
  line-height: 0;
}

/* Show tooltip content on hover */
[data-lyra-tooltip]:hover:before,
[data-lyra-tooltip]:hover:after {
  visibility: visible;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1;
}

[data-lyra-tooltip-with-delay]:before,
[data-lyra-tooltip-with-delay]:after {
  transition-delay: 0s;
}

[data-lyra-tooltip-with-delay]:hover:before,
[data-lyra-tooltip-with-delay]:hover:after {
  transition-delay: 0.5s;
}
`;

export { Tooltip };
