import styled, { withTheme } from 'styled-components';
import { Icon } from '../../Icon';

export type IconProps = { width?: string; height?: string; cursor?: string };

export const Cell = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

export const RegularLabel = styled.h4`
  font-family: Roboto;
  font-size: 12px;
  font-weight: 400;
  line-height: 1;
  color: #4a4a4a;
  &.mt-xxxs { 
    font-size: 10px;
  }
`;

export const BoldLabel = styled(RegularLabel)`
  font-size: 18px;
  font-weight: bold;
`;

export const StyledIcon = styled(Icon)<IconProps>`
  ${({ height, width }: IconProps): string =>
    !width && !height ? '' : `height: ${height ? height : width};`};
  ${({ width, height }: IconProps): string =>
    !width && !height ? '' : `width: ${width ? width : height};`};

  &:hover {
    cursor: ${({ cursor = 'default' }: IconProps): string => cursor};
  }
`;
