import {
  LyraButtons, LyraDivider, LyraTable
} from '@aurorasolar/lyra-ui-kit';
import { EButtonType } from '@aurorasolar/lyra-ui-kit/lib/components/Buttons/EButtonType';
import { EThemeComponentColor } from '@aurorasolar/lyra-ui-kit/lib/components/Theme/EThemeComponentColor';
import { observer } from 'mobx-react-lite';
import type { ReactElement } from 'react';
import React, {
  useCallback, useEffect, useState
} from 'react';
import type { ArrayPlacementStage } from '../../../domain/stages/DesignStages/ArrayPlacementStage';
import { stageIs } from '../../../domain/stages/DesignStages/utils';
import type { IStage } from '../../../domain/stages/IStage';
import useStore from '../../../stores/useStore';
import type { DesignWorkspace } from '../../../stores/UiStore/WorkspaceStore/workspaces/DesignWorkspace';
import { isDesignWorkspace } from '../../../stores/UiStore/WorkspaceStore/utils';
import PropertyPanelHeader from '../PropertyPanel/PropertyPanelHeader/PropertyPanelHeader';
import { LayoutStrategyCard } from '../../components/SystemDesign/LayoutStrategy/LayoutStrategyCard';
import { ModuleCard } from '../../components/SystemDesign/Module/ModuleCard';
import { sectionTitle } from '../../components/SystemDesign/SectionTitle';
import { MountingSystemDefinitionsCard } from '../../components/SystemDesign/MountingSystemDefinitionsCard';
import {
  PropertyPanelContainer,
  PropertyPanelWrapper,
  SubmitSectionWrapper,
  PropertyContentWrapper
} from '../PropertyPanel/styles';
import { DesignStep } from '../../../domain/models/Design/DesignState';
import { Workspace } from '../../../stores/UiStore/WorkspaceStore/index';
import config, { UI_MODE } from '../../../config/config';
import {
  ArrayPlacementProgressBar, ArrayTableWrapper, NextStepWrapper
} from './styles';

function isArrayPlacementStage(stage: IStage | undefined): boolean {
  if (stage) {
    return stageIs<ArrayPlacementStage>(stage, DesignStep.ARRAY_PLACEMENT);
  }
  return false;
}

const ArrayPlacement: React.FC = (): ReactElement | null => {
  const { workspace } = useStore();
  const [designWorkspace, setDesignWorkspace] = useState<DesignWorkspace | null>(null);
  const [arrayPlacementStage, setArrayPlacementStage] = useState<ArrayPlacementStage | null>(null);

  useEffect((): void => {
    if (isDesignWorkspace(workspace.currentWorkspace)) {
      setDesignWorkspace(workspace.currentWorkspace as DesignWorkspace);
    } else {
      setDesignWorkspace(null);
    }
  }, [workspace.currentWorkspace]);

  useEffect((): void => {
    if (designWorkspace) {
      const currentStage = designWorkspace.stageManager?.currentStage;
      const stage = isArrayPlacementStage(currentStage) ? (currentStage as ArrayPlacementStage) : null;

      setArrayPlacementStage(stage);
    } else {
      setArrayPlacementStage(null);
    }
  }, [designWorkspace]);

  const handleEditClick = useCallback((): void => {
    if (arrayPlacementStage) {
      arrayPlacementStage.editTargetSystemSize();
    }
  }, [arrayPlacementStage]);

  const handleEditModule = useCallback((): void => {
    if (arrayPlacementStage) {
      arrayPlacementStage.editPvModuleDefinition();
    }
  }, [arrayPlacementStage]);

  const handleItemDrag = useCallback(
    (rows: LyraTable.TableRowDataProps[]): void => {
      if (!arrayPlacementStage) {
        return;
      }

      const arrayAreaIds = rows.map((item: LyraTable.TableRowDataProps): string => item.data.arrayAreaId);
      arrayPlacementStage.updateDataTableArea(arrayAreaIds);
    },
    [arrayPlacementStage]
  );

  const handleItemClick = useCallback(
    (row: LyraTable.TableRowDataProps): void => {
      if (!arrayPlacementStage) {
        return;
      }
      arrayPlacementStage.onClickLayoutStrategyTable(row.data.arrayAreaId, row.data.roofFace);
    },
    [arrayPlacementStage]
  );

  const pvModuleInfo = arrayPlacementStage?.domainModel?.supplementalData.pvModuleInfo;

  return designWorkspace && arrayPlacementStage ? (
    <PropertyPanelWrapper>
      <PropertyPanelContainer>
        <PropertyPanelHeader icon="tool-properties" name="Array Placement" iconBg="#427ecd" />
      </PropertyPanelContainer>

      <PropertyContentWrapper>
        <ModuleCard pvModuleInfo={pvModuleInfo} onclick={handleEditModule} editable={true} />
        <LyraDivider.Divider direction="horizontal" color={EThemeComponentColor.GRAY_200} />
        <MountingSystemDefinitionsCard arrayPlacementStage={arrayPlacementStage} />
        <LyraDivider.Divider direction="horizontal" color={EThemeComponentColor.GRAY_200} />
        <LayoutStrategyCard arrayPlacementStage={arrayPlacementStage} />
        <LyraDivider.Divider direction="horizontal" color={EThemeComponentColor.GRAY_200} />
        <ArrayPlacementProgressBar
          value={arrayPlacementStage.totalTargetSizeInKw}
          percentRange={arrayPlacementStage.currentTargetSize}
          onEditClick={handleEditClick}
          label="Target Size"
          units="kW"
          minProgressBar={0}
          fractionDigits={2}
        />
        <LyraDivider.Divider direction="horizontal" color={EThemeComponentColor.GRAY_200} />
        <ArrayTableWrapper>
          <div className="mb-xxs">{sectionTitle('PRIORITY ORDER')}</div>
          <LyraTable.LayoutStrategyTable
            draggable={true}
            unit={arrayPlacementStage.unit}
            data={arrayPlacementStage.layoutsTableData}
            indexFieldName="arrayAreaId"
            onItemDragEnd={handleItemDrag}
            onItemClick={handleItemClick}
          />
        </ArrayTableWrapper>
      </PropertyContentWrapper>

      {config.featureFlag.uiMode !== UI_MODE.AURORA && (
        <SubmitSectionWrapper>
          <NextStepWrapper xs={12} className="pv-xs">
            <LyraButtons.Buttons
              className="back-button"
              styleType="flat"
              colorTheme={EThemeComponentColor.TRANSPARENT}
              fontColorTheme={EThemeComponentColor.AQUAMARINE}
              isLeft
              onClick={(): void => workspace.changeWorkspace(Workspace.Project)}
            >
              <b>Site Definition</b>
            </LyraButtons.Buttons>

            <LyraButtons.ButtonIcon
              icon="arrow"
              type={EButtonType.ROUNDED_BUTTON}
              colorTheme={EThemeComponentColor.AQUAMARINE}
              withIconTheme
              onClick={(): void => {
                designWorkspace.stageManager?.next();
              }}
            >
              <b>Layout Design</b>
            </LyraButtons.ButtonIcon>
          </NextStepWrapper>
        </SubmitSectionWrapper>
      )}
    </PropertyPanelWrapper>
  ) : null;
};

export default observer(ArrayPlacement);
