import type { PropsTheme } from 'styled-components';
import styled, { css } from 'styled-components';
import type { Color } from '../../../../domain/typings';

const ButtonStylesMixin = css`
  width: 26px;
  height: 25px;
  border-radius: 4px;
  border: solid 1px ${({ theme }: PropsTheme): Color => theme.colors.newGray1};
  background-color: ${({ theme }: PropsTheme): Color => theme.colors.whiteBg};
`;

const DisabledMixinStyle = css`
  cursor: not-allowed;
  opacity: 0.35;
`;
export const Container = styled.div`
  position: absolute;
  width: 310px;
  height: 238px;
  border: solid 1px ${({ theme }: PropsTheme): Color => theme.colors.gray2};
  background-color: ${({ theme }: PropsTheme): Color => theme.colors.grayColor7};
  z-index: 2;
  padding: 15px 15px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:after,
  &:before {
    top: 100%;
    left: calc(50% - 15px);
    border: solid 1px ${({ theme }: PropsTheme): Color => theme.colors.gray2};
    content: ' ';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }
  &:after {
    border-color: rgba(136, 183, 213, 0);
    border-top-color: solid ${({ theme }: PropsTheme): Color => theme.colors.grayColor7};
    border-width: 10px;
  }
`;

export const CloseButton = styled.button`
  ${ButtonStylesMixin};
  cursor: pointer;
  position: absolute;
  z-index: 5;
  right: 15px;
  top: 15px;
  box-shadow: 0px 1px 3px 0px ${({ theme }: PropsTheme): Color => theme.colors.gray2};
  &:hover {
    box-shadow: 0px 1px 4px 1px ${({ theme }: PropsTheme): Color => theme.colors.gray1};
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
`;

export const MainInverterContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-y: auto;
`;

export const Title = styled.span`
  font-family: 'Roboto', sans-serif;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${({ theme }: PropsTheme): Color => theme.colors.grey2};
  margin: 10px 0 15px;
`;

export const Border = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  height: 1px;
  background-color: ${({ theme }: PropsTheme): Color => theme.colors.newGray1};
`;

export const InverterContainer = styled.div`
  display: flex;
  flex-direction: row;
  min-height: 60px;
  padding: 10px 0;
  & > .left-side {
    display: flex;
    flex-direction: row;
    align-items: flex-start !important;
    justify-content: center;
    height: 100%;
    > div {
      position: relative;
      left: 3px;
      top: 15px;
      box-shadow: 0px 1px 3px 0px ${({ theme }: PropsTheme): Color => theme.colors.gray2};
      border-radius: 100%;
      &:hover {
        cursor: pointer;
        box-shadow: 0px 1px 4px 1px ${({ theme }: PropsTheme): Color => theme.colors.gray1};
      }
    }
  }
  & > .right-side {
    padding-left: 15px;
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  &.disabled {
    ${DisabledMixinStyle};
    & > .left-side {
      > div:hover {
        cursor: not-allowed;
      }
    }
  }
`;

export const InverterTitle = styled.span`
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${({ theme }: PropsTheme): Color => theme.colors.grey2};
  padding-bottom: 5px;
`;

export const InverterDescription = styled.span`
  font-family: 'Roboto', sans-serif;
  font-size: 13px !important;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${({ theme }: PropsTheme): Color => theme.colors.grey1};
  padding-bottom: 3px;
`;

export const InverterData = styled.span`
  font-family: 'Roboto', sans-serif;
  font-size: 11px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: ${({ theme }: PropsTheme): Color => theme.colors.grey1};
`;

export const CircleSelect = styled.div`
  width: 15px;
  height: 15px;
  border: solid 0.8px rgba(74, 74, 74, 0.25);
  border-radius: 15px;
`;

export const SecondSlide = styled.div<{ show: boolean }>`
  width: 100%;
  height: 100%;
  transform: translateX(130%);
  transition-timing-function: ease-in;
  transition: 0.2s;
  position: absolute;
  opacity: 0;
  ${({ show }: { show: boolean }): string => (!show ? 'transition: 0.25s' : '')};
  ${({ show }: { show: boolean }): string => (!show ? 'transition-timing-function: ease-out' : '')};
  ${({ show }: { show: boolean }): string => (!show ? 'transform: translateX(0)' : '')};
  ${({ show }: { show: boolean }): string => (!show ? 'opacity: 1' : '')};
`;

export const FirstSlide = styled.div<{ show: boolean }>`
  width: 100%;
  height: 100%;
  transform: translateX(0);
  transition-timing-function: ease-out;
  transition: 0.2s;
  position: absolute;
  opacity: 1;
  ${({ show }: { show: boolean }): string => (show ? 'transition: 0.25s' : '')};
  ${({ show }: { show: boolean }): string => (show ? 'transition-timing-function: ease-in' : '')};
  ${({ show }: { show: boolean }): string => (show ? 'transform: translateX(130%)' : '')};
  ${({ show }: { show: boolean }): string => (show ? 'opacity: 0' : '')};
`;

export const SlidesContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

export const BackHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding-bottom: 15px;
  & > span {
    font-family: 'Roboto', sans-serif;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: ${({ theme }: PropsTheme): Color => theme.colors.grey2};
    text-indent: 10px;
    margin-top: 5px;
  }
  & > button {
    ${ButtonStylesMixin};
    position: relative;
    top: 2px;
    left: 3px;
    box-shadow: 0px 1px 3px 0px ${({ theme }: PropsTheme): Color => theme.colors.gray2};
    > div {
      transform: rotate(180deg);
    }
    &:hover {
      box-shadow: 0px 1px 4px 1px ${({ theme }: PropsTheme): Color => theme.colors.gray1};
      cursor: pointer;
    }
  }
`;

export const SelectMpptChannelContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 60px;
  padding: 12px 0;
  border-bottom: solid 1px ${({ theme }: PropsTheme): Color => theme.colors.newGray1};
  & > .container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    padding: 0;
    & > .title {
      font-family: 'Roboto', sans-serif;
      font-size: 13px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: ${({ theme }: PropsTheme): Color => theme.colors.grey2};
      padding: 0 0 15px;
    }
    & > button {
      width: 34px;
      height: 32px;
      border-radius: 4px;
      box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.5);
      border: solid 1px ${({ theme }: PropsTheme): Color => theme.colors.newGray1};
      background-color: ${({ theme }: PropsTheme): Color => theme.colors.whiteBg};
      font-family: 'Roboto', sans-serif;
      font-size: 24px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: normal;
      text-align: center;
      color: ${({ theme }: PropsTheme): Color => theme.colors.grey1};
      margin-left: 14px;
      &:first-child {
        margin-left: 4px;
      }
      &:hover {
        box-shadow: 0px 1px 4px 1px ${({ theme }: PropsTheme): Color => theme.colors.gray1};
        cursor: pointer;
      }
      &:disabled,
      &[disabled] {
        ${DisabledMixinStyle};
        &:hover {
          box-shadow: none;
        }
      }
    }
  }
`;
