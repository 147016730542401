export enum MissingPropertyType {
  ROOF_FACE = 'ROOF_FACE',
  STREET_LOCATION = 'STREET_LOCATION',
  APN = 'APN',
  MSP_LOCATION = 'MSP_LOCATION',
  MSP_EXPOSURE = 'MSP_EXPOSURE',
  ROOF_OUTLINE = 'ROOF_OUTLINE',
  ELECTRICAL_CODE = 'ELECTRICAL_CODE',
  FIRE_CODE = 'FIRE_CODE',
  AHJ = 'AHJ',
  UTILITY_COMPANY = 'UTILITY_COMPANY',
  GROUND_SNOW_LOAD = 'GROUND_SNOW_LOAD',
  WIND_SPEED = 'WIND_SPEED',
  WIND_EXPOSURE_CATEGORY = 'WIND_EXPOSURE_CATEGORY',
  LOW_TEMPERATURE = 'LOW_TEMPERATURE',
  HIGH_TEMPERATURE = 'HIGH_TEMPERATURE',
  MSP_BUSBAR_RATING = 'MSP_BUSBAR_RATING',
  MSP_BUSBAR_CONNECTION_POINT = 'MSP_BUSBAR_CONNECTION_POINT',
  SUBPANEL_LOCATION = 'SUBPANEL_LOCATION',
  SUBPANEL_EXPOSURE = 'SUBPANEL_EXPOSURE',
  SUBPANEL_BUSBAR_RATING = 'SUBPANEL_BUSBAR_RATING',
  SUBPANEL_BUSBAR_CONNECTION_POINT = 'SUBPANEL_BUSBAR_CONNECTION_POINT',
  SUBPANEL_MAIN_BREAKER_RATING_OR_FEEDER_OCPD_RATING = 'SUBPANEL_MAIN_BREAKER_RATING_OR_FEEDER_OCPD_RATING',
  MAIN_BREAKER_RATING = 'MAIN_BREAKER_RATING',
  METER_SOCKET = 'METER_SOCKET',
  ARRAY_AREA = 'ARRAY_AREA',
  STRING = 'STRING',
  ROOF_SURFACE_TYPE = 'ROOF_SURFACE_TYPE',
  ROOF_FRAMING_SPACING = 'ROOF_FRAMING_SPACING',
  ROOF_FRAMING_DIMENSIONS = 'ROOF_FRAMING_DIMENSIONS',
  ROOF_DECKING_TYPE = 'ROOF_DECKING_TYPE',
  ROOF_SLOPE = 'ROOF_SLOPE',
  ROOF_AZIMUTH = 'ROOF_AZIMUTH',
  MOUNTING_SYSTEM_DEFINITION = 'MOUNTING_SYSTEM_DEFINITION',
  PV_MODULE = 'PV_MODULE',
  PV_MODULE_LAYOUT = 'PV_MODULE_LAYOUT',
  PARCEL_BOUNDARY = 'PARCEL_BOUNDARY'
}

export enum MissingPropertyLabel {
  ROOF_FACE = 'Create a roof face',
  STREET_LOCATION = 'Locate street',
  APN = 'Specify assessor parcel number (APN)',
  MSP_LOCATION = 'Add service entrance equipment',
  MSP_EXPOSURE = 'Specify service entrance equipment exposure',
  ROOF_OUTLINE = 'Define roof outline',
  ELECTRICAL_CODE = 'Specify electrical code',
  FIRE_CODE = 'Specify fire code',
  AHJ = 'Specify authority having jurisdicton (AHJ)',
  UTILITY_COMPANY = 'Specify utility company',
  GROUND_SNOW_LOAD = 'Specify ground snow load',
  WIND_SPEED = 'Specify wind speed',
  WIND_EXPOSURE_CATEGORY = 'Specify wind exposure category',
  LOW_TEMPERATURE = 'Specify site low temperature',
  HIGH_TEMPERATURE = 'Specify site high temperature',
  MSP_BUSBAR_RATING = 'Specify service entrance equipment load center busbar rating',
  MSP_BUSBAR_CONNECTION_POINT = 'Specify service entrance equipment load center busbar point of connection',
  SUBPANEL_LOCATION = 'Locate subpanel (SP)',
  SUBPANEL_EXPOSURE = 'Specify subpanel exposure',
  SUBPANEL_BUSBAR_RATING = 'Specify subpanel busbar rating',
  SUBPANEL_BUSBAR_CONNECTION_POINT = 'Specify subpanel point of connection',
  SUBPANEL_MAIN_BREAKER_RATING_OR_FEEDER_OCPD_RATING = 'Specify subpanel properties',
  MAIN_BREAKER_RATING = 'Specify main breaker rating',
  METER_SOCKET = 'Specify the meter base in service entrance equipment',
  ARRAY_AREA = 'Select at least one roof face where PV modules will be installed.',
  STRING = 'Be sure that all modules have been added to the stringing configuration!',
  ROOF_SURFACE_TYPE = 'Specify roof surface type',
  ROOF_FRAMING_SPACING = 'Specify roof framing spacing',
  ROOF_FRAMING_DIMENSIONS = 'Specify roof framing dimensions',
  ROOF_DECKING_TYPE = 'Specify roof decking type',
  ROOF_SLOPE = 'Specify Slope',
  ROOF_AZIMUTH = 'Specify Azimuth',
  MOUNTING_SYSTEM_DEFINITION = 'Select mounting system(s) compatible with roof faces',
  PV_MODULE = 'Add at least one PV module to the design',
  PV_MODULE_LAYOUT = 'The PV module layout is not valid',
  PARCEL_BOUNDARY = 'The parcel boundary needs to encompass all the buildings'
}

export enum MissingPropertyIcon {
  ROOF_FACE = 'trace-roof',
  APN = '',
  ROOF_OUTLINE = 'trace-outlines',
  GROUND_SNOW_LOAD = 'ground-snow',
  WIND_SPEED = 'wind-speed',
  WIND_EXPOSURE_CATEGORY = 'wind-exposure',
  LOW_TEMPERATURE = 'low-temperature',
  HIGH_TEMPERATURE = 'high-temperature',
  ROOF_SURFACE_TYPE = 'roof-surface',
  ROOF_FRAMING_SPACING = 'roof-framing',
  ROOF_FRAMING_DIMENSIONS = 'roof-framing',
  ROOF_DECKING_TYPE = 'roof-decking',
  ROOF_SLOPE = 'define-slope',
  ROOF_AZIMUTH = 'define-azimuth'
}

export interface IProjectMissingProperty {
  readonly type: MissingPropertyType;
  readonly blocking: boolean;
  readonly explanation: string;
}

export interface IProjectMissingPropertyObject {
  readonly missingProperties: IProjectMissingProperty[];
}

export interface ISiteEquipmentMissingProperties {
  readonly missingProperties: IProjectMissingProperty[];
  readonly mainServicePanel: IProjectMissingPropertyObject;
  readonly subpanel: IProjectMissingPropertyObject;
}

export interface IRoofFaceMissingProperties {
  readonly missingProperties: IProjectMissingProperty[];
}

interface IRoofFacesObjectList {
  readonly [key: string]: IRoofFaceMissingProperties;
}

export interface IBuildingMissingProperties {
  readonly missingProperties: IProjectMissingProperty[];
  readonly roofFaces: IRoofFacesObjectList;
}

export interface IBuildingObjectList {
  readonly [key: string]: IBuildingMissingProperties;
}

export interface IProjectMissingPropertiesResponse {
  readonly site: IProjectMissingPropertyObject;
  readonly designParameters: IProjectMissingPropertyObject;
  readonly siteEquipment: ISiteEquipmentMissingProperties;
  readonly design: IProjectMissingPropertyObject;
  readonly buildings: IBuildingObjectList;
}
