import React, { CSSProperties, ReactElement, ReactNode } from 'react';
import { Icon } from '../Icon';
import { EThemeComponentColor } from '../Theme/EThemeComponentColor';
import { EButtonSize } from './EButtonSize';
import {
  ButtonStyled,
  ButtonWrapper,
  FlatButton,
  FlatText,
  IStyleProps
} from './styles';

export interface IProps {
  children?: ReactNode;
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  styles?: CSSProperties;
  isLeft?: boolean;
  disabled?: boolean;
  colorTheme?: EThemeComponentColor;
  fontColorTheme?: EThemeComponentColor;
  size?: EButtonSize;
  className?: string;
}

const Button =
  (props: IProps & IStyleProps): ReactElement => {

    const {
      fontColorTheme = EThemeComponentColor.BLACK,
      colorTheme = EThemeComponentColor.WHITE,
      size = EButtonSize.MEDIUM,
      isLeft,
      disabled = false,
      styles,
      styleType,
      children,
      onClick
    } = props;

    return (
      <ButtonWrapper className={props.className} styleType={styleType}>
        {styleType === 'flat' &&
          <FlatButton
            disabled={disabled}
            onClick={onClick}
            colorTheme={colorTheme}
          >
            {isLeft && <Icon
              name="arrow"
              style={{ transform: 'rotate(180deg)' }}
              colorTheme={fontColorTheme}
            />}
            <FlatText
              colorTheme={fontColorTheme}
            >
              {children}
            </FlatText>
            {!isLeft && <Icon
              name="arrow"
              colorTheme={fontColorTheme}
            />}
          </FlatButton>
        }
        {styleType !== 'flat' &&
          <ButtonStyled
            disabled={disabled}
            onClick={onClick}
            styleType={styleType}
            styles={styles}
            size={size}
          >
            {children}
          </ButtonStyled>
        }
      </ButtonWrapper>);
  };

export { Button };
