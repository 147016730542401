import styled from 'styled-components';
import type { Color } from '../../typings';
import { ILoaderProps } from './Loader';

export const LoaderContainer = styled.div<ILoaderProps>`
  padding: ${({ padding = '0' }: ILoaderProps): string => padding};
  width: ${({ width = '100%' }: ILoaderProps): string => width};
  height: ${({ height = '100%' }: ILoaderProps): string => height};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  & span {
    font-family: 'Roboto', sans-serif;
    font-size: ${({ fontSize = '26px' }: ILoaderProps): string => fontSize};
    font-weight: 300;
    text-align: center;
    color: #4a4a4a;
    margin-top: 20px;
  }
`;

export const LoaderSpinner = styled.div<ILoaderProps>`
  @keyframes lyra-loading { 
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  font-size: 10px;
  text-indent: -9999em;
  width: ${(props: ILoaderProps): number => props.size!}px;
  height: ${(props: ILoaderProps): number => props.size!}px;
  border-radius: 50%;
  background: #427ecd;
  background: ${(props: ILoaderProps): string => `linear-gradient(to right, ${props.color!} 10%, rgba(66,126,205, 0) 42%)`};
  position: relative;
  animation: lyra-loading 1.4s infinite linear;
  transform: translateZ(0);
  
  &::before {
    width: 50%;
    height: 50%;
    background: ${(props: ILoaderProps): string => props.color!};
    border-radius: 100% 0 0 0;
    position: absolute;
    top: 0;
    left: 0;
    content: '';
  }
    
  &::after {
    background: ${(props: ILoaderProps): Color => props.bgColor!};
    width: 85%;
    height: 85%;
    border-radius: 50%;
    content: '';
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
`;
