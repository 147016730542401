import { createGlobalStyle, DefaultTheme, IStyledComponent, PropsTheme } from 'styled-components';
import { Color } from '../../typings';
import { inheritMinMaxHeight } from './mixins';
import { resetStyles } from './resetStyles';
import { spaces } from './spacing';
import { Tooltip } from './Tooltip';
import FlexboxGrid2Styles from './FlexboxGrid2Styles';
import { ToastifyRootStyles, ToastifyScopedStyles } from '../Toast/react-toastify-global-styles';

const GlobalStyles = createGlobalStyle<PropsTheme>`
  .lyra {
    line-height: 1;
    ${inheritMinMaxHeight};
    color: ${({ theme }: PropsTheme): Color => theme.colors.secondaryFont};

    * {
      font-family: Roboto, sans-serif;
    }

    ${resetStyles}
    ${FlexboxGrid2Styles}
    ${ToastifyScopedStyles}
    ${Tooltip}
    ${spaces}
  }
  ${ToastifyRootStyles}
`;

export { GlobalStyles };
