import { Col, Row } from 'react-flexbox-grid';
import styled, { PropsTheme } from 'styled-components';
import { Color } from '../../typings';
import { Paragraph } from '../FontFamily/Fonts';
import { Icon } from '../Icon/Icon';

type DataTableWrapperProps = PropsTheme & {
  fillContent?: boolean | string;
};

const DataTableWrapper = styled.div<DataTableWrapperProps>`
  border-radius: 4px;
  background-color: ${({ theme }: PropsTheme): Color => theme.colors.whiteBg};
  padding: 15px;
  ${({ fillContent }: DataTableWrapperProps): string => fillContent
    ? `height: 100%; width: ${fillContent === true ? '100%' : fillContent};`
    : ''
  }
`;

const ContentRow = styled(Row)`
  border-bottom: 1px solid #d8d8d8;
  padding-bottom: 17px;
  padding-top: 17px;
  align-items: center;
`;

const HeaderText = styled(Paragraph)`
  color: ${({ theme }: PropsTheme): Color => theme.colors.grayColor3};
  line-height: 1.43;
`;

const TableContent = styled.div`
  min-height: 80px;
`;

const CenterColumn = styled(Col)`
  text-align: center;
`;

const DownloadIcon = styled(Icon)`
  cursor: pointer;
`;

const CellTitle = styled(Paragraph)`
  line-height: 1.43;
`;

export {
  DataTableWrapper,
  ContentRow,
  HeaderText,
  TableContent,
  CenterColumn,
  DownloadIcon,
  CellTitle
};
