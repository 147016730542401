import { computed } from 'mobx';
import type { Object3D } from 'three';
import { Vector3 } from 'three';
import { Units } from '../../../domain/typings';
import type EditorStore from '../../../stores/EditorStore/EditorStore';
import type SmartGuidesStore from '../../../stores/UiStore/SmartGuidesStore/SmartGuidesStore';
import ProjectionUtil from '../../../utils/projectionUtil';
import type { Vertex } from '../../graphics/Vertex';
import {
  HALF_PI, SceneObjectType, WARNING
} from '../Constants';
import { getParentLyraModelByMesh } from '../../sceneObjectsWithLyraModelsHelpers';
import { notify } from '../../../utils/helpers';
import { calculateZFromInfiniteMesh } from '../../../utils/spatial';
import type { RoofFace } from './RoofFace';
import { RoofProtrusion } from './RoofProtrusion';

export class RectangleProtrusion extends RoofProtrusion {
  override type = SceneObjectType.Protrusion;
  propertyId: string = SceneObjectType.Protrusion;
  isMultipleVertices: boolean = true;
  selectWithParent: boolean = false;
  readonly MIN_WIDTH: number = ProjectionUtil.convertToWorldUnits(1, Units.Inches);
  readonly MIN_LENGTH: number = ProjectionUtil.convertToWorldUnits(1, Units.Inches);

  private initialCorner!: Vector3;

  @computed
  get width(): number {
    return this.getWidth();
  }

  getWidth(): number {
    if (this.polygon[3]) {
      const startVertex = this.polygon[3].getVector3();
      const endvertex = this.polygon[2].getVector3();

      return startVertex.distanceTo(endvertex);
    }

    return 0;
  }

  @computed
  get length(): number {
    return this.getLength();
  }

  getLength(): number {
    if (this.polygon[2]) {
      const startVertex = this.polygon[1].getVector3();
      const endvertex = this.polygon[2].getVector3();

      return startVertex.distanceTo(endvertex);
    }

    return 0;
  }
  showVertices(): boolean {
    return !this.boundary.closed || this.selected;
  }

  showLines(): boolean {
    return true;
  }

  dragVertices(): boolean {
    return true;
  }

  hasFill(): boolean {
    return true;
  }

  showFill(): boolean {
    return this.boundary.closed;
  }

  onClose(): void {
    /** */
  }

  override move(newVertices: Vector3[], editor: EditorStore, smartGuides: SmartGuidesStore): void {
    const parent = getParentLyraModelByMesh<RoofFace>(this.mesh);

    if (
      newVertices
      && this.isValidAgainstRoofFace(parent, newVertices)
      && this.isValidAgainstProtrusions(parent, newVertices)
    ) {
      newVertices.forEach((vertex: Vector3): void => {
        vertex.z = calculateZFromInfiniteMesh(this.getVector3s(), vertex);
      });
      this.drawVertices(newVertices);
    }
  }

  override afterMove(newVertices: Vector3[], editor: EditorStore, smartGuides: SmartGuidesStore): boolean {
    const parent = getParentLyraModelByMesh<RoofFace>(this.mesh);

    if (
      newVertices
      && this.isValidAgainstRoofFace(parent, newVertices)
      && this.isValidAgainstProtrusions(parent, newVertices)
    ) {
      this.canMove = true;
      const cloneVertices = newVertices.map((vector: Vector3): Vector3 => vector.clone());
      this.lastValidVertices = [];
      this.lastValidVertices.push(...cloneVertices);
    } else {
      this.canMove = false;
    }

    return true;
  }

  onMoveVertex(): boolean {
    const parent = getParentLyraModelByMesh<RoofFace>(this.mesh);
    const newPositions = this.boundary.vertices.map((vertex: Vertex): Vector3 => vertex.getVector3());
    if (
      this.isValidAgainstRoofFace(parent, newPositions)
      && this.isValidAgainstProtrusions(parent, newPositions)
      && this.hasRightDimensions()
    ) {
      return true;
    }

    return false;
  }

  resetVertex(vector: Vector3, index: number): void {
    this.polygon[index].mesh.position.x = vector.x;
    this.polygon[index].mesh.position.y = vector.y;
    this.polygon[index].mesh.position.z = vector.z;
    this.boundary.updateFirstOrLastVertexIfNeeded(this.polygon[index]);
  }

  hasRightDimensions(): boolean {
    return this.width >= this.MIN_WIDTH && this.length >= this.MIN_LENGTH;
  }

  override setVerticesFromVector3s(vertex: Vector3[], removeIfCollinear: boolean = false): void {
    // Put first to last because we need close polygon
    vertex.push(vertex[0].clone());

    this.drawVertices(vertex, removeIfCollinear);
  }

  setInitialCorner(vertex: Vector3): void {
    this.initialCorner = vertex;
  }

  calculateProtrusion(oppositeCorner: Vector3, roofFace?: RoofFace): void {
    const parent = roofFace ?? getParentLyraModelByMesh<RoofFace>(this.mesh);
    const vertices = this.calculateMissingVertices(parent, this.initialCorner, oppositeCorner);
    if (this.isValidAgainstRoofFace(parent, vertices) && this.isValidAgainstProtrusions(parent, vertices)) {
      this.drawVertices(vertices);
    } else {
      notify('Protrusion will collide with another roof object', WARNING);
    }
  }

  calculateInitialCorner(vector: Vector3): void {
    let i: number = 0;
    this.boundary.vertices.forEach((vertice: Vertex, index: number): void => {
      if (vertice.getVector3().distanceTo(vector) === 0) {
        i = index;
      }
    });

    const initialCorner: Vertex = i <= 1 ? this.boundary.vertices[i + 2] : this.boundary.vertices[i - 2];
    const parent = getParentLyraModelByMesh<RoofFace>(this.mesh);
    const vertices = this.calculateMissingVertices(parent, vector, initialCorner.getVector3());
    // TODO: Why are we recreating the vertices instead of moving the existing ones...?
    this.drawVertices(vertices);
  }

  setLength(length: number): void {
    const vertex = this.polygon[2].getVector3();
    const oppositeCorner = this.polygon[1].getVector3();
    const newOppositeCorner = new Vector3();

    newOppositeCorner.subVectors(vertex, oppositeCorner).setLength(length)
      .add(oppositeCorner);

    this.calculateProtrusion(newOppositeCorner);
  }

  setWidth(width: number): void {
    const vertex = this.polygon[2].getVector3();
    const oppositeCorner = this.polygon[3].getVector3();
    const newOppositeCorner = new Vector3();

    newOppositeCorner.subVectors(vertex, oppositeCorner).setLength(width)
      .add(oppositeCorner);

    this.calculateProtrusion(newOppositeCorner);
  }

  removeChildFromModel(object3D: Object3D): void {
    // Not implemented
  }

  private calculateMissingVertices(roofFace: RoofFace, initialCorner: Vector3, oppositeCorner: Vector3): Vector3[] {
    const azimuth = roofFace.getAzimuthOrSegmentAngle() * -1;
    const hipotenusa = new Vector3(initialCorner.x, initialCorner.y, 0).distanceTo(
      new Vector3(oppositeCorner.x, oppositeCorner.y, 0)
    );

    const angHip = Math.atan2(initialCorner.y - oppositeCorner.y, initialCorner.x - oppositeCorner.x) - HALF_PI;

    const ang = angHip - azimuth;
    const co = Math.sin(ang) * hipotenusa;

    const vertexA = new Vector3(initialCorner.x, initialCorner.y);
    vertexA.setZ(roofFace.calculateZ(vertexA));

    const vertexB = new Vector3(initialCorner.x + Math.cos(azimuth) * co, initialCorner.y + Math.sin(azimuth) * co);
    vertexB.setZ(roofFace.calculateZ(vertexB));

    const vertexC = new Vector3(oppositeCorner.x, oppositeCorner.y);
    vertexC.setZ(roofFace.calculateZ(vertexC));

    const vertexD = new Vector3(oppositeCorner.x - Math.cos(azimuth) * co, oppositeCorner.y - Math.sin(azimuth) * co);
    vertexD.setZ(roofFace.calculateZ(vertexD));

    return [vertexA, vertexB, vertexC, vertexD, vertexA];
  }
}
