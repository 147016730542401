import {
  LyraFontFamily, LyraFormElementsDesignTool
} from '@aurorasolar/lyra-ui-kit';
import {
  Grid, Row
} from 'react-flexbox-grid';
import type { PropsTheme } from 'styled-components';
import styled, { withTheme } from 'styled-components';
import type { Color } from '../../../../domain/typings';
import type { PropsWithTheme } from '../../../../ui/components/Button/styles';

const { H4 } = LyraFontFamily.FontFamily;
const TextField = LyraFormElementsDesignTool.FormElementsDesignTool.TextField.TextField;

type Props = PropsWithTheme & {
  active?: boolean;
  status?: boolean;
};

export const Message = styled.div`
  margin: 20px 0;
  padding: 10px;
  box-sizing: border-box;
  background-color: ${({ theme }: PropsTheme): Color => theme.colors.grayColor8};
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  line-height: 1.29;
  letter-spacing: normal;
  color: ${({ theme }: PropsTheme): Color => theme.colors.grey1};
`;

export const Header = styled.h2`
  font-family: 'Roboto', sans-serif;
  font-size: 26px;
  font-weight: 300;
  border-bottom: 1px solid ${({ theme }: PropsTheme): Color => theme.colors.grey4};
  padding-bottom: 10px;
  color: ${({ theme }: PropsTheme): Color => theme.colors.grey1};
`;

export const AdvancedSettings = styled.div`
  display: block;
`;

export const TitleText = styled.h2`
  font: 300 normal 26px Roboto;
  letter-spacing: normal;
  font-stretch: normal;
  margin-bottom: 12px;
  color: #4a4a4a;
`;

export const ModalWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  height: 530px;

  @media (max-height: 768px) {
    height: 400px;
  }
`;

export const TabsWrapper = styled.div`
  width: 70%;
  margin-right: 40px;
  overflow: hidden;
  min-width: 435px;
`;

export const OptionContainer = styled.div`
  margin-bottom: 20px;
  overflow-x: hidden;
`;

export const OptionView = styled.div`
  display: flex;
  padding: 15px 5px;
  align-items: center;

  &:not(:last-of-type) {
    border-bottom: 1px solid ${({ theme }: PropsWithTheme): Color => theme.colors.tertiary};
  }
`;

export const OptionViewFluid = styled(OptionView)`
  height: auto;
`;

export const TextFieldStyled = styled(TextField).attrs({
  height: '44px',
  fontSize: '16px'
})`
  & input {
    min-width: auto;
    width: 100%;
  }
`;

export const GridContainer = styled(Grid)`
  flex: 1 1 auto;
  width: 100%;
  margin-left: -16px;
  margin-right: -16px;
`;

export const GridRow = styled(Row)`
  &:not(:last-of-type) {
    margin-bottom: 16px;
  }
`;

export const PrimaryText = styled(H4)`
  width: 40%;
`;

export const PrimaryTextAlignedTop = styled(PrimaryText)`
  align-self: flex-start;
  margin-top: 15px;
  flex-shrink: 0;
`;

export const SecondaryText = styled(PrimaryText)`
  width: 50%;
`;

export const RadioView = styled.div`
  width: 50%;
  display: flex;
  justify-content: flex-end;
  & .toggleBig {
    margin: 0 20px 0 15px;
    > div label {
      top: 5px;
      transform: scale(1.5);
    }
  }
`;

export const RadioWrapper = styled.div`
  display: flex;
  align-items: center;

  > .toggleBig {
    margin: 0 20px 0 15px;
  }
`;

export const StatusText = styled(H4)<Props>`
  color: ${({
    theme, status
  }: Props): Color => (status ? theme.colors.gray1 : theme.colors.grayOpacity1)};
`;

export const PreviewWrapper = styled.div`
  width: 440px;
  min-width: 440px;
  position: relative;
  height: 100%;
`;

export const LeftView = styled.div`
  margin: 0 25px 0 0;
  display: flex;
  flex-shrink: 0;
  width: 300px;
`;

export const GroupWrapper = styled.div`
  margin-bottom: 40px;
  & > div:first-of-type {
    border-top: 1px solid ${({ theme }: PropsWithTheme): Color => theme.colors.tertiary};
  }
`;

export const RowContainer = styled.div`
  padding: 15px 10px 15px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${({ theme }: PropsWithTheme): Color => theme.colors.tertiary};
`;

export const RightView = styled.div`
  & .toggleBig > div {
    & label {
      top: 5px;
      transform: scale(1.5);
    }
  }
`;
