import { Vector3 } from 'three';
import {
  action, computed, observable
} from 'mobx';
import { getRootStore } from '../../../../stores/RootStoreInversion';
import type { EquipmentExposure } from '../EquipmentExposure';
import type { BaseUtilityMeterProps } from '../BaseUtilityMeterProps';
import {
  type ISiteImageData, SiteImage
} from '../SiteImage';

import type { IElectricalPanelData } from '../../../entities/SiteDesign/ElectricalPanelInstance';
import {
  DEFAULT_MAIN_BREAKER_RATING,
  getServiceEntranceAndSiteEquipmentProperties,
  LoadCenterName,
  ServiceEntranceEquipmentDevice,
  ServiceEntranceEquipmentType
} from '../SiteEquipmentTypesAndHelpers';
import type { ISiteEquipmentData } from '../../../entities/SiteDesign/SiteEquipment';
import { Marker } from './../Marker';
import type { GenericMarkerDependencies } from './GenericSiteEquipment';

const DEFAULT_BUSBAR_RATING = 200;

export class LoadCenter extends Marker {
  label: string;
  readonly propertyId: string;

  readonly isServiceEntranceEquipment: boolean = true;

  // Electrical equipment props (definition part):
  manufacturer?: string;
  model?: string;
  panelBoardStyle: string = 'SINGLE_BUSBAR_LOADS_PANEL';
  @observable
  busbarRating: number = DEFAULT_BUSBAR_RATING;
  busbarPointOfConnection: string = 'TOP';
  parallelEnergySourceInputRating?: number;
  meterSocket?: boolean;

  // Electrical equipment props (instance part):
  serialNumber?: string;
  mainBreakerRating: number = DEFAULT_MAIN_BREAKER_RATING;
  @observable
  shortCircuitCurrentRating?: string;
  mainBreakerAmpereInterruptingCapacity?: number;
  feederOcpdRating?: number;
  exposure?: EquipmentExposure;
  utilityMeter?: BaseUtilityMeterProps;

  /**
   * List of service entrance equipment images provided by user
   */
  @observable
  images: SiteImage[] = [];

  constructor({
    sceneObjectType,
    color,
    meterSocket,
    serviceEntranceEquipmentType
  }: GenericMarkerDependencies & { meterSocket?: boolean }) {
    super();

    this.type = sceneObjectType;
    this.propertyId = sceneObjectType;
    this.color = color;
    this.realWorldZValue = Marker.defaultEquipmentZValueInWorldUnits();
    this.meterSocket = meterSocket;

    const {
      name, label
    } = getServiceEntranceAndSiteEquipmentProperties(
      ServiceEntranceEquipmentDevice.LoadCenter,
      serviceEntranceEquipmentType ?? ServiceEntranceEquipmentType.MeterMainLoadCenter
    );
    this.name = name;
    this.label = label;

    const renderHeight = getRootStore().editor.getObjectRenderHeight(sceneObjectType);
    this.draw(new Vector3(undefined, undefined, renderHeight));
  }

  @action
  addImage = (image: SiteImage): void => {
    this.images.push(image);
  };

  @action
  deleteImage = (imageId: string): void => {
    this.images = this.images.filter((image: SiteImage): boolean => image.id !== imageId);
  };

  imageWithId = (imageId: string): SiteImage | undefined => {
    return this.images.find((image: SiteImage): boolean => image.id === imageId);
  };

  @computed
  get hasMainBreaker(): boolean {
    return this.name !== LoadCenterName.MainLugLoadCenter;
  }

  @action
  enrichWithData(data: IElectricalPanelData): void {
    const definition = data.definition!;
    this.serverId = data.id;
    this.manufacturer = definition.manufacturer;
    this.model = definition.model;
    this.busbarRating = definition.busbarRating;
    this.busbarPointOfConnection = definition.busbarPointOfConnection;
    this.parallelEnergySourceInputRating = definition.parallelEnergySourceInputRating;
    this.shortCircuitCurrentRating = definition.shortCircuitCurrentRating;
    this.mainBreakerAmpereInterruptingCapacity = data.mainBreakerAmpereInterruptingCapacity;
    this.feederOcpdRating = data.feederOcpdRating;
    this.exposure = data.exposure;
    this.meterSocket = definition.meterSocket;

    this.panelBoardStyle = definition.panelBoardStyle ?? this.panelBoardStyle;

    if (data.mainBreakerRating) {
      this.mainBreakerRating = data.mainBreakerRating;
    }
    if (data.utilityMeter) {
      this.utilityMeter = data.utilityMeter;
    }

    if (data.images) {
      this.images = data.images.map((imageData: ISiteImageData): SiteImage => new SiteImage(imageData));
    }

    this.realWorldZValue = data.location.z;
  }

  toData(): ISiteEquipmentData['mainServicePanel'] {
    const mainServicePanelData: IElectricalPanelData = {
      id: this.serverId,
      mainBreakerRating: this.name !== LoadCenterName.MainLugLoadCenter ? this.mainBreakerRating : undefined,
      feederOcpdRating: this.feederOcpdRating,
      mainBreakerAmpereInterruptingCapacity:
        this.name !== LoadCenterName.MainLugLoadCenter ? this.mainBreakerAmpereInterruptingCapacity : undefined,
      exposure: this.exposure,
      utilityMeter: this.utilityMeter,
      location: {
        x: this.mesh.position.x,
        y: this.mesh.position.y,
        z: this.realWorldZValue
      },

      definition: {
        manufacturer: this.manufacturer,
        model: this.model,
        panelBoardStyle: this.panelBoardStyle,
        busbarRating: this.busbarRating,
        shortCircuitCurrentRating: this.shortCircuitCurrentRating,
        busbarPointOfConnection: this.busbarPointOfConnection,
        parallelEnergySourceInputRating: this.parallelEnergySourceInputRating,
        meterSocket: this.meterSocket
      }
    };

    const images = this.images.map((image: SiteImage): ISiteImageData => image.toData());
    if (images.length) {
      mainServicePanelData.images = images;
    }

    return mainServicePanelData;
  }
}
