import React, {
  useCallback, useRef, useMemo, memo, useState, useLayoutEffect
} from 'react';
import debounce from 'lodash/debounce';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';
import type { IChangeEvent } from '@rjsf/core';
import Form from '@rjsf/core';
import { LyraButtons } from '@aurorasolar/lyra-ui-kit';
import type { EThemeComponentColor } from '@aurorasolar/lyra-ui-kit/lib/components/Theme/EThemeComponentColor';
// @ts-ignore
import predicate from 'predicate';
import type {
  CustomValidator,
  RegistryWidgetsType,
  RJSFSchema,
  RJSFValidationError,
  UiSchema
} from '@rjsf/utils';
import type { JSONSchema7Type } from 'json-schema';
import type { EButtonSize } from '@aurorasolar/lyra-ui-kit/lib/components/Buttons/EButtonSize';
import validator from '@rjsf/validator-ajv8';
import filter from 'lodash/filter';
import { ButtonStyleType } from '@aurorasolar/lyra-ui-kit/lib/components/Buttons/styles';
import applyRules from './interpreters/applyRules';
import { Engine } from './engine';
import extraEffects, { RecentValuesRegistry } from './effects';
import DebouncedBaseInputTemplate from './templates/DebouncedBaseInputTemplate';
import LyraToggle from './widgets/LyraToggle';
import LyraSelect from './widgets/LyraSelect';
import LyraNumberSpinner from './widgets/LyraNumberSpinner';
import { transformErrors } from './errors';
import {
  interpretSchema, interpretRules, interpretValidation
} from './interpreters';
import predicatesToAdd from './predicates';
import ShowMoreBottomButton from './ShowMoreBottomButton';
import CustomFieldTemplate from './templates/CustomFieldTemplate';
import type {
  DataSchema, FormData, FormRule, FormValidationEffect
} from './FormOptionsRulesAndState';
import type { Enclosure } from './effects/RecentValuesRegistry';
import type { EngineRule } from './engine/Engine';
import GroupHeaderTemplate from './templates/GroupHeaderTemplate';
import {
  decodeFormData, interpretFormData
} from './interpreters/formData';

type FormGeneratorProps = {
  readonly apiSchema: DataSchema;
  readonly apiRules: FormRule[],
  readonly apiValidation?: FormValidationEffect[];
  readonly formId: string;
  readonly formData: FormData;
  readonly onSubmit: (data: IChangeEvent) => void;
  readonly onChange?: (data: IChangeEvent) => void;
  readonly onError?: (errors: RJSFValidationError[]) => void;
  readonly className?: string;
  readonly showSubmitButton: boolean;
  readonly disableSubmitButton?: boolean;
  readonly submitButtonLabel?: string;
  readonly showErrorList?: boolean;
  readonly auroraMode?: boolean;
}

type WrapperProps = {
  readonly formSchema: RJSFSchema;
  readonly uiSchema: UiSchema;
  readonly rules: EngineRule[];
  readonly enclosure: Enclosure,
  readonly formId: string;
  readonly defaultData: {
    readonly [key: string]: JSONSchema7Type;
  };
  readonly onSubmit: (event: IChangeEvent) => void;
  readonly onChange: (event: IChangeEvent) => void;
  readonly onError: (errors: RJSFValidationError[]) => void;
  readonly className: string;
  readonly validatorFunction: CustomValidator;
  readonly showSubmitButton: boolean;
  readonly disableSubmitButton: boolean;
  readonly submitButtonLabel: string;
  readonly auroraMode?: boolean;
}

const customWidgets: RegistryWidgetsType = {
  // overridden defaults
  CheckboxWidget: LyraToggle,
  // custom widgets
  numericSpinner: LyraNumberSpinner,
  customSelect: LyraSelect
};

const customFields = {}; // leave empty
let prevFormData = {};

const FrameworkComponentWrapper = (props: WrapperProps): JSX.Element => {
  const {
    formId,
    formSchema,
    uiSchema,
    rules,
    enclosure,
    defaultData,
    onSubmit,
    onChange,
    onError,
    validatorFunction,
    className,
    showSubmitButton,
    disableSubmitButton = false,
    submitButtonLabel,
    auroraMode
  } = props;

  const containerRef = useRef<HTMLDivElement>(null);
  const [showMoreButtonVisible, setShowMoreButtonVisible] = useState(false);

  const toggleShowMoreButton = useCallback(
    (): void => {
      if (containerRef.current) {
        const containerHeight = containerRef.current.offsetHeight;
        const scrollHeight = containerRef.current.scrollHeight;
        const scrollOffset = containerRef.current.scrollTop;

        const isButtonVisible = scrollHeight - scrollOffset - containerHeight > 1;

        setShowMoreButtonVisible(isButtonVisible);
      }
    }, [containerRef]
  );

  useLayoutEffect(
    (): (() => void) => {
    // Create special event listener to listen to changes
    // of the form container dimensions;
    // Based on that we toggle visibility of 'Show more' button;
      const resizeObserver = new ResizeObserver((): void => {
        toggleShowMoreButton();
      });
      if (containerRef.current) {
        resizeObserver.observe(containerRef.current);
      }

      return (): void => {
        prevFormData = {};
        resizeObserver.disconnect();
      };
    }, [containerRef, toggleShowMoreButton]
  );

  const scrollDown = useCallback(
    (): void => {
      if (containerRef.current) {
        containerRef.current.scrollTop = containerRef.current.clientHeight;
      }
    }, [containerRef]
  );

  const onScroll = useMemo(
    () => debounce(
      (): void => {
        toggleShowMoreButton();
      }, 100
    ),
    [toggleShowMoreButton]
  );

  const displayErrors = useCallback(
    (errors: RJSFValidationError[]) => onError?.(errors || []), [onError]
  );
  const handleErrors = useCallback(
    (errors : RJSFValidationError[]) => displayErrors(errors), [displayErrors]
  );

  const handleChange = useCallback(
    ({
      formData, errors
    }: IChangeEvent) => {
      const filteredFormData = filter<FormData>(formData, entry => !isEmpty(entry));
      const filteredPreviousFormData = filter<FormData>(prevFormData, entry => !isEmpty(entry));
      if (isEmpty(filteredFormData)) {
        prevFormData = formData;
        return;
      }

      if (isEqual(
        filteredPreviousFormData, filteredFormData
      )) {
        return;
      }

      onChange?.(formData);
      displayErrors(errors);

      prevFormData = formData;
    },
    [displayErrors, onChange]
  );

  const handleSubmit = useCallback(
    ({ formData }: IChangeEvent) => {
      onSubmit?.(formData);
    },
    [onSubmit]
  );

  const FrameworkComponent = useMemo(
    () => {
      return applyRules(
        formSchema,
        uiSchema,
        rules,
        new Engine(
          null, formSchema, enclosure
        ),
        extraEffects
      )(Form);
    }, [
      enclosure,
      formSchema,
      rules,
      uiSchema
    ]
  );

  const formElement = useMemo(
    (): JSX.Element => {
      return (
        <FrameworkComponent
          id={formId}
          schema={formSchema}
          idPrefix={formId}
          showErrorList={false}
          transformErrors={transformErrors}
          templates={{
            FieldTemplate: CustomFieldTemplate,
            TitleFieldTemplate: GroupHeaderTemplate,
            BaseInputTemplate: DebouncedBaseInputTemplate
          }}
          formData={defaultData}
          widgets={customWidgets}
          fields={customFields}
          onSubmit={handleSubmit}
          onChange={handleChange}
          onError={handleErrors}
          customValidate={validatorFunction}
          className={className || 'lyra-form-generator'}
          validator={validator}
          liveValidate
          noHtml5Validate
        >
          {showSubmitButton && (
            <div className="generator-submit-button-wrapper">
              <LyraButtons.Buttons
                styleType={ButtonStyleType.PRIMARY}
                colorTheme={'aquamarine' as EThemeComponentColor}
                size={'small' as EButtonSize}
                disabled={disableSubmitButton}
                submitForm={formId}
              >
                {submitButtonLabel}
              </LyraButtons.Buttons>
            </div>
          )}
        </FrameworkComponent>
      );
    }, [
      FrameworkComponent,
      formId,
      formSchema,
      defaultData,
      handleSubmit,
      handleChange,
      handleErrors,
      validatorFunction,
      className,
      showSubmitButton,
      disableSubmitButton,
      submitButtonLabel
    ]
  );

  return (
    <div className="form-generator-position">
      <div className="form-generator-wrapper" onScroll={onScroll} ref={containerRef}>
        {formElement}
      </div>
      {!auroraMode && (
        <ShowMoreBottomButton onClick={scrollDown} visible={showMoreButtonVisible} />
      )}
    </div>
  );
};

const LyraFormGenerator = (props: FormGeneratorProps): JSX.Element => {
  const {
    formId,
    apiRules,
    apiSchema,
    apiValidation,
    onChange,
    onSubmit,
    onError,
    formData,
    className,
    showSubmitButton,
    disableSubmitButton,
    submitButtonLabel,
    auroraMode
  } = props;

  const initialDataWithEncodedFieldNames = interpretFormData(
    formData, apiSchema
  );
  Object.assign(
    predicate, predicatesToAdd
  );
  const {
    formSchema, uiSchema
  } = interpretSchema(
    apiSchema, initialDataWithEncodedFieldNames
  );

  uiSchema['ui:submitButtonOptions'] = {
    norender: !showSubmitButton
  };

  const enclosure = { recentValuesRegistry: new RecentValuesRegistry() };
  const rules = interpretRules(
    apiRules, enclosure, apiSchema
  );
  const validatorFunction = interpretValidation(
    apiValidation, formSchema
  );

  const handleSubmit = (event: IChangeEvent): void => onSubmit?.(decodeFormData(event));
  const handleChange = (event: IChangeEvent): void => onChange?.(decodeFormData(event));
  const handleError = (errors: RJSFValidationError[]): void => onError?.(errors);

  return (
    <FrameworkComponentWrapper
      formSchema={formSchema}
      uiSchema={uiSchema}
      rules={rules}
      enclosure={enclosure}
      formId={formId}
      defaultData={initialDataWithEncodedFieldNames}
      onSubmit={handleSubmit}
      onChange={handleChange}
      onError={handleError}
      className={className || 'lyra-form-generator'}
      validatorFunction={validatorFunction}
      showSubmitButton={showSubmitButton ?? false}
      disableSubmitButton={disableSubmitButton ?? false}
      submitButtonLabel={submitButtonLabel ?? 'Submit'}
      auroraMode={auroraMode}
    />
  );
};

export default memo(LyraFormGenerator);
