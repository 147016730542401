import {
  action, computed, observable, reaction
} from 'mobx';
import type { Option } from '@aurorasolar/lyra-ui-kit';
import find from 'lodash/find';
import debounce from 'lodash/debounce';
import { DesignService } from '../../../../../infrastructure/services/api/DesignService';
import type { IOption } from '../../../../../domain/models/SiteDesign/IOption';
import type { MarkerAttributes } from '../../../../../domain/typings';
import type { LoadCenter } from '../../../../../domain/models/SiteDesign/SiteEquipment/LoadCenter';
import { removePropertiesIfNullOrUndefined } from '../../../../../utils/helpers';
import type EditorStore from '../../../../EditorStore/EditorStore';
import type DomainStore from '../../../../DomainStore/DomainStore';
import type { ServiceBus } from '../../../../ServiceBus/ServiceBus';
import { getRootStore } from '../../../../RootStoreInversion';
import { DEFAULT_MAIN_BREAKER_RATING } from '../../../../../domain/models/SiteDesign/SiteEquipmentTypesAndHelpers';
import type { MeterMainProps } from 'domain/models/SiteDesign/MeterMainProps';

type TProperties = {
  mainBreakerRating?: number;
  mainBreakerAmpereInterruptingCapacity?: number;
};

export class MeterMainPropertiesViewModel {
  @observable
  mainBreakerRating?: Option;
  @observable
  mainBreakerAmpereInterruptingCapacity?: Option;

  @observable
  mainBreakerRatingOptions: Option[] = [];
  @observable
  mainBreakerAmpereInterruptingCapacityOptions: Option[] = [];

  @observable
  enclosureHasMainBreaker: boolean = false;

  designService: DesignService = new DesignService();
  editor: EditorStore;
  domain: DomainStore;
  serviceBus: ServiceBus;

  @observable
  serialNumber?: string;
  @observable
  electricServiceId?: string;
  @observable
  loading = false;

  readonly assignSerialNumber: (value: string, shouldUpdate: boolean) => void;
  readonly assignElectricServiceId: (value: string, shouldUpdate: boolean) => void;

  /**
    There is no real connection between view models,
    the only trackable values come from the objects inside domain
    Thus the usage of MobX side effects, reacting
    when load center's view model changes anything in former's object
    {@link clearMainBreakerRatingOptions}
   */
  clearMainBreakerAICOptionsAutoUpdate = reaction(
    () => getRootStore().domain.siteEquipment.mainServicePanel?.shortCircuitCurrentRating,
    (shortCircuitCurrentRating) => {
      this.loadMainBreakerAmpereInterruptingCapacityOptions(shortCircuitCurrentRating).then(() => {
        const mainBreakerAICOptions = this.mainBreakerAmpereInterruptingCapacityOptions;
        const isInvalidAIC = !find(
          mainBreakerAICOptions,
          this.findOptionByValue(this.mainBreakerAmpereInterruptingCapacity?.value)
        );
        if (this.mainBreakerAmpereInterruptingCapacity?.value && isInvalidAIC) {
          this.setMainBreakerAmpereInterruptingCapacity(mainBreakerAICOptions[mainBreakerAICOptions.length - 1].value);
        }
      });
    }
  );

  clearMainBreakerRatingOptionsAutoUpdate = reaction(
    () => getRootStore().domain.siteEquipment.mainServicePanel?.busbarRating,
    (busbarRating) => {
      const mainBreakerRating = this.mainBreakerRating?.value ?? 0;
      if (busbarRating && busbarRating !== mainBreakerRating) {
        this.loadMainBreakerRatingOptions(busbarRating).then(() => {
          if (busbarRating < +mainBreakerRating) {
            this.setMainBreakerRating(this.mainBreakerRatingOptions[this.mainBreakerRatingOptions.length - 1].value);
          }
        });
      }
    }
  );

  constructor(editor: EditorStore, domain: DomainStore, serviceBus: ServiceBus) {
    this.editor = editor;
    this.domain = domain;
    this.serviceBus = serviceBus;
    this.loadMainBreakerOptions(domain.siteEquipment.mainServicePanel).then(this.setPropertiesFromDomainObject);

    this.assignSerialNumber = debounce((serialNumber: string, shouldUpdate: boolean): void => {
      this.setProperties(
        {
          ...this.getPropertiesForDomainObject(),
          serialNumber
        },
        shouldUpdate
      );
    }, 300);

    this.assignElectricServiceId = debounce((electricServiceId: string, shouldUpdate: boolean = true): void => {
      this.setProperties(
        {
          ...this.getPropertiesForDomainObject(),
          electricServiceId
        },
        this.validateElectricServiceId(electricServiceId) && shouldUpdate
      );
    }, 300);
  }

  @computed
  get isElectricServiceIdFieldVisible(): boolean {
    return this.domain.project.site.address.province === 'TX';
  }

  @computed
  get isValidElectricServiceIdField(): boolean {
    return this.validateElectricServiceId(this.electricServiceId);
  }

  validateElectricServiceId(electricServiceId?: string): boolean {
    if (!electricServiceId) {
      return true;
    }
    const hasCorrectLength = electricServiceId.length === 17 || electricServiceId.length === 22;
    const hasNumbersOnly = /^\d+$/.test(electricServiceId);
    return hasCorrectLength && hasNumbersOnly;
  }

  async loadMainBreakerOptions(loadCenter?: LoadCenter): Promise<void> {
    this.loading = true;
    // For a better UX, all the requests should execute at the same time
    try {
      await Promise.all([
        this.loadMainBreakerRatingOptions(Number(loadCenter?.busbarRating)),
        this.loadMainBreakerAmpereInterruptingCapacityOptions(loadCenter?.shortCircuitCurrentRating)
      ]);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    } finally {
      this.loading = false;
    }
  }

  @action.bound
  setProperties(utilityMeterProps?: MeterMainProps, shouldUpdate: boolean = true): void {
    this.serialNumber = utilityMeterProps?.serialNumber || undefined;
    this.electricServiceId = utilityMeterProps?.electricServiceId || undefined;
    this.setMainBreakerRating(utilityMeterProps?.mainBreakerRating, shouldUpdate);
    this.setMainBreakerAmpereInterruptingCapacity(
      utilityMeterProps?.mainBreakerAmpereInterruptingCapacity,
      shouldUpdate
    );
    if (shouldUpdate) {
      this.setPropertiesToDomain(this.getPropertiesForDomainObject());
    }
  }

  getPropertiesForDomainObject(): MeterMainProps {
    return {
      serialNumber: this.serialNumber || undefined,
      electricServiceId: this.electricServiceId || undefined,
      mainBreakerRating: Number(this.mainBreakerRating?.value || DEFAULT_MAIN_BREAKER_RATING),
      mainBreakerAmpereInterruptingCapacity: this.mainBreakerAmpereInterruptingCapacity
        ? Number(this.mainBreakerAmpereInterruptingCapacity.value)
        : undefined
    };
  }

  @action.bound
  setPropertiesFromDomainObject(): void {
    const meterMain = this.domain.siteEquipment.meterMain;
    this.assignElectricServiceId(meterMain?.electricServiceId ?? '', false);
    this.assignSerialNumber(meterMain?.serialNumber ?? '', false);
    this.setMainBreakerRating(meterMain?.mainBreakerRating ?? DEFAULT_MAIN_BREAKER_RATING, false);
    this.setMainBreakerAmpereInterruptingCapacity(meterMain?.mainBreakerAmpereInterruptingCapacity, false);
  }

  async loadMainBreakerRatingOptions(busbarRating: number): Promise<void> {
    await this.designService
      .getMspMainBreakerRatingOptions(busbarRating)
      .then((response: IOption<MarkerAttributes>[]): void => {
        this.mainBreakerRatingOptions = this.mapToItemOption(response);
      });
  }

  async loadMainBreakerAmpereInterruptingCapacityOptions(panelShortCircuitCurrentRating?: string): Promise<void> {
    await this.designService
      .getMainBreakerAmpereInterruptingCapacityOptions(panelShortCircuitCurrentRating)
      .then((response: IOption<MarkerAttributes>[]): void => {
        this.mainBreakerAmpereInterruptingCapacityOptions = this.mapToItemOption(response);
      });
  }

  mapToItemOption(options: IOption<MarkerAttributes>[]): Option[] {
    return options.map(
      (option: IOption<MarkerAttributes>): Option => ({
        name: option.attributes.name,
        value: option.attributes.value
      })
    );
  }

  @action.bound
  setMainBreakerRating(value?: string | number, shouldUpdate: boolean = true): void {
    const mainBreakerRating = find(this.mainBreakerRatingOptions, this.findOptionByValue(value));
    if (mainBreakerRating) {
      this.mainBreakerRating = mainBreakerRating;
    }
    if (shouldUpdate) {
      this.setPropertiesToDomain({
        mainBreakerRating: Number(value ?? DEFAULT_MAIN_BREAKER_RATING)
      });
    }
  }

  @action.bound
  setMainBreakerAmpereInterruptingCapacity(value?: string | number, shouldUpdate: boolean = true): void {
    const option = find(this.mainBreakerAmpereInterruptingCapacityOptions, this.findOptionByValue(value));
    if (option) {
      this.mainBreakerAmpereInterruptingCapacity = option;
    }
    if (shouldUpdate) {
      this.setPropertiesToDomain({
        mainBreakerAmpereInterruptingCapacity: Number(value)
      });
    }
  }

  @action.bound
  setPropertiesToDomain(properties: TProperties): void {
    const meterMain = this.domain.siteEquipment.meterMain!;
    const mergedMeterMain = Object.assign(meterMain, properties);
    removePropertiesIfNullOrUndefined(mergedMeterMain);
    this.serviceBus.send('update_equipment', {
      editor: this.editor,
      domain: this.domain,
      equipment: mergedMeterMain
    });
  }

  protected findOptionByValue(value: string | number = ''): (option: Option) => boolean {
    return (option: Option): boolean => `${option.value}` === `${value}`;
  }
}
