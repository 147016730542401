import {
  LyraFormElementsDesignTool, LyraDivider, LyraGrid, LyraModal, LyraTypography, LyraLoader
} from '@aurorasolar/lyra-ui-kit';
import { Flexbox } from '@aurorasolar/lyra-ui-kit/lib/components/Layout';
import { EThemeComponentColor } from '@aurorasolar/lyra-ui-kit/lib/components/Theme/EThemeComponentColor';
import { observer } from 'mobx-react-lite';
import type { ReactElement } from 'react';
import React, {
  useEffect, useState
} from 'react';
import { SeismicDesignParameters } from '../../../../domain/models/SiteDesign/SeismicDesignParameters';
import { ELEVATION_FEET_LIMIT } from '../../../../domain/models/Constants';
import type { SiteOtherPropertiesViewModel } from '../../../../stores/UiStore/EnvironmentalProps/ViewModels/SiteTopographicalViewModel';
import useStore from '../../../../stores/useStore';

const { NumberField } = LyraFormElementsDesignTool.FormElementsDesignTool;

const SiteOtherPropertiesModal = (): ReactElement => {
  const { modal } = useStore();
  const [viewModel] = useState(modal.viewModel as SiteOtherPropertiesViewModel);

  useEffect((): void => {
    viewModel.loadSelectionOptions();
  }, [viewModel]);

  return (
    <LyraModal.Modal
      $show={true}
      title="Edit Other Site Properties"
      color={EThemeComponentColor.PURPLE}
      onClickLeftButton={(): void => viewModel.closeModal()}
      onClickCloseButton={(): void => viewModel.closeModal()}
      onClickRightButton={(): void => viewModel.save()}
      rightButtonDisabled={!viewModel.isValid}
    >
      <Flexbox
        dir="column"
        style={{
          width: 450
        }}
        align="flex-start"
      >
        {viewModel.isLoading ? (
          <LyraLoader.Loader text="Loading other site properties..." />
        ) : (
          <>
            <LyraTypography.Heading fontWeight="bold" margin="0" type="h5">
              Topographical Condition
            </LyraTypography.Heading>
            <LyraGrid.Grid
              options={viewModel.topographicalConditionOptions}
              hasCustomValues={false}
              selected={viewModel.currentTopographicalCondition}
              columns={4}
              gutter={1.5}
              selectedOptionColor={EThemeComponentColor.PURPLE}
              onSelect={viewModel.changeTopographicalCondition}
            />

            <LyraDivider.Divider
              direction={'horizontal'}
              thickness={'1px'}
              length={'400px'}
              color={EThemeComponentColor.WHITE}
              className={'m-s'}
            />
            <LyraTypography.Heading fontWeight="bold" margin="0" type="h5">
              Average Grade of Property
            </LyraTypography.Heading>
            <LyraGrid.Grid
              options={viewModel.averagePropertyGradeOptions}
              hasCustomValues={false}
              selected={viewModel.currentAveragePropertyGrade}
              columns={4}
              gutter={1.4}
              selectedOptionColor={EThemeComponentColor.PURPLE}
              onSelect={viewModel.changeAverageGrade}
            />

            <LyraDivider.Divider
              direction={'horizontal'}
              thickness={'1px'}
              length={'400px'}
              color={EThemeComponentColor.WHITE}
              className={'m-s'}
            />

            <Flexbox dir="row" align="flex-start">
              <Flexbox dir="column">
                <LyraTypography.Heading fontWeight="bold" margin="0" type="h5">
                  Spectral Acceleration (S<sub>DS</sub>)
                </LyraTypography.Heading>
                <NumberField.NumberField
                  value={viewModel.sds}
                  measure="g"
                  onChange={viewModel.changeSds}
                  limits={SeismicDesignParameters.SDS_LIMITS}
                  fractionDigits={3}
                  allowOutOfRange={false}
                  step={0.001}
                  className="pt-xxxs"
                  width="135px"
                />
              </Flexbox>
              <LyraDivider.Divider
                direction={'vertical'}
                thickness={'1px'}
                length={'55px'}
                color={EThemeComponentColor.WHITE}
                className={'mh-m'}
              />
              <Flexbox dir="column">
                <LyraTypography.Heading fontWeight="bold" margin="0" type="h5">
                  Elevation
                </LyraTypography.Heading>
                <NumberField.NumberField
                  className="pt-xxxs"
                  onChange={viewModel.changeElevation}
                  limits={ELEVATION_FEET_LIMIT}
                  value={viewModel.elevationInFeet}
                  measure="ft"
                  width="135px"
                  allowOutOfRange={false}
                  step={1}
                />
              </Flexbox>
            </Flexbox>
          </>
        )}
      </Flexbox>
    </LyraModal.Modal>
  );
};

export default observer(SiteOtherPropertiesModal);
