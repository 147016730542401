import styled from 'styled-components';

export const TooltipGuideName = styled.span`
  margin-right: 5px;
  vertical-align: middle;
`;

export const TooltipGuideKey = styled.span`
  display: inline-block;
  width: 15px;
  height: 15px;
  color: #4a4a4a;
  font-size: 9px;
  line-height: 15px;
  text-align: center;
  text-transform: uppercase;
  background-image: linear-gradient(to bottom, #ffffff, #e2e2e2 100%);
  border-radius: 2px;
  vertical-align: middle;
`;

export const TooltipContent = styled.div`
  white-space: nowrap;
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
  color: #fff;
  box-sizing: border-box;
  width: auto;
  min-height: 12px;
`;
