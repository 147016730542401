import {
  LyraButtonGroup, LyraFormElementsDesignTool
} from '@aurorasolar/lyra-ui-kit';
import { observer } from 'mobx-react-lite';
import type { ReactElement } from 'react';
import React, { useCallback } from 'react';
import { H4 } from '@aurorasolar/lyra-ui-kit/lib/components/FontFamily/Fonts';
import useStore from '../../../../../stores/useStore';
import type { PermitPackageViewModel } from '../../../../../stores/UiStore/Modal/ViewModels/PermitPackageModal/PermitPackageViewModel';
import {
  AdvancedSettings,
  GroupWrapper,
  LeftView,
  RowContainer,
  RadioWrapper,
  RightView,
  StatusText,
  TitleText
} from '../styles';
import OptionView from './OptionView/OptionView';

const {
  ButtonGroup, MultiButtonGroup
} = LyraButtonGroup;
const { Toggle } = LyraFormElementsDesignTool.FormElementsDesignTool;

const titleBlockOptions = [
  {
    name: 'APN',
    value: 'APN'
  },
  {
    name: 'Name of Residence',
    value: 'NAME_OF_RESIDENCE'
  },
  {
    name: 'Customer of Record',
    value: 'CUSTOMER_OF_RECORD'
  }
];

const mapStyleOptions = [
  {
    name: 'None',
    value: 'NONE'
  },
  {
    name: 'Aerial Image',
    value: 'AERIAL_MAP'
  },
  {
    name: 'Street Map',
    value: 'ROAD_MAP'
  }
];

const electricalDiagramOptions = [
  {
    name: 'Single-Line',
    value: 'SINGLE_LINE_DIAGRAM'
  },
  {
    name: 'Three-Line',
    value: 'THREE_LINE_DIAGRAM'
  },
  {
    name: 'Both',
    value: 'both'
  }
];

const NEC_2020 = 'NEC_2020';
const CEC_2022 = 'CEC_2022';
const MULTI_OPTION = 'APN';
const BOTH_DIAGRAMS = 'both';

const Settings = observer((): ReactElement => {
  const {
    modal, domain
  } = useStore();
  const permitPackageViewModel = modal.viewModel as PermitPackageViewModel;
  const {
    titleBlock,
    electricalPlan,
    sitePlacards,
    attachmentPlan,
    fireSafetyPlan,
    sitePlan,
    includeElectricalCalculations,
    setIncludeElectricalCalculations
  } = permitPackageViewModel.documentGenerationOptions.advancedSettings;

  const onSelect = useCallback(
    (value: string, lastInteractedWithValue: string[]): void => {
      const isCurrentValueSelected = lastInteractedWithValue.includes(value);
      const selectedValuesWithoutAPN = lastInteractedWithValue.filter((item: string): boolean => item !== MULTI_OPTION);
      const otherSelectedValueNotAPN = selectedValuesWithoutAPN[selectedValuesWithoutAPN.length - 1];
      const isSelectedAPN = lastInteractedWithValue.includes(MULTI_OPTION);

      if (!isCurrentValueSelected && (value === MULTI_OPTION || isSelectedAPN)) {
        titleBlock.setPropertyDescriptions(lastInteractedWithValue.length ? lastInteractedWithValue : [value]);
      } else if (isSelectedAPN && lastInteractedWithValue.length > 1) {
        titleBlock.setPropertyDescriptions([MULTI_OPTION, otherSelectedValueNotAPN]);
      } else {
        titleBlock.setPropertyDescriptions([value]);
      }
    },
    [titleBlock]
  );

  const isInstallerALicensedContractor = permitPackageViewModel.isInstallerALicensedContractor;
  const selectedSchematicType =
    electricalPlan.schematicTypes.length > 1 ? BOTH_DIAGRAMS : electricalPlan.schematicTypes[0];
  const electricalCode = permitPackageViewModel.electricalCode;
  const showRotateSiteMapPlacardOption = !(electricalCode === NEC_2020 || electricalCode === CEC_2022);

  return (
    <>
      <GroupWrapper>
        <RowContainer>
          <LeftView>
            <H4>Customer Identifier in the Title Block</H4>
          </LeftView>
          <MultiButtonGroup
            options={titleBlockOptions}
            selected={titleBlock.propertyDescriptions}
            onSelect={onSelect}
            height="36px"
          />
        </RowContainer>
        <RowContainer>
          <LeftView>
            <H4>Show Installer Logo in Title Block</H4>
          </LeftView>
          <RightView>
            <RadioWrapper>
              <StatusText status={!titleBlock.showContractorLogo}>No</StatusText>
              <Toggle.Toggle
                className="toggleBig"
                id="showLogo"
                checked={titleBlock.showContractorLogo}
                onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
                  titleBlock.setShowLogo(event.target.checked);
                }}
              />
              <StatusText status={titleBlock.showContractorLogo}>Yes</StatusText>
            </RadioWrapper>
          </RightView>
        </RowContainer>

        {isInstallerALicensedContractor && (
          <>
            <RowContainer>
              <LeftView>
                <H4>Show Installer Info in Title Block</H4>
              </LeftView>
              <RightView>
                <RadioWrapper>
                  <StatusText status={!titleBlock.showContractorInfo}>No</StatusText>
                  <Toggle.Toggle
                    className="toggleBig"
                    id="showContractorInfo"
                    checked={titleBlock.showContractorInfo}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
                      titleBlock.setShowInfo(event.target.checked);
                    }}
                  />
                  <StatusText status={titleBlock.showContractorInfo}>Yes</StatusText>
                </RadioWrapper>
              </RightView>
            </RowContainer>

            <RowContainer>
              <LeftView>
                <H4>Show Engineer of Record in Title Block</H4>
              </LeftView>
              <RightView>
                <RadioWrapper>
                  <StatusText status={!titleBlock.showEngineerOfRecordInfo}>No</StatusText>
                  <Toggle.Toggle
                    className="toggleBig"
                    id="showEORInfo"
                    checked={titleBlock.showEngineerOfRecordInfo}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
                      titleBlock.setShowEngineerOfRecordInfo(event.target.checked);
                    }}
                  />
                  <StatusText status={titleBlock.showEngineerOfRecordInfo}>Yes</StatusText>
                </RadioWrapper>
              </RightView>
            </RowContainer>
          </>
        )}

        <RowContainer>
          <LeftView>
            <H4>Include Electrical Calculations Worksheet</H4>
          </LeftView>
          <RightView>
            <RadioWrapper>
              <StatusText status={!includeElectricalCalculations}>No</StatusText>
              <Toggle.Toggle
                className="toggleBig"
                id="electricalCalculations"
                checked={includeElectricalCalculations}
                onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
                  setIncludeElectricalCalculations(event.target.checked);
                }}
              />
              <StatusText status={includeElectricalCalculations}>Yes</StatusText>
            </RadioWrapper>
          </RightView>
        </RowContainer>
      </GroupWrapper>

      <AdvancedSettings>
        <GroupWrapper>
          <TitleText>Site Plan</TitleText>
          <RowContainer>
            <LeftView>
              <H4>Choose Base Map Style</H4>
            </LeftView>
            <ButtonGroup
              options={mapStyleOptions}
              selected={sitePlan.sitePlanBackgroundImage}
              onSelect={(value: string | number): void => sitePlan.setSitePlanBackgroundImage(value.toString())}
              height="36px"
            />
          </RowContainer>
          {!domain.project.site.parcel.hasBoundary && sitePlan.sitePlanBackgroundImage === 'ROAD_MAP' && (
            <OptionView
              id="includeParcelBoundaries"
              shortDesc="Show Parcel Boundaries"
              viewState={sitePlan.includeParcelBoundaries}
              onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
                sitePlan.setIncludeParcelBoundaries(event.target.checked);
              }}
            />
          )}
          {domain.project.site.parcel.hasBoundary && (
            <OptionView
              id="showParcelBoundaryDistanceLines"
              shortDesc="Show Parcel Boundary Distance Lines"
              viewState={sitePlan.includeParcelBoundaryDistanceLines}
              onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
                sitePlan.setShowParcelBoundaryDistanceLines(event.target.checked);
              }}
            />
          )}
          <OptionView
            id="gasMeterClearanceRequirements"
            shortDesc="Show gas meter clearance requirements"
            viewState={fireSafetyPlan.showGasMeterClearanceRequirements}
            onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
              fireSafetyPlan.setShowGasMeterClearanceRequirements(event.target.checked);
            }}
          />
        </GroupWrapper>

        <GroupWrapper>
          <TitleText>Electrical Diagram</TitleText>
          <OptionView
            id="singleLineDiagram"
            shortDesc="Include Voltage Drop in Conductor Table"
            viewState={electricalPlan.includeVoltageDropInConductorTable}
            onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
              electricalPlan.setIncludeVoltageDropInConductorTable(event.target.checked);
            }}
          />
          <RowContainer>
            <LeftView>
              <H4>Electrical Diagram Style</H4>
            </LeftView>
            <ButtonGroup
              options={electricalDiagramOptions}
              selected={selectedSchematicType}
              onSelect={(value: string | number): void => electricalPlan.setElectricalDiagramType(value.toString())}
              height="36px"
            />
          </RowContainer>
        </GroupWrapper>

        <GroupWrapper>
          <TitleText>Site Placards</TitleText>
          <OptionView
            id="showNonAnsiLabelsAsRed"
            shortDesc="Show all non-ANSI safety labels as red"
            viewState={sitePlacards.showNonAnsiLabelsAsRed}
            onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
              sitePlacards.setShowNonAnsiLabelsAsRed(event.target.checked);
            }}
          />
          <OptionView
            id="forceInclusion"
            shortDesc="Show Site Map Placard, even if optional"
            viewState={sitePlacards.forceInclusion}
            onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
              sitePlacards.setForceInclusion(event.target.checked);
            }}
          />
          <OptionView
            id="showAsRed"
            shortDesc="Show Site Map Placard as red"
            viewState={sitePlacards.showAsRed}
            onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
              sitePlacards.setShowAsRed(event.target.checked);
            }}
          />
          {showRotateSiteMapPlacardOption && (
            <OptionView
              id="rotateToOrientationOfPocLocation"
              shortDesc="Rotate Site Map Placard to orientation of main service panel location"
              viewState={sitePlacards.rotateToOrientationOfPocLocation}
              onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
                sitePlacards.setRotateToOrientationOfPocLocation(event.target.checked);
              }}
            />
          )}
        </GroupWrapper>

        <GroupWrapper>
          <TitleText>Attachment Plan</TitleText>
          <OptionView
            id="showAttachmentPoints"
            shortDesc="Show attachment points"
            viewState={attachmentPlan.showAttachmentPoints}
            onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
              attachmentPlan.setShowAttachmentPoints(event.target.checked);
            }}
          />
          <OptionView
            id="showDeadLoadCalculations"
            shortDesc="Show dead load calculations"
            viewState={attachmentPlan.showDeadLoadCalculations}
            onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
              attachmentPlan.setShowDeadLoadCalculations(event.target.checked);
            }}
          />
          <OptionView
            id="showRoofFaceEdgeLength"
            shortDesc="Show roof face edge length"
            viewState={attachmentPlan.showRoofFaceEdgeLength}
            onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
              attachmentPlan.setShowRoofFaceEdgeLength(event.target.checked);
            }}
          />
        </GroupWrapper>

        <GroupWrapper>
          <TitleText>Fire Safety Plan</TitleText>
          <OptionView
            id="includePlan"
            shortDesc="Include Fire Safety Plan"
            viewState={fireSafetyPlan.include}
            onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
              fireSafetyPlan.setInclude(event.target.checked);
            }}
          />
        </GroupWrapper>
      </AdvancedSettings>
    </>
  );
});

export default Settings;
