import type { PropsTheme } from 'styled-components';
import styled from 'styled-components';
import type { Color } from '../../../../domain/typings';
import Flexbox from '../../../../ui/components/Layout/Flexbox';

type Props = {
  bgcolor?: string;
  hideLabel?: boolean;
};

function BackgroundColor(props: Props): string {
  return props.bgcolor ? `background-color: ${props.bgcolor}` : 'background-color: #8f8f8f';
}

export const ProjectInfoStyle = styled(Flexbox).attrs({
  dir: 'column',
  justify: 'space-evenly'
})`
  padding: 0px 20px;
  width: 350px;
  height: 100%;
`;

export const Label = styled(Flexbox).attrs({
  justify: 'flex-end',
  align: 'center'
})<Props>`
  display: ${({ hideLabel }: Props): string => (hideLabel ? 'none' : '')};
  visibility: ${({ hideLabel }: Props): string => (hideLabel ? 'hidden' : 'visible')};
`;

export const Id = styled.div`
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  margin: 0 10px;
`;

export const Address = styled.div<PropsTheme>`
  color: ${({ theme }: PropsTheme): Color => theme.colors.secondaryFont};
  font-size: 10px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
