import * as FileSaver from 'file-saver';
import set from 'lodash/set';
import {
  action, computed, observable
} from 'mobx';
import config from '../../../../../config/config';
import type { IDocumentResponse } from '../../../../../domain/entities/Documents/DocumentsResponse';
import {
  calculateEnergyProductionEstimate, handleApiError, notify
} from '../../../../../utils/helpers';
import { ERROR } from '../../../../../domain/models/Constants';
import type {
  IPreliminarySystemDatasheetParams,
  ISystemDatasheetOptions
} from '../../../../../domain/entities/Documents/SystemDatasheetOptions';
import type DomainStore from '../../../../DomainStore/DomainStore';
import { BaseViewModel } from '../../BaseViewModel';
import type { ModalStore } from '../../Modal';
import type EditorStore from '../../../../EditorStore/EditorStore';
import type { DesignWorkspace } from '../../../WorkspaceStore/workspaces/DesignWorkspace';
import { applyBlobMimeHackForFirefox } from '../../../../../utils/request';
import { validateEmail } from '../../../../../utils/validations';
import { SystemDatasheetGeneratedEvent } from '../../../../../services/analytics/DesignToolAnalyticsEvents';

interface ISystemDatasheetOptionViewModelDependencies {
  readonly modal: ModalStore;
  readonly domain: DomainStore;
  readonly workspace: DesignWorkspace;
  readonly editor: EditorStore;
}

export class SystemDatasheetOptionViewModel extends BaseViewModel {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @observable formContent: any = {
    includes: {},
    system: {},
    colorScheme: {},
    company: {},
    options: {},
    name: ''
  };

  @observable
  errors: Record<string, string> = {};

  @observable
  generatingDocument = false;

  @observable
  optionsLoaded = false;

  readonly propCodeUI = 'system_datasheet_option_modal';
  override readonly editor: EditorStore;
  private readonly workspace: DesignWorkspace;
  defaultValuesOptionsPromise: Promise<void>;
  private resolveDefaultValuesOptionsPromise!: () => void;

  constructor(dependencies: ISystemDatasheetOptionViewModelDependencies) {
    super(dependencies);
    this.workspace = dependencies.workspace;
    this.defaultValuesOptionsPromise = new Promise((resolve: () => void): void => {
      this.resolveDefaultValuesOptionsPromise = resolve;
    });
    this.getDefaultValues();
    this.editor = dependencies.editor;
  }

  @action
  downloadSystemDatasheet = (): void => {
    if (!this.documentGenerationOptionsLoaded) {
      return;
    }
    this.generatingDocument = true;
    const currentDesign = this.domain.design;
    if (Object.keys(this.errors).length) {
      return Object.keys(this.errors).forEach((error: string): void => {
        notify(`Error: ${this.errors[error]}`, ERROR);
      });
    }

    const apiCallPromise = currentDesign.state.isSynchronized()
      ? this.documentsService.generateSystemDatasheet(currentDesign.id, this.formContent)
      : this.documentsService.generatePreliminarySystemDataSheet(
        currentDesign.id,
        this.buildPreliminarySystemDatasheetRequest()
      );

    apiCallPromise
      .then((response: IDocumentResponse): void => {
        FileSaver.saveAs(applyBlobMimeHackForFirefox(response.file), response.fileName);
        this.closeModal();
        config.analytics?.trackEvent(new SystemDatasheetGeneratedEvent(this.domain));
        notify('System Datasheet is downloaded', 'confirmation');
      })
      .catch(handleApiError('Could not generate system datasheet'))
      .finally((): void => {
        this.generatingDocument = false;
      });
  };

  @computed
  get annualProductionEstimate(): number {
    return this.formContent?.system.annualProductionEstimate || 0;
  }

  @computed
  get documentGenerationOptionsLoaded(): boolean {
    return this.formContent && this.optionsLoaded;
  }

  @action.bound
  setFieldValue(path: string, value: boolean | number | string): void {
    if (path === 'system.annualProductionEstimate' && !value) {
      // If the user deletes the value in the UI, we restore the original estimate
      value = this.estimateAnnualEnergyProduction();
    }
    this.validateFieldValue(path, value);
    if (path === 'company.representative.email' && !value) {
      set(this.formContent, path, undefined);
    } else {
      set(this.formContent, path, value);
    }
  }

  private validateFieldValue(field: string, value: boolean | number | string): void {
    if (field === 'company.representative.email') {
      if (!value || validateEmail(value as string)) {
        delete this.errors[field];
      } else {
        this.errors[field] = 'Email not valid';
      }
    }
  }

  private estimateAnnualEnergyProduction(): number {
    const {
      roofTopArrayAreas, system
    } = this.domain.design;
    if (!roofTopArrayAreas) {
      return 0;
    }
    const { energyProductionEstimate } = calculateEnergyProductionEstimate(roofTopArrayAreas, system);

    return energyProductionEstimate;
  }

  private buildPreliminarySystemDatasheetRequest(): IPreliminarySystemDatasheetParams {
    const projectData = this.domain.project.toData();
    const IDesignData = this.domain.design.toData();
    return {
      options: this.formContent,
      project: projectData,
      design: IDesignData
    };
  }

  private getDefaultValues(): void {
    const currentDesign = this.domain.design;
    const apiCallPromise = currentDesign.state.isSynchronized()
      ? this.documentsService.getSystemDatasheetOptions(currentDesign.id)
      : this.documentsService.getPreliminarySystemDatasheetOptions(
        currentDesign.id,
        this.domain.project.participants.installerId
      );
    apiCallPromise
      .then((response: ISystemDatasheetOptions): void => {
        this.formContent = response;

        // API sends us wrong value after initial document generation,
        // so we decided to always calculate it on the FE side;
        this.formContent.system.annualProductionEstimate = this.estimateAnnualEnergyProduction();
        if (!this.formContent.options) {
          this.formContent.options = {};
        }
        this.formContent.options.creatorFirstName = config.user.firstName;
        this.formContent.options.creatorLastName = config.user.lastName;
        this.optionsLoaded = true;
        this.resolveDefaultValuesOptionsPromise();
      })
      .catch(handleApiError('Could not retrieve system datasheet generation options'));
  }

  override dispose() {
    // do nothing
  }
}
