import type { PropsTheme } from 'styled-components';
import styled from 'styled-components';
import type { Color } from '../../../domain/typings';
import Flexbox from '../../../ui/components/Layout/Flexbox';

const ButtonWrapper = styled(Flexbox).attrs({
  as: 'button',
  justify: 'center',
  align: 'center',
  dir: 'column'
})`
  width: 65px;
  height: 46px;
  border-radius: 4px;
  border: solid 1px ${({ theme }: PropsTheme): Color => theme.colors.tertiary};
  background-color: ${({ theme }: PropsTheme): Color => theme.colors.whiteBg};
  cursor: pointer;
  transition: all 0.3s ease;
  &.active,
  &:hover {
    border: 2px solid ${({ theme }: PropsTheme): Color => theme.colors.main3};
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.4);
  }

  @media (min-width: 2560px) {
    width: 75px;
  }
`;

const CustomUnits = styled.span`
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: bold;
  color: ${({ theme }: PropsTheme): Color => theme.colors.secondaryFont};
`;

const AngleGrades = styled.span`
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
  color: ${({ theme }: PropsTheme): Color => theme.colors.secondaryFont};
`;

export {
  AngleGrades, ButtonWrapper, CustomUnits
};
