import type { PropsTheme } from 'styled-components';
import styled from 'styled-components';
import type { Color } from '../../../../../../domain/typings';
import { Header } from '../../styles';

export const ImagesContainer = styled.div`
  margin-bottom: 38px;
`;

export const SubHeader = styled.span`
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: normal;
  color: ${({ theme }: PropsTheme): Color => theme.colors.grey1};
`;

export const MainHeader = styled.span`
  white-space: nowrap;
  margin-right: 10px;
`;

export const GalleryHeader = styled(Header)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;
