import isNil from 'lodash/isNil';
import { observable } from 'mobx';
import type { ITemperatureDesignParameterData } from './TemperatureDesignParameter';
import { TemperatureDesignParameter } from './TemperatureDesignParameter';

export interface ITemperatureDesignParametersData {
  lowTemperature?: ITemperatureDesignParameterData;
  highTemperature?: ITemperatureDesignParameterData;
}

export class TemperatureDesignParameters {
  @observable
  lowTemperature?: TemperatureDesignParameter;
  @observable
  highTemperature?: TemperatureDesignParameter;

  constructor(data?: ITemperatureDesignParametersData) {
    this.lowTemperature = data?.lowTemperature ? new TemperatureDesignParameter(data.lowTemperature) : undefined;
    this.highTemperature = data?.highTemperature ? new TemperatureDesignParameter(data.highTemperature) : undefined;
  }

  toData = (): ITemperatureDesignParametersData | undefined => {
    if (isNil(this.lowTemperature) && isNil(this.highTemperature)) {
      return undefined;
    }
    return {
      lowTemperature: this.lowTemperature?.toData(),
      highTemperature: this.highTemperature?.toData()
    };
  };

  copy = (): TemperatureDesignParameters => new TemperatureDesignParameters(this.toData());
}
