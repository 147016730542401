import styled from 'styled-components';

export const ContainerBranch = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 16px 0 9px 0;
`;

export const TitleBranchContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const TitleBranch = styled.span`
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #4a4a4a;
  text-indent: 4px;
`;

export const ListButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding-right: 4px;
`;

export const DCACRatioContainer = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #d8d8d8;
`;

export const DCACRatioTitleContainer = styled.div`
  width: 100%;
  padding: 5.8px 0 12.5px 0;
`;
export const DCACRatioProgressBarContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  height: 54px;
  margin-bottom: 14px;
  & > section {
    width: 100%;
    & > div {
      width: 100%;
    }
  }
`;

export const DCACRatioTitle = styled.span`
  font-family: 'Roboto', sans-serif;
  font-size: 8px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: normal;
  color: #4a4a4a;
`;
