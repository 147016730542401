import type Store from '../Store';
import { GuardStore } from './GuardStore/GuardStore';
import MapStore from './MapStore/MapStore';
import { NotificationStore } from './Notifications/Notifications';
import { WizardStore } from './Wizard/Wizard';
import { EnvironmentalPropsStore } from './EnvironmentalProps/EnvironmentalPropsStore';
import { ToolbarStore } from './ToolbarStore/Toolbar';
import { WorkspaceStore } from './WorkspaceStore';
import { DesignCreationWizardStore } from './Wizard/DesignCreationWizardStore';
import { BuildingPropertiesStore } from './Properties/BuildingProperties/BuildingPropertiesStore';
import { RoofFacePropertiesStore } from './Properties/RoofProperties/RoofFacePropertiesStore';
import { RoofProtrusionStore } from './Properties/RoofProtrusion/RoofProtrusionStore';
import { ModalStore } from './Modal/Modal';
import { PropertiesStore } from './Properties/Properties';
import { PanelsStore } from './Panels/Panels';
import SmartGuidesStore from './SmartGuidesStore/SmartGuidesStore';

export class UiStore {
  readonly panels: PanelsStore;
  readonly properties: PropertiesStore;
  readonly modal: ModalStore;
  readonly guardStore: GuardStore;
  readonly map: MapStore;
  readonly notification: NotificationStore;
  readonly wizard: WizardStore;
  readonly roofProtrusion: RoofProtrusionStore;
  readonly environmentalProps: EnvironmentalPropsStore;
  readonly toolbar: ToolbarStore;
  readonly roofFaceProps: RoofFacePropertiesStore;
  readonly smartGuides: SmartGuidesStore;
  readonly workspace: WorkspaceStore;
  readonly buildingProperties: BuildingPropertiesStore;
  readonly designCreationWizardStore: DesignCreationWizardStore;

  constructor(rootStore: Store) {
    this.designCreationWizardStore = new DesignCreationWizardStore();
    this.guardStore = new GuardStore(rootStore);
    this.map = new MapStore();
    this.notification = new NotificationStore();
    // We don't have a proper services initialisation that'd allow passing references
    // to a non-initialized services, hence this function returning root store.
    this.properties = new PropertiesStore(() => rootStore);
    this.panels = new PanelsStore();
    this.modal = new ModalStore(rootStore);
    this.wizard = new WizardStore();
    this.smartGuides = new SmartGuidesStore(rootStore);
    this.toolbar = new ToolbarStore(rootStore, this);
    this.roofProtrusion = new RoofProtrusionStore(rootStore, this);
    this.environmentalProps = new EnvironmentalPropsStore(rootStore, this);
    this.roofFaceProps = new RoofFacePropertiesStore(rootStore, this);
    this.buildingProperties = new BuildingPropertiesStore(rootStore, this);
    this.workspace = new WorkspaceStore(rootStore, this);
  }
}
