import type { IObservableArray } from 'mobx';
import {
  action, computed, observable
} from 'mobx';
import { MathUtils } from 'three';
import * as Sentry from '@sentry/react';
import type { DesignWorkspace } from '../../stores/UiStore/WorkspaceStore/workspaces/DesignWorkspace';
import { SentryException } from '../../utils/sentryLog';
import { DesignStep } from '../models/Design/DesignState';

import { getRootStore } from '../../stores/RootStoreInversion';
import { isCurrentWorkspace } from '../../stores/UiStore/WorkspaceStore/utils';
import config, { UI_MODE } from '../../config/config';
import type { IStage } from './IStage';
import type { IStageManager } from './IStageManager';
import type { StageFactoryParameters } from './StageFactory';
import { StageFactory } from './StageFactory';

interface IStageManagerDependencies<T extends IStage> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  stageFactoryParameters?: StageFactoryParameters<any, T>[];
  designWorkspace: DesignWorkspace;
  stageManagerName?: string;
}

export class StageManager<T extends IStage> implements IStageManager<T> {
  @observable
  stages: IObservableArray<T> = observable([]);
  @observable
  currentIndex: number = 0;
  stageFactory = new StageFactory();
  designWorkspace: DesignWorkspace;
  stageTransitionInProgress: boolean = false;
  stageManagerName?: string;

  /**
   * Creates an instance of StageManager.
   * {StageFactoryParameters<any, T>[]} [stageFactoryParameters=[]], parameters to
   * create dynamically stages
   * {boolean} [stateRecovery=true] optional parameter, if false it won't try to
   * recover the state of the stages, it just will go to the first stage
   */
  constructor(stageManagerDependencies: IStageManagerDependencies<T>) {
    const {
      stageFactoryParameters = [], designWorkspace, stageManagerName
    } = stageManagerDependencies;

    this.stageManagerName = stageManagerName;
    this.designWorkspace = designWorkspace;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    stageFactoryParameters.forEach((value: StageFactoryParameters<any>): void => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const stage = this.stageFactory.createStage<any, T>(value);
      this.stages.push(stage);
    });
  }

  @computed
  get current(): number {
    return this.currentIndex + 1;
  }

  @computed
  get currentStage(): T {
    // In some places we rely on currentStage being undefined, e.g.
    // this.currentIndex is out of bounds. A proper refactoring is required,
    // but as a temporary solution we can add this check. To do that without
    // changing return type we can add ts-ignore.
    // @ts-ignore
    return this.currentIndex < this.stages.length ? this.stages[this.currentIndex] : undefined;
  }

  @computed
  get steps(): number {
    return this.stages.length;
  }

  @computed
  get lastValidStage(): string {
    const currentUserStep = getRootStore().domain.optionalDesign?.state.currentUserStep() ?? DesignStep.ARRAY_PLACEMENT;
    return DesignStep[currentUserStep];
  }

  @action.bound
  async next(): Promise<void> {
    try {
      if (this.stageTransitionInProgress) {
        return;
      }
      this.stageTransitionInProgress = true;
      const nextNumber = this.currentIndex + 1;
      const currentStage = this.stages[this.currentIndex];

      await this.designWorkspace.saveManually();
      await currentStage?.beforeContinue?.();

      if (currentStage?.canContinue) {
        currentStage.continue?.();
        if (this.isValidStep(nextNumber)) {
          const nextStep = nextNumber < this.stages.length ? this.stages[nextNumber] : undefined;
          this.currentIndex = nextNumber;
          Sentry.setTag('currentStage', `${this.stageManagerName}(${nextNumber})`);
          nextStep?.setUp?.();
        }
      }
    } catch (error) {
      SentryException('It was not possible to continue to the next stage', error);
    } finally {
      this.stageTransitionInProgress = false;
    }
  }

  async resume(lastValidStage: string): Promise<void> {
    try {
      if (this.stageTransitionInProgress) {
        return;
      }
      this.stageTransitionInProgress = true;

      const nextNumber = this.currentIndex + 1;
      const currentStage = this.currentIndex < this.stages.length ? this.stages[this.currentIndex] : undefined;

      if (currentStage?.canContinue) {
        currentStage.continue?.();
        if (this.isValidStep(nextNumber)) {
          const nextStep = this.stages[nextNumber];
          this.currentIndex = nextNumber;
          Sentry.setTag('currentStage', `${this.stageManagerName}(${nextNumber})`);
          nextStep.resume?.(lastValidStage);
        } else {
          this.clear();
        }
      }
    } catch (error) {
      SentryException('It was not possible to continue', error);
    } finally {
      this.stageTransitionInProgress = false;
    }
  }

  async previous(): Promise<void> {
    try {
      if (this.stageTransitionInProgress) {
        return;
      }
      this.stageTransitionInProgress = true;

      const currentStage = this.stages[this.currentIndex];
      // Even if we're going back, we're "continuing", so there are things
      // that we might need to do before going back.
      await currentStage?.beforeContinue?.(true);
      await this.designWorkspace.saveManually();
      const prevNumber = this.currentIndex - 1;
      if (this.isValidStep(prevNumber)) {
        const previousStep = this.stages[prevNumber];
        this.currentStage.cancel?.();
        this.currentIndex = prevNumber;
        Sentry.setTag('currentStage', `${this.stageManagerName}(${prevNumber})`);
        previousStep.setUp?.();
      }
    } catch (error) {
      SentryException('It was not possible to return to the previous stage', error);
    } finally {
      this.stageTransitionInProgress = false;
    }
  }

  @action.bound
  clear(): void {
    for (let i = this.currentIndex + 1; i--; ) {
      if (i < this.stages.length && i >= 0) {
        this.stages[i]?.dispose?.();
      }
    }
    this.currentIndex = 0;
    Sentry.setTag('currentStage', `${this.stageManagerName}(0)`);
    this.stages.clear();
  }

  getStageData(step: number): IStage | undefined {
    if (this.isValidStep(step)) {
      return this.stages[step];
    }
  }

  isLastStage(): boolean {
    return this.stages[this.stages.length - 1].id === this.currentStage.id;
  }

  // Checks if current stage is before passed stage
  isBeforeStage(stage: string, inclusive?: boolean): boolean {
    const index = this.stages.findIndex((item: IStage): boolean => stage === item.id);
    return inclusive ? this.currentIndex <= index : this.currentIndex < index;
  }

  // Checks if current stage is after passed stage
  isAfterStage(stage: string, inclusive?: boolean): boolean {
    const index = this.stages.findIndex((item: IStage): boolean => stage === item.id);
    return inclusive ? this.currentIndex >= index : this.currentIndex > index;
  }

  /**
   * Method that try to recover the last state of the "wizard"
   * If current stage can't continue, automatically breaks the loop
   */
  async recoverLastState(): Promise<void> {
    config.analytics?.disableDesignToolEventTracking();
    for (const stage of this.stages) {
      if (this.lastValidStage !== stage.id && isCurrentWorkspace('design')) {
        await this.resume(this.lastValidStage);
      } else {
        break;
      }
    }
    if (config.featureFlag.uiMode === UI_MODE.AURORA && this.isBeforeStage(DesignStep.ELECTRICAL_BOS)) {
      throw new Error('This design is not allowed to be opened on this stage');
    }
    config.analytics?.enableDesignToolEventTracking();
  }

  private isValidStep(step: number): boolean {
    return step === MathUtils.clamp(step, 0, this.stages.length);
  }
}
