import type { IBaseToolDependencies } from '../Tool';
import { BaseTool } from '../Tool';
import { ECursor } from '../../../../domain/typings';
import { PANNING_TOOL_ID } from './constants';

export class PanningTool extends BaseTool {
  readonly id: string = PANNING_TOOL_ID;
  readonly icon: string = 'pan';
  readonly title: string = 'Pan';
  readonly description: string = this.title;

  constructor(dependencies: IBaseToolDependencies) {
    super(dependencies);
    this.cursor = ECursor.PANNING;
  }

  whenSelected(): void {
    if (this.editor.cameraControls) {
      this.editor.cameraControls.isPanningToolSelected = true;
    }
    this.updateCursor(this.cursor);
  }

  whenDeselected(): void {
    if (this.editor.cameraControls) {
      this.editor.cameraControls.isPanningToolSelected = false;
    }
    this.updateCursor(this.cursor);
  }

  dispose(): void {
    /** */
  }
}
