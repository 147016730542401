import { Vector3 } from 'three';
import { getRootStore } from '../../../../stores/RootStoreInversion';
import type { SceneObjectType } from '../../Constants';
import type { EquipmentExposure } from '../EquipmentExposure';
import { EquipmentExposureTypes } from '../../../entities/SiteDesign/EquipmentExposure';
import { Marker } from './../Marker';
import type { ServiceEntranceEquipmentType } from '../SiteEquipmentTypesAndHelpers';

export type GenericMarkerDependencies = Readonly<{
  sceneObjectType: SceneObjectType;
  color: string;
  /**
   * This type can be used for site equipment as well as service entrance
   * equipment (which is a subset of site equipment). So `serviceEntranceEquipmentType`
   * field is optional.
   */
  serviceEntranceEquipmentType?: ServiceEntranceEquipmentType;
}>;

export class GenericSiteEquipment extends Marker {
  label: string = '';
  readonly propertyId: string;
  exposure: EquipmentExposure = {
    type: EquipmentExposureTypes.OUTDOOR,
    constrained: true
  };
  readonly isServiceEntranceEquipment: boolean = false;

  constructor({
    sceneObjectType, color, serviceEntranceEquipmentType
  }: GenericMarkerDependencies) {
    super();

    this.type = sceneObjectType;
    this.propertyId = sceneObjectType;
    this.color = color;
    this.realWorldZValue = Marker.defaultEquipmentZValueInWorldUnits();

    const renderHeight = getRootStore().editor.getObjectRenderHeight(sceneObjectType);
    this.draw(new Vector3(undefined, undefined, renderHeight));
  }
}
