import styled, { PropsTheme } from 'styled-components';
import { Color } from '../../typings';

type StepProps = PropsTheme & {
  shpe?: boolean;
};

export const PipelineContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 16px;
  position: relative;
  border-left: 1px solid ${({ theme }: PropsTheme): Color => theme.colors.grayColor6};
  border-right: 1px solid ${({ theme }: PropsTheme): Color => theme.colors.grayColor6};
  width: auto
`;

export const ProgressBar = styled.ul`
  counter-reset: step;
  display: flex;
`;

export const StepContainer = styled.li<StepProps>`
  list-style-type: none;
  position: relative;
  text-align: center;
  width: 100px;
  margin-bottom: 3px;
  &:before {
    background-color: ${({ theme }: PropsTheme): Color => theme.colors.whiteBg};
    border: 1px solid ${({ theme }: PropsTheme): Color => theme.colors.grayColor6};
    border-radius: ${({shpe}: StepProps): string => shpe ? '50%' : '10%'};
    color: ${({ theme }: PropsTheme): Color => theme.colors.grayColor9};
    content: counter(step);
    counter-increment: step;
    display: block;
    height: 25px;
    line-height: 25px;
    margin: 2px auto 5px;
    text-align: center;
    width: 25px;
  }
  &:after {
    background-color: ${({ theme }: PropsTheme): Color => theme.colors.quaternary};
    content: "";
    position: absolute;
    top: 15px;
    left: -50%;
    height: 2px;
    width: 100%;
    z-index: -2;
  }
  &:first-child:after {
    content: none;
  }
  &.active {
    color: ${({ theme }: PropsTheme): Color => theme.colors.whiteBg};
  }
  &.ready {
    color: ${({ theme }: PropsTheme): Color => theme.colors.grayColor9};
  }
  &.active:before {
    background-color: ${({ theme }: PropsTheme): Color => theme.colors.main1};
    border-color: ${({ theme }: PropsTheme): Color => theme.colors.main1};
    color: ${({ theme }: PropsTheme): Color => theme.colors.whiteBg};
  }
  &.ready:before {
    border-color: ${({ theme }: PropsTheme): Color => theme.colors.grayColor9};
  }
  &.ready + &:after {
    background-color: ${({ theme }: PropsTheme): Color => theme.colors.grayColor9};
    height: 4px;
  }
  
  @media(max-width: 1260px) {
    width: 70px;
  }
`;

export const Title = styled.p`
  color: ${({ theme }: PropsTheme): Color => theme.colors.grayColor9};
  font-family: Roboto;
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: 0.5px;
  &.active {
    font-weight: bold;
  }
`;
