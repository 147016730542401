import {
  action, computed, observable
} from 'mobx';
import type { Object3D } from 'three';
import debounce from 'lodash/debounce';
import throttle from 'lodash/throttle';

import defer from 'lodash/defer';
import type { IStringingOptionsResponseData as StringingOption } from '../../domain/entities/StringingOption/IStringingOptionData';
import type { Stringing } from '../../domain/graphics/stringing/Stringing';
import type { Design } from '../../domain/models/Design/Design';
import type { IPvSystemCircuitData } from '../../domain/models/PvSystem/PvSystemCircuit';
import type { IInverterInfo } from '../../domain/stages/DesignStages/ElectricalDesignStage';
import type PvModule from '../../domain/models/SiteDesign/PvModule';
import type { IInverterSelected } from '../../domain/models/SupplementalData/IInverterInfo';

import type { Inverters } from '../../domain/models/PvSystem/Inverters';
import { stackTracedLog } from '../../utils/stackTracedLog';
import {
  getLastParallelString,
  type SortedAndAggregatedStrings,
  stringingOptionExists,
  getStringModulesLimits
} from './stringingFunctions';
import {
  syncPvModulesOrderInStringsWithDomain,
  applyPvModuleToString,
  balanceParallelStrings,
  createNewString,
  createStringFromCircuit,
  getIdListByType,
  buildInverterInfoList,
  getStringByPvModule,
  setValidationRatingForAllStrings,
  hasMpptAvailable,
  mergeStrings,
  selectAndSetInverterForNewString,
  selectParallelStrings,
  selectSortedParallelStrings,
  sortStringingOptions,
  getSortedAndAggregatedStrings,
  getStringLabel,
  ApplyPvModuleToStringResult,
  InverterSelectionForNewStringResult
} from './stringingFunctions';

interface IStringingServerDependencies {
  design: Design;
  roofFacePvModules: PvModule[];
  stringingOptions: StringingOption[];
  toggleCanvasLoaderCursorState: (_: boolean) => void;
  callbacks?: IStringingServiceCallbacks;
}

interface IStringingServiceCallbacks {
  addToScene(object: Object3D): void;

  removeFromScene(object: Object3D): void;

  clearStrings(): void;

  systemSummarySetStringing(id: string): void;

  systemSummarySetOption(selected: Stringing): void;

  systemSummaryDeleteStringing(selected: Stringing): void;

  openMpptAndMultiInverterSelectionPanel(invertersData: IInverterSelected[], stringing: Stringing): void;

  isMpptAndMultiInverterSelectionPanelOpen(): boolean;

  onStringingEnd(strings: Stringing[], isNewString: boolean, otherStringsInParallel?: Stringing[]): Promise<void>;
}

export enum ProcessStringingUserInputResult {
  NoMPPTAvailable = 'NoMPPTAvailable',
  NeedToSelectMPPTOrInverter = 'NeedToSelectMPPTOrInverter',
  FailedValidation = 'FailedValidation',
  NoInverterAvailable = 'NoInverterAvailable',
  NoInverterDataAvailable = 'NoInverterDataAvailable',
  NoStringSelected = 'NoStringSelected',
  Success = 'Success',
  Idle = 'Idle',
  Error = 'Error'
}

enum DebugLoggingLevel {
  Basic = 'Basic',
  Detailed = 'Detailed'
}

class StringingService {
  // Proxy to a function to avoid dependency cycle in Stringing model
  getStringModulesLimits = getStringModulesLimits;
  // Computed from design and state:

  // static configuration:
  // local storage configuration:
  private debugLogging: boolean = localStorage.stringingDebugLogging ?? false;
  // static configuration:
  private debugLoggingLevel: DebugLoggingLevel = DebugLoggingLevel.Basic;
  // static configuration:
  private debugLoggingWithStackTrace: boolean = false;

  private debouncedDebug = debounce((...args: any[]): void => {
    this.detailedDebugLog(...args);
  }, 500);

  @observable
  private lastStringUserInteractedWithId?: string;

  // All
  @observable
  strings: Stringing[] = [];

  @observable
  stringingOptions!: StringingOption[];

  @observable
  inverters: IInverterInfo[] = [];

  // flags:
  private selectedStringNeedsInverterSelection: boolean = false;
  @observable
  private finishingEditingStringInProgress: boolean = false;
  @observable
  private editingStringInProgress: boolean = false;
  @observable
  private creatingStringInProgress: boolean = false;

  // TODO: should we need support for parallel stringing?
  private initialModules?: PvModule[][];

  @observable
  private isFirstModule: boolean = false;

  // The one we're editing and the rest of parallel strings:
  selectedStrings = observable<Stringing>([]);
  private throttledSetStringingOption = throttle((): void => {
    if (this.activeSelectedString) {
      this.callbacks.systemSummarySetOption(this.activeSelectedString);
    }
  }, 100);

  // Other:
  resolveMpptOrInverterSelectionPromise?: () => void;

  // Input params:
  design!: Design;
  private roofFacePvModules!: PvModule[];
  private callbacks!: IStringingServiceCallbacks;

  @computed
  get addModulesToStringBeginning(): boolean {
    return this.isFirstModule;
  }

  @computed
  get activeSelectedString(): Stringing | null {
    return (
      this.selectedStrings.find(
        (string: Stringing): boolean => string.serverId === this.lastStringUserInteractedWithId
      ) || null
    );
  }

  @computed
  get selectedStringsModules(): PvModule[] {
    return this.selectedStrings.reduce(
      (acc: PvModule[], current: Stringing): PvModule[] => [...acc, ...current.getModules()],
      []
    );
  }

  // Computed props:
  getInverterType(): string {
    return this.stringingOptions?.[0]?.type || '';
  }

  toggleCanvasLoaderCursorState: (_: boolean) => void = (): void => {
    /* dummy */
  };

  get useDcOptimizers(): boolean {
    return !!this.design.system.equipment.optimizers?.definition;
  }
  get selectedStringIsNew(): boolean {
    return !!this.activeSelectedString && !this.strings.includes(this.activeSelectedString);
  }

  @computed
  get isParallelStringingMode(): boolean {
    return this.selectedStrings.length > 1;
  }

  getStrings(): Stringing[] {
    return this.strings;
  }

  // Actions:
  @action.bound
  initialise(stringingServiceDependencies: IStringingServerDependencies): Stringing[] {
    const {
      design, roofFacePvModules, stringingOptions, toggleCanvasLoaderCursorState, callbacks
    } =
      stringingServiceDependencies;

    if (this.debugLogging) {
      // eslint-disable-next-line no-console
      console.warn('StringingService debug mode enabled');
      // @ts-ignore
      window.debugStringingService = this;
    }

    this.design = design;
    this.roofFacePvModules = roofFacePvModules;
    this.stringingOptions = sortStringingOptions({
      rawStringingOptions: stringingOptions,
      design: design
    });
    if (callbacks) {
      this.callbacks = callbacks;
    }

    this.inverters = buildInverterInfoList(design);
    this.initExistingStringing();

    this.toggleCanvasLoaderCursorState = toggleCanvasLoaderCursorState;

    return this.strings;
  }

  // Called after inverter definition change instead of re-initialising the service.
  updateStringingOptions(stringingOptions: StringingOption[]): void {
    this.stringingOptions = this.design
      ? sortStringingOptions({
        rawStringingOptions: stringingOptions,
        design: this.design
      })
      : stringingOptions;
  }

  @action.bound
  clearStringing(): void {
    this.detailedDebugLog('Clearing strings');

    this.strings
      .concat(this.activeSelectedString ? [this.activeSelectedString] : [])
      .forEach((string: Stringing): void => {
        this.callbacks.removeFromScene(string.mesh);
        string.resetPvModulesColor();
        string.removeLabels();
        string.replaceModules([]);
      });

    this.detailedDebugLog('Resetting selected string');
    this.selectedStrings.clear();
    this.strings = [];

    this.callbacks?.clearStrings();
  }

  @action.bound
  addInverter(inverterInfo: IInverterInfo): void {
    this.detailedDebugLog('Adding inverter: ', inverterInfo);
    this.inverters.push(inverterInfo);
  }

  @action.bound
  setInvertersUnits(units: number): void {
    this.detailedDebugLog('Set inverter units', units);
    this.inverters = this.inverters.map((inverter: IInverterInfo): IInverterInfo => {
      inverter.units = units;
      return inverter;
    });
  }

  @action.bound
  updateMicroinvertersTotal(circuits: IPvSystemCircuitData[]): void {
    this.detailedDebugLog('Updating microinverts total count');
    this.inverters = this.inverters.map((inverter: IInverterInfo): IInverterInfo => {
      inverter.units = circuits.reduce((acc: number, current: IPvSystemCircuitData): number => {
        const microinverterList = getIdListByType({
          circuitSources: current.sources!,
          sourceType: 'MICROINVERTER'
        });
        return acc + microinverterList.length;
      }, 0);
      return inverter;
    });
    this.detailedDebugLog('Updated microinverter units in inverters list:', this.inverters);
  }

  @action.bound
  resetInverters(): void {
    this.detailedDebugLog('Resets inverters');
    this.inverters = [];
  }

  @action.bound
  removeInverter(inverterId: string): void {
    this.detailedDebugLog('Removing inverter by id: ', inverterId);
    this.strings = this.strings.filter((string: Stringing): boolean => {
      if (string.getInverterId() !== inverterId) {
        return true;
      }

      this.detailedDebugLog('Clearing string: ', string);
      string.removeLabels();
      this.callbacks.removeFromScene(string.mesh);
      string.resetPvModulesColor();

      return false;
    });
  }

  bindCallbacks(callbacks: IStringingServiceCallbacks): void {
    this.callbacks = callbacks;
  }

  private debugLog(...args: unknown[]): void {
    if (this.debugLogging) {
      if (this.debugLoggingWithStackTrace) {
        // eslint-disable-next-line no-restricted-syntax
        stackTracedLog(...args);
      } else {
        // eslint-disable-next-line no-console
        console.log(...args);
      }
    }
  }
  private detailedDebugLog(...args: unknown[]): void {
    if (this.debugLogging && this.debugLoggingLevel === DebugLoggingLevel.Detailed) {
      if (this.debugLoggingWithStackTrace) {
        // eslint-disable-next-line no-restricted-syntax
        stackTracedLog(...args);
      } else {
        // eslint-disable-next-line no-console
        console.log(...args);
      }
    }
  }

  private setUnbalancedParallelStringsModuleDiscrepancy(string: Stringing): void {
    const allStrings = mergeStrings(this.strings, [string]);
    const sortedParallelStrings = selectSortedParallelStrings({
      strings: allStrings,
      targetString: string
    });
    if (sortedParallelStrings.length === 1 || sortedParallelStrings[sortedParallelStrings.length - 1] !== string) {
      return;
    }

    const combinedModulesCount = sortedParallelStrings.reduce(
      (modulesSum: number, currentString: Stringing): number => modulesSum + currentString.getModules().length,
      0
    );
    let minDistanceToTheNextValidConfiguration = Infinity;
    for (
      let numberOfParallelStrings = 2;
      numberOfParallelStrings <= string.maxStringsPerMppt;
      numberOfParallelStrings++
    ) {
      for (
        let parallelStringLength = string.acceptableStringModulesLimit.lower;
        parallelStringLength <= string.acceptableStringModulesLimit.upper;
        parallelStringLength++
      ) {
        const nextParallelStringsModulesCount = parallelStringLength * numberOfParallelStrings;
        const distanceToTheNextValidConfiguration = nextParallelStringsModulesCount - combinedModulesCount;
        if (
          distanceToTheNextValidConfiguration >= 0
          && distanceToTheNextValidConfiguration < minDistanceToTheNextValidConfiguration
        ) {
          minDistanceToTheNextValidConfiguration = distanceToTheNextValidConfiguration;
        }
      }
    }
    const distanceToTheNextValidConfiguration = minDistanceToTheNextValidConfiguration;

    string.unbalancedStringLabelController.setModulesDiscrepancyUntilBalanced(
      distanceToTheNextValidConfiguration === Infinity ? 0 : distanceToTheNextValidConfiguration
    );

    string.redraw();
  }

  private async handleEditOneModule(pvModule: PvModule): Promise<void> {
    this.detailedDebugLog('Edit one module: ', pvModule);
    if (!this.activeSelectedString) {
      return;
    }

    const applyPvModuleToStringResult = applyPvModuleToString({
      stringingOptions: this.stringingOptions,
      strings: this.strings,
      selectedStrings: this.selectedStrings,
      moduleToProcessTarget: this.activeSelectedString!,
      moduleToProcess: pvModule,
      targetStringEnd: this.isFirstModule ? 'start' : 'finish'
    });
    this.debugLog('[handleEditOneModule] applyPvModuleToStringResult: ', applyPvModuleToStringResult);
    const allStrings = mergeStrings(this.strings, [this.activeSelectedString]);
    let sortedParallelStrings = selectSortedParallelStrings({
      strings: allStrings,
      targetString: this.activeSelectedString
    });
    const currentIsFirstModule = this.isFirstModule;

    switch (applyPvModuleToStringResult) {
      case ApplyPvModuleToStringResult.CreateNewStringInParallelForTheSameMppt: {
        this.detailedDebugLog('CreateNewStringInParallelForTheSameMppt');

        const selectedExistingString = this.activeSelectedString!;
        const lastParallelString = getLastParallelString({
          parallelStrings: this.strings,
          activeString: selectedExistingString
        });

        const mpptId = selectedExistingString.getMpptId();
        const inverterId = selectedExistingString.getInverterId();
        const inverters = this.design.system?.equipment?.inverters;
        const invertersSupplementalData = this.design.supplementalData?.invertersSelected ?? [];
        const prevParallelMpptStringNumber = lastParallelString?.parallelMpptStringNumber;
        await this.finishEditingString(true);

        // Set selected strings and update initial modules so that in case of failed
        // stringing finish on  the new string the previous one could be reset to a correct configuration
        this.selectedStrings.replace(
          selectSortedParallelStrings({
            strings: this.strings,
            targetString: selectedExistingString
          })
        );
        this.setInitialModules();

        this.addAndSelectNewString(
          pvModule,
          inverters,
          invertersSupplementalData,
          true,
          mpptId,
          inverterId,
          prevParallelMpptStringNumber
        );
        this.isFirstModule = currentIsFirstModule;

        // New string has the highest parallelMpptStringNumber, but if we're adding
        // it to the string beginning, we want to push it to the beginning in parallelMpptStringNumber too.
        if (this.isFirstModule) {
          // Place the new string in the beginning of the parallel strings list
          this.selectedStrings.forEach((string: Stringing): void => {
            if (string === this.activeSelectedString) {
              string.parallelMpptStringNumber = 1;
            } else {
              string.parallelMpptStringNumber++;
            }
          });
        }

        // Sort parallel strings in case the selected one is supposed to be the first one
        sortedParallelStrings = selectSortedParallelStrings({
          strings: allStrings,
          targetString: this.activeSelectedString!
        });
        this.selectedStrings.replace(sortedParallelStrings);

        const applyPvModuleToStringResult = applyPvModuleToString({
          strings: this.strings,
          selectedStrings: this.selectedStrings,
          moduleToProcess: pvModule,
          moduleToProcessTarget: this.activeSelectedString!,
          targetStringEnd: 'start' // Could be 'end' too because there would be a sole module
        });
        this.debugLog(
          '[handleEditOneModule->CreateNewStringInParallelForTheSameMppt] applyPvModuleToStringResult: ',
          applyPvModuleToStringResult
        );

        // To balance parallel strings...
      }
      // fall through
      case ApplyPvModuleToStringResult.BalanceParallelStrings: {
        const lastParallelString = getLastParallelString({
          parallelStrings: this.strings,
          activeString: this.activeSelectedString
        });
        const lastParallelStringModulesCount = lastParallelString?.getModules().length;
        const otherParallelStrings = sortedParallelStrings.filter(
          ({ serverId }: Stringing): boolean => serverId !== lastParallelString?.serverId
        );
        const numberOfSiblingParallelStrings = otherParallelStrings.length;
        const currentParallelStringsLength = otherParallelStrings[0]?.getModules().length ?? 0;

        if (numberOfSiblingParallelStrings > 0 && lastParallelStringModulesCount !== currentParallelStringsLength) {
          balanceParallelStrings({
            strings: this.strings,
            selectedString: this.activeSelectedString,
            stringingOptions: this.stringingOptions
          });
          this.highlightParallelStrings(this.activeSelectedString);
        }

        // First and last
        this.setUnbalancedParallelStringsModuleDiscrepancy(sortedParallelStrings[0]);
        this.setUnbalancedParallelStringsModuleDiscrepancy(sortedParallelStrings[sortedParallelStrings.length - 1]);

        sortedParallelStrings.forEach((string: Stringing, index: number, allStrings: Stringing[]): void =>
          string.placeStringingEndings(index, allStrings.length)
        );

        break;
      }
      case ApplyPvModuleToStringResult.SwitchToEditingPreviousParallelStringWithModuleTransfer: {
        this.detailedDebugLog('SwitchToEditingPreviousParallelStringWithModuleTransfer');
        const currentModules = this.activeSelectedString.getModules();
        const stringToTransferModulesTo =
          sortedParallelStrings[this.isFirstModule ? 1 : sortedParallelStrings.length - 2];
        const modulesToTransferTo = stringToTransferModulesTo.getModules();
        let modulesCount = currentModules.length;
        while (modulesCount-- > 0) {
          const module = currentModules[this.isFirstModule ? 'pop' : 'shift']();
          modulesToTransferTo[this.isFirstModule ? 'unshift' : 'push'](module!);
        }
      }
      // fall through
      case ApplyPvModuleToStringResult.SwitchToEditingPreviousParallelString: {
        this.detailedDebugLog('SwitchToEditingPreviousParallelString');
        let stringToSelect: Stringing | null = null;
        if (this.isFirstModule) {
          sortedParallelStrings.shift();
          stringToSelect = sortedParallelStrings[0];
        } else {
          sortedParallelStrings.pop();
          stringToSelect = sortedParallelStrings[sortedParallelStrings.length - 1];
        }

        this.deleteSelectedString(false);
        this.selectString(stringToSelect);

        break;
      }
      default:
    }
  }

  @computed
  get isAnyAsyncActionInProgress(): boolean {
    return this.finishingEditingStringInProgress || this.editingStringInProgress || this.creatingStringInProgress;
  }

  // Used from stringingTool
  @action.bound
  async processPvModule({
    pvModule,
    calledFromBatchedProcessing = true,
    allowStringSwitching = true
  }: {
    pvModule: PvModule;
    calledFromBatchedProcessing?: boolean;
    allowStringSwitching?: boolean;
  }): Promise<ProcessStringingUserInputResult> {
    if (this.isAnyAsyncActionInProgress || this.callbacks.isMpptAndMultiInverterSelectionPanelOpen()) {
      return ProcessStringingUserInputResult.Idle;
    }

    if (calledFromBatchedProcessing) {
      this.detailedDebugLog('Process PV module: ', pvModule);
    } else {
      this.debugLog('Process PV module: ', pvModule);
    }

    this.creatingStringInProgress = true;
    const beforeReturn = (): void => {
      this.selectedStrings.forEach((string: Stringing, index: number, allStrings: Stringing[]): void =>
        string.placeStringingEndings(index, allStrings.length)
      );
      this.creatingStringInProgress = false;
    };

    const existingStringOnPvModule = getStringByPvModule({
      pvModuleSelected: pvModule,
      strings: this.strings
    });

    this.detailedDebugLog('Create or start editing string or add/remove one module');

    const clickOnCurrentlySelectedString =
      existingStringOnPvModule
      && this.selectedStrings.some((string: Stringing): boolean => string.serverId === existingStringOnPvModule.serverId);
    const currentlySelectedStringModules = (clickOnCurrentlySelectedString && this.selectedStringsModules) || [];
    const clickOnFirstModule = currentlySelectedStringModules[0] === pvModule;
    const clickOnLastModule = currentlySelectedStringModules[currentlySelectedStringModules.length - 1] === pvModule;
    const shouldActiveStringEndBeSwitched =
      clickOnCurrentlySelectedString
      && ((clickOnFirstModule && !this.isFirstModule) || (clickOnLastModule && this.isFirstModule))
      // In this case, switching the "active" end of the string, something to avoid when dragging
      && allowStringSwitching;

    if (shouldActiveStringEndBeSwitched) {
      this.debugLog('Switching the active string end');
    }

    if (localStorage.detailedExtraDebugLogging) {
      // Condition to avoid duplicating param computation
      this.debugLog({
        clickOnCurrentlySelectedString,
        currentlySelectedStringModules,
        clickOnFirstModule,
        clickOnLastModule,
        allowStringSwitching,
        shouldActiveStringEndBeSwitched
      });
    }

    if (shouldActiveStringEndBeSwitched) {
      this.isFirstModule = !this.isFirstModule;
      this.selectString(existingStringOnPvModule);

      beforeReturn();

      return ProcessStringingUserInputResult.Success;
    } else if (
      // Found exising string.
      // Switching if no selected or if it's another string.
      existingStringOnPvModule
      && !this.selectedStrings.some(
        (string: Stringing): boolean => string.serverId === existingStringOnPvModule.serverId
      )
      && allowStringSwitching
    ) {
      this.detailedDebugLog('Found existing string: ', existingStringOnPvModule);

      const existingModules = existingStringOnPvModule.getModules();
      const isParallelStringing = existingStringOnPvModule.maxStringsPerMppt > 1;
      const isFirstParallelString = existingStringOnPvModule.parallelMpptStringNumber === 1;

      this.isFirstModule = existingModules.length > 1 && existingModules[0] === pvModule;
      let isSecondModule = existingModules.length > 1 && existingModules[1] === pvModule;

      // If it's not the first string in parallel stringing, we need to ignore it for dragging
      if (isParallelStringing && !isFirstParallelString) {
        this.isFirstModule = false;
        isSecondModule = false;
      }

      // When we click on the first string's non-first module, we should just select the last parallel string

      await this.saveCurrentlySelectedStringBeforeUnselecting(existingStringOnPvModule);

      if (this.isFirstModule || isSecondModule) {
        this.selectString(existingStringOnPvModule);
      } else {
        // Select last parallel string even if we click on any other parallel string unless the click
        // was on the first or second (for stringing back) modules of the first string
        this.selectString(
          selectSortedParallelStrings({
            strings: this.strings,
            targetString: existingStringOnPvModule
          }).reverse()[0]
        );
      }

      beforeReturn();

      return ProcessStringingUserInputResult.Success;
    } else if (
      // Editing an already selected string or creating a new one (if we can)
      this.activeSelectedString
      || hasMpptAvailable({
        strings: this.strings,
        stringingOptionsList: this.stringingOptions
      })
    ) {
      this.detailedDebugLog('New string has MPPT available or editing selected string');

      const invertersSupplementalData = this.design.supplementalData?.invertersSelected ?? [];

      if (!this.activeSelectedString) {
        if (
          !allowStringSwitching
          // Do not add new string if the maximum number of AC branches has been reached:
          || (this.stringingOptions[0]?.maximumAllowableNumberOfBranches
            && this.strings.filter((string: Stringing): boolean => string.parallelMpptStringNumber === 1).length
              >= this.stringingOptions[0].maximumAllowableNumberOfBranches)
        ) {
          beforeReturn();
          return ProcessStringingUserInputResult.Idle;
        }

        const inverters = this.design.system?.equipment?.inverters;
        beforeReturn();

        return this.addAndSelectNewString(pvModule, inverters, invertersSupplementalData);
      } else if (
        !stringingOptionExists({
          selectedString: this.activeSelectedString,
          stringingOptionsList: this.stringingOptions
        })
      ) {
        this.openMpptAndMultiInverterSelectionPanel(this.activeSelectedString, invertersSupplementalData);
        beforeReturn();

        return ProcessStringingUserInputResult.NeedToSelectMPPTOrInverter;
      } else {
        await this.handleEditOneModule(pvModule);

        this.setAllStringsRatings();
      }

      this.throttledSetStringingOption();
      beforeReturn();

      return ProcessStringingUserInputResult.Success;
    } else if (!this.activeSelectedString) {
      this.detailedDebugLog('No selected string to edit and can\'t create new one');
      beforeReturn();

      return ProcessStringingUserInputResult.NoMPPTAvailable;
    }

    this.detailedDebugLog('Nothing to create or edit.');
    beforeReturn();

    return ProcessStringingUserInputResult.Idle;
  }

  @action.bound
  async processPvModules(pvModules: PvModule[]): Promise<ProcessStringingUserInputResult> {
    if (pvModules.length > 1) {
      this.debugLog(`Process ${pvModules.length} PV modules`);
    }

    let lastResult: ProcessStringingUserInputResult = ProcessStringingUserInputResult.Idle;
    for (let pvModule of pvModules) {
      lastResult = await this.processPvModule({
        pvModule: pvModule,
        allowStringSwitching: false,
        calledFromBatchedProcessing: pvModules.length > 1
      });
      if (lastResult === ProcessStringingUserInputResult.Idle) {
        continue;
      }
      if (lastResult !== ProcessStringingUserInputResult.Success) {
        // If any of the modules failed, we can quit early
        return lastResult;
      }
    }

    return lastResult;
  }

  @action.bound
  async finishEditingString(forceEntry: boolean = false): Promise<ProcessStringingUserInputResult> {
    if (!forceEntry && this.isAnyAsyncActionInProgress) {
      return ProcessStringingUserInputResult.Idle;
    }

    this.debugLog('Finish editing string. forceEntry: ', forceEntry);

    this.toggleCanvasLoaderCursorState(true);
    this.finishingEditingStringInProgress = true;
    const beforeReturn = (): void => {
      this.selectedStrings.forEach((string: Stringing, index: number, allStrings: Stringing[]): void =>
        string.placeStringingEndings(index, allStrings.length)
      );
      this.toggleCanvasLoaderCursorState(false);
      this.finishingEditingStringInProgress = false;
      this.isFirstModule = false;
    };
    this.detailedDebugLog('Finish editing string');

    if (!this.activeSelectedString) {
      this.detailedDebugLog('No selected string to finish editing');

      beforeReturn();
      return ProcessStringingUserInputResult.NoStringSelected;
    }

    if (this.selectedStringNeedsInverterSelection) {
      this.debouncedDebug('Can\'t finish string, it needs inverter selection.');

      beforeReturn();
      return ProcessStringingUserInputResult.Idle;
    }

    this.throttledSetStringingOption();

    const selectedStringIsNew = this.selectedStringIsNew;

    if (selectedStringIsNew) {
      const sortedAndAggregatedStrings: SortedAndAggregatedStrings = getSortedAndAggregatedStrings({
        strings: this.activeSelectedString ? mergeStrings(this.strings, [this.activeSelectedString]) : this.strings,
        design: this.design
      });
      this.selectedStrings.forEach((string: Stringing): void => {
        string.stringLabelController.setStringLabel(
          getStringLabel({
            string: string,
            inverterType: this.getInverterType(),
            sortedAndAggregatedStrings: sortedAndAggregatedStrings
          })
        );
      });
    }

    this.selectedStrings.forEach((string: Stringing): void => string.finishStringing());

    this.unselectSelectedStrings();

    this.detailedDebugLog('Calling onStringingEnd');

    const stringRef = this.activeSelectedString!;

    try {
      await this.saveSelectedStrings();

      if (selectedStringIsNew && this.activeSelectedString) {
        this.strings.push(this.activeSelectedString);
        this.activeSelectedString.drawStringLabels();
      }

      this.selectedStrings.clear();
    } catch (e) {
      this.detailedDebugLog('Finish string failed', e);

      // Setting selected strings to previous, correct configuration
      this.selectedStrings?.forEach((string: Stringing, index: number): void => {
        string.resetPvModulesColor();
        string.replaceModules(this.initialModules![index]);
        string.placeStringingEndings(index, this.selectedStrings.length);
        string.redraw();
      });

      if (this.isParallelStringingMode && !selectedStringIsNew && stringRef) {
        this.detailedDebugLog('Resetting selected strings modules');
        this.highlightParallelStrings(stringRef);
      } else {
        this.detailedDebugLog('Deleting the string');
        // Delete invalid stringing:
        this.selectString(stringRef);
        this.deleteSelectedString(false);

        this.selectedStrings.clear();
        this.unselectSelectedStrings();
      }
      this.setAllStringsRatings();
    }

    this.redrawLabels();

    beforeReturn();
    return ProcessStringingUserInputResult.Success;
  }

  @action.bound
  private async saveSelectedStrings(): Promise<void> {
    if (this.activeSelectedString) {
      this.detailedDebugLog('Save selected editing string');
      await this.callbacks.onStringingEnd(
        this.selectedStrings.toJS(),
        this.isParallelStringingMode ? false : this.selectedStringIsNew
      );
      this.selectedStrings.forEach((string: Stringing): void => {
        string.resetModifiedState();
      });
    }
  }

  @action.bound
  private async saveCurrentlySelectedStringBeforeUnselecting(newSelectedString: Stringing): Promise<void> {
    if (
      this.activeSelectedString
      && this.activeSelectedString!.serverId !== newSelectedString.serverId
      && this.activeSelectedString!.isModified()
    ) {
      this.detailedDebugLog('Save selected editing string if switched.');
      await this.finishEditingString(true);
    }
  }

  private redrawLabels(): void {
    this.debouncedDebug('Redraw strings labels');
    const sortedAndAggregatedStrings = getSortedAndAggregatedStrings({
      strings: this.activeSelectedString ? mergeStrings(this.strings, [this.activeSelectedString]) : this.strings,
      design: this.design
    });

    mergeStrings(this.strings, this.activeSelectedString ? [this.activeSelectedString] : []).forEach(
      (string: Stringing): void => {
        string.stringLabelController.setStringLabel(
          getStringLabel({
            string: string,
            inverterType: this.getInverterType(),
            sortedAndAggregatedStrings: sortedAndAggregatedStrings
          })
        );
        string.drawStringLabels();
      }
    );
  }

  // Used to select stringing from systemSummary.
  @action.bound
  async selectStringById(toSelectServerId: string): Promise<void> {
    this.detailedDebugLog('Select string by id: ', toSelectServerId);
    const string = this.strings.find(({ serverId }: { serverId: string }): boolean => serverId === toSelectServerId);

    if (string) {
      await this.saveCurrentlySelectedStringBeforeUnselecting(string);
      this.selectString(string);
    }
  }

  private unselectSelectedStrings(): void {
    this.selectedStrings.forEach((string: Stringing): void => string.unselect());
  }

  /**
   * @returns index of the selected string in the parallel strings array if
   *          it's parallel stringing, otherwise void.
   */
  @action.bound
  selectString(string: Stringing): void | number {
    this.detailedDebugLog('Select string: ', string, this.strings);

    this.unselectSelectedStrings();
    this.highlightParallelStrings(string);

    const stringsInParallel = selectSortedParallelStrings({
      strings: this.strings,
      targetString: string
    });
    this.selectedStrings.replace(stringsInParallel);
    this.lastStringUserInteractedWithId = string.serverId;

    this.callbacks.systemSummarySetStringing(this.activeSelectedString!.serverId);
    this.throttledSetStringingOption();

    this.setInitialModules();
  }

  setInitialModules(): void {
    if (this.selectedStrings) {
      this.initialModules = this.selectedStrings.map((string: Stringing): PvModule[] => [...string.getModules()]);
    }
  }

  highlightParallelStrings(string: Stringing): void {
    const stringsInParallel = selectSortedParallelStrings({
      strings: this.strings,
      targetString: string
    });
    this.detailedDebugLog('Highlight parallel strings', stringsInParallel);
    if (stringsInParallel.length === 1) {
      stringsInParallel[0].select();
    } else if (stringsInParallel.length >= 2) {
      stringsInParallel[0].selectAsParallelStringListBeginning();
      stringsInParallel[stringsInParallel.length - 1].selectAsParallelStringListEnding();
      for (let i = 1; i < stringsInParallel.length - 1; i++) {
        stringsInParallel[i].selectAsParallelStringListMiddle();
      }
    }
  }

  @action.bound
  deleteSelectedString(withParallelStrings: boolean = true): void {
    this.detailedDebugLog('Deleting selected stringing: ', this.activeSelectedString);
    if (this.activeSelectedString) {
      this.unselectSelectedStrings();
      this.deleteString(this.activeSelectedString, withParallelStrings);
      if (withParallelStrings) {
        this.selectedStrings.clear();
      } else {
        const deletedParallelStringNumber = this.activeSelectedString.parallelMpptStringNumber;
        this.selectedStrings.splice(this.selectedStrings.indexOf(this.activeSelectedString), 1);
        if (deletedParallelStringNumber === 1) {
          this.selectedStrings.forEach((string: Stringing): void => {
            string.parallelMpptStringNumber--;
          });
        }
      }
    }
  }

  @action.bound
  deleteString(stringToDelete: Stringing, withParallelStrings: boolean): void {
    const stringsToDelete = withParallelStrings
      ? selectParallelStrings({
        strings: this.strings,
        targetString: stringToDelete
      })
      : [stringToDelete];

    stringsToDelete.forEach((string: Stringing): void => {
      this.detailedDebugLog('Deleting string: ', string);
      const selectedStringServerId = string.serverId;

      string.resetPvModulesColor();
      string.removeLabels();
      this.callbacks.systemSummaryDeleteStringing(string);

      this.strings = this.strings.filter(
        ({ serverId }: { serverId: string }): boolean => serverId !== selectedStringServerId
      );

      this.callbacks.removeFromScene(string.mesh);
    });

    defer((): void => {
      this.redrawLabels();
    });
  }

  syncPvModulesOrderInStringsWithDomain(): void {
    syncPvModulesOrderInStringsWithDomain({
      circuits: this.design.system.circuits ?? [],
      design: this.design,
      pvModules: this.roofFacePvModules,
      stringsToSortModulesIn: this.strings
    });
  }

  private initExistingStringing(): void {
    this.clearStringing();

    const circuits = this.design.system.circuits ?? [];
    this.detailedDebugLog('Init existing strings', circuits);
    this.strings = circuits.reduce(
      (strings: Stringing[], circuit: IPvSystemCircuitData): Stringing[] =>
        strings.concat(
          createStringFromCircuit({
            circuit: circuit,
            design: this.design,
            pvModules: this.roofFacePvModules,
            stringingOptionsList: this.stringingOptions,
            otherStrings: strings
          })
        ),
      []
    );

    this.detailedDebugLog('Strings created from domain model: ', this.strings);
    this.strings.forEach((string: Stringing): void => {
      this.setUnbalancedParallelStringsModuleDiscrepancy(string);
      this.callbacks.addToScene(string.mesh);
      this.callbacks.systemSummarySetOption(string);
    });

    if (this.design.system.equipment.inverters) {
      const { sortedStrings } = getSortedAndAggregatedStrings({
        strings: this.activeSelectedString ? mergeStrings(this.strings, [this.activeSelectedString]) : this.strings,
        design: this.design
      });
      this.strings = sortedStrings;
    }
    this.redrawLabels();

    this.setAllStringsRatings();
  }

  // Private actions:
  private addAndSelectNewString(
    pvModule: PvModule,
    inverters: Inverters | undefined,
    invertersData: IInverterSelected[],
    allowParallelStringing: boolean = false,
    prevStringMpptId?: string,
    prevStringInverterId?: string,
    prevParallelMpptStringNumber?: number
  ): ProcessStringingUserInputResult {
    this.detailedDebugLog('Creating a new string and selecting inverter for it');
    const newString = createNewString({
      pvModule: pvModule,
      strings: this.strings,
      stringingOptionsList: this.stringingOptions
    });
    this.selectedStrings.replace(
      // Setting only the new string as selected, but later we'll set all parallel strings as
      // selected. That's a limitation of the current algorithm
      [newString]
    );
    this.lastStringUserInteractedWithId = newString.serverId;

    this.callbacks.addToScene(this.activeSelectedString!.mesh);

    const inverterSelectionForNewStringResult = selectAndSetInverterForNewString({
      string: this.activeSelectedString!,
      inverters: inverters,
      invertersData: invertersData,
      useParallelStringing: allowParallelStringing,
      prevStringMpptId: prevStringMpptId,
      prevStringInverterId: prevStringInverterId,
      prevParallelMpptStringNumber: prevParallelMpptStringNumber
    });
    this.debugLog('[addAndSelectNewString] inverterSelectionForNewStringResult: ', inverterSelectionForNewStringResult);

    switch (inverterSelectionForNewStringResult) {
      case InverterSelectionForNewStringResult.UseNewParallelString:
        this.detailedDebugLog(
          'Create new parallel string. Reuse inverter and mppt from the previous string: ',
          prevStringMpptId,
          prevStringInverterId
        );
        this.selectedStrings.replace(
          selectSortedParallelStrings({
            strings: this.strings,
            targetString: newString
          })
        );

      // fall through
      case InverterSelectionForNewStringResult.Success:
        this.detailedDebugLog(
          'Success. Selected inverter for new string: ',
          this.activeSelectedString!.getInverterId()
        );
        this.callbacks.systemSummarySetOption(this.activeSelectedString!);
        this.callbacks.systemSummarySetStringing(this.activeSelectedString!.serverId);

        this.setAllStringsRatings();

        break;
      case InverterSelectionForNewStringResult.NeedToOpenMpptAndMultiInverterSelectionPanel:
        this.detailedDebugLog('Need to open MpptAndMultiInverterSelectionPanel to select inverter or MPPT');

        this.openMpptAndMultiInverterSelectionPanel(this.activeSelectedString!, invertersData);

        return ProcessStringingUserInputResult.NeedToSelectMPPTOrInverter;
      default:
    }

    return ProcessStringingUserInputResult.Success;
  }

  resetMpptAndMultiInverterSelectionPanelFlags(): void {
    this.selectedStringNeedsInverterSelection = false;
  }

  private openMpptAndMultiInverterSelectionPanel(selectedString: Stringing, invertersData: IInverterSelected[]): void {
    this.detailedDebugLog('Open mppt/inverter selection panel');
    this.selectedStringNeedsInverterSelection = true;
    this.callbacks.openMpptAndMultiInverterSelectionPanel(invertersData, selectedString);

    // This promise will be resolved in
    // MPPTAndMultiInverterSelectionPanelViewModel
    new Promise((resolve: (_: unknown) => void): void => {
      this.resolveMpptOrInverterSelectionPromise = (): void => {
        resolve(undefined);
      };
    }).then((): void => {
      this.resetMpptAndMultiInverterSelectionPanelFlags();

      if (this.activeSelectedString) {
        this.setAllStringsRatings();
      }
    });
  }

  private setAllStringsRatings(): void {
    this.detailedDebugLog('Set stringing ratings for all strings');
    setValidationRatingForAllStrings({
      allStrings: this.selectedStrings.length ? mergeStrings(this.strings, [this.activeSelectedString!]) : this.strings,
      stringingOptionsList: this.stringingOptions
    });
  }
}

const StringingServiceInstance = new StringingService();

export default StringingServiceInstance;
