export enum ServiceEntranceEquipmentDevice {
  LoadCenter = 'LoadCenter',
  UtilityMeter = 'UtilityMeter'
}

/**
 * @description Service Entrance Equipment is site equipment that receives
 * power from the utility and distributes it to the site. Usually, it is a
 * device or a set of devices (see {@link ServiceEntranceEquipmentDevice})
 * including a utility meter and a load center (which usually
 * consists of main breaker, busbar, etc.)
 */
export enum ServiceEntranceEquipmentType {
  /**
   * Utility meter and load center are in one enclosure
   */
  MeterMainLoadCenter = 'MeterMainLoadCenter',
  /**
   * Utility meter is in a separate device user can move on the map
   * independently of the load center.
   */
  MeterBaseAndMainBreakerLoadCenter = 'MeterBaseAndMainBreakerLoadCenter',
  /**
   * Utility meter and main breaker in the same enclosure, load center in a separate one
   */
  MeterMainAndMainLugLoadCenter = 'MeterMainAndMainLugLoadCenter'
}

export enum LoadCenterName {
  MainBreakerLoadCenter = 'Main Breaker Load Center',
  MeterMainLoadCenter = 'Meter-Main-Load Center',
  MainLugLoadCenter = 'Main Lug Load Center'
}

enum LoadCenterLabel {
  MainBreakerLoadCenter = 'MBLC',
  MeterMainLoadCenter = 'MMLC',
  MainLugLoadCenter = 'MLLC'
}

const loadCenterNamesAndLabels = {
  [ServiceEntranceEquipmentType.MeterBaseAndMainBreakerLoadCenter]: {
    name: LoadCenterName.MainBreakerLoadCenter,
    label: LoadCenterLabel.MainBreakerLoadCenter
  },
  [ServiceEntranceEquipmentType.MeterMainLoadCenter]: {
    name: LoadCenterName.MeterMainLoadCenter,
    label: LoadCenterLabel.MeterMainLoadCenter
  },
  [ServiceEntranceEquipmentType.MeterMainAndMainLugLoadCenter]: {
    name: LoadCenterName.MainLugLoadCenter,
    label: LoadCenterLabel.MainLugLoadCenter
  }
};

export function getServiceEntranceAndSiteEquipmentProperties(
  device: ServiceEntranceEquipmentDevice,
  serviceEntranceEquipmentType: ServiceEntranceEquipmentType
): {
  label: string;
  name: string;
} {
  switch (device) {
  case ServiceEntranceEquipmentDevice.LoadCenter: {
    return (
      loadCenterNamesAndLabels[serviceEntranceEquipmentType] ?? {
        label: 'LC',
        name: 'Load Center'
      }
    );
  }
  case ServiceEntranceEquipmentDevice.UtilityMeter: {
    switch (serviceEntranceEquipmentType) {
    case ServiceEntranceEquipmentType.MeterMainAndMainLugLoadCenter: {
      return {
        name: 'Meter-Main',
        label: 'MM'
      };
    }
    default: {
      return {
        name: 'Meter Base',
        label: 'MB'
      };
    }
    }
  }
    // no default
  }
}

/**
 * @description Service entrance equipment field names in domain (`project.site.equipment`)
 */
export enum SiteEquipmentItemKeyName {
  mainServicePanel = 'mainServicePanel',
  utilityMeter = 'utilityMeter',
  subpanel = 'subpanel',
  gasMeter = 'gasMeter',
  streetLocation = 'streetLocation',
  meterMain = 'meterMain'
}

export const DEFAULT_MAIN_BREAKER_RATING = 200;
