import { Color, ThemeOption } from '../../typings';

export interface IColorBasePalette {
  // Greys
  grey1: Color;
  grey2: Color;
  grey3: Color;
  grey4: Color;
  grey5: Color;

  // Main colors
  main1: Color;
  main2: Color;
  main3: Color;

  // Greys
  lighter: Color;
  darker: Color;
  neutral: Color;

  secondaryFont: Color;

  // Stringings colors
  str01: Color;
  str02: Color;
  success: Color;
  warn: Color;
  error: Color;
}

export type ColorScheme = ThemeOption<Color> & IColorBasePalette;

/**
 * This is a bit of a mess.
 * It seems it has constants for two different color schemes - Design tool and Host App,
 * but the distinction is not very explicit, especially where both schemes define multiple main colors/shades of grey
 */
const colorScheme: ColorScheme = {
  // The primary color scheme used in the Design Tool can be found here:
  // https://app.zeplin.io/project/5daddbd530b1aa127f0d7ad8/screen/5db20c7fe0e5042c2d416f33

  grey1: '#4A4A4A',
  grey2: '#8F8F8F',
  grey3: '#BFBFBF',
  grey4: '#D9D9D9',
  grey5: '#ECECEC',

  main1: '#427ECD',
  main2: '#1FC0C0',
  main3: '#685FCB',

  darker: '#4A4A4A',
  neutral: '#979797',
  lighter: '#E2E2E2',

  str01: '#C3BC6A',
  str02: '#B0DF6C',
  str03: '#36A247',
  success: '#58AC65',
  warn: '#E7AB39',
  error: '#D34A4A',

  // LEGACY COLOR SCHEMA
  primary: '#427ecd',
  primaryHover: '#31609e',
  secondary: '#f2f2f2',
  tertiary: '#d9d9d9',
  quaternary: '#979797',
  info: '#ffffff',
  highlight: '#ffffff',
  primaryFont: '#201e39',
  secondaryFont: '#4a4a4a',
  tertiaryFont: '#f8f7fc',
  iconBg2: '#595959',
  lineHr: '#d3d3d3',
  whiteBg: '#ffffff',
  black: '#000000',
  hintBg: 'rgb(59, 152, 148, .1)',

  // The primary color scheme used in the Host App can be found here:
  // https://app.zeplin.io/project/5db36bd7a165755768bb06e4/screen/5db74a8d4683c656203abcd1
  mainColor1: '#427ecd',    // Note: this should be '#2D80EF' but changing it affected Design Tool too
  mainColor2: '#685FCB',
  mainColor3: '#1FC0C0',
  mainColor4: '#D34A4A',
  mainColor5: '#FF736E',
  mainColor6: '#100E2A',
  mainColor7: '#3B71B9',

  grayColor1: '#333038',
  grayColor2: '#858585',
  grayColor3: '#ADADAD',
  grayColor4: '#D9D9D9',
  grayColor5: 'rgba(173, 173, 173, .2)',
  grayColor6: '#d8d8d8',
  grayColor7: '#f2f2f2',
  grayColor8: '#eaeaea',
  grayColor9: '#4a4a4a',
  grayColor10: '#e7e7e7',
  grayColor11: '#fafafa',
  grayForIcons: '#9e9e9e',

  redColor1: '#ae0318',
  redColor2: 'rgba(174, 3, 24, 0.07)',

  transparent: 'transparent',
  yellowColor1: '#cb5013',

  greenColor1: '#52b8b8',
  greenColor2: '#d9e2e2',

  opacityColor1: 'rgba(0, 0, 0, 0.5)',
  opacityColor2: 'rgba(211, 211, 211, 0.5)',
  secondaryFontOpacity: 'rgba(74, 74, 74, 0.5)',
  opacityColor3: 'rgba(59, 152, 148, 0.1)',
  opacityColor4: 'rgba(0, 155, 149, 0.15)',
  opacityColor5: 'rgba(74, 74, 74, 0.5)',

  blackColor1: '#282829',

  // Note: The following styles were copied over from design tool when duplicated theme management was removed.
  // It's likely that some of these can (and should) be removed, but due to lack of time this was left as tech debt.
  gray1: '#4a4a4a',
  gray2: '#979797',
  gray3: '#e2e2e2',
  grayOpacity1: 'rgba(74, 74, 74, 0.25)',
  grayOpacity2: 'rgba(74, 74, 74, 0.15)',
  grayOpacity3: 'rgba(107, 111, 113, 0.25)',
  grayOpacity4: 'rgba(74, 74, 74, 0.35)',
  gray4: '#eaeaea',
  gray5: '#a8a8a8',
  gray6: '#f4f4f4',
  gray7: '#e7e7e7',
  gray8: '#fafafa',
  gray9: '#f4f8fe',
  gray10: '#C4C4C4',
  gray11: '#AAAAAA',
  grayLightBg: '#F0F0F0',

  newGray1: '#cccccc',
  newGray2: '#ececec',
  newGray3: '#c9c9c9',

  primary1: '#388deb',
  primary2: '#2d80ef',
  blackOpacity: 'rgba(0, 0, 0, 0.5)',
  orange: '#F0BA44',
  brightOrange: '#ff8c00',
  orangeLightBg: '#FFF8E5',
  blue: '#427ECD',
  blueLight: '#417ECD',
  blueGreen: '#3B9894',
  red: '#D8000A',
  redLightBg: '#FFEAE9'
};

export { colorScheme };
