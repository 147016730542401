import { computed } from 'mobx';
import type Store from '../../Store';
import type DomainStore from '../../DomainStore/DomainStore';
import type EditorStore from '../../EditorStore/EditorStore';
import type { ModalStore } from '../Modal/Modal';
import type { ServiceBus } from '../../ServiceBus/ServiceBus';
import type { UiStore } from '../UiStore';
import { GroundSnowViewModel } from './ViewModels/GroundSnowViewModel';
import { SiteTemperatureViewModel } from './ViewModels/SiteTemperatureViewModel';
import { SiteOtherPropertiesViewModel } from './ViewModels/SiteTopographicalViewModel';
import { WindExposureViewModel } from './ViewModels/WindExposureViewModel';
import { WindSpeedViewModel } from './ViewModels/WindSpeedViewModel';
import { GenericEnvProp } from './GenericEnvProp';

const topographicalConditionList: { [key: string]: string } = {
  STANDARD: 'Standard',
  TOP_OF_HILL: 'Top Of Hill',
  COASTAL: 'Coastal',
  MESA: 'Mesa'
};

export class EnvironmentalPropsStore {
  private readonly domain: DomainStore;
  private readonly modal: ModalStore;
  private readonly serviceBus: ServiceBus;
  private readonly editor: EditorStore;

  constructor(root: Store, uiStore: UiStore) {
    this.domain = root.domain;
    this.modal = uiStore.modal;
    this.serviceBus = root.serviceBus;
    this.editor = root.editor;
  }

  @computed
  get topographicalEnvProp(): GenericEnvProp {
    const conditionName = this.domain.project.designParameters.terrain.topographicalCondition;
    const topographicalCondition = conditionName ? topographicalConditionList[conditionName] : '--';
    return new GenericEnvProp('Topographical Condition', topographicalCondition);
  }

  get averageGradeEnvProp(): GenericEnvProp {
    const averagePropertyGrade = this.domain.project.designParameters.terrain.averagePropertyGradeAsText;
    return new GenericEnvProp('Avg. Grade of Property', averagePropertyGrade);
  }

  @computed
  get elevationEnvProp(): GenericEnvProp {
    return new GenericEnvProp('Elevation', this.domain.project.site.elevationInFeetAsString);
  }

  @computed
  get seismicEnvProp(): GenericEnvProp {
    return new GenericEnvProp('Spectral Acceleration (SDS)', this.domain.project.designParameters.seismic.sdsAsText);
  }

  @computed
  get lowTemperatureEnvProp(): GenericEnvProp {
    const lowTemperature = this.domain.project.designParameters.temperature.lowTemperature;
    const lowTemperatureComponent = new GenericEnvProp(
      lowTemperature?.descriptionAsText ?? 'Low temperature',
      lowTemperature?.valueAsText ?? '--'
    );
    lowTemperatureComponent.metaDetail = lowTemperature?.dataSourceName ?? '';
    lowTemperatureComponent.icon = 'low-temperature';
    lowTemperatureComponent.color = '#024FD0';
    return lowTemperatureComponent;
  }

  @computed
  get highTemperatureEnvProp(): GenericEnvProp {
    const highTemperature = this.domain.project.designParameters.temperature.highTemperature;
    const highTemperatureComponent = new GenericEnvProp(
      highTemperature?.descriptionAsText ?? 'High temperature',
      highTemperature?.valueAsText ?? '--'
    );
    highTemperatureComponent.metaDetail = highTemperature?.dataSourceName ?? '';
    highTemperatureComponent.icon = 'high-temperature';
    highTemperatureComponent.color = '#D06002';
    return highTemperatureComponent;
  }

  @computed
  get groundSnowLoadEnvProp(): GenericEnvProp {
    const groundSnowLoadComponent = new GenericEnvProp(
      'Ground Snow Load',
      this.domain.project.designParameters.snow.groundSnowLoadAsText
    );
    groundSnowLoadComponent.icon = 'ground-snow';
    groundSnowLoadComponent.color = '#4CBDE3';
    return groundSnowLoadComponent;
  }

  @computed
  get windEnvProp(): GenericEnvProp {
    const {
      wind, codesAndStandards
    } = this.domain.project.designParameters;
    const windParametersComponent = new GenericEnvProp(
      `Wind Speed (${codesAndStandards.structuralStandardDisplayName})`,
      wind.windSpeedAsText
    );
    windParametersComponent.icon = 'wind-speed';
    windParametersComponent.color = '#2D6663';
    return windParametersComponent;
  }

  @computed
  get windExposureEnvProp(): GenericEnvProp {
    const windExposureComponent = new GenericEnvProp(
      'Wind Exposure Category',
      this.domain.project.designParameters.terrain.windExposureCategory ?? '--'
    );
    windExposureComponent.icon = 'wind-exposure';
    windExposureComponent.color = '#0F501F';
    return windExposureComponent;
  }

  // Show modals methods
  showSiteGroundSnowModal(): void {
    if (this.domain.project.id) {
      this.modal.createModal(
        'groundsnow_modal',
        new GroundSnowViewModel({
          domain: this.domain,
          modal: this.modal,
          serviceBus: this.serviceBus,
          editor: this.editor
        })
      );
    }
  }

  showSiteTemperatureModal(): void {
    if (this.domain.project.id) {
      this.modal.createModal(
        'site_temperature_modal',
        new SiteTemperatureViewModel({
          domain: this.domain,
          modal: this.modal,
          serviceBus: this.serviceBus,
          editor: this.editor
        })
      );
    }
  }

  showWindExposureModal(): void {
    if (this.domain.project.id) {
      this.modal.createModal(
        'wind_exposure_modal',
        new WindExposureViewModel({
          domain: this.domain,
          modal: this.modal,
          serviceBus: this.serviceBus,
          editor: this.editor
        })
      );
    }
  }

  showWindSpeedModal(): void {
    if (this.domain.project.id) {
      this.modal.createModal(
        'wind_speed_modal',
        new WindSpeedViewModel({
          domain: this.domain,
          modal: this.modal,
          serviceBus: this.serviceBus,
          editor: this.editor
        })
      );
    }
  }

  showSiteOtherPropertiesModal(): void {
    if (this.domain.project.id) {
      this.modal.createModal(
        'site_other_properties_modal',
        new SiteOtherPropertiesViewModel({
          domain: this.domain,
          modal: this.modal,
          serviceBus: this.serviceBus,
          editor: this.editor
        })
      );
    }
  }
}
