import union from 'lodash/union';
import { observer } from 'mobx-react-lite';
import type { ReactElement } from 'react';
import React, { useCallback } from 'react';
import {
  LyraDivider, LyraIcon, LyraTypography
} from '@aurorasolar/lyra-ui-kit';
import { EThemeComponentColor } from '@aurorasolar/lyra-ui-kit/lib/components/Theme/EThemeComponentColor';
import { Flexbox } from '@aurorasolar/lyra-ui-kit/lib/components/Layout';
import useStore from '../../../stores/useStore';
import type { DesignWorkspace } from '../../../stores/UiStore/WorkspaceStore/workspaces/DesignWorkspace';
import config, { UI_MODE } from '../../../config/config';
import { DesignStep } from '../../../domain/models/Design/DesignState';
import {
  DocumentGenerationMenuContainer,
  AuroraRow,
  LyraDesignRow,
  TooltipOnHover,
  TooltipOnHoverText
} from './styles';

interface IDocumentGenerationMenuProps {
  showSalesMenuSection: boolean;
}

interface DocumentGenerationItemProps {
  documentName: string;
  documentIcon?: string;
  documentAvailable: boolean;
  downloadDocumentWithFormat: () => void;
  openDocumentModal: () => void;
}

const DocumentGenerationItem = (props: DocumentGenerationItemProps): ReactElement => {
  const {
    documentName, documentIcon, documentAvailable, downloadDocumentWithFormat, openDocumentModal
  } = props;

  const getDocumentIcon = (): string => {
    if (config.featureFlag.uiMode === UI_MODE.AURORA) {
      return documentIcon ?? '';
    }
    return documentAvailable ? 'icon-download-default' : 'icon-document-empty';
  };

  return (
    <>
      <Flexbox
        justify="space-between"
        onClick={(): void => (config.featureFlag.uiMode !== UI_MODE.AURORA ? void 0 : downloadDocumentWithFormat())}
        style={
          config.featureFlag.uiMode === UI_MODE.AURORA
            ? {
              width: '100%'
            }
            : {
              width: 'auto',
              padding: '16px 0px'
            }
        }
      >
        <Flexbox align="center">
          <TooltipOnHover available={documentAvailable}>
            <LyraIcon.Icon
              name={getDocumentIcon()}
              onClick={(): void | false => (documentAvailable ? downloadDocumentWithFormat() : false)}
              style={{
                width: 16,
                height: 20
              }}
            />
            <TooltipOnHoverText>Download the {documentName}</TooltipOnHoverText>
          </TooltipOnHover>
          <LyraTypography.Heading
            color={
              config.featureFlag.uiMode !== UI_MODE.AURORA ? EThemeComponentColor.BLACK : EThemeComponentColor.GRAY_800
            }
            fontSize={config.featureFlag.uiMode !== UI_MODE.AURORA ? '10px' : '14px'}
            fontWeight={config.featureFlag.uiMode !== UI_MODE.AURORA ? 'bold' : 'normal'}
            lineHeight={1.2}
            margin="0 0 0 10px"
          >
            {documentName}
          </LyraTypography.Heading>
        </Flexbox>
        {config.featureFlag.uiMode !== UI_MODE.AURORA && (
          <Flexbox justify="end" align="center" stretch="auto">
            <LyraDivider.Divider
              direction={'vertical'}
              thickness="1px"
              length="20px"
              color={EThemeComponentColor.GRAY_200}
            />
            <TooltipOnHover available={documentAvailable}>
              <LyraIcon.Icon
                onClick={(): void | false => (documentAvailable ? openDocumentModal() : false)}
                name="tool-settings"
                className="ml-xs"
                style={{
                  width: 14,
                  height: 14,
                  cursor: documentAvailable ? 'pointer' : 'not-allowed',
                  marginLeft: '14px'
                }}
              />
              <TooltipOnHoverText>Edit the {documentName}</TooltipOnHoverText>
            </TooltipOnHover>
          </Flexbox>
        )}
      </Flexbox>
    </>
  );
};

const DocumentGenerationMenu = observer((props: IDocumentGenerationMenuProps): ReactElement => {
  const { showSalesMenuSection } = props;
  const { workspace } = useStore();
  // We can be sure that currentWorkspace here is DesignWorkspace
  // because we have additional check in ProgressStepper.tsx that is parent component;
  const designWorkspace = workspace.currentWorkspace as DesignWorkspace;
  const stageManager = designWorkspace.stageManager;
  const RowComponent = config.featureFlag.uiMode === UI_MODE.AURORA ? AuroraRow : LyraDesignRow;

  const Sales = useCallback(
    (systemDatasheetAvailable: boolean, conceptDesignAvailable: boolean): ReactElement => (
      <React.Fragment key="sales">
        {showSalesMenuSection && (
          <>
            <LyraTypography.Heading type="h4" fontWeight="bold" color={EThemeComponentColor.BLUE}>
              SALES
            </LyraTypography.Heading>
            <RowComponent available={systemDatasheetAvailable}>
              <LyraDivider.Divider direction={'horizontal'} color={EThemeComponentColor.GRAY_200} />

              <DocumentGenerationItem
                documentName="System Datasheet"
                documentAvailable={systemDatasheetAvailable}
                downloadDocumentWithFormat={designWorkspace?.downloadSystemDataSheetWithDefaultValues}
                openDocumentModal={designWorkspace?.openSystemDataSheetModal}
              />

              <LyraDivider.Divider direction={'horizontal'} color={EThemeComponentColor.GRAY_200} />

              <DocumentGenerationItem
                documentName="Concept Design"
                documentAvailable={conceptDesignAvailable}
                downloadDocumentWithFormat={designWorkspace?.downloadConceptDesignWithDefaultValues}
                openDocumentModal={designWorkspace?.openConceptDesignModal}
              />

              <LyraDivider.Divider direction={'horizontal'} color={EThemeComponentColor.GRAY_200} />
            </RowComponent>
          </>
        )}
      </React.Fragment>
    ),
    [
      RowComponent,
      designWorkspace?.downloadConceptDesignWithDefaultValues,
      designWorkspace?.downloadSystemDataSheetWithDefaultValues,
      designWorkspace?.openConceptDesignModal,
      designWorkspace?.openSystemDataSheetModal,
      showSalesMenuSection
    ]
  );

  const Procurement = useCallback(
    (billOfMaterialsAvailable: boolean, wireAndConduitScheduleAvailable: boolean): ReactElement => {
      const RowComponent = config.featureFlag.uiMode === UI_MODE.AURORA ? AuroraRow : LyraDesignRow;
      return (
        <React.Fragment key="procurement">
          <LyraTypography.Heading type="h4" fontWeight="bold" color={EThemeComponentColor.BLUE} margin="30px 0 0">
            PROCUREMENT
          </LyraTypography.Heading>
          <RowComponent available={billOfMaterialsAvailable}>
            <LyraDivider.Divider direction={'horizontal'} color={EThemeComponentColor.GRAY_200} />

            <DocumentGenerationItem
              documentName="Bill of Materials"
              documentAvailable={billOfMaterialsAvailable}
              downloadDocumentWithFormat={designWorkspace?.downloadBillOfMaterialsWithFormat}
              openDocumentModal={designWorkspace?.openBillOfMaterialsModal}
            />
          </RowComponent>
        </React.Fragment>
      );
    },
    [designWorkspace]
  );

  const BillOfMaterialsOnly = useCallback(() => {
    const RowComponent = config.featureFlag.uiMode === UI_MODE.AURORA ? AuroraRow : LyraDesignRow;
    return (
      <React.Fragment key="procurement">
        <RowComponent available>
          <DocumentGenerationItem
            documentName="Bill of Materials (PDF)"
            documentIcon="aurora-pdf"
            documentAvailable
            downloadDocumentWithFormat={(): void => {
              designWorkspace?.downloadBillOfMaterialsWithFormat('PDF');
            }}
            openDocumentModal={(): void => void 0}
          />
        </RowComponent>
        <RowComponent available>
          <DocumentGenerationItem
            documentName="Bill of Materials (XLSX)"
            documentIcon="aurora-xls"
            documentAvailable
            downloadDocumentWithFormat={(): void => {
              designWorkspace?.downloadBillOfMaterialsWithFormat('EXCEL');
            }}
            openDocumentModal={(): void => void 0}
          />
        </RowComponent>
      </React.Fragment>
    );
  }, [designWorkspace]);

  const selectComponentsToShow = useCallback(
    (currentDesignStageId: DesignStep): JSX.Element[] => {
      let components = [<></>];

      if (config.featureFlag.uiMode !== UI_MODE.AURORA) {
        switch (currentDesignStageId) {
          case DesignStep.LAYOUT_DESIGN:
          case DesignStep.ELECTRICAL_DESIGN:
            components = union([Sales(true, true)], [Procurement(false, false)]);
            break;
          case DesignStep.ELECTRICAL_BOS:
          case DesignStep.CIRCUIT_TABLE:
          case DesignStep.MOUNTING_BOS:
          case DesignStep.CUSTOMIZATION:
          case DesignStep.COMPLETED:
            components = union([Sales(true, true)], [Procurement(true, false)]);
        }
      } else if (
        [
          DesignStep.ELECTRICAL_BOS,
          DesignStep.CIRCUIT_TABLE,
          DesignStep.MOUNTING_BOS,
          DesignStep.CUSTOMIZATION,
          DesignStep.COMPLETED
        ].includes(currentDesignStageId)
      ) {
        components = [BillOfMaterialsOnly()];
      }
      return components;
    },
    [BillOfMaterialsOnly, Procurement, Sales]
  );

  return (
    <DocumentGenerationMenuContainer>
      {selectComponentsToShow(stageManager!.currentStage.id as DesignStep)}
    </DocumentGenerationMenuContainer>
  );
});

export default DocumentGenerationMenu;
