import React, { BaseProps, ReactElement } from 'react';
import { StepData, StepProps } from '../../typings';
import {
  StepContainer,
  Title
} from './styles';

type Props = BaseProps & StepData & StepProps;

const Step = (props: Props): ReactElement => {
  const {
    title,
    circle,
    state
  } = props;

  return (
    <StepContainer
      className={state}
      shpe={circle}
    >
      <Title className={state}>{title}</Title>
    </StepContainer>
  );
};

export { Step };
