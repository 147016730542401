import type { PropsTheme } from 'styled-components';
import styled from 'styled-components';
import {
  LyraTypography, LyraLayout, LyraIcon
} from '@aurorasolar/lyra-ui-kit';
import type { Color } from '@aurorasolar/lyra-ui-kit';
const { Paragraph } = LyraTypography;
const { Icon } = LyraIcon;
const {
  Row, Col
} = LyraLayout;

type Props = PropsTheme & {
  selected: boolean;
};

const getImageSelectedStyle = ({
  selected, theme
}: Props): string => {
  if (selected) {
    return `
      border: 3px solid ${theme.colors.main1};
      opacity: 1;
    `;
  }
  return `
    border: 3px solid transparent;
    opacity: 0.8;
  `;
};

export const GalleryWrapper = styled(Row)``;

export const GalleryItem = styled(Col)`
  margin-top: 15px;
  cursor: pointer;
  position: relative;
`;

export const CheckIcon = styled(Icon).attrs<{ name?: string }>({
  name: 'image-selection-check'
})`
  position: absolute;
  top: 10px;
  left: 17px;
`;

export const UncheckIcon = styled.div`
  position: absolute;
  top: 10px;
  left: 17px;
  width: 18px;
  height: 18px;
  box-sizing: border-box;
  border-radius: 2px;
  z-index: 2;
  border: solid 2px ${({ theme }: PropsTheme): Color => theme.colors.whiteBg};
  background-color: ${({ theme }: PropsTheme): Color => theme.colors.whiteBg};
`;

export const GalleryItemImage = styled.img<Props>`
  width: 100%;
  height: 165px;
  object-fit: cover;
  box-sizing: border-box;
  ${getImageSelectedStyle};
`;

export const GalleryItemTitle = styled(Paragraph)`
  margin-top: 10px;
  font-weight: bold;
  color: ${({ theme }: PropsTheme): Color => theme.colors.grey1};
`;

export const GalleryItemDescription = styled(Paragraph)`
  margin-top: 5px;
  color: ${({ theme }: PropsTheme): Color => theme.colors.grey1};
`;
