import styled, { withTheme } from 'styled-components';

export const WrapperButtons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  width: auto;
  height: 21.2px;
  border-radius: 2.9px;
  border: solid 0.7px #c9c9c9;
  background-color: #ffffff;
  padding: 0 1px;
`;

export const ButtonList = styled.div<{type: string; selected: boolean}>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15.7px;
  height: 14.7px;
  box-sizing: border-box;
  border-radius: 2.2px;
  border: solid 0.7px #cccccc;
  margin: 0 3.3px;

  opacity: ${({selected}: {selected: boolean}): number => selected !== undefined && selected ? 1 : 0.25};
  background-color: ${({selected}: {selected: boolean}): string => selected !== undefined && selected
    ? '#427ecd'
    : '#cccccc'
  };
  font-family: 'Roboto';
  font-size: 10.2px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: ${({selected}: {selected: boolean}): string => selected !== undefined && selected ? '#ffffff' : '#4a4a4a'};

  &:hover {
    background-color: #427ecd;   
    color: #ffffff;
    opacity: 1;
  }
  &:active {
    background-color: #427ecd;   
    color: #ffffff;
    opacity: 1;
  }
`;
