import { observer } from 'mobx-react-lite';
import type { ReactElement } from 'react';
import React from 'react';
import { canvasConfig } from '../../../config/canvasConfig';
import { useUiStore } from '../../../stores/useStore';
import PropertyPanelHeader from '../PropertyPanel/PropertyPanelHeader/PropertyPanelHeader';
import {
  SERVICE_ENTRANCE_EQUIPMENT_TOOL_ID,
  type ServiceEntranceEquipmentTool
} from '../../../stores/UiStore/ToolbarStore/Project/SiteEquipmentTools/ServiceEntranceEquipmentTool';
import { ServiceEntranceEquipmentItemKeyName } from '../../../domain/models/SiteDesign/SiteEquipment';
import { getRootStore } from '../../../stores/RootStoreInversion';
import { ServiceEntranceEquipmentType } from '../../../domain/models/SiteDesign/SiteEquipmentTypesAndHelpers';
import {
  ServiceEntranceEquipmentOption,
  ServiceEntranceEquipmentOptionDescription,
  ServiceEntranceEquipmentOptionTitle,
  ServiceEntranceEquipmentSubTitle,
  ServiceEntranceEquipmentConfigurationContainer
} from './styles';

type ServiceEntranceEquipmentOption = {
  type: ServiceEntranceEquipmentType;
  title: string;
  description: string;
};

export const SEOptionTitlesAndDescriptions = {
  [ServiceEntranceEquipmentType.MeterMainLoadCenter]: {
    title: 'Meter-Main-Load Center',
    description: 'Utility meter, main breaker, and panelboard all in one enclosure.'
  },
  [ServiceEntranceEquipmentType.MeterBaseAndMainBreakerLoadCenter]: {
    title: 'Meter Base and Main Breaker Load Center',
    description: 'Utility meter in standalone enclosure, main breaker and panelboard in separate enclosure.'
  },
  [ServiceEntranceEquipmentType.MeterMainAndMainLugLoadCenter]: {
    title: 'Meter-Main and Main Lug Load Center',
    description: 'Utility meter and main breaker in one enclosure, main lug panelboard in separate enclosure.'
  }
};

const SEOptions: ServiceEntranceEquipmentOption[] = [
  {
    type: ServiceEntranceEquipmentType.MeterMainLoadCenter,
    ...SEOptionTitlesAndDescriptions[ServiceEntranceEquipmentType.MeterMainLoadCenter]
  },
  {
    type: ServiceEntranceEquipmentType.MeterBaseAndMainBreakerLoadCenter,
    ...SEOptionTitlesAndDescriptions[ServiceEntranceEquipmentType.MeterBaseAndMainBreakerLoadCenter]
  },
  {
    type: ServiceEntranceEquipmentType.MeterMainAndMainLugLoadCenter,
    ...SEOptionTitlesAndDescriptions[ServiceEntranceEquipmentType.MeterMainAndMainLugLoadCenter]
  }
];

const ServiceEntranceEquipmentOptions = observer((): ReactElement => {
  const { toolbar } = useUiStore();
  const { domain } = getRootStore();
  const currentServiceEntranceEquipmentType = domain.siteEquipment.inferServiceEntranceEquipmentType();

  const createSelectEquipmentHandler =
    (type: ServiceEntranceEquipmentType): (() => void) =>
      (): void => {
        const {
          serviceBus, domain, editor
        } = getRootStore();
        const siteEquipment = domain.siteEquipment;

        // Delete existing equipment first:
        let equipmentType: keyof typeof ServiceEntranceEquipmentItemKeyName;
        for (equipmentType in ServiceEntranceEquipmentItemKeyName) {
          const equipment = siteEquipment[equipmentType];

          if (equipment) {
            serviceBus.send('delete_site_equipment', {
              editor,
              domain,
              object: equipment,
              skipConfirmation: true
            });
          }
        }

        toolbar.activateToolInProjectWorkspaceWithoutClick(SERVICE_ENTRANCE_EQUIPMENT_TOOL_ID);

        const serviceEntranceEquipmentTool = toolbar.selectedTool as ServiceEntranceEquipmentTool;

        serviceEntranceEquipmentTool.createServiceEntranceEquipment(type);
      };

  return (
    <>
      <PropertyPanelHeader
        icon="tool-properties"
        iconBg={canvasConfig.gasMeterIconColor}
        name="Service Entrance Equipment Options"
      />
      <ServiceEntranceEquipmentConfigurationContainer>
        <ServiceEntranceEquipmentSubTitle>
          Choose configuration from the following list:
        </ServiceEntranceEquipmentSubTitle>
        {SEOptions.map(({
          type, title, description
        }: ServiceEntranceEquipmentOption) => (
          <ServiceEntranceEquipmentOption
            selected={currentServiceEntranceEquipmentType === type}
            onClick={createSelectEquipmentHandler(type)}
            key={type}
          >
            <ServiceEntranceEquipmentOptionTitle>
              {title}
            </ServiceEntranceEquipmentOptionTitle>
            <ServiceEntranceEquipmentOptionDescription>{description}</ServiceEntranceEquipmentOptionDescription>
          </ServiceEntranceEquipmentOption>
        ))}
      </ServiceEntranceEquipmentConfigurationContainer>
    </>
  );
});
export default ServiceEntranceEquipmentOptions;
