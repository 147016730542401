import { KeyboardBehaviour } from '../../../../domain/behaviour/KeyboardBehaviour';
import { RectangleProtrusion } from '../../../../domain/models/SiteDesign/RectangleProtrusion';
import { RoofFace } from '../../../../domain/models/SiteDesign/RoofFace';
import { EApplicationContext } from '../../../../domain/typings';
import type { IAddSiteEquipmentDependencies } from '../../../ServiceBus/Commands/AddSiteEquipmentCommand';
import type { IUpdateRoofFacePropertyDependencies } from '../../../ServiceBus/Commands/UpdateRoofFacePropertyCommand';
import type { IUpdateRoofProtrusionPropertyDependencies } from '../../../ServiceBus/Commands/UpdateRoofProtrusionPropertyCommand';
import { canvasConfig } from '../../../../config/canvasConfig';
import type DomainStore from '../../../DomainStore/DomainStore';
import type {
  IControlDragging, IControlSelectionChange
} from '../../../EditorStore/Controls/ControlEvents';
import { DragControl } from '../../../EditorStore/Controls/DragControl';
import { SelectionControl } from '../../../EditorStore/Controls/SelectionControl';
import type { PropertiesStore } from '../../Properties/Properties';
import type SmartGuidesStore from '../../SmartGuidesStore/SmartGuidesStore';
import type { IBaseToolDependencies } from '../Tool';
import { BaseTool } from '../Tool';
import { PropsPanelUICodes } from '../../Properties/propertiesStoreConstants';
import {
  EventType, useEventSystemDispatch
} from '../../../../services/eventSystem/eventSystemHook';
import { UpdateRoofFacePropertyKey } from '../../../ServiceBus/Commands/UpdateRoofFacePropertyCommand';
import {
  getLyraModelByMesh,
  getLyraModelByOptionalMesh,
  getParentLyraModelByMeshOrLyraModel
} from '../../../../domain/sceneObjectsWithLyraModelsHelpers';
import { SceneObjectType } from '../../../../domain/models/Constants';
import type { Segment } from '../../../../domain/graphics/Segment';
import { Marker } from '../../../../domain/models/SiteDesign/Marker';
import type { Drawable } from '../../../../domain/mixins/Drawable';
import type { Selectable } from '../../../../domain/mixins/Selectable';
import type { Deletable } from '../../../../domain/mixins/Deletable';
import type { GenericSiteEquipment } from '../../../../domain/models/SiteDesign/SiteEquipment/GenericSiteEquipment';
import type { ServiceEntranceEquipment } from '../../../../domain/models/SiteDesign/SiteEquipment';
import { getRootStore } from '../../../RootStoreInversion';
import { SELECT_TOOL_ID } from './constants';

export interface ISelectionToolDependencies extends IBaseToolDependencies {
  properties: PropertiesStore;
  domain: DomainStore;
  smartGuides: SmartGuidesStore;
}

export class SelectionTool extends BaseTool {
  readonly id: string = SELECT_TOOL_ID;
  readonly icon: string = 'selection';
  readonly title: string = 'Select';
  readonly description: string = this.title;
  override testId: string = 'SelectionTool';

  private selectionControl?: SelectionControl;
  private dragControl?: DragControl;
  private objectStaged?: Selectable;

  private readonly properties: PropertiesStore;
  private readonly domain: DomainStore;
  private readonly smartGuides: SmartGuidesStore;

  constructor(dependencies: ISelectionToolDependencies) {
    super(dependencies);
    this.properties = dependencies.properties;
    this.domain = dependencies.domain;
    this.smartGuides = dependencies.smartGuides;
  }

  whenSelected(): void {
    this.configureSelectionControl();
    this.configureDragControl();
    KeyboardBehaviour.addKeyboardEvents(this);
    this.properties.setPropertyPanel(PropsPanelUICodes.SiteStructure);
  }

  whenDeselected(): void {
    this.selectionControl!.unselectAll();
    this.removeEventListeners();
    this.properties.setPropertyPanel();
    this.deactivateControls();
  }

  override onKeyUp = ({ key }: KeyboardEvent): void => {
    const { shortcutDelete } = canvasConfig;
    const deleteObject = shortcutDelete.split('|').find((command: string): boolean => command === key);

    if (deleteObject && this.objectStaged) {
      const {
        editor, domain, objectStaged: object
      } = this;

      const subpanelExists = this.editor.getObjectsByType(SceneObjectType.Subpanel).length;
      if ((object as GenericSiteEquipment).isServiceEntranceEquipment && (object as Deletable).isDeletable) {
        (object as Deletable).deleteConfirmationWarning = subpanelExists
          ? 'If you remove service entrance equipment, the subpanel will also be removed. Do you want to proceed?'
          : 'Are you sure you want to delete service entrance equipment?';
      }

      /*
       * delete_building, delete_parcel_boundary, delete_empty_buildings, delete_gas_meter,
       * delete_main_service_panel, delete_outline, delete_pv_modules_and_module_positions,
       * delete_rectangular_protrusion, delete_roof_face, delete_site_image,
       * delete_street_location, delete_stringing_command, delete_subpanel, delete_utility_meter
       */
      const objectType = object.type === SceneObjectType.MeterMain ? 'utility_meter' : object.type;

      this.serviceBus.send(`delete_${objectType}`, {
        editor,
        domain,
        object
      });
      this.properties.setPropertyPanel(PropsPanelUICodes.SiteStructure);
    }
  };

  dispose(): void {
    this.removeEventListeners();
  }

  configureSelectionControl(): void {
    this.selectionControl = SelectionControl.getInstance(this.editor, this.editor.viewport, this.editor.activeCamera);

    this.selectionControl.setTargetObjects(undefined, true);
    this.selectionControl.activate({
      allowMultiSelect: false,
      autoselectBy(selectable: Selectable): Selectable[] {
        if ((selectable as ServiceEntranceEquipment).isServiceEntranceEquipment) {
          return getRootStore().domain.siteEquipment.getServiceEntranceEquipment();
        }
        return [];
      }
    });

    this.selectionControl.addEventListener('selection_change', this.onSelectionChange);
  }

  configureDragControl(): void {
    this.dragControl = DragControl.getInstance(
      this.editor,
      this.editor.viewport,
      this.editor.activeCamera,
      this.smartGuides
    );

    this.dragControl.activate();
    this.dragControl.setTargetObjects(undefined, true);
    this.dragControl.addEventListener('hoveron', this.onHoverOn);
    this.dragControl.addEventListener('hoveroff', this.onHoverOff);
    this.dragControl.addEventListener('dragend', this.onDragEnd);
    this.dragControl.addEventListener('drag', this.onDragging);
  }

  onSelectionChange = (event: IControlSelectionChange): void => {
    if (event.selection === undefined || event.unselected === undefined) {
      return;
    }
    if (event.selection.length === 0) {
      this.properties.setPropertyPanel(PropsPanelUICodes.SiteStructure);
    }

    this.editor.updateApplicationContext(EApplicationContext.DRAW_CONTEXT);

    this.editor.getObjectsByType<Drawable>(SceneObjectType.RoofFace).forEach((value: Drawable): void => {
      const roofFace = value as RoofFace;
      const lineSegmentsShowing = roofFace.boundary.segments.some((segment: Segment): boolean => {
        return segment.getShowSegmentLength();
      });
      if (lineSegmentsShowing && roofFace.azimuth !== undefined && roofFace.slope) {
        // Labels for the selected roof will be shown by the `select` method in the `LengthLabelable` mixin.
        roofFace.setShowSegmentLengths(false);
        roofFace.redraw();
      }
    });

    event.selection.forEach((selectedObject: Selectable): void => {
      selectedObject.setSelectedMode(true);
      if ((selectedObject as Deletable).isDeletable) {
        this.objectStaged = selectedObject;
      }
    });

    event.unselected.forEach((unselectedObject: Selectable): void => {
      unselectedObject.setSelectedMode(false);
    });
  };

  onDragging = (event: IControlDragging): void => {
    const {
      object: rawObject, objectTarget
    } = event;
    const object = rawObject && rawObject.mesh;

    if (object === undefined) {
      return;
    }
    const isRoofFace = getLyraModelByOptionalMesh(object) instanceof RoofFace;
    const isRoofFaceVertex = getLyraModelByOptionalMesh(object?.parent?.parent) instanceof RoofFace;
    if ((isRoofFaceVertex || isRoofFace) && objectTarget) {
      const roofFace = getLyraModelByMesh<RoofFace>(isRoofFace ? object : object.parent!.parent!);
      if (roofFace.azimuth !== undefined && roofFace.slope && objectTarget.length > 1) {
        roofFace.setShowSegmentLengths(false);
      }
    }

    if (object?.parent?.parent instanceof RectangleProtrusion) {
      const protrusion: RectangleProtrusion = object.parent.parent;

      const dispatchEvent = useEventSystemDispatch();
      dispatchEvent({
        type: EventType.EditProtrusion,
        payload: {
          width: protrusion.getWidth(),
          length: protrusion.getLength()
        }
      });
    }
  };

  onHoverOn = (event: IControlDragging): void => {
    //
  };

  onHoverOff = (event: IControlDragging): void => {
    //
  };

  onDragEnd = (event: IControlDragging): void => {
    const { object: rawObject } = event;
    const object = rawObject && rawObject.mesh;

    const isRoofFace = getLyraModelByOptionalMesh(object) instanceof RoofFace;
    const isRoofFaceVertex = getLyraModelByOptionalMesh(object?.parent?.parent) instanceof RoofFace;
    // Auto-save after dragging a roof outline vertex:
    if (isRoofFaceVertex || isRoofFace) {
      const roofFace = getLyraModelByMesh<RoofFace>(isRoofFace ? object! : object!.parent!.parent!);

      if (roofFace.verticesUpdateIsInProgress) {
        return;
      }

      this.editor.toggleCanvasLoaderCursorState(true);
      roofFace.verticesUpdateIsInProgress = true;

      const updateRoofPropCommandDependencies: IUpdateRoofFacePropertyDependencies = {
        domain: this.domain,
        editor: this.editor,
        roofFace,
        key: UpdateRoofFacePropertyKey.Edge,
        value: roofFace.boundary
      };

      this.serviceBus.send('update_roof_face_property', updateRoofPropCommandDependencies);
    } else if (
      // Auto-save after dragging a protrusion vertex:
      object?.parent?.parent instanceof RectangleProtrusion
      // Auto-save after dragging a whole protrusion:
      || object instanceof RectangleProtrusion
    ) {
      const protrusion: RectangleProtrusion = (
        object instanceof RectangleProtrusion ? object : getLyraModelByOptionalMesh(object?.parent?.parent)
      ) as RectangleProtrusion;

      const protrusionRoofFace: RoofFace = getParentLyraModelByMeshOrLyraModel<RoofFace>(protrusion)!;

      const saveProtrusionCommandDependencies: IUpdateRoofProtrusionPropertyDependencies<'edge'> = {
        domain: this.domain,
        roofFace: protrusionRoofFace,
        protrusion,
        key: 'edge',
        value: protrusion.boundary
      };

      this.serviceBus.send('update_roof_protrusion_property', saveProtrusionCommandDependencies);
    } else if (
      // Auto-save after dragging a site equipment:
      object instanceof Marker
    ) {
      const equipment: Marker = object;
      const commandDependencies: IAddSiteEquipmentDependencies = {
        editor: this.editor,
        domain: this.domain,
        equipment
      };

      this.serviceBus.send('add_site_equipment', commandDependencies);
    }
  };

  private removeEventListeners(): void {
    this.selectionControl!.removeEventListener('selection_change', this.onSelectionChange);
    this.dragControl!.removeEventListener('dragging', this.onDragging);
    this.dragControl!.removeEventListener('dragend', this.onDragEnd);
    KeyboardBehaviour.removeKeyboardEvents(this);
  }

  private deactivateControls(): void {
    this.dragControl!.deactivate();
    this.selectionControl!.deactivate();
  }
}
