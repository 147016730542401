import { LyraFormGenerator } from '@aurorasolar/lyra-form-generator';
import {
  LyraButtons, LyraLoader
} from '@aurorasolar/lyra-ui-kit';
import { EButtonType } from '@aurorasolar/lyra-ui-kit/lib/components/Buttons/EButtonType';
import { EThemeComponentColor } from '@aurorasolar/lyra-ui-kit/lib/components/Theme/EThemeComponentColor';
import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import type { ReactElement } from 'react';
import React from 'react';
import { DesignStep } from '../../../domain/models/Design/DesignState';
import type { IStage } from '../../../domain/stages/IStage';
import { stageIs } from '../../../domain/stages/DesignStages/utils';
import type { ElectricalBosStage } from '../../../domain/stages/DesignStages/ElectricalBosStage';
import useStore from '../../../stores/useStore';
import PropertyPanelHeader from '../PropertyPanel/PropertyPanelHeader/PropertyPanelHeader';
import {
  NextStepWrapper, PropertyPanelContainer, SubmitSectionWrapper
} from '../PropertyPanel/styles';
import config, { UI_MODE } from '../../../config/config';
import {
  BackButtonsContainer, ContentContainer, FormDataContainer, LyraFormGeneratorContainer
} from './styles';
import '../SharedStyles/FormGenerator.css';

function isElectricalBOSStage(stage: IStage | undefined): boolean {
  if (stage) {
    return stageIs<ElectricalBosStage>(stage, DesignStep.ELECTRICAL_BOS);
  }
  return false;
}

type Props = {
  electricalBosStage: ElectricalBosStage;
};

const ElectricalBosContainer = observer((props: Props): ReactElement => {
  const { electricalBosStage } = props;
  const {
    formSpecification, showCircuitTableOrGenerateLocationsInProgress, generateLocations, onChangeBosFormValue
  } =
    electricalBosStage;

  return (
    <ContentContainer>
      <LyraFormGeneratorContainer>
        {!!formSpecification ? (
          <LyraFormGenerator
            formId="electrical-bos-form"
            formData={toJS(formSpecification.data)}
            apiSchema={toJS(formSpecification.form)}
            apiRules={toJS(formSpecification.rules)}
            onSubmit={generateLocations}
            onChange={onChangeBosFormValue}
            showSubmitButton={true}
            disableSubmitButton={showCircuitTableOrGenerateLocationsInProgress}
            submitButtonLabel="Generate Locations"
          />
        ) : (
          <LyraLoader.Loader text="Loading..." />
        )}
      </LyraFormGeneratorContainer>
    </ContentContainer>
  );
});

export const ElectricalBos = observer((): ReactElement => {
  const { workspace } = useStore();
  const stage = workspace?.currentWorkspace?.stageManager?.currentStage;
  const electricalBosStage = isElectricalBOSStage(stage) ? (stage as ElectricalBosStage) : undefined;

  if (!electricalBosStage) {
    return <></>;
  }
  return (
    <FormDataContainer>
      <PropertyPanelContainer>
        <PropertyPanelHeader icon="tool-properties" name={electricalBosStage.title} iconBg="#427ecd" />
      </PropertyPanelContainer>
      <ElectricalBosContainer electricalBosStage={electricalBosStage} />
      {config.featureFlag.uiMode !== UI_MODE.AURORA && (
        <BackButtonsContainer>
          <SubmitSectionWrapper>
            <NextStepWrapper xs={12} className="pv-xs">
              <LyraButtons.Buttons
                className="back-button"
                styleType="flat"
                colorTheme={EThemeComponentColor.TRANSPARENT}
                fontColorTheme={EThemeComponentColor.AQUAMARINE}
                isLeft
                onClick={(): void => {
                  workspace.currentWorkspace.stageManager?.previous();
                }}
              >
                <b>Back</b>
              </LyraButtons.Buttons>
              <LyraButtons.ButtonIcon
                icon="arrow"
                disabled={electricalBosStage.isReviewCircuitsButtonDisabled}
                {...(electricalBosStage.isReviewCircuitsButtonDisabled && {
                  tooltip: electricalBosStage.showCircuitTableOrGenerateLocationsInProgress
                    ? 'Operation in progress...'
                    : 'Generate equipment locations first'
                })}
                type={EButtonType.ROUNDED_BUTTON}
                colorTheme={EThemeComponentColor.AQUAMARINE}
                withIconTheme
                onClick={(): void => {
                  electricalBosStage.openReviewCircuitsAndProceedToMountingModal();
                }}
              >
                <b>Review Circuits and Proceed to Mounting</b>
              </LyraButtons.ButtonIcon>
            </NextStepWrapper>
          </SubmitSectionWrapper>
        </BackButtonsContainer>
      )}
    </FormDataContainer>
  );
});
