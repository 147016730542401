import { Color } from 'csstype';
import styled, {
  css,
  PropsTheme,
  SimpleInterpolation,
  withTheme
} from 'styled-components';
import { Flexbox } from '../../Layout/index';
import { COLOR_PLACEHOLDER, FIXED_PADDING, MAX_CHARACTERS, MIN_WIDTH } from './constants';

type QuantityProps = {
  decrease?: boolean;
};

type ContainerProps = {
  width?: string;
};

type Props = PropsTheme & {
  content?: string;
  width?: string;
  height?: string;
  withQuantityArrows: boolean;
};

function calcPadding(content: string): string {
  // Restrict of units placeholder to 5 characters
  const fixedContent = content.substr(0, MAX_CHARACTERS);
  // 3 pixels by character
  return `${FIXED_PADDING + fixedContent.length * 3}px`;
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;
  ${({ width }: ContainerProps): SimpleInterpolation =>
    css`
      width: ${width};
    `}
  box-sizing: border-box;
`;

export const InputNumberWrapper = styled.div<Props>`
  width: ${({ width = '100%' }: Props): string => width};
  height: ${({ height = '22px' }: Props): string => height};
  min-width: ${MIN_WIDTH};
  display: flex;
  border: 1px solid
    ${({ theme }: PropsTheme & QuantityProps): Color => theme.colors.tertiary as string};
  border-radius: 4px;
  padding-left: 8px;
  align-items: center;
`;

export const InputElement = styled.input`
  border: none;
  width: 100%;
  font-size: 10px;
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &:focus,
  &:active {
    outline: none;
  }
`;

export const Messure = styled.span`
  padding: 0 8px;
  font-size: 12px;
  font-weight: normal;
  color: ${COLOR_PLACEHOLDER};
`;

export const QuantityWrapper = styled.div<PropsTheme & QuantityProps>`
  box-sizing: border-box;
  height: 100%;
  display: flex;
  flex-direction: column;
  border-left: 1px solid
    ${({ theme }: PropsTheme & QuantityProps): Color => theme.colors.tertiary as string};

  div:nth-child(2) {
    border-bottom: none;
  }
  &:hover {
    cursor: pointer;
  }
`;

export const Quantity = styled.div<PropsTheme & QuantityProps>`
  border-bottom: 1px solid
    ${({ theme }: PropsTheme & QuantityProps): Color => theme.colors.tertiary as string};
  padding: 0 2px;
  svg {
    vertical-align: middle;
    ${({ decrease }: PropsTheme & QuantityProps): SimpleInterpolation =>
      decrease
        ? css`
            transform: rotate(180deg);
          `
        : ''}
  }
`;
