import React, { BaseProps, ReactElement, ReactNode } from 'react';
import { Card } from './styles';
import { EThemeComponentColor } from '../Theme/EThemeComponentColor';

type OptionProps = {
  name: string;
  value: string;
  component?: ReactNode;
  description?: string;
  label?: ReactNode;
};

type CellProps = {
  option: OptionProps;
  isSelected?: boolean;
  selectedOptionColor?: EThemeComponentColor | undefined;
  columns?: number;
  gutter?: number;
  onSelect?: (option: OptionProps) => void;
};

const Cell = (props: BaseProps & CellProps): ReactElement => {
  const { className = '', children, option, selectedOptionColor, isSelected, columns, gutter, onSelect } = props;
  const { component, label, ...rest } = option;
  const tooltip = !!option.description ? { 'data-tooltip': option.description } : {};

  return (
    <>
      <Card
        type="button"
        className={`${className} tooltip-top`}
        isSelected={isSelected}
        columns={columns}
        gutter={gutter}
        onClick={(): void => onSelect && onSelect(rest)}
        selectedOptionColor={selectedOptionColor as unknown as number}
        {...tooltip}
      >
        {children}
      </Card>
      {label}
    </>
  );
};

export { Cell, CellProps, OptionProps };
