import type { IElectricalPanelDefinitionData } from './ElectricalPanelDefinition';
import type { IElectricalPanelData } from './ElectricalPanelInstance';
import type { IGenericEquipmentData } from './GenericEquipmentInstance';
import type { IMeterMainData } from './MeterMainInstance';

interface ISiteEquipmentDefinitionsData {
  readonly mainServicePanel?: IElectricalPanelDefinitionData;
  readonly subpanel?: IElectricalPanelDefinitionData;
}

interface ISiteEquipmentInstancesData {
  mainServicePanel?: IElectricalPanelData;
  subpanel?: IElectricalPanelData;
  utilityMeter?: IGenericEquipmentData;
  gasMeter?: IGenericEquipmentData;
  meterMain?: IMeterMainData;
}

export enum SiteEquipmentDataKeys {
  utilityMeter = 'utilityMeter',
  meterMain = 'meterMain',
  mainServicePanel = 'mainServicePanel',
  subpanel = 'subpanel',
  gasMeter = 'gasMeter'
}

export interface ISiteEquipmentData {
  readonly definitions?: ISiteEquipmentDefinitionsData;
  readonly instances?: ISiteEquipmentInstancesData;
  [SiteEquipmentDataKeys.mainServicePanel]?: ISiteEquipmentInstancesData['mainServicePanel'];
  [SiteEquipmentDataKeys.utilityMeter]?: ISiteEquipmentInstancesData['utilityMeter'];
  [SiteEquipmentDataKeys.meterMain]?: IMeterMainData;
  [SiteEquipmentDataKeys.subpanel]?: ISiteEquipmentInstancesData['subpanel'];
  [SiteEquipmentDataKeys.gasMeter]?: ISiteEquipmentInstancesData['gasMeter'];
}
