import { LyraLayout } from '@aurorasolar/lyra-ui-kit';
import { observer } from 'mobx-react-lite';
import type { ReactElement } from 'react';
import * as React from 'react';
import { EApplicationContext } from '../../../domain/typings';
import useStore from '../../../stores/useStore';
import { panelRegistry } from './registry';

const PropertyPanel = (): ReactElement => {
  const {
    properties, editor
  } = useStore();

  const PropertyPanelComponent = panelRegistry.get(properties.showingPanelOf);

  const handleOnPropertiesEdition = (): void => {
    const { target } = window.event!;
    const isTyping = target instanceof HTMLInputElement || target instanceof HTMLTextAreaElement;

    editor.updateApplicationContext(
      isTyping ? EApplicationContext.PROPERTIES_CONTEXT : EApplicationContext.DRAW_CONTEXT
    );
  };

  return (
    <LyraLayout.Flexbox dir="column" align="center" onClick={handleOnPropertiesEdition} fullHeight>
      {PropertyPanelComponent && <PropertyPanelComponent />}
    </LyraLayout.Flexbox>
  );
};

export default observer(PropertyPanel);
