import debounce from 'lodash/debounce';
import {
  computed, observable
} from 'mobx';
import { handleApiError } from '../../../../utils/helpers';
import type { IProjectReadinessDependencies } from '../../../../domain/models/SiteDesign/ProjectReadiness';
import { ProjectReadiness } from '../../../../domain/models/SiteDesign/ProjectReadiness';
import type DomainStore from '../../../DomainStore/DomainStore';
import type EditorStore from '../../../EditorStore/EditorStore';
import type RootStore from '../../../Store';
import type { IControlSelectionChange } from '../../../EditorStore/Controls/ControlEvents';
import { SelectionControl } from '../../../EditorStore/Controls/SelectionControl';
import type { PropertiesStore } from '../../Properties/Properties';
import type { ServiceBus } from '../../../ServiceBus/ServiceBus';
import { canvasConfig } from '../../../../config/canvasConfig';
import type { EnvironmentalPropsStore } from '../../EnvironmentalProps/EnvironmentalPropsStore';
import type { ModalStore } from '../../Modal/Modal';
import type { IMissingPropertiesViewModelDependencies } from '../../Modal/ViewModels/MissingProperties/MissingPropertiesViewModel';
import { MissingPropertiesViewModel } from '../../Modal/ViewModels/MissingProperties/MissingPropertiesViewModel';
import { ROOF_COLOR_LEVEL_RANGE } from '../../../../domain/models/RoofColorLevelRange';
import type { RoofFacePropertiesStore } from '../../Properties/RoofProperties/RoofFacePropertiesStore';
import type SmartGuidesStore from '../../SmartGuidesStore/SmartGuidesStore';
import EGuideIdentifier from '../../SmartGuidesStore/EGuideIdentifier';
import {
  GM_TOOL_ID,
  IMPORT_FILE_TOOL_ID,
  MAP_PROVIDER_TOOL,
  MSP_TOOL_ID,
  OUTLINE_TOOL_ID,
  PANNING_TOOL_ID,
  PARCEL_BOUNDARY_TOOL_ID,
  ROOF_TRACING_TOOL_ID,
  SELECT_TOOL_ID,
  SITE_EQUIPMENT_TOOL_ID,
  SITE_INFO_TOOL_ID,
  STREET_LOCATION_ID,
  SUBPANEL_TOOL_ID,
  TRACE_INDIVIDUAL_ROOFFACE_TOOL_ID,
  UTILITY_METER_TOOL_ID
} from '../../ToolbarStore/Project/constants';
import type { ToolbarStore } from '../../ToolbarStore/Toolbar';
import type { WizardStore } from '../../Wizard/Wizard';
import { DesignService } from '../../../../infrastructure/services/api/DesignService';
import { SceneObjectType } from '../../../../domain/models/Constants';
import type { UiStore } from '../../UiStore';
import { ERoofType } from '../../../../domain/typings';
import { SERVICE_ENTRANCE_EQUIPMENT_TOOL_ID } from '../../ToolbarStore/Project/SiteEquipmentTools/ServiceEntranceEquipmentTool';
import { DefaultWorkspace } from './DefaultWorkspace';
import type { RoofFace } from 'domain/models/SiteDesign/RoofFace';

export class ProjectWorkspace extends DefaultWorkspace {
  override readonly id: string = 'project';
  override readonly toolbarRegistry = 'toolbar_project';

  override readonly defaultToolsWhiteList: string[] = [
    SELECT_TOOL_ID,
    ROOF_TRACING_TOOL_ID,
    TRACE_INDIVIDUAL_ROOFFACE_TOOL_ID,
    PARCEL_BOUNDARY_TOOL_ID,
    SITE_EQUIPMENT_TOOL_ID,
    STREET_LOCATION_ID,
    SERVICE_ENTRANCE_EQUIPMENT_TOOL_ID,
    MSP_TOOL_ID,
    SUBPANEL_TOOL_ID,
    GM_TOOL_ID,
    UTILITY_METER_TOOL_ID,
    MAP_PROVIDER_TOOL,
    OUTLINE_TOOL_ID,
    PANNING_TOOL_ID,
    IMPORT_FILE_TOOL_ID,
    SITE_INFO_TOOL_ID
  ];

  private readonly defaultGuidelinesWhiteList: EGuideIdentifier[] = [
    EGuideIdentifier.EXTENSION_LINES,
    EGuideIdentifier.MID_POINTS,
    EGuideIdentifier.LIVE_ANGLES,
    EGuideIdentifier.PARALLEL,
    EGuideIdentifier.PERPENDICULAR,
    EGuideIdentifier.SNAP_LINES,
    EGuideIdentifier.SQUARE
  ];

  private readonly editor: EditorStore;
  private readonly domainStore: DomainStore;
  private readonly serviceBus: ServiceBus;
  private readonly modal: ModalStore;
  private readonly toolbar: ToolbarStore;
  private readonly smartGuides: SmartGuidesStore;
  private readonly wizardStore: WizardStore;
  private readonly environmentalProps: EnvironmentalPropsStore;
  private readonly roofFaceProps: RoofFacePropertiesStore;
  @observable
  autoSaveCommandsCounter: number = 0;
  private readonly autoSaveMaxCommandsCounter: number = 10;
  private selectionControl?: SelectionControl;
  private readonly properties: PropertiesStore;
  private latestProjectReadiness?: ProjectReadiness;

  private debounceSave: () => void;
  private readonly designService = new DesignService();

  private canvasReady: boolean = false;

  constructor(root: RootStore, uiStore: UiStore) {
    super();
    this.editor = root.editor;
    this.domainStore = root.domain;
    this.serviceBus = root.serviceBus;
    this.properties = uiStore.properties;
    this.modal = uiStore.modal;
    this.toolbar = uiStore.toolbar;
    this.smartGuides = uiStore.smartGuides;
    this.wizardStore = uiStore.wizard;
    this.environmentalProps = uiStore.environmentalProps;
    this.roofFaceProps = uiStore.roofFaceProps;
    this.editor.addEventListener('canvasReady', this.handleCanvasReady);

    this.debounceSave = debounce((): void => {
      if (this.autoSaveCommandsCounter !== 0 && !localStorage.disableAutoSave) {
        this.persist();
      }
    }, canvasConfig.autosaveDebounce);
  }

  private handleCanvasReady = (): void => {
    this.canvasReady = true;
    this.setup();
  };

  @computed
  get isUnsavedState(): boolean {
    return this.autoSaveCommandsCounter <= this.autoSaveMaxCommandsCounter;
  }
  // When navigating through the stages, a function that updates the design is triggered
  // and the counter becomes 1. I.e. it seems as if one action has been done.
  @computed
  get isSavedState(): boolean {
    return this.autoSaveCommandsCounter <= 0;
  }

  setup = (): void => {
    this.selectionControl = SelectionControl.getInstance(this.editor, this.editor.viewport, this.editor.activeCamera);

    this.serviceBus.addEventListener('commandExecuted', this.onCommandExecuted);
    this.selectionControl?.addEventListener('selection_change', this.onSelectionChange);

    this.smartGuides.enable = true;
    this.smartGuides.initGuides(this.defaultGuidelinesWhiteList);

    if (this.canvasReady) {
      this.toolbar.activateSelectionToolInProjectWorkspace();
      this.smartGuides.enableLineSnapGuide();
      this.smartGuides.enableAngleSnapGuide();
      this.repaintRoofFaces();
    }
  };

  dispose(): void {
    this.serviceBus.removeEventListener('commandExecuted', this.onCommandExecuted);
    this.selectionControl?.removeEventListener('selection_change', this.onSelectionChange);
    this.smartGuides.disableLineSnapGuide();
    this.smartGuides.disableAngleSnapGuide();
  }

  async saveManually(): Promise<void> {
    await this.persist();
    this.resetCounter();
  }

  async canContinue(): Promise<boolean> {
    if (!this.domainStore.project.id) {
      return false;
    }
    const response = await this.designService
      .getProjectMissingProperties(this.domainStore.project.id)
      .catch(handleApiError(`Failed to fetch missing properties for project ${this.domainStore.project.id}`));
    const dependencies: IProjectReadinessDependencies = {
      editor: this.editor,
      domain: this.domainStore,
      modal: this.modal,
      serviceBus: this.serviceBus,
      toolbar: this.toolbar,
      properties: this.properties,
      smartGuides: this.smartGuides,
      wizard: this.wizardStore,
      currentWorkspace: this,
      environmentalProps: this.environmentalProps,
      roofFaceProps: this.roofFaceProps,
      missingPropertiesResponse: response
    };
    this.latestProjectReadiness = new ProjectReadiness(dependencies);
    return !this.latestProjectReadiness.hasMissingProperties();
  }

  canSwitchToDesignWorkspaceAfterLoadingDesign = async (): Promise<boolean> => {
    const canContinue = await this.canContinue();
    if (canContinue) {
      return true;
    }
    if (!this.latestProjectReadiness) {
      throw new Error('Illegal state - project readiness for design is not known');
    }
    return !this.latestProjectReadiness.hasMissingMandatoryProperties;
  };

  showMissingRequiredPropertiesModal = (): void => {
    if (!this.latestProjectReadiness) {
      throw new Error('Illegal state - project readiness for design is not known');
    }
    const missingPropertiesDependencies: IMissingPropertiesViewModelDependencies = {
      domain: this.domainStore,
      modal: this.modal,
      projectReadiness: this.latestProjectReadiness,
      editor: this.editor
    };
    this.modal.createModal('missing_properties_modal', new MissingPropertiesViewModel(missingPropertiesDependencies));
  };

  onCommandExecuted = (): void => {
    this.autoSave();
  };

  private onSelectionChange = (event: IControlSelectionChange): void => {
    if (event.selection === undefined) {
      return;
    }
    this.properties.computePropertiesPanel(event.selection);
  };

  private autoSave = async (): Promise<void> => {
    // Verify count is less than 10
    this.count();
    this.debounceSave();

    if (this.autoSaveCommandsCounter >= 10 && !localStorage.disableAutoSave) {
      await this.persist();
      this.resetCounter();
    }
  };

  private async persist(): Promise<void> {
    this.saving = true;
    this.inProgress = false;
    this.errors.clear();
    try {
      await this.domainStore.updateProject();
      this.resetCounter();
    } catch (error) {
      handleApiError('Updating project workspace failed')(error);
    } finally {
      this.saving = false;
    }
  }

  private count(): void {
    this.autoSaveCommandsCounter++;
    this.inProgress = true;
    this.saving = false;
  }

  private resetCounter(): void {
    this.autoSaveCommandsCounter = 0;
  }

  repaintRoofFaces(): void {
    const allRoofFaces: RoofFace[] = this.editor.getObjectsByType(SceneObjectType.RoofFace, true);
    allRoofFaces.forEach((roof: RoofFace): void => {
      const isFlatRoof = roof.roofType === ERoofType.FLAT;
      roof.setColor(ROOF_COLOR_LEVEL_RANGE[this.domainStore.getLevelOfRoofFace(roof)]);
      roof.azimuthArrow.setVisible(!isFlatRoof);
    });
  }
}
