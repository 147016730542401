import React, { BaseProps, ReactElement } from 'react';
import { PipelineProps, StepData } from '../../typings';
import { Flexbox } from '../Layout';
import { Step } from './Step';
import {
  PipelineContainer,
  ProgressBar
} from './styles';

type Props = BaseProps & PipelineProps;

const getStep = (
  index: number,
  currentStep: number
): 'ready' | 'active' | 'pending' => {
  if (index < currentStep) return 'ready';
  if (index > currentStep) return 'pending';
  return 'active';
};

const HorizontalPipeline = (props: Props): ReactElement => {
  const {
    currentStep = 0,
    data
  } = props;

  return (
    <PipelineContainer>
      <ProgressBar>
        {data.map(
          (step: StepData, index: number): ReactElement => (
            <Step key={index} state={getStep(index, currentStep)} {...step} />
          )
        )}
      </ProgressBar>
    </PipelineContainer>
  );
};

export { HorizontalPipeline };
