import React, { ReactElement } from 'react';
import { Col } from 'react-flexbox-grid';
import {
  CenterColumn,
  ContentRow,
  HeaderText
} from './styles';

type TableHeaderProps = {
  noDownloadColumn?: boolean;
};

const DataTableHeader = ({ noDownloadColumn }: TableHeaderProps): ReactElement => {
  return (
    <ContentRow>
      <Col md={6}>
        <HeaderText>Product Details</HeaderText>
      </Col>
      <CenterColumn md={2}>
        <HeaderText>Date</HeaderText>
      </CenterColumn>
      <CenterColumn md={2}>
        <HeaderText>Transaction</HeaderText>
      </CenterColumn>
      {noDownloadColumn ? null : (
        <CenterColumn md={2}>
          <HeaderText>Download</HeaderText>
        </CenterColumn>
      )}
    </ContentRow>
  );
};

export { DataTableHeader };
