import styled, {
  CSSRule,
  FCWithTheme,
  PropsTheme,
  withTheme
} from 'styled-components';
import { Flexbox, Props as FlexProps } from '../Flexbox';

type Props = FlexProps & {
  withGradient?: boolean;
  withBorder?: boolean;
};

type WithTheme = PropsTheme<Props>;

const FlexBG = styled(Flexbox)<Props>`
  /** Applying the border from mixins **/
  ${(props: WithTheme): CSSRule => props.withBorder
    ? props.theme.mixins.borderBox
    : ''
  }
  ${(props: WithTheme): CSSRule =>
    props.withGradient
      ? props.theme.mixins.gradientBg
      : props.theme.mixins.flatBg}
  box-sizing: border-box;
`;

export { FlexBG };
