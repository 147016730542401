import type { BaseProps } from 'react';
import type { PropsTheme } from 'styled-components';
import styled from 'styled-components';
import Flexbox from '../../../../ui/components/Layout/Flexbox';

type TooltipWrapperProps = BaseProps & {
  tooltipPosition?: 'top' | 'bottom';
};

type TooltipStyleProps = TooltipWrapperProps &
  PropsTheme & {
    parentWidth?: number;
    step?: number;
    totalSteps?: number;
    tooltipTheme?: 'dark' | 'white';
  };

function calculateTooltipContent({ tooltipPosition }: TooltipWrapperProps): string {
  if (tooltipPosition === 'bottom') {
    return `
      left: calc(-100% - 19px);
      bottom: calc(-20% - 15px);
    `;
  }
  return `
    top: -20px;
    bottom: 0;
    left: 50%;
    height:0px;
  `;
}

function setTooltipTheme({
  tooltipTheme, theme
}: TooltipStyleProps): string {
  if (tooltipTheme === 'dark') {
    return `
    background-color: ${theme.colors.black};
    color: ${theme.colors.whiteBg}`;
  } else {
    return `
    background-color: ${theme.colors.whiteBg};
  `;
  }
}
styled(Flexbox).attrs({
  dir: 'row',
  justify: 'center',
  align: 'center'
})`
  cursor: pointer;
  height: inherit;
`;

export const TooltipWrapper = styled.div<TooltipWrapperProps>`
  min-width: 100%;
  position: absolute;
  display: block;
  ${({ tooltipPosition }: TooltipWrapperProps): string =>
    calculateTooltipContent({
      tooltipPosition
    })}
`;

export const TooltipStyle = styled.div<TooltipStyleProps>`
  ${({
    tooltipTheme, theme
  }: TooltipStyleProps): string =>
    setTooltipTheme({
      tooltipTheme,
      theme
    })}
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
  border-radius: 3px;
  bottom: 0;
  box-shadow: 3px 4px 5px rgba(0, 0, 0, 0.1);
  min-width: 100%;
  position: absolute;
  transform: translateX(-50%);
  text-align: center;
  z-index: ${({ theme }: PropsTheme): number => theme.layers.foreground};

  & > span {
    background-color: inherit;
    border-bottom: none;
    border-right: none;
    bottom: ${({ tooltipPosition }: TooltipStyleProps): string => (tooltipPosition === 'bottom' ? '40px' : '-5px')};
    display: block;
    height: 12px;
    position: absolute;
    transform: rotate(45deg);
    width: 12px;
  }

  & > ul {
    & > li {
      cursor: pointer;
    }
  }
`;

export const TooltipTriggerWrapper = styled.div`
  position: relative;
  height: 100%;
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
`;
