import type { ProjectReadiness } from '../../../../../domain/models/SiteDesign/ProjectReadiness';
import type DomainStore from '../../../../DomainStore/DomainStore';
import type EditorStore from '../../../../EditorStore/EditorStore';
import { BaseViewModel } from '../../BaseViewModel';
import type { ModalStore } from '../../Modal';

export interface IMissingPropertiesViewModelDependencies {
  readonly domain: DomainStore;
  readonly projectReadiness: ProjectReadiness;
  readonly modal: ModalStore;
  readonly editor: EditorStore;
}

export class MissingPropertiesViewModel extends BaseViewModel {
  readonly propCodeUI = 'missing_properties_modal';
  override readonly editor: EditorStore;
  readonly projectReadiness: ProjectReadiness;

  constructor(dependencies: IMissingPropertiesViewModelDependencies) {
    super(dependencies);
    this.projectReadiness = dependencies.projectReadiness;
    this.editor = dependencies.editor;
  }

  openSiteInfo(): void {
    this.modal.closeModal();
    this.modal.createModalBasedOnViewModalCode('project_properties_modal');
  }

  override dispose() {
    // do nothing
  }
}
