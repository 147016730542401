import {
  action, computed
} from 'mobx';
import { MathUtils } from 'three';
import uniq from 'lodash/uniq';
import type { IProgressStepperStage } from '../IProgressStepperStage';
import type DomainStore from '../../../stores/DomainStore/DomainStore';
import type EditorStore from '../../../stores/EditorStore/EditorStore';
import type { ModalStore } from '../../../stores/UiStore/Modal/Modal';
import type { InverterType } from '../../../stores/UiStore/Modal/ViewModels/InverterSelectionModal/InverterSelectionViewModel';
import {
  InverterSelectionViewModel,
  isInverterOutsideInputVoltageRange
} from '../../../stores/UiStore/Modal/ViewModels/InverterSelectionModal/InverterSelectionViewModel';
import type { PanelsStore } from '../../../stores/UiStore/Panels/Panels';
import type { IMpptAndMultiInverterSelectionPanelViewModelDependencies } from '../../../stores/UiStore/Panels/ViewModels/MPPTAndMultiInverterSelectionPanel/MPPTAndMultiInverterSelectionPanelViewModel';
import { MpptAndMultiInverterSelectionPanelViewModel } from '../../../stores/UiStore/Panels/ViewModels/MPPTAndMultiInverterSelectionPanel/MPPTAndMultiInverterSelectionPanelViewModel';
import type {
  IInverterInformation, IInverterSelected
} from '../../models/SupplementalData/IInverterInfo';
import type {
  ISystemSummaryPanelViewModelDependencies,
  SystemSummaryPanelViewModel
} from '../../../stores/UiStore/Panels/ViewModels/SystemSummary/SystemSummaryPanelViewModel';
import type {
  ServiceBus, CommandExecutedEvent
} from '../../../stores/ServiceBus/ServiceBus';
import type { DeleteObjectDependencies } from '../../../stores/ServiceBus/Commands/DeleteObjectCommand';

import {
  selectSortedParallelStrings,
  stringingOptionsToSupplementalData
} from '../../../services/stringing/stringingFunctions';
import StringingService from '../../../services/stringing/stringingService';
import { handleApiError } from '../../../utils/helpers';
import { SentryException } from '../../../utils/sentryLog';
import {
  ADD_MODULE_ID,
  CHANGE_ORIENTATION_ID,
  PANNING_TOOL_ID,
  REMOVE_MODULE_ID,
  REVIEW_CIRCUITS_ID,
  STRINGING_ID
} from '../../../stores/UiStore/ToolbarStore/Design/constants';
import type { ToolbarStore } from '../../../stores/UiStore/ToolbarStore/Toolbar';
import type { DesignWorkspace } from '../../../stores/UiStore/WorkspaceStore/workspaces/DesignWorkspace';
import type { ISearchInverterParameters } from '../../entities/Design/ISearchInverterParameters';
import type {
  IDesignData, Design
} from '../../models/Design/Design';
import type {
  IDcOptimizerStringOptionsData,
  ISeriesStringConfigurationOptionsData,
  IStringingOptionsResponseData as StringingOption,
  IStringingOptionsResponseData
} from '../../entities/StringingOption/IStringingOptionData';
import type { Stringing } from '../../graphics/stringing/Stringing';
import {
  BRANCH_AC_MODULE,
  BRANCH_MICROINVERTER,
  DC_MODULE,
  CENTRAL_MPPT_STRING,
  STRING_WITH_DC_OPTIMIZERS,
  SceneObjectType
} from '../../models/Constants';
import type PvModule from '../../models/SiteDesign/PvModule';
import { DesignReadiness } from '../../models/SiteDesign/DesignReadiness';
import type {
  IAcBranch, IAcBranchCircuitRequest
} from '../../request/ElectricalDesign/IAcBranchCircuitRequest';
import type {
  ICircuitSpecification,
  IDcSourceCircuitRequest,
  IDcSourceCircuitsRequest
} from '../../request/ElectricalDesign/IDcSourceCircuitRequest';
import type { Chain } from '../../../utils/chainer';
import { createChainer } from '../../../utils/chainer';
import type { DesignDelta } from '../../../domain/entities/Design/DesignDelta';
import { DesignStep } from '../../models/Design/DesignState';
import { KeyboardListener } from '../../../utils/KeyboardListener';
import type { IAcCoupledEnergyStorageSystemDefinitionData } from '../../models/PvSystem/AcCoupledEnergyStorageSystems';
import { PropsPanelUICodes } from '../../../stores/UiStore/Properties/propertiesStoreConstants';
import {
  cancelablePromise, CancellablePromiseKeys
} from '../../../utils/CancellablePromise';
import { DesignService } from '../../../infrastructure/services/api/DesignService';
import { EquipmentService } from '../../../infrastructure/services/api/EquipmentService';
import {
  type IKeyboardBehaviourHandler, KeyboardBehaviour
} from '../../behaviour/KeyboardBehaviour';
import {
  ElectricalDesignCompleted,
  AcBranchCircuitAddedEvent,
  DcSourceCircuitAddedEvent,
  PvSourceCircuitAddedEvent,
  AcBranchCircuitDeletedEvent,
  DcSourceCircuitsDeletedEvent,
  DcSourceCircuitDeletedEvent,
  PvSourceCircuitDeletedEvent,
  AcBranchCircuitUpdatedEvent,
  DcSourceCircuitUpdatedEvent,
  PvSourceCircuitUpdatedEvent
} from '../../../services/analytics/DesignToolAnalyticsEvents';
import type PvModulePosition from '../../models/SiteDesign/PvModulePosition';
import type { IPvSourceCircuitRequest } from '../../request/ElectricalDesign/IPvSourceCircuitRequest';
import type { IUpdateDesignSupplementalDataDependencies } from '../../../stores/ServiceBus/Commands/UpdateDesignSupplementalDataCommand';
import config from '../../../config/config';

const userSwitchedDesignStepErrorMessage = 'User switched design step, aborting stringing initialization';

export interface IElectricalDesignDependencies {
  editor: EditorStore;
  domain: DomainStore;
  designWorkspace: DesignWorkspace;
  serviceBus: ServiceBus;
  toolbar: ToolbarStore;
  modal: ModalStore;
  panels: PanelsStore;
}

export interface IInverterInfo {
  model: string;
  units: number;
}

export interface ManufacturerDefinition {
  readonly manufacturer: string;
  readonly make: string;
}

type SourceCircuit = string[];
type PvSourceCircuit = SourceCircuit[];

export class ElectricalDesignStage implements IProgressStepperStage, IKeyboardBehaviourHandler {
  static readonly toolBlacklist: string[] = [
    ADD_MODULE_ID,
    CHANGE_ORIENTATION_ID,
    REMOVE_MODULE_ID,
    REVIEW_CIRCUITS_ID
  ];

  static readonly initialToolWhitelist: string[] = [PANNING_TOOL_ID];

  static readonly toolWhitelist: string[] = [PANNING_TOOL_ID, STRINGING_ID];

  readonly propCodeUI = PropsPanelUICodes.ElectricalDesign;
  readonly title = 'Electrical Design';

  readonly id: DesignStep = DesignStep.ELECTRICAL_DESIGN;

  private cicruitModificationQueue: Chain<void> = createChainer();
  private inverterSelectionViewModel: InverterSelectionViewModel | undefined;
  private hasMissingProperties: boolean = false;
  private selectedInverterOption?: InverterType;

  private readonly domain: DomainStore;
  private readonly editor: EditorStore;
  private readonly designWorkspace: DesignWorkspace;
  private readonly serviceBus: ServiceBus;
  private readonly toolbar: ToolbarStore;
  private readonly modal: ModalStore;
  private readonly panels: PanelsStore;
  private readonly designService = new DesignService();
  private readonly equipmentService = new EquipmentService();

  constructor(dependencies: IElectricalDesignDependencies) {
    const {
      designWorkspace, domain, editor, serviceBus, toolbar, modal, panels
    } = dependencies;

    this.designWorkspace = designWorkspace;
    this.domain = domain;
    this.editor = editor;
    this.serviceBus = serviceBus;
    this.toolbar = toolbar;
    this.modal = modal;
    this.panels = panels;
  }

  setUp = async (): Promise<void> => {
    this.hideEmptyPvModulePositions();
    this.addCommandExecutionListener();
    this.toolbar.deselectTool();
    this.changeToolsTo(ElectricalDesignStage.toolWhitelist);
    this.setDesignState();
    await this.initStringingService();
    this.inverterSelectionViewModel = undefined;
    this.editor.renderSiteMarkers(this.designWorkspace);
  };

  setUpTool = (toolId: string): void => {
    if (toolId === STRINGING_ID) {
      KeyboardBehaviour.addKeyboardEvents(this);
    }
  };

  @computed
  get design(): Design {
    return this.domain.design;
  }

  resume(lastValidStage: string): void {
    if (lastValidStage === this.id) {
      this.changeToolsTo(ElectricalDesignStage.initialToolWhitelist);
      this.addCommandExecutionListener();
    }
    this.initStringingService();
    this.hideEmptyPvModulePositions();
  }

  get canContinue(): boolean {
    return !this.hasMissingProperties;
  }

  @computed
  get showEnergyStorageAndBackup(): boolean {
    return !!this.design?.system.equipment.acCoupledEnergyStorageSystems?.instances.length;
  }

  async showNoteForAcModule(): Promise<ManufacturerDefinition> {
    const pvModuleDefinition = this.domain.design.system.equipment.pvModules.definition;
    const {
      electricalComponents: {
        inverter: { externalDefinitionId: inverterId }
      }
    } = await this.equipmentService.getPvModuleDefinition(pvModuleDefinition);
    const response = await this.equipmentService.getInverterDefinition(inverterId);
    return {
      manufacturer: response.manufacturer.name,
      make: response.model
    };
  }

  @computed
  get energyStorageAndBackup(): readonly IAcCoupledEnergyStorageSystemDefinitionData[] {
    return this.design.system.equipment.acCoupledEnergyStorageSystems?.definitions ?? [];
  }

  @computed
  get energyStorageAndBackupEquipmentCount(): number {
    return this.design.system.equipment.acCoupledEnergyStorageSystems?.instances.length ?? 0;
  }

  energyStorageAndBackupEquipmentCountByDefinition(definitionId: string): number {
    return this.design.system.equipment.acCoupledEnergyStorageSystems?.instanceCountByDefinition(definitionId) ?? 0;
  }

  beforeContinue(): void {
    if (StringingService.activeSelectedString) {
      void StringingService.finishEditingString();
    }
  }

  continue(): void {
    this.dispose();
    config.analytics?.trackEvent(new ElectricalDesignCompleted(this.domain));
  }

  cancel(): void {
    this.dispose();
    StringingService.clearStringing();
  }

  dispose(): void {
    this.toolbar.deselectTool();
    this.disposeEvents();
    this.destroyOpenedPanel();
    this.serviceBus.removeEventListener('commandExecuted', this.onCommandExecuted);
  }

  disposeEvents(): void {
    KeyboardBehaviour.removeKeyboardEvents(this);
    this.removeCommandExecutionListener();
  }

  onKeyDown = (): void => {
    // do nothing
  };

  onKeyUp = (event: KeyboardEvent): void => {
    if (event.key === KeyboardListener.KEY_BACKSPACE || event.key === KeyboardListener.KEY_DELETE) {
      if (StringingService.activeSelectedString) {
        const dependencies: DeleteObjectDependencies = {
          editor: this.editor,
          domain: this.domain,
          object: {
            ...StringingService.activeSelectedString,
            name: 'string'
          } as Stringing
        };
        this.serviceBus.send('delete_string_command', dependencies);
      }
    }

    const isMpptAndMultiInverterSelectionPanel =
      this.panels.viewModel && this.panels.viewModel.propCodeUI === 'MPPT_and_multi_inverter_selection_panel';
    if (event.key === KeyboardListener.KEY_ESCAPE && isMpptAndMultiInverterSelectionPanel) {
      this.closeMPPTModal();
    }
  };

  openMpptAndMultiInverterSelectionPanel(invertersData: IInverterSelected[], string: Stringing): void {
    const inverters: IInverterInformation[] = [];

    invertersData.forEach((element: IInverterSelected, index: number): void => {
      // Show as selected only when an inverter has MPPTs and all of them are already used.
      const showInverterAsSelected =
        !!element.data?.mppts
        && uniq(
          StringingService.strings
            .filter((string: Stringing) => string.getInverterId() === element.instanceId)
            .map((string: Stringing): string => string.getMpptId())
        ).length >= (element.data.mppts.length ?? 0);

      const data: IInverterInformation = {
        id: index + 1,
        selected: showInverterAsSelected,
        mppts: element.data?.mppts,
        definitionId: element.definitionId,
        inverterId: element.instanceId,
        stringingOptions: element.data?.stringingOptions,
        type: element.data?.type,
        name: element.name,
        inverterDefinition: element.data?.inverterDefinition
      };
      inverters.push(data);
    });

    const inverterPanelDependencies: IMpptAndMultiInverterSelectionPanelViewModelDependencies = {
      panel: this.panels,
      domain: this.domain,
      designWorkspace: this.designWorkspace,
      serviceBus: this.serviceBus,
      editor: this.editor,
      inverters,
      string
    };

    this.panels.createPanel('MPPT_and_multi_inverter_selection_panel', inverterPanelDependencies);
  }

  isMpptAndMultiInverterSelectionPanelOpen(): boolean {
    return this.panels.viewModel instanceof MpptAndMultiInverterSelectionPanelViewModel;
  }

  async onStringingEnd(strings: Stringing[], isNewString: boolean): Promise<void> {
    const [string, ...otherStringsInParallel] = strings;
    return this.cicruitModificationQueue.queue(async (): Promise<void> => {
      try {
        const isNewStringForExistingMppt = otherStringsInParallel.length > 0;
        if (!isNewString || isNewStringForExistingMppt) {
          await this.updateCircuit(string, otherStringsInParallel);
        } else {
          await this.addCircuit(string);
          const systemSummaryPanelViewModel = this.panels.viewModel as SystemSummaryPanelViewModel;
          systemSummaryPanelViewModel?.setSelectedString?.(string.serverId);
        }
      } catch (error) {
        SentryException('Error creating or editing stringing', error);
        throw error;
      }
    }).head;
  }

  bindStringingServiceCallbacks(): void {
    const systemSummaryPanelViewModel = this.panels.viewModel as SystemSummaryPanelViewModel;
    StringingService.bindCallbacks({
      addToScene: this.editor.addOrUpdateObject.bind(this.editor),
      removeFromScene: this.editor.removeObject.bind(this.editor),
      clearStrings: systemSummaryPanelViewModel.clearStrings.bind(this.panels.viewModel),
      systemSummarySetOption: systemSummaryPanelViewModel.setStringingOption.bind(this.panels.viewModel),
      systemSummarySetStringing: systemSummaryPanelViewModel.setSelectedString.bind(this.panels.viewModel),
      systemSummaryDeleteStringing: systemSummaryPanelViewModel.deleteString.bind(this.panels.viewModel),
      openMpptAndMultiInverterSelectionPanel: this.openMpptAndMultiInverterSelectionPanel.bind(this),
      isMpptAndMultiInverterSelectionPanelOpen: this.isMpptAndMultiInverterSelectionPanelOpen.bind(this),
      onStringingEnd: this.onStringingEnd.bind(this)
    });
  }

  private bindDummyStringingServiceCallbacks(): void {
    StringingService.bindCallbacks({
      addToScene: this.editor.addOrUpdateObject.bind(this.editor),
      removeFromScene: this.editor.removeObject.bind(this.editor),
      clearStrings: (): void => undefined,
      systemSummarySetStringing: (_: string): void => undefined,
      systemSummarySetOption: (_: Stringing): void => undefined,
      systemSummaryDeleteStringing: (_: Stringing): void => undefined,
      openMpptAndMultiInverterSelectionPanel: (_: IInverterSelected[], __: Stringing): void => undefined,
      isMpptAndMultiInverterSelectionPanelOpen: (): boolean => false,
      onStringingEnd: (_: Stringing[], __: boolean, ___?: Stringing[]): Promise<void> => Promise.resolve(undefined)
    });
  }

  async reloadStringingOptionsAndShowSummaryPanel(): Promise<StringingOption[]> {
    const pvModuleType = this.design.supplementalData?.pvModuleInfo?.type;

    const hasNoInverterDefinitions: boolean = !this.design.system.equipment.inverters;
    const hasNoInverterDataInSupplementalData: boolean = !this.design.supplementalData?.invertersSelected?.length;
    const missingInverterDataForDcModule: boolean =
      pvModuleType === DC_MODULE && (hasNoInverterDefinitions || hasNoInverterDataInSupplementalData);

    this.inverterSelectionViewModel = await cancelablePromise<InverterSelectionViewModel>(
      CancellablePromiseKeys.CreateInverterSelectionViewModel,
      this.createInverterSelectionViewModel()
    );

    this.selectedInverterOption = await cancelablePromise<InverterType | undefined>(
      CancellablePromiseKeys.GetSelectedInverterOption,
      this.inverterSelectionViewModel.getSelectedInverterOption()
    );

    const outsideInputVoltageRange = isInverterOutsideInputVoltageRange(this.selectedInverterOption);

    if (missingInverterDataForDcModule || outsideInputVoltageRange) {
      await this.showInverterSelectionModal();
      return [];
    }
    if (!this.design.state.isUserInOrAfter(DesignStep.ELECTRICAL_DESIGN)) {
      throw new Error(userSwitchedDesignStepErrorMessage);
    }

    return cancelablePromise<StringingOption[]>(
      CancellablePromiseKeys.LoadStringingOptions,
      this.loadStringingOptions()
    ).then((stringingOptionsList: StringingOption[]): IStringingOptionsResponseData[] => {
      // Check whether user is on a step that has stringing
      if (this.design.state.isUserInOrAfter(DesignStep.ELECTRICAL_DESIGN)) {
        StringingService.updateStringingOptions(stringingOptionsList);

        // Show summary only on electrical design step:
        if (this.design.state.isUserIn(DesignStep.ELECTRICAL_DESIGN)) {
          this.showSummaryPanel();
        } else {
          this.bindDummyStringingServiceCallbacks();
        }
      } else {
        throw new Error(userSwitchedDesignStepErrorMessage);
      }
      return stringingOptionsList;
    });
  }

  async initStringingService(): Promise<void> {
    try {
      const stringingOptionsList = await cancelablePromise<IStringingOptionsResponseData[]>(
        CancellablePromiseKeys.StringingOptionsAndShowSummaryPanel,
        this.reloadStringingOptionsAndShowSummaryPanel()
      );

      StringingService.initialise({
        design: this.design,
        roofFacePvModules: this.editor.getObjectsByType(SceneObjectType.PvModule, true),
        stringingOptions: stringingOptionsList,
        toggleCanvasLoaderCursorState: this.editor.toggleCanvasLoaderCursorState.bind(this.editor)
      });
    } catch (e) {
      if ((e as Error).message !== userSwitchedDesignStepErrorMessage) {
        throw e;
      }
    }
  }

  editInverterAndDcOptimizer(): void {
    this.toolbar.deselectTool();
    this.destroyOpenedPanel();
    this.showInverterSelectionModal();
  }

  resetInvertersAndDcOptimizerValues(): void {
    StringingService.resetInverters();
  }

  @action.bound
  setInverter(inverterInfo: IInverterInfo): void {
    StringingService.addInverter(inverterInfo);
  }

  showSummaryPanel(): void {
    const stringingOptionsList = StringingService.stringingOptions;

    const dependencies: ISystemSummaryPanelViewModelDependencies = {
      panel: this.panels,
      domain: this.domain,
      designWorkspace: this.designWorkspace,
      serviceBus: this.serviceBus,
      editor: this.editor,
      stringingOptions: stringingOptionsList,
      stringingList: StringingService.getStrings(),
      minDcInputVoltage: this.selectedInverterOption?.attributes.minDcInputVoltage,
      maxDcInputVoltage: this.selectedInverterOption?.attributes.maxDcInputVoltage
    };

    this.panels.destroyPanel();
    this.panels.createPanel('system_summary_panel', dependencies);

    const isSingleInverter = stringingOptionsList.some(
      (response: StringingOption): boolean =>
        response.type === STRING_WITH_DC_OPTIMIZERS || response.type === CENTRAL_MPPT_STRING
    );
    if (isSingleInverter) {
      StringingService.setInvertersUnits(1);
    }

    this.changeToolsTo(ElectricalDesignStage.toolWhitelist);

    if (!this.toolbar.selectedTool) {
      this.toolbar.activateToolInDesignWorkspaceWithoutClick(STRINGING_ID, this);
    }

    this.bindStringingServiceCallbacks();

    (this.panels.viewModel as SystemSummaryPanelViewModel).setStringingOptions(
      stringingOptionsList,
      StringingService.getStrings()
    );
  }

  setStringingOption(selected: Stringing): void {
    const systemSummaryPanelViewModel = this.panels.viewModel as SystemSummaryPanelViewModel;
    systemSummaryPanelViewModel.setStringingOption(selected);
  }

  closeMPPTModal(): void {
    if (!StringingService.activeSelectedString) {
      this.panels.destroyPanel();
      return;
    }
    const selectedString = StringingService.activeSelectedString;
    StringingService.deleteSelectedString();
    StringingService.resetMpptAndMultiInverterSelectionPanelFlags();
    const systemSummaryPanelViewModel = this.panels.viewModel as SystemSummaryPanelViewModel;
    systemSummaryPanelViewModel?.deleteString?.(selectedString);
    this.panels.destroyPanel();
  }

  removeInverter(inverterId: string): void {
    StringingService.removeInverter(inverterId);
  }

  async awaitCompletionOfPendingChanges(): Promise<void> {
    await this.cicruitModificationQueue.head;
  }

  async validateMissingProperties(): Promise<void> {
    const nextStep = this.designWorkspace.stageManager!.currentIndex + 1;
    const nextStage = this.designWorkspace.stageManager!.getStageData(nextStep);
    if (nextStage) {
      const missingPropertiesResponse = await this.designService
        .getDesignMissingProperties(this.design.id, nextStage.id)
        .catch(handleApiError('Failed to get missing design properties'));
      const designReadiness = new DesignReadiness({
        missingPropertiesResponse
      });
      this.hasMissingProperties = designReadiness.hasMissingProperties();
      designReadiness.createNotifications();
    }
  }

  private hideEmptyPvModulePositions(): void {
    const pvModulePositions: PvModulePosition[] = this.editor.getObjectsByType(SceneObjectType.PvModulePosition, true);
    pvModulePositions.forEach((pvModulePosition: PvModulePosition): void => pvModulePosition.hideIfEmpty());
  }

  private addCommandExecutionListener(): void {
    this.serviceBus.addEventListener('commandExecuted', this.onCommandExecuted);
  }

  private removeCommandExecutionListener(): void {
    this.serviceBus.removeEventListener('commandExecuted', this.onCommandExecuted);
  }

  private onCommandExecuted = async (event: CommandExecutedEvent): Promise<void> => {
    if (event.commandName === 'delete_string_command') {
      const selectedString = StringingService.activeSelectedString;
      const selectedParallelStrings = StringingService.selectedStrings.toJS();
      const selectedStringIsNew = StringingService.selectedStringIsNew;

      if (selectedString) {
        StringingService.deleteSelectedString();

        this.cicruitModificationQueue.queue(async (): Promise<void> => {
          try {
            // Delete other parallel strings from domain because they are already present there
            if (!selectedStringIsNew || selectedParallelStrings.length > 1) {
              await this.deleteCircuit(selectedString);
            }

            const systemSummaryPanelViewModel = this.panels.viewModel as SystemSummaryPanelViewModel;
            systemSummaryPanelViewModel?.deleteString?.(selectedString);
          } catch (error) {
            SentryException('Error deleting stringing', error);
          }
        });
      }
    }
    if (event.commandName === 'update_design_delta') {
      StringingService.syncPvModulesOrderInStringsWithDomain();
    }
  };

  private async showInverterSelectionModal(): Promise<void> {
    if (!this.inverterSelectionViewModel) {
      this.inverterSelectionViewModel = await this.createInverterSelectionViewModel();
    }
    await this.inverterSelectionViewModel.resumeInverterSelection();
    this.inverterSelectionViewModel.enableKeyboardEventListeners();
    this.modal.createModal('inverter_selection_modal', this.inverterSelectionViewModel);
  }

  private async createInverterSelectionViewModel(): Promise<InverterSelectionViewModel> {
    const designService = new DesignService();
    const design: IDesignData = this.design.toData();
    const inverterSearchParams: ISearchInverterParameters = await designService
      .getInverterSearchParameters(design)
      .catch(handleApiError('Failed to get inverter search params'));

    const dependencies = {
      domain: this.domain,
      modal: this.modal,
      designWorkspace: this.designWorkspace,
      serviceBus: this.serviceBus,
      editor: this.editor,
      searchInverterParameters: inverterSearchParams
    };

    return new InverterSelectionViewModel(dependencies);
  }

  private destroyOpenedPanel(): void {
    if (this.isMpptAndMultiInverterSelectionPanelOpen()) {
      StringingService.resetMpptAndMultiInverterSelectionPanelFlags();
    }
    this.panels.destroyPanel();
  }

  private changeToolsTo(toolWhitelist: string[]): void {
    this.toolbar.blacklistTools(ElectricalDesignStage.toolBlacklist);
    this.toolbar.whitelistTools(toolWhitelist);
  }

  private async loadStringingOptions(): Promise<StringingOption[]> {
    const designService = new DesignService();
    const design: IDesignData = this.design.toData();

    const inverters = this.domain.design.system.equipment.inverters;

    const isMicroinverterSystem = inverters?.areMicroinverters;
    const isAcPvModuleSystem = !inverters;
    if (isMicroinverterSystem || isAcPvModuleSystem) {
      const response: StringingOption = await designService
        .getStringingOptions(design)
        .catch(handleApiError('Failed to get stringing options'));
      return [response];
    }

    const stringInverterIds = [inverters.firstStringInverterId, inverters.secondStringInverterId];
    const newStringingOptionsList: StringingOption[] = [];
    for (const stringInverterId of stringInverterIds) {
      if (!stringInverterId) {
        continue;
      }
      const response: StringingOption = await designService
        .getStringingOptions(design, stringInverterId)
        .catch(handleApiError('Failed to get stringing options'));
      newStringingOptionsList.push(response);
      this.fillDataToInverters(response, stringInverterId);
    }
    return newStringingOptionsList;
  }

  private fillDataToInverters(response: StringingOption, inverterId: string): void {
    const responseData = response.stringingOptions
      ? (response as IDcOptimizerStringOptionsData)
      : (response as ISeriesStringConfigurationOptionsData);
    const updatedSupplementalData = stringingOptionsToSupplementalData({
      supplementalData: this.design.supplementalData.copy(),
      data: responseData
    });
    const updateDesignDependencies: IUpdateDesignSupplementalDataDependencies = {
      domain: this.domain,
      updatedSupplementalData
    };
    this.serviceBus.send('update_design_supplemental_data', updateDesignDependencies);
  }

  private async addCircuit(selectedString: Stringing): Promise<void> {
    const designService = new DesignService();
    const design: IDesignData = this.design.toData();
    const pvModules = selectedString.getModules().map((module: PvModule): string => module.serverId);
    const inverterType = StringingService.getInverterType();
    const stringInverterWithDcOptimizers = inverterType === CENTRAL_MPPT_STRING && StringingService.useDcOptimizers;
    let response: DesignDelta;

    if (inverterType === BRANCH_MICROINVERTER || inverterType === BRANCH_AC_MODULE) {
      const acBranch: IAcBranch = {
        id: selectedString.serverId,
        pvModules
      };
      const request: IAcBranchCircuitRequest = {
        acBranch,
        design
      };
      response = await designService
        .addAcBranchCircuit(request)
        .catch(handleApiError('Failed to add AC branch circuit'));
      config.analytics?.trackEvent(new AcBranchCircuitAddedEvent(this.domain));
      const circuits = response.system?.circuits;
      if (circuits && inverterType === BRANCH_MICROINVERTER) {
        StringingService.updateMicroinvertersTotal(circuits);
      }
    } else if (inverterType === STRING_WITH_DC_OPTIMIZERS || stringInverterWithDcOptimizers) {
      const circuitSpecification: ICircuitSpecification = {
        id: selectedString.serverId,
        pvModules
      };
      const request: IDcSourceCircuitRequest = {
        ...(stringInverterWithDcOptimizers
          ? { mpptId: selectedString.getMpptId() }
          : { inverterId: selectedString.getInverterId() }),
        circuitSpecification,
        design
      };
      response = await designService
        .addDcSourceCircuit(request)
        .catch(handleApiError('Failed to add DC source circuit'));
      config.analytics?.trackEvent(new DcSourceCircuitAddedEvent(this.domain));
    } else {
      const request: IPvSourceCircuitRequest = {
        mpptId: selectedString.getMpptId(),
        pvSources: [pvModules] as PvSourceCircuit,
        design
      };
      response = await designService
        .addPvSourceCircuit(request)
        .catch(handleApiError('Failed to add PV source circuit'));
      config.analytics?.trackEvent(new PvSourceCircuitAddedEvent(this.domain));
    }
    this.serviceBus.send('update_design_delta', response.toApplyDesignDeltaCommand(this.domain));
  }

  private async deleteCircuit(selectedString: Stringing): Promise<void> {
    const designService = new DesignService();
    const design: IDesignData = this.design.toData();
    const inverterType = StringingService.getInverterType();
    const stringInverterWithDcOptimizers = inverterType === CENTRAL_MPPT_STRING && StringingService.useDcOptimizers;

    if (!design.system.circuits) {
      return Promise.resolve();
    }

    let response: DesignDelta;
    if (inverterType === BRANCH_MICROINVERTER || inverterType === BRANCH_AC_MODULE) {
      const request: IAcBranchCircuitRequest = {
        acBranchId: selectedString.serverId,
        design
      };
      response = await designService
        .deleteAcBranchCircuit(request)
        .catch(handleApiError('Failed to remove AC branch circuit'));
      config.analytics?.trackEvent(new AcBranchCircuitDeletedEvent(this.domain));
      const circuits = response.system?.circuits;
      if (circuits && inverterType === BRANCH_MICROINVERTER) {
        StringingService.updateMicroinvertersTotal(circuits);
      }
    } else if (stringInverterWithDcOptimizers) {
      const request: IPvSourceCircuitRequest = {
        mpptId: selectedString.getMpptId(),
        design
      };
      response = await designService
        .deleteDcSourceCircuits(request)
        .catch(handleApiError('Failed to remove DC source circuits'));
      config.analytics?.trackEvent(new DcSourceCircuitsDeletedEvent(this.domain));
    } else if (inverterType === STRING_WITH_DC_OPTIMIZERS) {
      const request: IDcSourceCircuitRequest = {
        circuitId: selectedString.serverId,
        design
      };
      response = await designService
        .deleteDcSourceCircuit(request)
        .catch(handleApiError('Failed to remove DC source circuit'));
      config.analytics?.trackEvent(new DcSourceCircuitDeletedEvent(this.domain));
    } else {
      const request: IPvSourceCircuitRequest = {
        mpptId: selectedString.getMpptId(),
        design
      };
      response = await designService
        .deletePvSourceCircuit(request)
        .catch(handleApiError('Failed to remove PV source circuit'));
      config.analytics?.trackEvent(new PvSourceCircuitDeletedEvent(this.domain));
    }
    this.serviceBus.send('update_design_delta', response.toApplyDesignDeltaCommand(this.domain));
  }

  private async updateCircuit(selectedString: Stringing, otherStringsInParallel?: Stringing[]): Promise<void> {
    const designService = new DesignService();
    const design: IDesignData = this.design.toData();

    const pvModules = selectedString.getModules().map((module: PvModule): string => module.serverId);
    const inverterType = StringingService.getInverterType();
    const stringInverterWithDcOptimizers = inverterType === CENTRAL_MPPT_STRING && StringingService.useDcOptimizers;

    let response: DesignDelta;
    if (inverterType === BRANCH_MICROINVERTER || inverterType === BRANCH_AC_MODULE) {
      const acBranch: IAcBranch = {
        id: selectedString.serverId,
        pvModules
      };
      const request: IAcBranchCircuitRequest = {
        acBranch,
        design
      };
      response = await designService
        .updateAcBranchCircuit(request)
        .catch(handleApiError('Failed to update AC branch circuit'));
      config.analytics?.trackEvent(new AcBranchCircuitUpdatedEvent(this.domain));
      const circuits = response.system?.circuits;
      if (circuits && inverterType === BRANCH_MICROINVERTER) {
        StringingService.updateMicroinvertersTotal(circuits);
      }
    } else if (stringInverterWithDcOptimizers) {
      const sortedStrings = selectSortedParallelStrings({
        strings: [selectedString, ...(otherStringsInParallel ?? [])],
        targetString: selectedString
      });
      const pvSources = sortedStrings.map((string: Stringing): string[] =>
        string.getModules().map((module: PvModule): string => module.serverId)
      ) as PvSourceCircuit;

      const request: IDcSourceCircuitsRequest = {
        mpptId: selectedString.getMpptId(),
        circuitSpecifications: pvSources.map(
          (pvModules: string[]): ICircuitSpecification => ({
            id: MathUtils.generateUUID(),
            pvModules
          })
        ),
        design
      };

      response = await designService
        .updateDcSourceCircuits(request)
        .catch(handleApiError('Failed to update DC source circuits'));

      config.analytics?.trackEvent(new DcSourceCircuitUpdatedEvent(this.domain));
    } else if (inverterType === STRING_WITH_DC_OPTIMIZERS) {
      const circuitSpecification: ICircuitSpecification = {
        id: selectedString.serverId,
        pvModules
      };
      const request: IDcSourceCircuitRequest = {
        circuitSpecification,
        design
      };

      response = await designService
        .updateDcSourceCircuit(request)
        .catch(handleApiError('Failed to update DC source circuit'));

      config.analytics?.trackEvent(new DcSourceCircuitUpdatedEvent(this.domain));
    } else {
      const sortedStrings = selectSortedParallelStrings({
        strings: [selectedString, ...(otherStringsInParallel ?? [])],
        targetString: selectedString
      });
      const pvSources = sortedStrings.map((string: Stringing): string[] =>
        string.getModules().map((module: PvModule): string => module.serverId)
      ) as PvSourceCircuit;

      const request: IPvSourceCircuitRequest = {
        mpptId: selectedString.getMpptId(),
        pvSources,
        design
      };
      response = await designService
        .updatePvSourceCircuit(request)
        .catch(handleApiError('Failed to update PV source circuit'));
      config.analytics?.trackEvent(new PvSourceCircuitUpdatedEvent(this.domain));
    }
    this.serviceBus.send('update_design_delta', response.toApplyDesignDeltaCommand(this.domain));
  }

  private setDesignState(): void {
    const dependencies = this.design.state.withUserState(this.id).toUpdateStateCommand(this.domain);
    this.serviceBus.send('update_design_state', dependencies);
  }
}
