import {
  LyraButtons, LyraLayout
} from '@aurorasolar/lyra-ui-kit';
import { EButtonType } from '@aurorasolar/lyra-ui-kit/lib/components/Buttons/EButtonType';
import { EThemeComponentColor } from '@aurorasolar/lyra-ui-kit/lib/components/Theme/EThemeComponentColor';
import { observer } from 'mobx-react-lite';
import type { ReactElement } from 'react';
import * as React from 'react';
import type { BaseRoofFaceViewModel } from '../../../stores/UiStore/Properties/RoofProperties/BaseRoofFaceViewModel';
import {
  AcceptOptionWrapper, CancelOptiontWrapper
} from './styles';

type Props = {
  viewModel: BaseRoofFaceViewModel;
};

const SubmitSection = observer((props: Props): ReactElement => {
  const { viewModel } = props;

  React.useEffect((): (() => void) => {
    const handleKeydown = (event: KeyboardEvent): void => {
      if (event.key === 'Escape') {
        viewModel.cancel();
      }
      if (!viewModel.savePropertyButtonDisabled && event.key === 'Enter') {
        viewModel.saveProperty();
      }
    };

    window.addEventListener('keydown', handleKeydown);
    return (): void => {
      window.removeEventListener('keydown', handleKeydown);
    };
  }, [viewModel.savePropertyButtonDisabled, viewModel, viewModel.saveProperty, viewModel.cancel]);

  return (
    <LyraLayout.Container className="pt-xs">
      <LyraLayout.Row>
        <CancelOptiontWrapper xs={12} className="pv-xs">
          <LyraButtons.Buttons
            styleType="flat"
            colorTheme={EThemeComponentColor.TRANSPARENT}
            fontColorTheme={EThemeComponentColor.AQUAMARINE}
            isLeft
            onClick={(): void => {
              viewModel.cancel();
            }}
          >
            <b>Cancel</b>
          </LyraButtons.Buttons>
        </CancelOptiontWrapper>
      </LyraLayout.Row>

      <LyraLayout.Row>
        <AcceptOptionWrapper xs={12} className="pv-xs">
          <LyraButtons.ButtonIcon
            icon="arrow"
            type={EButtonType.ROUNDED_BUTTON}
            colorTheme={EThemeComponentColor.AQUAMARINE}
            withIconTheme
            disabled={viewModel.savePropertyButtonDisabled}
            onClick={(): void => {
              viewModel.saveProperty();
            }}
          >
            <b>OK</b>
          </LyraButtons.ButtonIcon>
        </AcceptOptionWrapper>
      </LyraLayout.Row>
    </LyraLayout.Container>
  );
});

export default SubmitSection;
