import styled, { PropsTheme } from 'styled-components';
import { Flexbox } from '../Layout/Flexbox';
import { Color, IDefaultTheme } from '../../typings';
import { EThemeComponentColor } from '../Theme/EThemeComponentColor';

export type OptionProps = {
  selected?: boolean;
  disabled?: boolean;
  selectedOptionColor?: EThemeComponentColor | undefined;
};

type ButtonWrapperProps = {
  flexGrow?: number;
  height?: string;
  disabled?: boolean;
};

const getSelectedColor = (theme: IDefaultTheme, selectedOptionColor: EThemeComponentColor | undefined): Color => {
  return selectedOptionColor === EThemeComponentColor.PURPLE ? theme.colors.mainColor2 : theme.colors.mainColor1;
};

const updateBorderStyle = ({ theme, selected, selectedOptionColor}: PropsTheme<OptionProps>): Color => {
  return selected
    ? getSelectedColor(theme, selectedOptionColor)
    : theme.colors.grey4;
};

export const Button = styled(Flexbox).attrs({
    justify: 'center',
    align: 'center'
  })<PropsTheme<OptionProps>>`
    border: 2px solid ${(props : PropsTheme<OptionProps>): Color => updateBorderStyle(props)};
    height: 100%;
    border-radius: 4px;
    box-shadow: ${({ selected, disabled }: PropsTheme<OptionProps>): string => selected ? '0 2px 4px 0 rgba(0, 0, 0, 0.4)' : 'none'};
    padding: 0 8px;
    max-width: calc(100% - 16px);
  &:hover {
    border: ${({ theme, selectedOptionColor, disabled }: PropsTheme<OptionProps>): Color => (
      disabled ? '' : `solid 2px ${getSelectedColor(theme, selectedOptionColor)}`
    )};
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.4);
  }
`;

export const ButtonWrapper = styled.div<ButtonWrapperProps>`
  cursor: ${({ disabled }: ButtonWrapperProps): string => disabled ? 'not-allowed' : 'pointer'};
  color: ${({ disabled, theme }: PropsTheme<ButtonWrapperProps>): Color => disabled ? theme.colors.grayColor3 : 'inherit'};
  flex-grow: ${({ flexGrow = 1 }: ButtonWrapperProps): number => flexGrow};
  height: ${({ height = '100%' }: ButtonWrapperProps): string => height};
  margin: 0 2px;
  padding: 2px;
  font-size: 14px;
  & > p {
    font-size: 10px;
    margin: 5px 0;
    text-align: center;
  }
`;
