/* eslint-disable react-hooks/exhaustive-deps */
import { observer } from 'mobx-react-lite';
import type {
  FC, EffectCallback, ReactElement
} from 'react';
import React, {
  useEffect, useRef
} from 'react';
import styled from 'styled-components';
import Vector2D from '../../../domain/models/Vector2D';
import useStore from '../../../stores/useStore';

const ContainerPanel = styled.div`
  height: 100%;
  overflow: hidden;
  width: 100%;
  background-color: #f2f2f2;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  & > canvas {
    flex: 0 1 auto;
    position: absolute;
    min-width: 100%;
    min-height: 100%;
  }
`;

const DrawingViewport: FC = (): ReactElement => {
  const { editor } = useStore();
  const containerRef = useRef({} as HTMLDivElement);

  const resizeWindow = (): void => {
    editor.onWindowResize(new Vector2D(containerRef.current.offsetWidth, containerRef.current.offsetHeight));
  };

  useEffect((): ReturnType<EffectCallback> => {
    window.addEventListener('resize', resizeWindow);
    return (): void => window.removeEventListener('resize', resizeWindow);
  }, []);

  useEffect((): void => {
    editor.setup(
      new Vector2D(containerRef.current.offsetWidth, containerRef.current.offsetHeight),
      containerRef.current
    );
  }, [
    // Editor should be constant throughout whole app lifecycle though.
    // Reinitialization of EditorStore is not supported.
    editor
  ]);

  return <ContainerPanel ref={containerRef} id="lyra-canvas-container" />;
};

export default observer(DrawingViewport);
