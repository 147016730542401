import { observer } from 'mobx-react-lite';
import type { ReactElement } from 'react';
import * as React from 'react';
import useStore from '../../../stores/useStore';
import { panelRegistry } from './registry';

const Panel = observer((): ReactElement => {
  const { panels } = useStore();
  // Panel to be rendered
  const PanelUI = panels.viewModel && panelRegistry.get(panels.viewModel.propCodeUI);

  return <>{PanelUI && <PanelUI />}</>;
});

export default Panel;
