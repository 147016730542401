import React, { BaseProps, ReactElement, useCallback } from 'react';
import Dropzone, { Accept, DropzoneRootProps, FileError, FileRejection } from 'react-dropzone';
import { EThemeComponentColor } from '../Theme/EThemeComponentColor';
import { Buttons } from '../../components/Buttons';
import { Divider } from '../../components/Divider';
import { Loader } from '../../components/Loader';
import { EButtonSize } from '../../components/Buttons/EButtonSize';
import {
  DropzoneWrapper,
  SupportedFormatString,
  CheckIcon,
  ButtonFont,
  BrowseButton,
  DropzoneOuterContainer,
  DropzoneWrapperWithOutPreview,
  DropzoneContainerWithOutPreview,
  LoaderContainer
} from './styles';

export type DropzoneProps = BaseProps & {
  accept?: Accept;
  multiple?: boolean;
  maxSize?: number;
  canSave?: boolean;
  fileName?: string;
  supportedFormat?: string;
  noClick?: boolean;
  noKeyboard?: boolean;
  withFileNamePreview?: boolean;
  showLoader?: boolean;
  onClick?: boolean;
  saveFiles?: (files: File[]) => void;
  onError?: (error: FileError) => void;
};

const DropzoneContainer = (props: DropzoneProps): ReactElement => {
  const {
    accept,
    multiple = false,
    maxSize,
    onError,
    canSave,
    noClick = false,
    noKeyboard,
    fileName,
    supportedFormat,
    withFileNamePreview = true,
    onClick,
    saveFiles
  } = props;

  const onFileDrop = useCallback(
    (acceptedFiles: File[], rejectedFiles: FileRejection[]): void => {
      if (rejectedFiles.length && onError) {
        onError(rejectedFiles[0].errors[0]);
      }
      if (acceptedFiles.length && saveFiles) {
        saveFiles([acceptedFiles[0]]);
      }
    },
    [saveFiles]
  );

  return (
    <Dropzone
      multiple={multiple}
      accept={accept}
      maxSize={maxSize}
      onDrop={onFileDrop}
      noClick={noClick}
      noKeyboard={noKeyboard}
    >
      {(dropParam: DropzoneRootProps): ReactElement => (
        <>
          {withFileNamePreview ? (
            <DropzoneOuterContainer {...dropParam.getRootProps()}>
              <div>
                <input {...dropParam.getInputProps()} />
              </div>
              <DropzoneWrapper>
                {canSave ? (
                  <>
                    <CheckIcon name={'icon-pp-purchased-purple'}/>
                    <b>{fileName}</b>
                  </>
                ) : (
                  <>
                    <b>Drag & Drop file</b>
                    <SupportedFormatString>
                      Supported format: <b>{supportedFormat}</b>
                    </SupportedFormatString>
                  </>
                )}
              </DropzoneWrapper>
              <Buttons
                styleType="secondary"
                colorTheme={EThemeComponentColor.WHITE}
                fontColorTheme={EThemeComponentColor.BLACK}
                size={EButtonSize.SMALL}
              >
                <ButtonFont onClick={onClick ?? dropParam.open}>or Browse file</ButtonFont>
              </Buttons>
            </DropzoneOuterContainer>
          ) : (
            <DropzoneWrapperWithOutPreview>
              <DropzoneContainerWithOutPreview  {...dropParam.getRootProps()}>
                <input {...dropParam.getInputProps()} />
                <span>Drag & Drop file</span>
                <Divider title="or" length="90%" color={EThemeComponentColor.GRAY_600}/>
                <BrowseButton onClick={...dropParam.open}>Browse file</BrowseButton>
              </DropzoneContainerWithOutPreview>
              <LoaderContainer>
                <Loader size={50} text="Uploading..." />
              </LoaderContainer>
            </DropzoneWrapperWithOutPreview>
          )}
        </>
      )}
    </Dropzone>
  );
};

export { DropzoneContainer };
