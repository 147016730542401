import {
  LyraButtonGroup, LyraFormElementsDesignTool
} from '@aurorasolar/lyra-ui-kit';
import { observer } from 'mobx-react-lite';
import type { ReactElement } from 'react';
import React, {
  useCallback, useState
} from 'react';
import useStore from '../../../../../stores/useStore';
import type { PermitPackageViewModel } from '../../../../../stores/UiStore/Modal/ViewModels/PermitPackageModal/PermitPackageViewModel';
import {
  OptionContainer,
  OptionView,
  PrimaryText,
  RadioView,
  RadioWrapper,
  SecondaryText,
  StatusText
} from '../styles';
import type { IPermitPackageOptionsData } from '../../../../../domain/models/DocumentGenerationOptions/PermitPackage/PermitPackageOptions';

const { ButtonGroup } = LyraButtonGroup;
const TextField = LyraFormElementsDesignTool.FormElementsDesignTool.TextField.TextField;
const Toggle = LyraFormElementsDesignTool.FormElementsDesignTool.Toggle.Toggle;

const watermarkOptions = [
  {
    name: 'None',
    value: 'NONE'
  },
  {
    name: 'NFC',
    value: 'NOT_FOR_CONSTRUCTION'
  },
  {
    name: 'FQO',
    value: 'FOR_QUOTING_ONLY'
  },
  {
    name: 'Draft',
    value: 'DRAFT_PRINT'
  },
  {
    name: 'Prel.',
    value: 'PRELIMINARY'
  }
];

const Options = (): ReactElement => {
  const { modal } = useStore();
  const permitPackageViewModel = modal.viewModel as PermitPackageViewModel;
  const [optionsData] = useState<IPermitPackageOptionsData>(permitPackageViewModel.documentGenerationOptions.options);
  const [sourceCardFiles, setSourceCardFiles] = useState(optionsData.includeSourceCadFiles);

  const handleToggleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>): void => {
      permitPackageViewModel.onHandleChangeIncludeSourceCadFiles(event.target.checked);
      setSourceCardFiles(event.target.checked);
    },
    [permitPackageViewModel]
  );

  return (
    <OptionContainer>
      <OptionView>
        <PrimaryText>Project Reference ID</PrimaryText>
        <TextField disabled={true} value={optionsData.projectReferenceId} height="44px" fontSize="18px" />
      </OptionView>

      <OptionView data-testid="reviewer-full-name-container">
        <PrimaryText>Reviewer Full Name</PrimaryText>
        <TextField
          value={optionsData.reviewerFullName}
          height="44px"
          fontSize="18px"
          onChange={permitPackageViewModel.onChangeReviewerName}
        />
      </OptionView>

      <OptionView>
        <PrimaryText>Watermark</PrimaryText>
        <ButtonGroup
          options={watermarkOptions}
          disabled={permitPackageViewModel.areWatermarkOptionsDisabled}
          onSelect={permitPackageViewModel.onHandleChangeWatermark}
          selected={optionsData.watermark}
          height="36px"
        />
      </OptionView>

      <OptionView>
        <SecondaryText>Include DWG (electrical diagram only at this time)</SecondaryText>
        <RadioView>
          <RadioWrapper>
            <StatusText status={!sourceCardFiles}>No</StatusText>
            <Toggle
              className="toggleBig"
              id="sourceCardFiles"
              checked={sourceCardFiles}
              onChange={handleToggleChange}
            />
            <StatusText status={sourceCardFiles}>Yes</StatusText>
          </RadioWrapper>
        </RadioView>
      </OptionView>
    </OptionContainer>
  );
};

export default observer(Options);
