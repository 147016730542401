import type { Color } from '@aurorasolar/lyra-ui-kit';
import {
  LyraFontFamily, LyraIcon
} from '@aurorasolar/lyra-ui-kit';
import styled from 'styled-components';
import type { PropsWithTheme } from '../../../components/Button/styles';

const { Notes } = LyraFontFamily.FontFamily;

type Props = PropsWithTheme & {
  bordered?: boolean;
  black?: boolean;
  color?: string;
  tipContainer?: boolean;
};

export const SummaryPanelContainer = styled.div`
  width: 290px;
  height: 81%;
  border-radius: 3px;
  border: solid 1px ${({ theme }: PropsWithTheme): Color => theme.colors.gray2};
  background-color: ${({ theme }: PropsWithTheme): Color => theme.colors.newGray2};
  position: absolute;
  top: 72px;
  right: 5px;
  padding: 10px;
  z-index: 2;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  scrollbar-width: none;
  & .showtip {
    display: none;
  }
  & .hidetip + div {
    position: relative;
    bottom: -15px;
    width: 100%;
  }
`;

export const SummaryContentWrapper = styled.div`
  height: auto;
  overflow-y: scroll;
  overflow-x: hidden;
  width: 100%;
  scrollbar-width: none;
`;

export const ContainerWrapperInitial = styled.div<Props>`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  & .showhide {
    display: ${({ tipContainer }: Props): string => (tipContainer ? 'block' : 'none')};
  }
`;

export const PanelContent = styled.div`
  display: block;
`;

export const PanelHeader = styled.div<Props>`
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  border-radius: 4px;
  background-color: ${({
    theme, black
  }: Props): Color => (black ? theme.colors.gray1 : theme.colors.primary)};
  cursor: pointer;
  margin-bottom: 5px;
  padding: 5px 0;
`;

export const PanelText = styled.span`
  font-size: 12px;
  font-weight: bold;
  line-height: 1;
  color: ${({ theme }: PropsWithTheme): Color => theme.colors.highlight};
  text-indent: 12px;
`;

export const RightArrow = styled(LyraIcon.Icon)`
  position: absolute;
  right: 10px;
  top: 4px;
  width: 13.1px;
  height: 13.1px;
  transform: rotate(-90deg);
  path:first-child {
    fill: ${({ theme }: PropsWithTheme): Color => theme.colors.highlight};
  }
`;

export const DownArrow = styled(LyraIcon.Icon)`
  position: absolute;
  right: 10px;
  top: 4px;
  width: 13.1px;
  height: 13.1px;
  path:first-child {
    fill: ${({ theme }: PropsWithTheme): Color => theme.colors.highlight};
  }
`;

export const ContentItem = styled.div`
  border-radius: 4px;
  border: solid 1px ${({ theme }: PropsWithTheme): Color => theme.colors.newGray3};
  background-color: ${({ theme }: PropsWithTheme): Color => theme.colors.highlight};
  padding: 0 12px;
  margin-top: 5px;
  margin-bottom: 5px;
  width: 100%;
  box-sizing: border-box;
`;

export const ContentText = styled(Notes)`
  line-height: 1.19;
`;

export const DeleteButtonWrapper = styled.button`
  width: 46px;
  height: 22px;
  border-radius: 16px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.5);
  background-color: #fff;
  cursor: pointer;
`;

export const DeleteIcon = styled(LyraIcon.Icon)`
  width: 12px;
  height: 12px;
  > path {
    fill: #4a4a4a;
  }
`;

export const ProgressItemContainer = styled.div`
  margin-top: 8px;
  border-bottom: solid 1px ${({ theme }: PropsWithTheme): Color => theme.colors.tertiary};
`;

export const ValueItemWrapper = styled.div<Props>`
  display: flex;
  min-height: 35px;
  align-items: center;
  border-bottom: ${({
    bordered, theme
  }: Props): string => (bordered ? `1px solid ${theme.colors.tertiary}` : '')};
  &:last-child {
    border-bottom: none;
  }
`;

export const WrapperItemText = styled.div`
  width: 70%;
  display: flex;
  flex-direction: row;
  height: 100%;
  align-items: center;
`;

export const WrapperValueText = styled.div`
  width: 30%;
  display: flex;
  flex-direction: row;
  height: 100%;
  align-items: center;
  justify-content: flex-end;
`;

export const ItemText = styled(ContentText)`
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const ValueText = styled.span`
  font-size: 12px;
  font-weight: bold;
  text-align: right;
  color: #4a4a4a;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const UnitText = styled.span`
  font-weight: bold;
  font-size: 9px;
  text-indent: 2px;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 3px;
`;

export const DcOptimizerWrapper = styled.div`
  display: flex;
  padding-bottom: 20px;
  border-bottom: solid 1px ${({ theme }: PropsWithTheme): Color => theme.colors.tertiary};
  &:last-child {
    border-bottom: none;
  }
`;

export const DcOptimizerItem = styled.div`
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
`;

export const TextWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
`;

export const DcOptimizerIcon = styled(LyraIcon.Icon)``;

export const DcOptimizerText = styled(Notes)`
  line-height: 1.9;
  margin-left: 5px;
`;

export const ISCWrapper = styled.div`
  display: flex;
  padding-bottom: 10px;
`;

export const ProgressWrapper = styled.div`
  width: 80%;
  padding-top: 25px;
`;

export const WrapperLinearProgress = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
  border-bottom: 1px solid ${({ theme }: PropsWithTheme): Color => theme.colors.newGray1};
  padding: 25px 0 15px;
`;

export const WrapperLinear = styled.div`
  width: 90%;
  padding: 0 15px;
`;

export const WrapperLinearText = styled.div`
  width: 10%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  font-family: 'Roboto', sans-serif;
  font-size: 9px;
  line-height: 0.97;
  letter-spacing: normal;
  color: ${({ theme }: PropsWithTheme): Color => theme.colors.gray1};
`;

export const LicenseText = styled.div`
  font-size: 11px;
  font-style: italic;
  position: relative;
  top: 30px;
`;
