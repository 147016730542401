import { observable } from 'mobx';
import config from '../../../../config/config';

export type Watermark =
  | 'NONE'
  | 'NOT_FOR_CONSTRUCTION'
  | 'FOR_QUOTING_ONLY'
  | 'DRAFT_PRINT'
  | 'PRELIMINARY'
  | 'FREE_TRIAL_DEMO';

export interface IPermitPackageOptionsData {
  readonly projectReferenceId: string;
  readonly reviewerFullName: string;
  readonly creatorFirstName: string;
  readonly creatorLastName: string;
  readonly watermark: Watermark;
  readonly includeSourceCadFiles: boolean;
}

export default class PermitPackageOptions {
  @observable
  projectReferenceId: string;

  @observable
  reviewerFullName: string;

  @observable
  creatorFirstName: string;

  @observable
  creatorLastName: string;

  @observable
  watermark: Watermark;

  @observable
  includeSourceCadFiles: boolean;

  constructor(data: IPermitPackageOptionsData, internalReferenceId: string) {
    this.projectReferenceId = internalReferenceId;
    this.reviewerFullName = data.reviewerFullName;
    this.creatorFirstName = config.user.firstName;
    this.creatorLastName = config.user.lastName;
    this.watermark = data.watermark;
    this.includeSourceCadFiles = data.includeSourceCadFiles;
  }

  toData(): IPermitPackageOptionsData {
    return {
      projectReferenceId: this.projectReferenceId,
      reviewerFullName: this.reviewerFullName,
      creatorFirstName: this.creatorFirstName,
      creatorLastName: this.creatorLastName,
      watermark: this.watermark,
      includeSourceCadFiles: this.includeSourceCadFiles
    };
  }
}
