import { LyraModal } from '@aurorasolar/lyra-ui-kit';
import { observer } from 'mobx-react-lite';
import type { ReactElement } from 'react';
import React from 'react';
import type {
  CircuitTableViewModel,
  OptionType,
  RowProps,
  TableHeaderType,
  VoltageDropSummary
} from '../../../../stores/UiStore/Modal/ViewModels/CircuitTable/CircuitTableViewModel';
import { useUiStore } from '../../../../stores/useStore';
import './custom.css';
import { isEmpty } from '../../../../utils/helpers';

import {
  DataInput,
  DataSelect,
  VoltageDropView,
  HeaderItem,
  ModalWrapper,
  PlainText,
  RowItem,
  SeparatorLine,
  SummaryHeader,
  SummaryText,
  TableHeaderWrapper,
  TableRowWrapper,
  TableWrapper,
  Table,
  VoltageDropText
} from './styles';

const CircuitTableModal = observer((): ReactElement => {
  const { modal } = useUiStore();
  const circuitTableViewModel = modal.viewModel as CircuitTableViewModel;

  const {
    circuitData, tableData, headerItems, optionChange, inputChange, errors, disableAllFields
  } =
    circuitTableViewModel;

  return (
    <LyraModal.Modal
      title="Circuit Table"
      leftButtonLabel="Back to Electrical BOS"
      rightButtonLabel="Proceed to Mounting BOS"
      rightButtonDisabled={!isEmpty(errors) || disableAllFields}
      $show={true}
      showFooterDivider={false}
      width="100%"
      onClickCloseButton={(): void => circuitTableViewModel.backToElectricalBosStage()}
      onClickLeftButton={(): void => circuitTableViewModel.backToElectricalBosStage()}
      onClickRightButton={(): void => circuitTableViewModel.processToMountingBosStage()}
    >
      <ModalWrapper>
        <SummaryHeader>
          <SummaryText>Voltage Drop Summary</SummaryText>
        </SummaryHeader>
        <SeparatorLine className="mt-xs" />
        {circuitData.circuitVoltageDrops.map(
          (circuitVoltageDrop: VoltageDropSummary): ReactElement => (
            <VoltageDropView className={circuitVoltageDrop.class ?? 'default'} key={circuitVoltageDrop.id}>
              <VoltageDropText>{circuitVoltageDrop.title}</VoltageDropText>
              <VoltageDropText>{circuitVoltageDrop.value}</VoltageDropText>
            </VoltageDropView>
          )
        )}
        <SeparatorLine className="mt-s" />
        <SummaryHeader>
          <SummaryText>Circuit Connections</SummaryText>
        </SummaryHeader>
        <TableWrapper>
          <Table>
            <TableHeaderWrapper>
              {headerItems?.map(
                (item: TableHeaderType, index: number): ReactElement => (
                  <HeaderItem key={index} align={item.align}>
                    {item.id === 'number' ? 'No.' : item.title}
                  </HeaderItem>
                )
              )}
            </TableHeaderWrapper>
            {tableData.map(
              (data: RowProps, index: number): ReactElement => (
                <TableRowWrapper key={index}>
                  {headerItems.map((header: TableHeaderType, columnIndex: number): ReactElement => {
                    const id: keyof typeof data = header.id;
                    const propertyKeys = data[id]?.id.split('.') ?? [];
                    const rowHasFieldInColumn = !!propertyKeys[1];
                    const values: OptionType[] = data[id]?.options ?? [];
                    const hasOptionsProperty: boolean = !!data[id]?.options;
                    const hasOneOrNoOptions: boolean = hasOptionsProperty && data[id]!.options!.length <= 1;
                    const isReadOnlyField: boolean =
                      (!hasOptionsProperty && data[id]?.readOnly) || !rowHasFieldInColumn || hasOneOrNoOptions;
                    const isInputField = !hasOptionsProperty;
                    return (
                      <React.Fragment key={columnIndex}>
                        <RowItem>
                          {/* eslint-disable-next-line no-nested-ternary */}
                          {isReadOnlyField ? (
                            <PlainText
                              data-testid={`${index}.${data[id]?.id}`}
                              className={rowHasFieldInColumn && data[id]?.class ? data[id]?.class : 'default'}
                              align={header.align}
                            >
                              {rowHasFieldInColumn ? values[0]?.name || data[id]?.value : 'N/A'}
                            </PlainText>
                          ) : isInputField ? (
                            <DataInput
                              data-testid={`${index}.${data[id]?.id}`}
                              type="number"
                              defaultValue={data[id]?.value}
                              value={data[id]?.value}
                              className={data[id]?.class || 'default'}
                              onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
                                inputChange(event.target.value, index, columnIndex, propertyKeys);
                              }}
                              error={!!errors[index]?.[columnIndex]}
                              disabled={disableAllFields}
                            />
                          ) : (
                            <DataSelect
                              data-testid={`${index}.${data[id]?.id}`}
                              value={data[id]?.value}
                              className={data[id]?.class || 'default'}
                              onChange={(event: React.ChangeEvent<HTMLSelectElement>): void => {
                                optionChange(event, propertyKeys);
                              }}
                              disabled={disableAllFields}
                            >
                              {values?.map(
                                (dropdown: OptionType, optionIndex: number): ReactElement => (
                                  <option value={dropdown.value} key={optionIndex}>
                                    {dropdown.name || dropdown.value}
                                  </option>
                                )
                              )}
                            </DataSelect>
                          )}
                        </RowItem>
                      </React.Fragment>
                    );
                  })}
                </TableRowWrapper>
              )
            )}
          </Table>
        </TableWrapper>
      </ModalWrapper>
    </LyraModal.Modal>
  );
});

export default CircuitTableModal;
