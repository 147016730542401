import { observable } from 'mobx';

export enum EDesignFileType {
  SITE_PLAN = 'SITE_PLAN',
  ELECTRICAL_PLAN = 'ELECTRICAL_PLAN',
  SAFETY_LABELS = 'LABELS',
  ATTACHMENT_PLAN = 'ATTACHMENT_PLAN',
  ATTACHMENT_DETAIL = 'ATTACHMENT_DETAILS',
  FIRE_SAFETY_PLAN = 'FIRE_SAFETY_PLAN',
  CUSTOM_CONTENT = 'CUSTOM_CONTENT',
  APPENDIX_CUSTOM_CONTENT = 'APPENDIX_CUSTOM_CONTENT'
}

interface IFileTypeOption {
  name: string;
  value: EDesignFileType;
}

export interface IDesignFileData {
  readonly id: string;
  readonly title: string;
  readonly type?: EDesignFileType;
  readonly role?: string;
}

export interface ISiteImageData {
  readonly id: string;
  selected: boolean;
}

export interface IPermitPackageFilesData {
  readonly siteImages: ISiteImageData[];
  readonly designFiles: IDesignFileData[];
  readonly customParcelMap: boolean;
}

export default class PermitPackageFiles {
  static readonly fileTypeOptions: IFileTypeOption[] = [
    {
      name: 'Site Plan',
      value: EDesignFileType.SITE_PLAN
    },
    {
      name: 'Electrical Plan',
      value: EDesignFileType.ELECTRICAL_PLAN
    },
    {
      name: 'Safety Labels',
      value: EDesignFileType.SAFETY_LABELS
    },
    {
      name: 'Attachment Plan',
      value: EDesignFileType.ATTACHMENT_PLAN
    },
    {
      name: 'Attachment Detail',
      value: EDesignFileType.ATTACHMENT_DETAIL
    },
    {
      name: 'Fire Safety Plan',
      value: EDesignFileType.FIRE_SAFETY_PLAN
    },
    {
      name: 'Custom Sheet',
      value: EDesignFileType.CUSTOM_CONTENT
    },
    {
      name: 'Appendix',
      value: EDesignFileType.APPENDIX_CUSTOM_CONTENT
    }
  ];

  @observable
  siteImages: ISiteImageData[];

  @observable
  designFiles: IDesignFileData[];

  @observable
  customParcelMap: boolean;

  private matchingDeprecatedData(
    role: string
  ): EDesignFileType.APPENDIX_CUSTOM_CONTENT | EDesignFileType.CUSTOM_CONTENT {
    return role == 'CUSTOM_SHEET' ? EDesignFileType.CUSTOM_CONTENT : EDesignFileType.APPENDIX_CUSTOM_CONTENT;
  }

  private mapDeprecatedData(files: IPermitPackageFilesData): IDesignFileData[] {
    return files.designFiles.map(
      (fileType: IDesignFileData): IDesignFileData => ({
        id: fileType.id,
        title: fileType.title,
        type: fileType.type ?? this.matchingDeprecatedData(fileType.role!)
      })
    );
  }

  constructor(data: IPermitPackageFilesData) {
    this.siteImages = [...data.siteImages];
    this.designFiles = [...this.mapDeprecatedData(data)];
    this.customParcelMap = data.customParcelMap;
  }

  toData(): IPermitPackageFilesData {
    return {
      siteImages: [...this.siteImages],
      designFiles: [...this.designFiles],
      customParcelMap: this.customParcelMap
    };
  }
}
