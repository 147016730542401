import styled from 'styled-components';

export const SnappingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SnappingWidth = styled.div`
  margin: 0 auto;
  width: 800px;
`;
