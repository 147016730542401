import React, { BaseProps, ReactElement, useState } from 'react';
import { PipelineProps } from '../../typings';
import { Button } from '../Buttons/Button';
import { HorizontalPipeline } from '../HorizontalPipeline';
import { EThemeComponentColor } from '../Theme/EThemeComponentColor';
import { SnappingContainer } from './styles';

type Props = BaseProps & PipelineProps & {
  onClickLeft: () => Promise<void>;
  onClickRight: () => Promise<void>;
};

const PipelineSnapping = (props: Props): ReactElement => {
  const {
    currentStep = 0,
    data,
    onClickLeft,
    onClickRight
  } = props;
  const finalStep = data.length - 1;
  const [leftButtonInExecution, setLeftButtonInExecution] = useState(false);
  const [rightButtonInExecution, setRightButtonInExecution] = useState(false);

  const handleOnClickLeft = (): void => {
    setLeftButtonInExecution(true);
    onClickLeft()
        .finally((): void => {
          setLeftButtonInExecution(false);
        });
  };

  const handleOnClickRight = (): void => {
    setRightButtonInExecution(true);
    onClickRight()
        .finally((): void => {
          setRightButtonInExecution(false);
        });
  };

  return (
    <SnappingContainer>
      {currentStep > 0 &&
        <Button
          styleType="flat"
          isLeft
          fontColorTheme={EThemeComponentColor.AQUAMARINE}
          colorTheme={EThemeComponentColor.TRANSPARENT}
          onClick={handleOnClickLeft}
          disabled={leftButtonInExecution}
        >
          {`Back to ${data[currentStep - 1].title}`}
        </Button>
      }
      <HorizontalPipeline
        currentStep={currentStep}
        data={data}
      />
      {currentStep < finalStep &&
        <Button
          styleType="flat"
          onClick={handleOnClickRight}
          disabled={rightButtonInExecution}
        >
          {data[currentStep + 1].title}
        </Button>
      }
    </SnappingContainer>
  );
};

export { PipelineSnapping };
