import styled from 'styled-components';

export const RowLayout = styled.div<{ align?: string }>`
  display: flex;
  flex-direction: row;
  align-items: ${({ align }: { align?: string }): string => (align ? align : 'center')};
  width: 100%;
`;

export const ColumnLayout = styled.div<{ width: string }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: ${({ width }: { width: string }): string => (width ? width : '100%')};
  height: 100%;
  & > label {
    font-family: 'Roboto', sans-serif;
    font-size: 10px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #4a4a4a;
    margin-bottom: 7px;
  }
`;

export const Wrapper = styled.span<{ padding?: string }>`
  padding-left: ${({ padding }: { padding?: string }): string => (padding ? padding : 'unset')};
`;

export const AzimuthContainer = styled.div`
  width: 100%;
  height: 100%;
`;
