import { LyraFormGenerator } from '@aurorasolar/lyra-form-generator/dist';
import {
  LyraButtons, LyraDivider, LyraLoader
} from '@aurorasolar/lyra-ui-kit';
import { EButtonType } from '@aurorasolar/lyra-ui-kit/lib/components/Buttons/EButtonType';
import { EThemeComponentColor } from '@aurorasolar/lyra-ui-kit/lib/components/Theme/EThemeComponentColor';
import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import type { ReactElement } from 'react';
import React, { useContext } from 'react';
import { ButtonStyleType } from '@aurorasolar/lyra-ui-kit/lib/components/Buttons/styles';
import { ThemeContext } from 'styled-components';
import { DesignStep } from '../../../domain/models/Design/DesignState';
import type { IStage } from '../../../domain/stages/IStage';
import type { MountingBosStage } from '../../../domain/stages/DesignStages/MountingBosStage';
import { stageIs } from '../../../domain/stages/DesignStages/utils';
import { useUiStore } from '../../../stores/useStore';
import PropertyPanelHeader from '../PropertyPanel/PropertyPanelHeader/PropertyPanelHeader';
import {
  NextStepWrapper, PropertyPanelContainer, SubmitSectionWrapper
} from '../PropertyPanel/styles';
import {
  AuroraFormGeneratorContainer,
  ContentContainer,
  FormDataContainer,
  LyraFormGeneratorContainer
} from '../ElectricalBos/styles';
import config, { UI_MODE } from '../../../config/config';
import '../SharedStyles/FormGenerator.css';
import {
  BackButtonsContainer, FormInfoContainer, FormInfoHeader, FormInfoText, FormInfoTextBold
} from './styles';

export const MOUNTING_BOS_FORM_ID: string = 'mounting-bos-form';

function isMountingBosStage(stage: IStage | undefined): boolean {
  if (stage) {
    return stageIs<MountingBosStage>(stage, DesignStep.MOUNTING_BOS);
  }
  return false;
}

type Props = {
  mountingBosStage: MountingBosStage;
};

const LoaderElement = (): ReactElement => {
  const DS = useContext(ThemeContext)!.DS;

  return config.featureFlag.uiMode === UI_MODE.AURORA ? <DS.Loader /> : <LyraLoader.Loader text="Loading..." />;
};

function LyraFormGeneratorContent(props: Props): ReactElement {
  const { mountingBosStage } = props;

  return !mountingBosStage.hasFormFields ? (
    <FormInfoContainer>
      <FormInfoHeader>WHAT'S NEXT?</FormInfoHeader>
      <LyraDivider.Divider direction="horizontal" color={EThemeComponentColor.GRAY_200} />
      <FormInfoText>There are no mounting options available for the current configuration.</FormInfoText>
      <FormInfoTextBold>Proceed directly to the permit package by clicking the button below.</FormInfoTextBold>
    </FormInfoContainer>
  ) : (
    <LyraFormGenerator
      formId={MOUNTING_BOS_FORM_ID}
      formData={toJS(mountingBosStage.formSpecification?.data)}
      apiSchema={toJS(mountingBosStage.formSpecification?.form)}
      apiRules={toJS(mountingBosStage.formSpecification?.rules)}
      onSubmit={mountingBosStage.onSubmitForm}
      showSubmitButton={false}
      auroraMode={config.featureFlag.uiMode === UI_MODE.AURORA}
    />
  );
}

const MountingBosContainer = observer((props: Props): ReactElement => {
  const { mountingBosStage } = props;

  const FormGeneratorContainer =
    config.featureFlag.uiMode !== UI_MODE.AURORA ? LyraFormGeneratorContainer : AuroraFormGeneratorContainer;

  return (
    <ContentContainer>
      <FormGeneratorContainer>
        {mountingBosStage.formSpecificationLoaded ? (
          <LyraFormGeneratorContent mountingBosStage={mountingBosStage} />
        ) : (
          <LoaderElement />
        )}
      </FormGeneratorContainer>
    </ContentContainer>
  );
});

export const MountingBos = observer((): ReactElement => {
  const { workspace } = useUiStore();
  const stage = workspace?.currentWorkspace?.stageManager?.currentStage;
  const mountingBosStage = isMountingBosStage(stage) ? (stage as MountingBosStage) : undefined;

  if (!mountingBosStage) {
    return <></>;
  }

  const previewButtonProps = mountingBosStage.hasFormFields
    ? { submitForm: MOUNTING_BOS_FORM_ID }
    : {
      onClick: (): void => {
        mountingBosStage.openPermitPackageModalOrPage();
      }
    };

  return (
    <FormDataContainer>
      <PropertyPanelContainer>
        <PropertyPanelHeader icon="tool-properties" name={mountingBosStage.title} iconBg="#427ecd" />
      </PropertyPanelContainer>
      <MountingBosContainer mountingBosStage={mountingBosStage} />
      {config.featureFlag.uiMode !== UI_MODE.AURORA && (
        <BackButtonsContainer>
          <SubmitSectionWrapper>
            <NextStepWrapper xs={12} className="pv-xs">
              <LyraButtons.Buttons
                className="back-button"
                styleType={ButtonStyleType.FLAT}
                colorTheme={EThemeComponentColor.TRANSPARENT}
                fontColorTheme={EThemeComponentColor.AQUAMARINE}
                isLeft
                onClick={(): void => {
                  workspace.currentWorkspace.stageManager?.previous();
                }}
              >
                <b>Back</b>
              </LyraButtons.Buttons>
              <LyraButtons.ButtonIcon
                icon="arrow"
                disabled={!mountingBosStage.formSpecificationLoaded}
                type={EButtonType.ROUNDED_BUTTON}
                colorTheme={EThemeComponentColor.AQUAMARINE}
                withIconTheme
                {...previewButtonProps}
              >
                <b>Preview Permit Package</b>
              </LyraButtons.ButtonIcon>
            </NextStepWrapper>
          </SubmitSectionWrapper>
        </BackButtonsContainer>
      )}
    </FormDataContainer>
  );
});
