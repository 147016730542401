import type { PropsTheme } from 'styled-components';
import styled from 'styled-components';
import type { Color } from '../../../domain/typings';
import { CollapseStyle } from '../../components/Collapse/styles';
import { PADDING_CARD } from '../../components/Layout/SolarLayout/constants';

type ButtonProps = {
  onClick?: (event: MouseEvent) => void;
};

export const RoofPropertyButtonWrapper = styled.div`
  padding: ${PADDING_CARD};
`;
export const RoofPropertyButton = styled.button<PropsTheme<ButtonProps>>`
  width: 100%;
  min-height: 30px;
  border-radius: 16px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.5);
  cursor: pointer;
  position: relative;
  background-color: ${({ theme }: PropsTheme<ButtonProps>): Color => theme.colors.whiteBg};

  svg {
    position: absolute;
    left: 15px;
    margin: auto;
    top: 0;
    bottom: 0;
  }
`;

export const RoofPropertyHeaderRow = styled.div`
  display: flex;
  align-items: baseline;
`;

export const BasicPropsContainer = styled.div`
  width: 100%;

  & > ${CollapseStyle} {
    position: relative;

    & > div:first-child {
      background: none;
      padding: 0;
      font-family: 'Roboto', sans-serif;
      font-weight: bold;
      font-size: 14px;
      color: ${({ theme }: PropsTheme): Color => theme.colors.grey1};
      margin-bottom: 15px;

      & > span {
        font-size: 14px;
        font-weight: bold;
      }

      & svg {
        position: absolute;
        top: 0;
        right: 0;
        margin-right: 0;
      }
    }
  }
`;

export const BasicPropsBox = styled.div`
  border-radius: 4px;
  width: 100%;
  background-color: ${({ theme }: PropsTheme): Color => theme.colors.whiteBg};
  color: ${({ theme }: PropsTheme): Color => theme.colors.secondaryFont};
  .box {
    height: 68px;
    .text-box {
      width: 130px;
      .title-box {
        font-size: 10px;
        margin-bottom: 3px;
      }
      .name-box {
        font-size: 15px;
        font-weight: bold;
      }
    }
  }
`;

export const RoofIconWrapper = styled.div`
  width: 40px;
`;

export const RoofTypeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 0 10px;
  border: 1px solid ${({ theme }: PropsTheme): Color => theme.colors.grey4};
`;
